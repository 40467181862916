import styled from '@emotion/styled';
import ReactModalAdapter from 'src/components/ReactModalAdapter';

const contentStyle = `
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  max-height: calc(100% - 10%);
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  border-radius: 12px;
  background: white;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 24px;
  z-index: 9;
`;

const fixedOverlayPosition = `
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

export const StyledModal = styled(ReactModalAdapter)`
  .ReactModal__Overlay {
    ${fixedOverlayPosition}
    background-color: ${(props) => props.theme.colors.black}4d;
    z-index: 9;
  }
  .ReactModal__Content {
    ${contentStyle}
  }
` as React.ComponentType<ReactModal.Props>;

export const WideWidthStyleModal = styled(StyledModal)`
  z-index: 99;
  overflow-y: unset !important;
  .ReactModal__Content {
    width: 50%;
    min-width: 500px;
    max-width: 650px;
    overflow-y: auto !important;
  }
` as React.ComponentType<ReactModal.Props>;

export const ScrollStyleModal = styled(StyledModal)`
  z-index: 99;
  .ReactModal__Content {
    width: 50%;
    min-width: 500px;
    max-width: 650px;
  }
` as React.ComponentType<ReactModal.Props>;

export const Layout = styled.div`
  height: 100%;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 28px;
  height: 25px;
  line-height: 38px;
  font-family: Nunito !important;
  font-style: normal;
  height: 100%;
  color: ${(props) => props.theme.colors.redPink};
  div {
    margin-left: auto;
  }
`;

export const CloseBtn = styled.div`
  z-index: 1;
  cursor: pointer;
  position: relative;
  width: 22px;
  height: 22px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    top: 2px;
    left: 10px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const ScrollLayout = styled.div`
  overflow-y: auto;
  padding-right: 20px;
`;
