import React, {Component} from 'react';
import * as R from 'ramda';
import {AuthPayload, Chat} from 'src/types';
import styled from '@emotion/styled';
import getChatTitle from 'src/utils/messengerHelper/getChatTitle';
import ProfilePic from 'src/components/ProfilePic';
import {ApolloClient} from 'apollo-client';
import EditChatTitleMutation from 'src/gql/mutation/EditChatTitleMutation';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import {ApolloConsumer} from 'react-apollo';
import Input from 'src/styles/styled-components/Input';
import {TextOnlyButton} from 'src/styles/styled-components/Button';
import {AuthContext} from 'src/auth';
import {toast} from 'react-toastify';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {UPLOAD_CHAT_PICTURE} from 'src/constants/fileUpload';
import ChangeImageModal from 'src/components/ChangeImageModal';

const Layout = styled.div`
  flex: 1;
  font-family: Nunito;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  min-height: 100px;
  max-height: 130px;
  padding: 1em;
`;

const TitleLayout = styled.div<WrapperProps>`
  padding: 0 5px;
  border: 1px solid transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    border: ${(props) =>
      props.isGroupChat ? `1px dashed ${props.theme.colors.slightlyGrey}` : '1px solid transparent'};
  }
  input {
    &:focus {
      border-bottom: 1px solid ${(props) => props.theme.colors.slightlyGrey};
    }
  }
`;

const TitleText = styled.div`
  min-width: 0;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Role = styled.div`
  color: ${(props) => props.theme.colors.slightlyGrey};
  font-size: 13px;
  text-align: left;
  line-height: 1.5em;
`;

const EditableTitle = styled.div`
  display: flex;
  align-items: center;
`;

const ChatImageWrapper = styled.div<WrapperProps>`
  cursor: ${(props) => (props.isGroupChat ? `pointer` : `unset`)};
`;

interface WrapperProps {
  isGroupChat: boolean;
}

interface Props {
  client: ApolloClient<any>;
  authInfo: AuthPayload;
  chat: Chat;
}

interface State {
  isEditing: boolean;
  title: string;
}

class ChatInformationTitle extends Component<Props> {
  public state = {
    isEditing: false,
    title: '',
    showChangeChatPictureModal: false,
  };

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const switchedToEditing = this.state.isEditing && !prevState.isEditing;
    const titleChanged = prevProps.chat.title !== this.props.chat.title;
    if (titleChanged || switchedToEditing) {
      this.setState({
        title: this.props.chat.title || '',
      });
    }
  }

  public render() {
    const {authInfo, chat} = this.props;
    const {isEditing, title, showChangeChatPictureModal} = this.state;

    const isGroupChat = chat.type === 'group';
    const {members} = chat;

    let titleComponent = (
      <TitleText title={'Change chat title'} onClick={this.toggleEditable}>
        {getChatTitle(chat)}
      </TitleText>
    );
    if (members.length <= 2) {
      const notMeMember = chat.members.find((member) => member.id !== authInfo.user.id);
      titleComponent = (
        <React.Fragment>
          <TitleText>{getChatTitle(chat)}</TitleText>
          <Role>{notMeMember && notMeMember.role}</Role>
        </React.Fragment>
      );
    } else if (isEditing) {
      titleComponent = (
        <ClickAwayListener
          onClickAway={() => {
            this.setState({isEditing: false});
          }}
        >
          <EditableTitle>
            <Input
              id="chatTitleInput"
              hasUnderline={false}
              value={title}
              autoFocus
              onKeyUp={this.onKeyUp}
              onChange={this.onChange}
            />
            <TextOnlyButton onClick={this.changeChatTitle} fontWeight="400">
              Done
            </TextOnlyButton>
          </EditableTitle>
        </ClickAwayListener>
      );
    }

    return (
      <Layout>
        <ChatImageWrapper
          isGroupChat={isGroupChat}
          title={isGroupChat ? 'Set photo for chat' : undefined}
          onClick={(e) => {
            isGroupChat ? this.onProfilePicClicked() : e.preventDefault();
          }}
        >
          <ProfilePic
            users={chat.members}
            chatImageUrl={chat.image ? chat.image.url : undefined}
            status={chat.status}
          />
        </ChatImageWrapper>

        <TitleLayout isGroupChat={isGroupChat}>{titleComponent}</TitleLayout>

        {showChangeChatPictureModal && (
          <ChangeImageModal
            uploadType={UPLOAD_CHAT_PICTURE}
            uploadPayload={{chatId: chat.id}}
            closeModal={() => this.setState({showChangeChatPictureModal: false})}
            isOpen={showChangeChatPictureModal}
          />
        )}
      </Layout>
    );
  }

  private onProfilePicClicked = () => {
    this.setState({
      showChangeChatPictureModal: true,
    });
  };

  private onChange = (e) => {
    const value = e.target.value;
    this.setState({
      title: value,
    });
  };

  private onKeyUp = (e) => {
    const key = e.key;
    const {title} = this.state;
    if (key === 'Enter' && title.length > 0) {
      this.changeChatTitle();
    } else if (key === 'Escape') {
      // ESC
      this.setState({isEditing: false});
    }
  };

  private toggleEditable = () => this.setState({isEditing: true});

  private changeChatTitle = async () => {
    const {chat, client} = this.props;
    const {title} = this.state;
    if (title.trim() === '') {
      this.setState({
        isEditing: false,
      });
      return;
    }
    const chatId = chat.id;
    try {
      await client.mutate({
        mutation: EditChatTitleMutation,
        variables: {
          chatId,
          title,
        },
        update: (store, {data}) => {
          const chatQuery = store.readQuery({
            query: GetChatQuery,
            variables: {chatId},
          });

          if (chatQuery) {
            const newChatTitle = R.pathOr(title, ['chat', 'editTitle', 'title'])(data);
            const newChatQuery = R.evolve({
              chat: {
                title: R.always(newChatTitle),
              },
            })(chatQuery);

            store.writeQuery({
              query: GetChatQuery,
              variables: {chatId},
              data: newChatQuery,
            });
          }
        },
      });
      toast.success('Title changed succesfully');

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.changeChatTitle,
        params: {
          chat_id: chatId,
        },
      });
    } catch (e) {
      toast.error('Failed to change the title');
      console.error('Failed to change the title', e);
    }
    this.setState({
      isEditing: false,
    });
  };
}

export default (props) => (
  <AuthContext.Consumer>
    {({authInfo}) => (
      <ApolloConsumer>
        {(client) => <ChatInformationTitle {...props} authInfo={authInfo} client={client} />}
      </ApolloConsumer>
    )}
  </AuthContext.Consumer>
);
