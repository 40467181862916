import * as React from 'react';
import transformResponseToViewModel from 'src/utils/scheduling/transformResponseToViewModel';
import {QueryResult} from 'react-apollo';
import {FetchRolesAndShiftsResult, NewFetchRolesAndShiftsResult, ScheduleViewModelData} from 'src/types';

interface Props {
  fetchRolesAndShiftsResult: QueryResult;
  preventSplit: boolean;
  currentDepartmentId: number;
}

const useScheduleViewModelData = ({fetchRolesAndShiftsResult, preventSplit, currentDepartmentId}: Props) => {
  const {loading, error, variables, data} = fetchRolesAndShiftsResult;

  const viewModelScheduleData: ScheduleViewModelData | null = React.useMemo(() => {
    if (loading || error || !data || !data.locating || !variables) {
      return null;
    }
    let startDate = variables.startDate || variables.shiftStartDate;
    let endDate = variables.endDate || variables.shiftEndDate;

    const roles = data.locating.department?.roles || data.locating.department?.schedule?.roles;
    const schedule = data.locating.department?.schedule;
    const scheduleData = transformResponseToViewModel(
      {
        roles,
        startDate,
        endDate,
        scheduleInfo: {
          state: schedule ? schedule.state : undefined,
          id: schedule ? schedule.id : undefined,
        },
      },
      preventSplit,
    );

    return scheduleData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDepartmentId, variables, data]);

  return viewModelScheduleData;
};

export default useScheduleViewModelData;
