import React from 'react';
import {Assignee, Role, AssigneeWithRole, ShiftRequestType} from 'src/types';
import {
  RoleHolderDetailTime,
  RoleHolderDetailTitle,
  RoleHolderDetail,
  RoleHolderWrapper,
} from 'src/pages/SchedulingPage/scheduling/FullCalendarGridSystem';
import ProfilePic from 'src/components/ProfilePic';
import hexToRgba from 'src/utils/scheduling/hexToRgba';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import SwapShiftIcon from 'src/svgs/SwapShiftIcon';
import MarketplaceIcon from 'src/svgs/MarketplaceIcon';
import {SCHEDULING} from 'src/constants/routerPathName';
import {AppDispatch} from 'src/redux';
import {useDispatch} from 'react-redux';
import {Actions} from 'src/redux/reducers';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {sortStartTimeTypeofAssignee} from 'src/utils/scheduling/sortAssignee';
import OfferMarketplaceModal from 'src/pages/SchedulingPage/schedule-layout/OfferMarketplaceModal';
import {MenuItem, StyledMenuPaper, StyledPopper} from 'src/pages/SchedulingPage/scheduling/SharedPopperStyles';

interface Props {
  assignees: Assignee[];
  role: Role;
  isInteractable: boolean;
  userToColorCodes: {
    [userId: string]: string;
  };
}

const SchedulingShiftAssignees = ({assignees, userToColorCodes, role, isInteractable}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [targetShift, setTargetShift] = React.useState<Assignee | null>(null);
  const [showOfferModal, setOfferModal] = React.useState(false);

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;

  const dispatch: AppDispatch = useDispatch();

  const handleHolderClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (!isInteractable) return;
    setAnchorEl(event.currentTarget);
    setTargetShift(sortedAssignees[index]);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    // @ts-ignore
    if (anchorEl && anchorEl.contains(event.target)) return;
    setAnchorEl(null);
  };

  const handleClick = (type: ShiftRequestType) => {
    if (type === 'swap') onSwapShift();
    if (type === 'give_away') onOfferMarketplace();
  };

  const onSwapShift = () => {
    const assignee = targetShift;

    const assigneeWithRole = {
      assignee,
      role,
    } as AssigneeWithRole;

    dispatch({
      type: selfID === assignee?.userId ? 'tradeShift/SET_SELF' : 'tradeShift/SET_OTHER',
      payload: {shift: assigneeWithRole},
    } as Actions);

    window.routerHistory.push(`/${SCHEDULING}/swap`);
  };

  const onOfferMarketplace = () => {
    setAnchorEl(null);
    setOfferModal(true);
  };

  const sortedAssignees = assignees.sort(sortStartTimeTypeofAssignee);

  return (
    <React.Fragment>
      {sortedAssignees.map((shift, index) => {
        const {shiftId} = shift;
        const assignedColor = userToColorCodes[shift.userId];
        const assignedColorBackground = hexToRgba(userToColorCodes[shift.userId], 0.1);

        return (
          <RoleHolderWrapper
            key={`${shiftId}-${index}`}
            assignedColorBackground={assignedColorBackground}
            assignedColor={assignedColor}
            onClick={(e) => handleHolderClick(e, index)}
          >
            <ProfilePic size={30} users={[shift]} />
            <RoleHolderDetail>
              <RoleHolderDetailTitle>{selfID === shift.userId ? 'ME' : shift.userFullName}</RoleHolderDetailTitle>

              <RoleHolderDetailTime>
                {`${shift.startTime.format('HH:mm')} - ${shift.endTime.format('HH:mm')}`}
              </RoleHolderDetailTime>
            </RoleHolderDetail>
          </RoleHolderWrapper>
        );
      })}

      <StyledPopper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom-start'} transition>
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
            <Fade {...TransitionProps} timeout={350}>
              <StyledMenuPaper>
                <MenuItem onClick={() => handleClick('swap')}>
                  <SwapShiftIcon />{' '}
                  <span>{targetShift && targetShift.userId === selfID ? 'Swap Shift' : 'Request to swap'}</span>
                </MenuItem>
                {targetShift && targetShift.userId === selfID && (
                  <MenuItem onClick={() => handleClick('give_away')}>
                    <MarketplaceIcon /> <span>Offer on marketplace</span>
                  </MenuItem>
                )}
              </StyledMenuPaper>
            </Fade>
          </ClickAwayListener>
        )}
      </StyledPopper>

      {showOfferModal && (
        <OfferMarketplaceModal
          isOpen={showOfferModal}
          closeModal={() => setOfferModal(false)}
          selfShift={
            {
              assignee: targetShift,
              role,
            } as AssigneeWithRole
          }
          userToColorCodes={userToColorCodes}
        />
      )}
    </React.Fragment>
  );
};

export default SchedulingShiftAssignees;
