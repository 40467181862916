import React from 'react';
import {DELETE_SELF_PROFILE_NOTE} from 'src/gql/v2/mutation/DeleteSelfProfileNoteMutation';
import {CREATE_SELF_PROFILE_NOTE} from 'src/gql/v2/mutation/CreateSelfProfileNoteMutation';
import {EditNotesFormValues} from 'src/types/HiddenNotes';

import client from 'src/apollo';
import {UPDATE_SELF_PROFILE_NOTE} from 'src/gql/v2/mutation/UpdateSelfProfileNoteMutation';
interface HiddenNotesRepositoryProps {
  deleteSelfProfileNote: (noteId: string) => Promise<{error: boolean | null; result: any}>;
  createSelfProfileNote: (noteId: string, access: string) => Promise<{error: boolean | null; result: any}>;
  updateSelfProfileNote: (values: EditNotesFormValues) => Promise<{error: boolean | null; result: any}>;
}

export default (): HiddenNotesRepositoryProps => {
  const deleteSelfProfileNote = async (noteId: string) => {
    try {
      const result = await client.mutate({
        mutation: DELETE_SELF_PROFILE_NOTE,
        variables: {
          id: noteId,
        },
      });

      return Promise.resolve({error: null, result});
    } catch (err: any) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  const createSelfProfileNote = async (description: string, access: string) => {
    try {
      const result = await client.mutate({
        mutation: CREATE_SELF_PROFILE_NOTE,
        variables: {
          details: {
            note: description,
            access,
          },
        },
      });

      return Promise.resolve({error: null, result});
    } catch (err: any) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  const updateSelfProfileNote = async ({description, access, noteId}: EditNotesFormValues) => {
    try {
      const result = await client.mutate({
        mutation: UPDATE_SELF_PROFILE_NOTE,
        variables: {
          id: noteId,
          details: {
            note: description.trim(),
            access,
          },
        },
      });

      return Promise.resolve({error: null, result});
    } catch (err: any) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  return {
    deleteSelfProfileNote,
    createSelfProfileNote,
    updateSelfProfileNote,
  };
};
