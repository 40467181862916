import React, {useState} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {toast} from 'react-toastify';
import {Box, Typography} from '@mui/material';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

import NewNextStepButton from '../components/NextStepButton';
import NewVerificationCodeInput from '../components/NewVerificationCodeInput';
import styled from '@emotion/styled';
import {TOO_MANY_CHALLENGES} from 'src/constants/networkError';
import SignupPage from 'src/microfrontend/signup/SignupPage';
import {StepContainerCopy} from '../components/step-container-copy';
import NewStepHeader from '../components/step-header-copy';
import NewStepFooter from '../components/step-footer-copy';

const PREFIX = 'HypercareSignupVerifyEmailAddressView';

const classes = {
  verificationError: `${PREFIX}-verificationError`,
  verificationWrapper: `${PREFIX}-verificationWrapper`,
};

const StyledStepContainerCopy = styled(StepContainerCopy)(({theme}) => ({
  [`& .${classes.verificationError}`]: {
    marginTop: 16,
    color: theme.palette.error.main,
  },

  [`& .${classes.verificationWrapper}`]: {
    marginLeft: 4,
  },
}));

const validationSchema = yup.object().shape<IVerifyEmailStepOutputData>({
  code: yup
    .string()
    .required('Verification code is required')
    .test('empty', 'Verification code is required', (value: string) => !!value && value.replace(/ /g, '').length > 0)
    .test(
      'len',
      'Verification code should be 6 digit',
      (value: string) => !!value && value.replace(/ /g, '').length === 6,
    ),
});

interface IVerifyEmailStepInputData {
  email: string;
}

export interface IVerifyEmailStepOutputData {
  code: string;
}

export const HypercareSignupVerifyEmailAddressView = ({
  inputData,
  onBackButtonClick,
  viewModel,
  setChallengeId,
  challengeId,
}) => {
  const [resending, setResending] = useState(false);

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupVerifyAddress);
  }, []);

  const handleResend = async (email) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signUpResendVerifyCode,
      params: {
        address: inputData.email,
      },
    });

    try {
      setResending(true);
      const requestVerificationResult = await viewModel.resendEmailValidationCode(inputData.email);
      if (requestVerificationResult?.data?.response) {
        setChallengeId(requestVerificationResult?.data?.response.challengeId);
        setResending(false);
        toast.success(`Successfully resent verification code`);
      } else {
        if (requestVerificationResult?.data?.errors && requestVerificationResult?.data?.errors[0]) {
          let errorCode = requestVerificationResult?.data?.errors[0].name;
          if (errorCode === TOO_MANY_CHALLENGES) {
            setResending(false);
            toast.error(requestVerificationResult?.data?.errors[0].message);
          } else {
            setResending(false);
            toast.error('Unknown error occurred, please check your internet connection and try again');
          }
        } else {
          toast.error('Failed to resend verification code, please check your internet connection and try again');
          setResending(false);
        }
      }
    } catch (err) {
      setResending(false);
    }
  };

  const onNextClick = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupVerifyEmailNextPressed,
      params: {
        address: inputData.email,
      },
    });
  };

  return (
    <StepContainerCopy currentStep={2}>
      <NewStepHeader
        isDisabled={false}
        title="Step 2 of 5: Verify your email"
        description={`Enter the 6-digit verification code sent to ${inputData.email}`}
        onBackButtonClick={onBackButtonClick}
      />
      <Formik
        initialValues={{code: ''}}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          validationSchema
            .validate(values)
            .then(async () => {
              setResending(true);

              const result = await viewModel.handleNextButtonPressed({
                challengeId,
                token: values.code,
              });

              if (result?.error) {
                setResending(false);
                actions.setFieldError('code', result.error?.message);
              }
            })
            .catch((e) => {
              actions.setFieldError('code', e?.errors[0]);
              actions.setSubmitting(false);
              setResending(false);
            });
        }}
      >
        {({handleSubmit, values, dirty, isValid, isSubmitting, setFieldValue, handleBlur, touched, errors}) => (
          <React.Fragment>
            <form onSubmit={handleSubmit}>
              <Box className={classes.verificationWrapper}>
                <NewVerificationCodeInput
                  length={6}
                  size="sm"
                  value={values.code}
                  disabled={isSubmitting || resending}
                  onChange={(value) => setFieldValue('code', value)}
                  handleResend={handleResend}
                  renderError={
                    touched.code &&
                    Boolean(errors.code) && (
                      <Typography variant="body2" className={classes.verificationError}>
                        {errors.code}
                      </Typography>
                    )
                  }
                />
              </Box>
              <NewNextStepButton
                label={'Next'}
                onClick={onNextClick}
                disabled={isSubmitting}
                loading={isSubmitting || resending}
                loadingLabel={`${resending ? 'Resending' : 'Verifying'} code...`}
              />
            </form>
          </React.Fragment>
        )}
      </Formik>
      <NewStepFooter />
    </StepContainerCopy>
  );
};
