import {VFC} from 'react';
import {HCFilterBaseProps} from '../base/HCFilterBase';
import React from 'react';
import styled from '@emotion/styled';
import CheckmarkIcon from 'src/svgs/CheckmarkIcon';

export interface HCFilterSimpleProps extends HCFilterBaseProps {
  options?: CategorizedHCFilterOption;
  optionRender?: (option: HCFilterOption) => React.ReactNode;
}

export interface HCFilterOption {
  id: string;
  label: string;
  username?: string;
  role?: string;
}

export interface CategorizedHCFilterOption {
  title?: string;
  options?: HCFilterOption[];
}

export const FilterItemContainer = styled.div`
  margin: 10px 0 5px 0;
  padding: 10px 8px 10px 8px;
  border-radius: 4px;
  display: flex;
  gap: 10px;

  :hover {
    background: #f6f6f9;
    cursor: pointer;
  }

  :hover span {
    border: 1px solid #80c2cc;
    border-radius: 4px;
  }
`;

export const CheckboxContainer = styled.div<{isSelected: boolean}>`
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 2px;
  margin: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px;
  background: ${(props) => (props.isSelected ? '#00859A' : 'white')};
  border: ${(props) => !props.isSelected && '1px solid #4A4A4A'};
`;

const HoverableCheckbox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  border: 1px solid white;
  width: 28px;
  height: 28px;
  justify-self: center;
  align-self: center;
  :hover {
    border: 1px solid #80c2cc;
    border-radius: 4px;
  }
`;

const Checkbox = ({isSelected}) => {
  return (
    <HoverableCheckbox>
      <CheckboxContainer isSelected={isSelected}>
        <CheckmarkIcon />
      </CheckboxContainer>
    </HoverableCheckbox>
  );
};

type FilterItemPropsType<T = Record<string, any>> = T & {
  onToggle?: (option: HCFilterOption) => void;
  optionRender?: (option: HCFilterOption) => React.ReactNode;
  isSelected?: boolean;
  option: HCFilterOption;
};

interface FilterItemProps extends FilterItemPropsType {}

export const SelectableFilterItem: VFC<FilterItemProps> = ({onToggle, isSelected, option, optionRender}) => {
  return (
    <FilterItemContainer onClick={() => onToggle?.(option)}>
      <Checkbox isSelected={isSelected} />
      <div style={{display: 'flex', alignItems: 'center'}}>{optionRender ? optionRender(option) : option.label}</div>
    </FilterItemContainer>
  );
};
