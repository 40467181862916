import React from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import {ActionButtonWrapper} from 'src/styles/styled-components/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {
  SelectionFlexContainer,
  SelectionLabel,
  StyledSelectionMenuItem,
  SelectionWrapper,
  StyledFormControl,
} from 'src/styles/styled-components/Selection';
import {ScrollLayout} from 'src/styles/styled-components/ModalStyleComponents';
import {toast} from 'react-toastify';
import StyledTooltip from 'src/components/StyledTooltip';
import InfoIcon from 'src/svgs/Info';
import {useQuery} from 'react-apollo';
import {StyledInputBase} from 'src/components/StepFormPartials';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Chevron from 'src/svgs/Chevron';
import {DepartmentScope, EscalationLadder, FetchEscalationLaddersByDepartmentResult, SiteScope} from 'src/types';
import FetchEscalationLaddersByDepartmentID from 'src/gql/query/FetchEscalationLaddersByDepartmentID';
import {MinimalSiteData} from 'src/gql/query/FetchSitesForOrganization';

const IntroText = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1.5em;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

const StyledTextAreaInput = styled(TextareaAutosize)`
  border-color: lightgrey;
  max-height: 450px;
  resize: none;
  margin-top: 0.5em;
  margin-bottom: 1em;
  width: 100%;
  font-size: 1em;
  border-radius: 5px;
  border-width: 1.5px;
  border-style: solid;
  border-image: initial;
  padding: 12px;
  outline: none;
  overflow: auto !important;
  &:focus {
    border-color: ${(props) => props.theme.colors.chatTeal};
  }
`;

const StyledInfo = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  overflow: visible;
`;

const StyledTooltipAlt = styled(StyledTooltip)`
  & .tooltip {
    max-width: 290px !important;
  }
`;

const ErrorMessageSection = styled.div`
  margin-bottom: 1em;
  color: ${(props) => props.theme.colors.redPink};
`;

const StyledChevron = styled(Chevron)`
  position: absolute;
  right: 4px;
`;

interface Props {
  selectedSite: MinimalSiteData | null;
  onSiteSelected: (site: MinimalSiteData | null) => void;
  siteSelectionList: MinimalSiteData[];
  selectedDepartment: DepartmentScope | null;
  onDepartmentSelected: (department: DepartmentScope | null) => void;
  message: string;
  onMessageSet: (message: string) => void;
  selectedEscalationLadder: EscalationLadder | null;
  onEscalationLadderSelected: (ladder: EscalationLadder | null) => void;
  handleNext: () => void;
  closeModal: () => void;
}

const DISABLED_PLACEHOLDER = 'disabledPlaceholder';

const LocatingEscalationSelectionStep = ({
  selectedSite,
  onSiteSelected,
  siteSelectionList,
  selectedDepartment,
  onDepartmentSelected,
  message,
  onMessageSet: setMessage,
  selectedEscalationLadder,
  onEscalationLadderSelected,
  handleNext,
  closeModal,
}: Props) => {
  const departmentIDRef = React.useRef(selectedDepartment ? selectedDepartment.id : null);

  const {
    loading: loadingForLadder,
    error: errorForLadder,
    data: escalationLadderData,
  } = useQuery<FetchEscalationLaddersByDepartmentResult>(FetchEscalationLaddersByDepartmentID, {
    variables: {
      departmentId: selectedDepartment && selectedDepartment.id,
    },
    skip: !selectedDepartment,
  });

  const fetchedEscalationLadderList: EscalationLadder[] = React.useMemo(() => {
    if (
      escalationLadderData &&
      escalationLadderData?.locating?.department &&
      escalationLadderData?.locating?.department.id === departmentIDRef.current
    ) {
      const sortedEscalationLadders = escalationLadderData.locating.department.escalationLadders.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );
      return sortedEscalationLadders;
    }
    return [];
  }, [escalationLadderData, departmentIDRef]);

  React.useEffect(() => {
    if (fetchedEscalationLadderList.length >= 1) {
      if (selectedEscalationLadder && fetchedEscalationLadderList[0].id === selectedEscalationLadder.id) return;
      onEscalationLadderSelected(selectedEscalationLadder || fetchedEscalationLadderList[0]);
    }
    if (fetchedEscalationLadderList.length === 0) {
      onEscalationLadderSelected(null);
    }
    return () => {};
  }, [fetchedEscalationLadderList, selectedEscalationLadder, selectedDepartment, onEscalationLadderSelected]);

  const departmentSelectionList: DepartmentScope[] = React.useMemo(() => {
    if (selectedSite) {
      // const sortedDepartments = selectedSite.departments.sort((a, b) =>
      //   a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      // );
      return [];
    }
    return [];
  }, [selectedSite]);

  let shouldSiteSelectionDisabled = siteSelectionList.length <= 1;

  let shouldDepartmentSelectionDisabled = !departmentSelectionList || departmentSelectionList.length <= 1;

  let shouldShowDepartmentPlaceholderOption = !departmentSelectionList || departmentSelectionList.length === 0;

  let shouldLadderSelectionDisabled =
    loadingForLadder || !fetchedEscalationLadderList || fetchedEscalationLadderList.length <= 1;

  let shouldLadderPlaceholderOption =
    loadingForLadder || !fetchedEscalationLadderList || fetchedEscalationLadderList.length === 0;

  let shouldShowSuggestionErrorMessage = departmentSelectionList && departmentSelectionList.length === 0;

  const generateLadderPlaceHolder = React.useCallback(() => {
    if (shouldShowSuggestionErrorMessage) return 'No escalation policies available';
    if (!selectedDepartment) return 'Please select a department first';
    if (loadingForLadder) return 'Loading ...';
    return 'No escalation policies available';
  }, [shouldShowSuggestionErrorMessage, selectedDepartment, loadingForLadder]);

  if (errorForLadder) {
    toast.error('Failed to get the escalation ladder request, please check your internet connection and try again');
  }

  const handleChangeSite = (event: SelectChangeEvent<any>) => {
    let targetId = Number(event.target.value);
    let result = siteSelectionList.find((site) => site.id === targetId);
    onEscalationLadderSelected(null);
    onSiteSelected(result ?? null);
    // onDepartmentSelected(result?.departments ? result.departments[0] : null);
    onDepartmentSelected(null);
    // departmentIDRef.current = result?.departments?.[0] ? result.departments[0].id : null;
    departmentIDRef.current = null;
  };

  const handleChangeDepartment = (event: SelectChangeEvent<any>) => {
    let targetId = Number(event.target.value);
    let result = departmentSelectionList.find((department) => department.id === targetId);
    onEscalationLadderSelected(null);
    onDepartmentSelected(result ?? null);
    departmentIDRef.current = result?.id || null;
  };

  const handleChangeEscalationLadder = (event: SelectChangeEvent<any>) => {
    let targetId = event.target.value;
    let result = fetchedEscalationLadderList.find((ladder) => ladder.id === targetId);
    onEscalationLadderSelected(result ?? null);
  };

  const handleChangeEscalationMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setMessage(inputValue);
  };

  return (
    <>
      <IntroText>
        A department escalation will automatically send priority messages to users based on department/organization
        escalation policies until there is an acknowledgement.
      </IntroText>

      <ScrollLayout>
        <SelectionWrapper>
          <SelectionFlexContainer>
            <SelectionLabel>Select a Site</SelectionLabel>
          </SelectionFlexContainer>
          <StyledFormControl
            fullWidth
            disabled={shouldSiteSelectionDisabled}
            $isDisabled={shouldSiteSelectionDisabled}
            $shouldHighlight={true}
          >
            <Select
              value={selectedSite ? selectedSite.id : siteSelectionList[0].id}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                style: {
                  maxHeight: '680px',
                },
              }}
              onChange={handleChangeSite}
              IconComponent={() => (shouldSiteSelectionDisabled ? null : <StyledChevron />)}
              input={<StyledInputBase />}
            >
              {siteSelectionList.map((site) => (
                <StyledSelectionMenuItem value={site.id} key={`site-${site.id}`}>
                  {site.name}
                </StyledSelectionMenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </SelectionWrapper>

        <SelectionWrapper>
          <SelectionFlexContainer>
            <SelectionLabel>Select a Department</SelectionLabel>
          </SelectionFlexContainer>
          <StyledFormControl
            fullWidth
            disabled={shouldDepartmentSelectionDisabled}
            $isDisabled={shouldDepartmentSelectionDisabled}
            $shouldHighlight={!!selectedDepartment}
          >
            <Select
              value={
                !!selectedDepartment
                  ? selectedDepartment.id
                  : departmentSelectionList[0]
                  ? departmentSelectionList[0].id
                  : DISABLED_PLACEHOLDER
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                style: {
                  maxHeight: '480px',
                },
              }}
              onChange={handleChangeDepartment}
              IconComponent={() => (shouldDepartmentSelectionDisabled ? null : <StyledChevron />)}
              input={<StyledInputBase />}
            >
              {shouldShowDepartmentPlaceholderOption ? (
                <option value={DISABLED_PLACEHOLDER} disabled>
                  No departments with escalation policies available
                </option>
              ) : (
                departmentSelectionList.map((department) => (
                  <StyledSelectionMenuItem value={department.id} key={`department-${department.id}`}>
                    {department.name}
                  </StyledSelectionMenuItem>
                ))
              )}
            </Select>
          </StyledFormControl>
        </SelectionWrapper>

        {shouldShowDepartmentPlaceholderOption && (
          <ErrorMessageSection>
            Please select another site or contact your Administrators to help set this up.
          </ErrorMessageSection>
        )}

        <SelectionWrapper>
          <StyledTooltipAlt
            arrow
            title="Escalation ladder defines the order in which 
            users will be contacted (e.g. how much time 
            between attempts, how many attempts 
            before the next user is contacted)."
            className="styledPopper"
            placement="right"
          >
            <SelectionFlexContainer>
              <SelectionLabel>Select an Escalation Ladder</SelectionLabel>
              <StyledInfo stroke={'dimgrey'} circlefill={'white'} strokefill={'dimgrey'} />
            </SelectionFlexContainer>
          </StyledTooltipAlt>
          <StyledFormControl
            fullWidth
            disabled={shouldLadderSelectionDisabled}
            $isDisabled={shouldLadderSelectionDisabled}
            $shouldHighlight={!!selectedEscalationLadder}
          >
            <Select
              value={
                shouldLadderPlaceholderOption
                  ? DISABLED_PLACEHOLDER
                  : !!selectedEscalationLadder
                  ? selectedEscalationLadder.id
                  : fetchedEscalationLadderList[0]
                  ? fetchedEscalationLadderList[0].id
                  : DISABLED_PLACEHOLDER
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                style: {
                  maxHeight: '320px',
                },
              }}
              onChange={handleChangeEscalationLadder}
              IconComponent={() => (shouldLadderSelectionDisabled ? null : <StyledChevron />)}
              input={<StyledInputBase />}
            >
              {shouldLadderPlaceholderOption ? (
                <option value={DISABLED_PLACEHOLDER} disabled>
                  {generateLadderPlaceHolder()}
                </option>
              ) : (
                fetchedEscalationLadderList.map((escalationLadder) => (
                  <StyledSelectionMenuItem value={escalationLadder.id} key={`ladder-${escalationLadder.id}`}>
                    {escalationLadder.name}
                  </StyledSelectionMenuItem>
                ))
              )}
            </Select>
          </StyledFormControl>
        </SelectionWrapper>

        {shouldLadderPlaceholderOption && selectedDepartment && !loadingForLadder && !selectedEscalationLadder && (
          <ErrorMessageSection>No escalation policies available for this department</ErrorMessageSection>
        )}

        <SelectionLabel>Type a message</SelectionLabel>
        <StyledTextAreaInput
          minRows={4}
          maxRows={4}
          onChange={handleChangeEscalationMessage}
          value={message}
          disabled={!selectedEscalationLadder}
          placeholder="A message is required in order to trigger this escalation."
        />
      </ScrollLayout>

      <ActionButtonWrapper>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={!selectedDepartment || !selectedSite || !selectedEscalationLadder || !message || loadingForLadder}
          variant="contained"
          color="secondary"
          onClick={handleNext}
        >
          Next
        </Button>
      </ActionButtonWrapper>
    </>
  );
};

export default LocatingEscalationSelectionStep;
