import React from 'react';
import client from 'src/apollo';
import AccordionDetails from '@mui/material/AccordionActions';
import List from '@mui/material/List';
import styled from '@emotion/styled';
import FavouriteRoleMutation from 'src/gql/mutation/FavouriteRoleMutation';
import UnfavouriteRoleMutation from 'src/gql/mutation/UnfavouriteRoleMutation';
import {toast} from 'react-toastify';
import RoleListItems from 'src/pages/LocatingPage/locating/search-section/RoleListItems';
import TeamListItems from 'src/pages/LocatingPage/locating/search-section/TeamListItems';
import {LocatingCodeTeam, LocatingRole} from 'src/types';
import isNextShiftWithinGivenHour from 'src/utils/locating/isNextShiftWithinGivenHour';
import {NEXT_SHIFT_DISPLAY_GAP} from 'src/constants/locating';
import locatingKeywordSearch from 'src/utils/locating/locatingKeywordSearch';
import sortRoles from 'src/utils/sortRoles';
import {LOCATING} from 'src/constants/routerPathName';
import {IsFeatureFlagEnabled} from '../../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../../utils/FeatureFlags';

const NoResult = styled.p`
  margin-top: 1em;
  font-size: 16px;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

interface Props {
  roleList: LocatingRole[];
  teamList: LocatingCodeTeam[];
  favouriteRolesList: LocatingRole[];
  setFavouriteRolesList: React.Dispatch<React.SetStateAction<LocatingRole[]>>;
  searchKeyword: string;
  isAllFavourite: boolean;
  departmentId?: number;
}

const AccordionDetailsWrapper = ({
  roleList,
  teamList,
  favouriteRolesList,
  setFavouriteRolesList,
  searchKeyword,
  isAllFavourite,
  departmentId,
}: Props) => {
  const optimizeOnCallPageFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.optimizeOnCallNetworkRequest);
  const {roleListResult, teamsListResult} = locatingKeywordSearch({
    roleList,
    teamList,
    searchKeyword,
  });

  const finalRoleList = !optimizeOnCallPageFeatureFlag ? roleListResult : roleList;
  const finalTeamsList = !optimizeOnCallPageFeatureFlag ? teamsListResult : teamList;

  function handleClickRole(role: LocatingRole) {
    const {currentShift, nextShift} = role;
    let url = '';
    if (
      Boolean(currentShift) ||
      (Boolean(nextShift) && isNextShiftWithinGivenHour(role.nextShift.startDate, NEXT_SHIFT_DISPLAY_GAP))
    ) {
      const user = Boolean(currentShift) ? currentShift.user : nextShift.user;
      url = `/${LOCATING}/user/${user?.id}`;
    } else {
      url = `/${LOCATING}`;
    }
    window.routerHistory.push(url);
  }

  async function setFavourite(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, role: LocatingRole) {
    const isFavouriteRole = isGivenRoleFavourite(role);
    e.stopPropagation();

    toast.success(`${isFavouriteRole ? 'Unfavoriting' : 'Favoriting'}...`, {
      autoClose: false,
    });

    let roleId = role.id;

    if (typeof role.id === 'string') {
      roleId = parseInt(role.id);
    }

    try {
      await client.mutate({
        mutation: isFavouriteRole ? UnfavouriteRoleMutation : FavouriteRoleMutation,
        variables: {
          roleId,
        },
      });

      if (!isFavouriteRole) {
        setFavouriteRolesList([...favouriteRolesList, role]);
      } else {
        let filteredRoles = favouriteRolesList.filter((favRole) => role.id !== favRole.id);
        setFavouriteRolesList([...filteredRoles]);
      }
      toast.dismiss();
    } catch (e) {
      console.error(e);
      toast.dismiss();
      toast.error(`Failed to favourite this role, please check your internet connection and try again`, {
        autoClose: false,
      });
    }
  }

  function isGivenRoleFavourite(role: LocatingRole) {
    if (!favouriteRolesList || !role?.id) {
      return false;
    }

    if (isAllFavourite) {
      return true;
    }

    return favouriteRolesList?.some((favRole) => {
      const favRoleId = typeof favRole?.id === 'string' ? parseInt(favRole.id) : favRole.id;
      return favRoleId === role.id;
    });
  }

  const noSearchResult = Boolean(searchKeyword) && roleListResult.length === 0 && teamsListResult.length === 0;

  return (
    <AccordionDetails style={{padding: 0}}>
      <List component="nav" style={{width: '100%'}} disablePadding aria-label="role expandable detail">
        {noSearchResult && !optimizeOnCallPageFeatureFlag && <NoResult>No results found</NoResult>}
        {finalTeamsList && finalTeamsList.map((team, i) => <TeamListItems codeTeam={team} key={`team-${i}`} />)}
        {finalRoleList?.sort(sortRoles).map((role) => (
          <RoleListItems
            key={`role${role.id}`}
            role={role}
            handleClickRole={handleClickRole}
            setFavourite={setFavourite}
            isGivenRoleFavourite={isGivenRoleFavourite}
            departmentId={departmentId}
          />
        ))}
      </List>
    </AccordionDetails>
  );
};

export default AccordionDetailsWrapper;
