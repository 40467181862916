import React from 'react';
import {FieldArrayRenderProps} from 'formik';
import {GetSelfProfileWithAddresses} from 'src/gql/query/GetSelfProfile';
import styled from '@emotion/styled';
import client from 'src/apollo';
import RemoveAddressMutation from 'src/gql/mutation/RemoveAddressMutation';
import {toast} from 'react-toastify';
import {User, UserAddress} from 'src/types';
import {ONE_ADDRESS_REQUIRED} from 'src/constants/networkError';
import {TrashButtonIcon} from 'src/svgs/TrashBucketIcon';

const DeleteRowIconHolder = styled.div`
  color: grey;
  position: relative;
  margin-left: 3px;
  user-select: none;
`;

// NOTE: there is a hacky solution from backend to adding phone number as a pager, no way to edit on client rn,
// when delete a phone number, also delete the associated pager;
// There is a potential bug for current frontend implement if the user email includes their phone number...

interface Props {
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  currentAddress: UserAddress;
  setEditProfileFormLoading: (flag: boolean) => void;
}

const DeleteAddressActionIcon = ({arrayHelpers, index, currentAddress, setEditProfileFormLoading}: Props) => {
  const handleDeleteInfo = async () => {
    setEditProfileFormLoading(true);
    try {
      await client.mutate({
        mutation: RemoveAddressMutation,
        variables: {
          address: currentAddress.address,
          type: currentAddress.type,
        },
      });

      const selfProfileQuery = client.readQuery({
        query: GetSelfProfileWithAddresses,
      });
      const selfProfile: User = selfProfileQuery.me;

      const updatedUserAddresses =
        selfProfile.addresses?.reduce((accAddresses: UserAddress[], existingAddress: UserAddress) => {
          if (!existingAddress.address.includes(currentAddress.address)) {
            accAddresses.push(existingAddress);
          }
          return accAddresses;
        }, []) ?? [];

      client.writeQuery({
        query: GetSelfProfileWithAddresses,
        data: {
          me: {
            ...selfProfileQuery.me,
            addresses: [...updatedUserAddresses],
          },
        },
      });

      toast.success('Address has been deleted');
      arrayHelpers.remove(index);
    } catch (e: any) {
      console.error(e);
      let errorMsg = 'Failed to delete address, please check your internet connection and try again';
      if (e.networkError?.result?.errors[0]) {
        let errorCodeName = e.networkError.result.errors[0].code;
        if (errorCodeName === ONE_ADDRESS_REQUIRED) {
          errorMsg = e.networkError.result.errors[0].message;
        }
      }
      toast.error(errorMsg);
    } finally {
      setEditProfileFormLoading(false);
    }
  };

  return (
    <DeleteRowIconHolder onClick={handleDeleteInfo}>
      <TrashButtonIcon />
    </DeleteRowIconHolder>
  );
};

export default DeleteAddressActionIcon;
