import styled from '@emotion/styled';

export const FilterControlSectionWrapper = styled.div`
  flex: 1;
  padding: 1.5em 10px 0 1.5em;
  border-right: 1.5px solid whitesmoke;
  min-width: 280px;
  max-width: 400px;
  position: relative;
  margin-right: 20px;
  z-index: 2;
`;

export const FilteroptionsWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  width: 100%;
`;

export const StyledOverflowWrapper = styled.div`
  flex: 1;
  padding-left: 1em;
  height: inherit;
  max-height: 500px;
  overflow-y: auto;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.greyishBrown};
  margin-top: 20px;
  margin-bottom: 10px;
`;
