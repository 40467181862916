import React from 'react';
import {useDropzone} from 'react-dropzone';
import styled from '@emotion/styled';
import store from 'src/redux';
import {actions} from 'src/redux/actions/filedrop';
import {toast} from 'react-toastify';
import {FILETYPES} from 'src/constants/fileUpload';

const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

interface Props {
  children?: React.ReactNode;
}

const isAnyModalOpen = (): boolean => Boolean(document.querySelector('.ReactModal__Content'));

const MessagesDropzone = (props: Props) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: FILETYPES.split(', '),
    noClick: true,
    noKeyboard: true,
    disabled: isAnyModalOpen(),
    noDragEventsBubbling: true,
    onDrop: (acceptedFiles) => {
      const currentFiles = store.getState().filedrop.files;
      if (currentFiles.length + acceptedFiles.length > 10) {
        toast.error('You can not select more than 10 files.');
      } else {
        store.dispatch(actions.appendDroppedFiles(acceptedFiles));
      }
    },
  });

  return (
    <DropzoneContainer style={{outline: 'none'}} {...getRootProps()}>
      <input {...getInputProps()} />
      {props.children}
    </DropzoneContainer>
  );
};

export default MessagesDropzone;
