import * as React from 'react';
import * as R from 'ramda';
import {Query, QueryResult} from 'react-apollo';
import styled from '@emotion/styled';
import GetColleaguesQuery from 'src/gql/query/GetColleaguesQuery';
import ProfilePic from 'src/components/ProfilePic';
import Check from 'src/svgs/Check';
import {User} from 'src/types';
import {connect} from 'react-redux';
import {actions} from 'src/redux/actions/colleagues';
import {withRouter} from 'react-router-dom';
import LoadingDiv from 'src/components/LoadingDiv';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

const MemberListWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const MemberContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: ${(props) => props.theme.colors.lightGrey};
  }
  background-color: 'white';
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 5px;
`;

const RightSection = styled.div`
  flex: 4;
  min-width: 0;
  padding-right: 10px;
`;

const MemberName = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5em;
`;

const MemberTitle = styled.div`
  min-width: 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.labelGrey};
`;

const Circle = styled.div`
  border: 1px solid ${(props) => props.theme.colors.slightlyGrey};
  border-radius: 50%;
  min-width: 23px;
  min-height: 23px;
  margin-right: 5px;
`;

const StyledCheckContainer = styled.div`
  min-width: 25px;
  min-height: 25px;
  margin-right: 5px;
`;

const StyledInfoContainer = styled.div`
  text-align: center;
  margin-top: 1em;
  padding: 14px;
  color: ${(props) => props.theme.colors.greyishBrown};
  div {
    padding-bottom: 5px;
  }
`;

// TODO: Seperate members by their departments
class ColleagueListWrapper extends React.Component<any> {
  public unlisten: () => void;

  public componentDidMount() {
    this.props.history.listen((location) => null);
    this.unlisten = this.props.history.listen(this.onRouteChange.bind(this));

    AnalyticsManager.recordPageVisited(PAGE_VIEWS.selectContactsList);
  }

  public onRouteChange() {
    this.props.clearAllColleagues();
  }

  public componentWillUnmount() {
    this.unlisten();
  }

  public render() {
    const {selectedColleagues, colleagues} = this.props;
    const selectedColleaguesArray = Array.from(selectedColleagues);

    return (
      <MemberListWrapper>
        {colleagues.map((colleague, index) => {
          return (
            // tslint:disable jsx-no-lambda
            <MemberContainer key={index} onClick={() => this.onClickColleague(colleague, selectedColleaguesArray)}>
              <LeftSection>
                <ProfilePic users={[colleague]} />
              </LeftSection>
              <RightSection>
                <MemberName>
                  {colleague.firstname} {colleague.lastname}
                </MemberName>
                <MemberTitle>{colleague.role || '--'}</MemberTitle>
              </RightSection>
              {this.checkColleagueSelected(selectedColleaguesArray, colleague.id) ? (
                <StyledCheckContainer>
                  <Check />
                </StyledCheckContainer>
              ) : (
                <Circle />
              )}
            </MemberContainer>
          );
        })}
        {(!colleagues || colleagues.length <= 3) && (
          <StyledInfoContainer>
            <div>You can invite your colleagues</div>
            <div>by clicking your name and selecting</div>
            <div>"Invite Colleagues"</div>
          </StyledInfoContainer>
        )}
      </MemberListWrapper>
    );
  }

  private checkColleagueSelected = (arr, id) => {
    // set.has lost obj reference
    return arr.some((colleague) => colleague.id === id);
  };

  private onClickColleague = (colleague: User, arr) => {
    const {removeSelectedColleagues, addSelectedColleagues} = this.props;
    if (arr.some((user) => user.id === colleague.id)) {
      removeSelectedColleagues(colleague);
    } else {
      addSelectedColleagues(colleague);
    }
  };
}

const mapStateToProps = (state: any) => {
  return {
    selectedColleagues: state.colleagues.selectedColleagues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSelectedColleagues: (colleague) => {
      dispatch(actions.addSelectedColleagues(colleague));
    },
    removeSelectedColleagues: (colleague) => {
      dispatch(actions.removeSelectedColleagues(colleague));
    },
    clearAllColleagues: () => {
      dispatch(actions.clearAllColleagues());
    },
  };
};

// @ts-ignore
const ConnectedListWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(ColleagueListWrapper));

export default (props) => (
  <Query query={GetColleaguesQuery}>
    {({loading, error, data, client}: QueryResult) => {
      if (error || loading) return <LoadingDiv />;
      const colleagues: User[] = R.pathOr([], ['colleagues'], data);

      const filterOutShellColleagues = colleagues.filter((colleague) => colleague.status !== 'shell');

      filterOutShellColleagues.sort((a, b) => {
        const aLastName = a.lastname ?? '';
        const bLastName = b.lastname ?? '';

        if (aLastName < bLastName) {
          return -1;
        }
        if (aLastName > bLastName) {
          return 1;
        }
        return 0;
      });

      return <ConnectedListWrapper {...props} client={client} colleagues={filterOutShellColleagues} />;
    }}
  </Query>
);
