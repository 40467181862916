import {HCBodyOne, HCBodyTwo, HCHeadingFour, HCLabelTwo} from 'src/components/HypercareComponents';
import React, {useState} from 'react';
import {
  GridHeaderRoleNotes,
  GridHeaderRoleNotesTitle,
} from 'src/pages/SchedulingPage/scheduling/FullCalendarGridSystem';
import {ROLE_NOTES} from 'src/constants/strings';
import {typedUseSelector} from 'src/redux';
import {Popover} from '@mui/material';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styled from '@emotion/styled';
import {HiddenNotes} from 'src/types/HiddenNotes';
import {Role, ScheduleViewModelData} from 'src/types';
import {muiTheme} from 'src/styles/theme';

export const RoleNoteModalContainer = styled.div`
  width: 320px;
  padding: 24px 16px 12px 16px;
`;

export const RoleNoteModalBody = styled.div`
  max-height: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  margin: 12px 8px;
  webkit-line-clamp: 8;
  line-clamp: 8;
  webkit-box-orient: vertical;
  gap: 15px;
  overflow-y: auto;
  line-height: 20px;
  word-break: break-all;
  overflow-block: auto;
`;

const Note = styled.div`
  margin-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 5.9em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  word-break: break-all;
`;

interface IGridRoleNoteHeader {
  scheduleData?: ScheduleViewModelData;
}

const RoleNoteNameWrapper = styled.div`
  word-break: break-all;
  line-height: 24px;
`;

const GridRoleNoteHeader = ({scheduleData}: IGridRoleNoteHeader) => {
  const roleNoteData = typedUseSelector((state) => state.scheduling.roleNotes);
  const [selectedNote, setSelectedNote] = useState<{notes: HiddenNotes[]; roleName: string} | null>(null);
  const [notesPopup, setNotesPopup] = useState<HTMLElement | null>(null);

  const roleContainer = scheduleData?.roleContainer;

  const handlePopoverOpen = (event, key, note) => {
    setNotesPopup(event.currentTarget);
    setSelectedNote({...note, key});
  };

  const handlePopoverClose = () => {
    setNotesPopup(null);
  };

  return (
    <>
      <GridHeaderRoleNotesTitle>
        {' '}
        <HCHeadingFour fontWeight={700} color={muiTheme.colors.greyishBrown}>
          {ROLE_NOTES}
        </HCHeadingFour>
      </GridHeaderRoleNotesTitle>
      {roleNoteData &&
        roleContainer &&
        roleContainer.map((role: Role, index) => {
          return (
            <div
              key={role.roleId}
              style={{margin: '10px 0 10px 0', height: '100px'}}
              onMouseEnter={(e) => handlePopoverOpen(e, role.roleId, roleNoteData[role.roleId])}
              onMouseLeave={() => handlePopoverClose}
            >
              <GridHeaderRoleNotes aria-describedby={index.toString()}>
                {roleNoteData[role.roleId]?.notes.map((note, index) => (
                  <HCBodyTwo key={note.id}>
                    <Note>
                      {'•'} {note.note}
                    </Note>
                  </HCBodyTwo>
                ))}
              </GridHeaderRoleNotes>
            </div>
          );
        })}

      {selectedNote?.notes?.length && notesPopup ? (
        <Popover
          open={Boolean(notesPopup)}
          anchorEl={notesPopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className="popper__container notes__popover"
          onClose={handlePopoverClose}
        >
          <Paper className="popper__content__wrapper">
            <ClickAwayListener onClickAway={handlePopoverClose}>
              <RoleNoteModalContainer>
                <RoleNoteNameWrapper>
                  <HCBodyOne>{selectedNote.roleName}</HCBodyOne>
                </RoleNoteNameWrapper>
                <RoleNoteModalBody>
                  {' '}
                  {selectedNote.notes?.map((note: HiddenNotes) => (
                    <HCLabelTwo key={note.id}>
                      <span>&#8226;</span>
                      <span style={{marginLeft: '6px'}}>{`${note.note}`}</span>
                    </HCLabelTwo>
                  ))}
                </RoleNoteModalBody>
              </RoleNoteModalContainer>
            </ClickAwayListener>
          </Paper>
        </Popover>
      ) : null}
    </>
  );
};

export default GridRoleNoteHeader;
