import * as React from 'react';
import styled from '@emotion/styled';
import {ScheduleLayoutRouterParams, ScheduleViewModelData} from 'src/types';
import SwapFilterControlSection from 'src/pages/SchedulingPage/action-calendar/swap-section/SwapFilterControlSection';
import MarketplaceFilterControlSection from 'src/pages/SchedulingPage/action-calendar/marketplace-section/MarketplaceFilterControlSection';
import LoadingDiv from 'src/components/LoadingDiv';
import {useParams, useLocation} from 'react-router-dom';
import {typedUseSelector} from 'src/redux';
import {GIVEAWAYSHIFTVIEW, SWAPSCHEDULINGVIEW} from 'src/constants/scheduler';
import ScheduleCalendarDelegate from 'src/pages/SchedulingPage/action-calendar/ScheduleCalendarDelegate';

const MARKETPLACE = 'marketplace';

const LayoutWrapper = styled.div`
  max-width: 100%;
  min-width: min-content;
`;

const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CalendarSectionWrapper = styled.div`
  padding: 1.5em 1.5em 1.5em 0;
  flex: 3;
  height: min-content;
  z-index: 1;
`;

interface Props {
  viewModelScheduleData: ScheduleViewModelData;
  children: React.ReactElement;
}

const BaseActionCalendarLayout = ({viewModelScheduleData, children}: Props) => {
  let params = useParams() as ScheduleLayoutRouterParams;
  let location = useLocation();
  const userToHexColorCode = typedUseSelector((state) => state.scheduling.userToHexColorCode);

  const {roleContainer} = viewModelScheduleData;

  if (!userToHexColorCode || !params) return <LoadingDiv />;

  const isMarketplace = location?.pathname.includes(MARKETPLACE);

  const scheduleCalendarDelegate = new ScheduleCalendarDelegate(
    viewModelScheduleData,
    userToHexColorCode,
    isMarketplace ? GIVEAWAYSHIFTVIEW : SWAPSCHEDULINGVIEW,
  );

  return (
    <LayoutWrapper>
      <SectionsWrapper>
        {isMarketplace ? (
          <MarketplaceFilterControlSection roles={roleContainer} />
        ) : (
          <SwapFilterControlSection
            roles={roleContainer}
            userToColorCodes={userToHexColorCode}
            scheduleId={viewModelScheduleData.scheduleId}
          />
        )}
        <CalendarSectionWrapper>
          {children}
          {scheduleCalendarDelegate.renderShift()}
        </CalendarSectionWrapper>
      </SectionsWrapper>
    </LayoutWrapper>
  );
};

export default BaseActionCalendarLayout;
