import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

interface IMenuItem {
  isSelf?: boolean;
  isCurrent?: boolean;
}

export const MenuItem = styled.div<IMenuItem>`
  padding: 0 1.5em 0 1em;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  line-height: 40px;
  min-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(props) => (props.isCurrent ? 'lightgrey' : 'unset')};
  &:hover {
    background-color: whitesmoke;
  }
  svg {
    width: 20px;
    margin-right: 8px;
  }
`;

export const StyledMenuPaper = styled(Paper)`
  width: 100%;
  position: relative;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledPopper = styled(Popper)`
  margin-top: 5px;
  z-index: 3;
`;
