import {OrganizationScope} from 'src/types/Organization';

// PublicUser
export interface User {
  id: string;
  username: string | null | void;
  email: string | null | void;
  firstname: string | null | void;
  firstName: string | null | void;
  image: string | null;
  lastname: string | null | void;
  lastName: string | null | void;
  note?: string | null | void;
  phoneNumber: string | null | void; // BUG: backend needs to align to phoneNumber or phonenumber
  phonenumber?: string | null | void;
  role: string | null;
  profilePic: ProfilePic | null;
  eulaStatus?: boolean;
  statusExpiryDate?: string;
  workStatus?: WorkStatus;
  statusDescription?: string;
  workStatusProxy?: User;
  status?: UserStatusType;
  addresses?: UserAddress[];
  statusResetFields?: string[];
  label?: string;
}
export interface FetchDepartmentUsersResult {
  locating: {
    department: {
      members: User[];
    };
  };
}

export type UserStatusType = 'active' | 'inactive' | 'shell';
export type UserAddressAccess = 'public' | 'private';
export type UserAddressType = 'phoneNumber' | 'email' | 'pager' | 'alphanumeric_pager' | 'numeric_pager';

export interface UserAddress {
  access: UserAddressAccess;
  address: string;
  ordinal?: string;
  type?: UserAddressType;
  id?: string;
  syncStatus?: boolean;
  label?: string;
}

export interface CreateUserPayload {
  username: string;
  password: string;
  userDetails: {
    firstname: string;
    lastname: string;
    role?: string;
  };
  verificationDetails: {
    email: string;
    token: string;
  };
  inviteCode?: string;
}

export interface CreateOrganizationPayload {
  organizationDetails: {
    name: string;
  };
}

type ChatMemberPrivilege = 'ADMIN' | 'REGULAR';

export type WorkStatus = 'on_shift' | 'busy' | 'unavailable';

export interface ChatUser extends User {
  privilege: ChatMemberPrivilege;
}

export interface ProfilePic {
  url: string | null;
}

export interface ColleagueStore {
  id: string;
  label: string;
}
export interface UserSelectOptionType {
  label: string;
  value: string;
  username?: string;
  role?: string;
}

export interface UserOrganizationScopeResult {
  me: {
    id: string;
    email: string;
    organizations: OrganizationScope[];
  };
}

export interface FullOrganizationMember {
  firstName: string;
  lastName: string;
  username: string;
  id: string;
  isDirectorySynced?: boolean;
  isSSOEnabled?: boolean;
  workStatus?: {
    value: string;
    alternateContact?: {
      id: string;
      accountStatus: string;
      firstName: string;
      lastName: string;
      username: string;
    };
    message?: string;
  };
  accountStatus?: string;
  avatar: {
    url: string;
  };
  role: string;
  statusDescription?: string;
  statusExpiryDate?: string;
  workStatusProxyId?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  addresses: UserAddress[];
  __typename: string;
}

export interface UpdateProfileResult {
  me: {
    id: string;
    username: string | null | void;
    email: string | null | void;
    firstname: string | null | void;
    image: string | null;
    lastname: string | null | void;
    note?: string | null | void;
    phoneNumber: string | null | void; // BUG: backend needs to align to phoneNumber or phonenumber
    phonenumber?: string | null | void;
    role: string | null;
    profilePic: ProfilePic | null;
    eulaStatus?: boolean;
    statusExpiryDate?: string;
    workStatus?: WorkStatus;
    statusDescription?: string;
    workStatusProxy?: User;
    status?: UserStatusType;
    addresses?: UserAddress[];
    statusResetFields?: string[];
  };
}

export enum AddressType {
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  NUMERIC_PAGER = 'pager',
  ALPHANUMERIC_PAGER = 'alphanumeric_pager',
}

export enum ProfileFieldVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type UpdateUserProfileV2Result = {
  selfMutation: {
    updateProfile: {
      __typename: string;
      id: string;
      firstname: string;
      lastname: string;
      username: string;
    };
  };
};
