import React from 'react';
import {TimeFirstVariant} from './TimeFirstVariant';
import {ScheduleLayoutRouterParams, Assignee} from '../../types';
import {TimeWithNameVariant} from './TimeWithNameGridViewVariant';

interface Props {
  assignee: Assignee;
  roleName: string;
  userToColorCodes: {
    [userId: string]: string;
  };
  onCellClick: any;
  menuItem: {
    name: JSX.Element;
    clickHandler: any;
  }[];
  anchorEl: HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  isSelf?: boolean;
  onMouseEnter?: any;
  onMouseLeave?: any;
  params?: ScheduleLayoutRouterParams;
  showOfferTypes?: boolean;
  isSelecting?: boolean;
  shouldGreyOut?: boolean;
  shiftIcon?: JSX.Element;
  variant: 'TimeFirstVariant' | 'TimeWithNameVariant';
}

export function CalenderShifts(props: Props) {
  if (props.variant === 'TimeFirstVariant') return <TimeFirstVariant {...props} />;
  if (props.variant === 'TimeWithNameVariant') return <TimeWithNameVariant {...props} />;
  return null;
}
