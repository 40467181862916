import {Message, PushChat, Chat} from 'src/types';
import {
  CONSULT,
  IMAGE,
  ATTACHMENT,
  TEMPLATE,
  StatMessagePriority,
  UrgentMessagePriority,
  GeneralMessagePriority,
} from 'src/constants/messageTypes';
import {
  ARCHIVED,
  MESSENGER,
  LOCATING,
  CHANGEPASSWORD,
  JOINORGANIZATION,
  SCHEDULING,
  CONTACTS,
} from 'src/constants/routerPathName';
import {updateScopeToken} from 'src/utils/localStorageHandler';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import getCachedOrganizationById from 'src/utils/organizationHelper/getCachedOrganizationById';
import store from 'src/redux';
import {actions} from 'src/redux/actions/organization';

class LocalNotificationManager {
  public static requestPermission() {
    if (!Notification || !Notification.requestPermission) return false;
    // TODO: custom ui for user to accept permission
    Notification.requestPermission();
    // .then((permission) => {
    //   if (permission === 'granted') {
    //      ...
    //   } else {
    //     // Unable to get permission to notify
    //   }
    // });
    // [END request_permission]
  }

  public static playStatPriorityNotificationSound() {
    const audio: HTMLAudioElement = document.getElementById('priority-notification-player') as HTMLAudioElement;
    this.tryPlayAudio(audio);
  }

  public static playUrgentPriorityNotificationSound() {
    const audio: HTMLAudioElement = document.getElementById('urgent-notification-player') as HTMLAudioElement;
    this.tryPlayAudio(audio);
  }

  public static playRegularNotificationSound() {
    const audio: HTMLAudioElement = document.getElementById('regular-notification-player') as HTMLAudioElement;
    this.tryPlayAudio(audio);
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/notification/silent
  // note that firefox does not support silent
  private static tryPlayAudio(audioElement: HTMLAudioElement) {
    audioElement.muted = false;
    const audioPromise = audioElement.play();
    if (audioPromise !== undefined) {
      audioPromise.catch(function (error) {
        // TODO: Show a UI element to let the user turn on sound setting
        console.error(error);
      });
    }
  }

  public static getNotificationMessageBody(message: Message, chat: PushChat | Chat | null, isSocket: boolean) {
    const {sender} = isSocket ? message : (chat as PushChat);
    const {firstname, lastname} = sender;

    const notificationBodyPrefix: string =
      chat && chat.title ? `${firstname} ${lastname} to ${chat.title}` : `${firstname} ${lastname}`;

    let notificationBodySuffix: string;

    switch (message.type) {
      case IMAGE:
        notificationBodySuffix = `Sent an image.`;
        break;
      case CONSULT:
        notificationBodySuffix = `Sent a consult message.`;
        break;
      case TEMPLATE:
        notificationBodySuffix = 'Sent a template message.';
        break;
      case ATTACHMENT:
        notificationBodySuffix = `Sent an attachment.`;
        break;
      default:
        notificationBodySuffix = `${message.message}`;
    }

    const notificationBody = `${notificationBodyPrefix}: ${notificationBodySuffix}`;

    return notificationBody;
  }

  public static async displayMessageNotification(message: Message, chat: PushChat | Chat | null, isSocket: boolean) {
    if (navigator.serviceWorker?.controller?.scriptURL?.includes('firebase')) return;
    const parsedAuthInfo = getParsedAuthInfo();
    if (!parsedAuthInfo || !('serviceWorker' in navigator)) return;

    const currentServiceWorker = await navigator.serviceWorker?.controller?.scriptURL;

    if (currentServiceWorker?.includes('firebase')) return;

    let currentRegistration = await navigator.serviceWorker.getRegistration();

    // TODO: find web push notification solution thats compatible for all browsers
    const isPushNotificationSpawnable = currentRegistration && false;

    // socket notification will be spawned when failed to init push notification or fetch push registration token
    if (isSocket && isPushNotificationSpawnable) return;

    const organizationId = isSocket ? message.organizationId : (chat as PushChat).organizationId;

    const currentOrganizationID = getCurrentOrganizationId();
    const chatId = isSocket ? message.chatId : chat?.id;
    // play priority message event on focus
    if (message.priorityType === StatMessagePriority) this.playStatPriorityNotificationSound();
    else if (message.priorityType === UrgentMessagePriority) this.playUrgentPriorityNotificationSound();

    const windowClientHref = window.location.href;
    // adding routes that needs to show notification
    if (
      !window.document.hasFocus() ||
      organizationId !== currentOrganizationID ||
      windowClientHref.includes(CHANGEPASSWORD) ||
      windowClientHref.includes(JOINORGANIZATION) ||
      windowClientHref.includes(LOCATING) ||
      windowClientHref.includes(ARCHIVED) ||
      windowClientHref.includes(CONTACTS) ||
      windowClientHref.includes(SCHEDULING) ||
      windowClientHref.includes(`${MESSENGER}/new`)
    ) {
      const notificationBody = this.getNotificationMessageBody(message, chat, isSocket);
      const notificationOrganization = getCachedOrganizationById(organizationId);

      const notificationTitle = [StatMessagePriority, UrgentMessagePriority].includes(message.priorityType)
        ? `${message.priorityType === UrgentMessagePriority ? 'Urgent' : 'Stat'} message from ${
            notificationOrganization ? notificationOrganization.name : 'one of your organization'
          }`
        : notificationOrganization
        ? notificationOrganization.name
        : 'You have new message waiting for you on Hypercare';

      const notificationPayload = {
        icon: '/favicon-normal.ico',
        body: notificationBody,
        silent: true,
        tag: message.id,
        priorityType: message.priorityType,
        data: {
          chatId,
          organizationId,
        },
      };

      if (!isSocket && isPushNotificationSpawnable) {
        this.dispatchWorkerNotification({
          notificationTitle,
          notificationPayload,
        });
      } else {
        this.dispatchSocketMessageNotification({
          notificationTitle,
          notificationPayload,
          organizationId,
          chatId,
        });
      }
    }
  }

  public static displayLogoutNotification() {
    if (!('serviceWorker' in navigator)) return;

    const notificationTitle = 'You have been logged out due to inactivity';
    const notificationPayload = {
      icon: '/favicon-normal.ico',
      body: 'Click here to sign back in to start receiving notifications from Hypercare',
    };

    const notification: Notification = new Notification(notificationTitle, notificationPayload);

    notification.onclick = function () {
      window.focus();
      notification.close();
    };
  }

  /** notification originated from push notification when client is on foreground */
  private static dispatchWorkerNotification({notificationTitle, notificationPayload}) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification(notificationTitle, notificationPayload);
    });
  }

  /** notification originated from socket events */
  private static dispatchSocketMessageNotification({notificationTitle, notificationPayload, organizationId, chatId}) {
    const notification: Notification = new Notification(notificationTitle, notificationPayload);

    notification.onshow = function () {
      if (notificationPayload.priorityType === GeneralMessagePriority)
        LocalNotificationManager.playRegularNotificationSound();
    };

    notification.onclick = function () {
      const currentOrganizationID = getCurrentOrganizationId();
      if (currentOrganizationID !== organizationId) {
        const notificationOrganization = getCachedOrganizationById(organizationId);
        store.dispatch(actions.setCurrentOrganization(notificationOrganization));
        updateScopeToken({organizationId});

        window.focus();
        window.routerHistory.push(`/messenger/${chatId}`);
        window.location.reload();
      } else {
        window.focus();
        window.routerHistory.push(`/messenger/${chatId}`);
      }
      notification.close();
    };
  }
}

export default LocalNotificationManager;
