import {AuthPayload} from 'src/types';
import HypercareSignupRepository from '../HypercareSignupRepository';
import sleep from 'src/utils/sleep';
import {ALREADY_EXISTS, INVITE_CODE_NOT_FOUND, VULNERABLEPASSWORD} from 'src/constants/networkError';
import {AuthHelper} from 'src/auth';

class CreateAccountViewModel {
  nextButtonPressed;
  repository: HypercareSignupRepository;

  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    console.log(values);
    try {
      if (values.orgName) {
        const createOrgResult = await this.handleCreateOrg({
          organizationDetails: {
            name: values.orgName,
          },
        });
        console.log(createOrgResult);
        const organizationUrl = createOrgResult.data.data.createOrganization.url;
        const inviteCode = createOrgResult.data.data.createOrganization.inviteCodes[0].code;
        console.log(inviteCode);
        console.log(organizationUrl);
        const newValues = {...values, organizationUrl: organizationUrl, inviteCode: inviteCode};
        console.log(newValues);
        if (createOrgResult) {
          const createAccountPayload = {
            challengeId: values.challengeId,
            organizationUrl: organizationUrl,
            userDetails: {
              firstName: values.firstName,
              lastName: values.lastName,
              role: values.jobTitle,
              password: values.password,
            },
            inviteCode: inviteCode,
          };

          const createUserAccountResult = await this.handleCreateUserAccount(newValues);
          console.log(createUserAccountResult);
          if (createUserAccountResult?.data.data.createAccount.firstName) {
            const authResult = await AuthHelper.fetchAuthInfoWithEmailAndPassword(
              organizationUrl,
              values.email,
              values.password,
            );
            console.log(authResult);
            if (!authResult.error) {
              const authPayload: AuthPayload = {...(authResult.data as AuthPayload)};
              const result = await this.handleAcceptEula(authPayload.accessToken as string);

              // TODO: fix with proper handling - temp solution as backend input delay
              // e.g. might not write the status before login call
              await sleep(2000);

              if (result) this.nextButtonPressed(values);
            } else {
              return {error: 'accessToken'};
            }
          }
        }
      }
      // const createUserAccountResult = await this.handleCreateUserAccount(values);
      // console.log(createUserAccountResult);
      // if (createUserAccountResult?.data.data.createAccount.firstname) {
      //   const res = await AuthHelper.fetchAuthInfoWithEmailAndPassword(orgUrl, values.email, values.password);

      //   const authResult = await this.handleFetchUserAuthInfo(
      //     createUserAccountResult.data.data.createUser.username,
      //     values.password,
      //   );
      //   if (authResult.success) {
      //     const authPayload: AuthPayload = {...(authResult.data as AuthPayload)};
      //     const result = await this.handleAcceptEula(authPayload.accessToken as string);

      //     // TODO: fix with proper handling - temp solution as backend input delay
      //     // e.g. might not write the status before login call
      //     await sleep(2000);

      //     if (result) this.nextButtonPressed(values);
      //   } else {
      //     return {error: 'accessToken'};
      //   }
      // } else {
      //   if (createUserAccountResult.data.data.createUser && createUserAccountResult.data.data.createUser.message) {
      //     return {error: createUserAccountResult.data.data.createUser.message};
      //   }
      // }
    } catch (error: any) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };

  handleCreateUserAccount = async (payload) => {
    const createUserResult = await this.repository?.createSTAUserAccount(payload);
    return createUserResult;
  };

  handleCreateOrg = async (payload) => {
    const createOrgResult = await this.repository?.createSTAOrg(payload);
    return createOrgResult;
  };

  handleFetchUserAuthInfo = async (username, password) => {
    const userAuthInfo = await this.repository?.fetchAuthInfo(username, password);
    return userAuthInfo;
  };

  handleAcceptEula = async (accessToken) => {
    const fetchEulaPayload = await this.repository?.acceptEula(accessToken);
    return fetchEulaPayload;
  };
}

export default CreateAccountViewModel;
