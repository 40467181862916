/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import {
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
} from '@mui/material';
import moment from 'moment';
import {isNil, isEmpty} from 'ramda';
import {toast} from 'react-toastify';
import styled from '@emotion/styled';
import {useSelector} from 'react-redux';
import MomentUtils from '@date-io/moment';
import {useDropzone} from 'react-dropzone';
import {Lightbox} from 'react-modal-image';
import Switch, {SwitchClassKey, SwitchProps} from '@mui/material/Switch';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import store from 'src/redux';
import {RootState} from 'src/redux/reducers';
import {Closemark} from 'src/svgs/Closemark';
import WarningIcon from 'src/svgs/WarningIcon';
import FileSelector from 'src/components/FileSelector';
import DefaultFileIcon from 'src/svgs/DefaultFileIcon';
import AttachementsIcon from 'src/svgs/AttachmentsIcon';
import {actions} from 'src/redux/actions/messageTemplates';
import mimeToExtension from 'src/utils/mimeTypeToExtensions';
import mapMimeTypeToIconName from 'src/utils/mapMimeTypeToIconName';
import {actions as FiledropActions} from 'src/redux/actions/filedrop';
import {TemplateElements, TemplateViews} from 'src/types/MessageTemplate';
import {FILETYPEARRAY, IMAGETYPEARRAY, IMAGEFILETYPES} from 'src/constants/fileUpload';
import {FieldInputLabel, ThemedCheckbox} from 'src/styles/styled-components/StyledMaterialComponents';
import {muiTheme} from 'src/styles/theme';
import AdapterMoment from '@mui/lab/AdapterMoment';

const PREFIX = 'TemplateViewModal';

const classes = {
  root: `${PREFIX}-root`,
  checked: `${PREFIX}-checked`,
  root2: `${PREFIX}-root2`,
  switchBase: `${PREFIX}-switchBase`,
  thumb: `${PREFIX}-thumb`,
  track: `${PREFIX}-track`,
  checked2: `${PREFIX}-checked2`,
  focusVisible: `${PREFIX}-focusVisible`,
  root3: `${PREFIX}-root3`,
};

const StyledGrid = styled(Grid)(({theme}) => ({
  [`& .${classes.root}`]: {
    color: theme.colors.greyishBrown,
    '&$checked': {
      color: theme.colors.chatTeal,
    },
  },

  [`& .${classes.checked}`]: {},

  [`& .${classes.root2}`]: {
    fontFamily: 'Nunito Sans',
    fontSize: 17,
    fontWeight: 700,
    lineHeight: '24px',
  },
}));

const FIELDDATANOTAVAILABLE = 'N/A';
const DEFAULTMAXTEMPLATEATTACHMENTS = 50;

const StyledTypography = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

const TemplateMessageFieldInput = styled(FieldInputLabel)<{templateview: TemplateViews}>`
  font-weight: ${(props) =>
    [TemplateViews.RequestReview, TemplateViews.ReviewResponse, TemplateViews.DetailResponseReview].includes(
      props.templateview,
    )
      ? '600'
      : null} !important;
  text-transform: ${(props) =>
    [TemplateViews.RequestReview, TemplateViews.ReviewResponse, TemplateViews.DetailResponseReview].includes(
      props.templateview,
    )
      ? 'uppercase'
      : null};
`;

const StyledUL = styled.ul`
  margin-block-end: 0;
  margin-block-start: 6px;
  padding-inline-start: 30px;
  li {
    color: ${(props) => props.theme.colors.greyishBrown} !important;
  }
`;

const PreviewAttachmentWrapper = styled.div`
  display: flex;
  padding: 8px 12px;
  background-color: #f6f6f9;
  border-radius: 4px;
  justify-content: space-between;
  cursor: pointer;
`;

const PreviewAttachmentFilename = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.chatTeal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropzoneContainer = styled.div`
  display: flex;
  margin-top: 12px;
  position: relative;
  border-radius: 3px;
  align-items: center;
  flex-direction: column;
  padding: 20px 0 20px 0;
  border: 1px dashed ${(props) => props.theme.colors.borderColor};
`;

const DragActiveContent = styled.div<{isDragActive: boolean}>`
  position: absolute;
  top: 40%;
  font-size: 14px;
  color: ${(props) => props.theme.colors.chatTeal};
  font-weight: 600;
  visibility: ${(props) => (props.isDragActive ? 'visible' : 'hidden')};
`;

const StyledFileControl = styled(FormControl)`
  display: block !important;
  .MuiFormControl-root {
    display: block !important;
  }
`;

const FileIconIndicator = styled.img`
  min-height: 18px;
  min-width: 18px;
`;

const PreviewAttachmentFilesize = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #bcbcbc;
`;

const FileuploadButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  input[type='file'] {
    cursor: pointer;
    width: 175px;
    height: 75px;
    position: absolute;
    left: 5px;
    top: 0;
    opacity: 0;
  }
`;

const FileuploadButton = styled.div`
  color: ${(props) => props.theme.colors.chatTeal};
  padding: 12px 16px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 16px;
`;

const FileUploadErrorWrapper = styled.div`
  padding: 16px;
  display: flex;
  border-radius: 3px;
  margin-top: 16px;
  background-color: #ffd8dd;
  > * {
    &:first-child {
      margin-right: 16px !important;
    }
  }
`;

const StyledSwitch = styled(Switch)`
  .MuiSwitch-root {
    width: 42px;
    height: 26px;
    padding: 0;
    margin: ${(props) => props.theme.spacing(1)};
  }
  .MuiSwitch-switchBase {
    padding: 1px;
    &:checked {
      transform: translateX(16px);
      color: ${(props) => props.theme.palette.common.white};
      & + .MuiSwitch-track {
        backgroundColor: ${(props) => props.theme.colors.chatTeal}
        opacity: 1;
        border: none;
      }
    }
    &:.Mui-focusVisible .MuiSwitch-thumb {
      color: ${(props) => props.theme.colors.chatTeal};
      border: 6px solid #fff;
    }
  }
  .MuiSwitch-thumb {
    width: 24px;
    height: 24px;
  }
  .MuiSwitch-track {
    border-radius: 13px;
    border: 1px solid ${(props) => props.theme.palette.grey[400]};
    background-color: ${(props) => props.theme.palette.grey[50]};
    opacity: 1;
    transition: ${(props) => props.theme.transitions.create(['background-color', 'border'])};
  }
`;

// const StyledSwitch = () => createStyles({
//   root: {
//     width: 42,
//     height: 26,
//     padding: 0,
//     margin: localTheme.spacing(1),
//   },
//   switchBase: {
//     padding: 1,
//     '&$checked': {
//       transform: 'translateX(16px)',
//       color: localTheme.palette.common.white,
//       '& + $track': {
//         backgroundColor: localTheme.chatTeal,
//         opacity: 1,
//         border: 'none',
//       },
//     },
//     '&$focusVisible $thumb': {
//       color: localTheme.chatTeal,
//       border: '6px solid #fff',
//     },
//   },
//   thumb: {
//     width: 24,
//     height: 24,
//   },
//   track: {
//     borderRadius: 26 / 2,
//     border: `1px solid ${localTheme.palette.grey[400]}`,
//     backgroundColor: localTheme.palette.grey[50],
//     opacity: 1,
//     transition: localTheme.transitions.create(['background-color', 'border']),
//   },
//   checked: {},
//   focusVisible: {},
// }),
//     ) (({ ...props }: Props) => {
//   return (
//     <Switch
//       disableRipple
//       checked={toggleSelectionValue[0]?.toggleState === true}
//       onChange={(e) => store.dispatch(actions.setMessageTemplateFormValues({ id, toggleState: e.target.checked }))}
//       classes={{
//         root: classes.root,
//         switchBase: classes.switchBase,
//         thumb: classes.thumb,
//         track: classes.track,
//         checked: classes.checked,
//       }}
//       focusVisibleClassName={classes.focusVisible}
//       {...props}
//     />
//   );
// });

export default class TemplateViewModal {
  elementJson;
  elementIndex;
  templateView;

  constructor(elementJson, index, templateView) {
    this.elementJson = elementJson;
    this.elementIndex = index;
    this.templateView = templateView;
  }

  public renderTextfieldElement = () => {
    const {id, placeholder, title, type, text, validation, isRequired} = this.elementJson,
      {maxLength = null} = validation || {},
      isMultilineTextField = type === TemplateElements.MultilineTextfield,
      [fieldValue, setFieldValue] = React.useState<string | string[]>(''),
      {formValues, templateFormErrors} = useSelector((state: RootState) => state.messageTemplate),
      isMLTFLengthOutOfLimit = maxLength ? fieldValue?.length > maxLength : false;

    let currentFieldError: any = templateFormErrors.filter((fieldError) => fieldError?.id === id);
    currentFieldError = !isEmpty(currentFieldError) ? currentFieldError[0] : null;

    React.useEffect(() => {
      store.dispatch(actions.setInitialMessageTemplateFormValues({id, text: text ? text : ''}));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Subscribed to redux state form values change with useSelector hook  when the state is reset setting it back
     * to the useState hook variables. To handle resetState button click.
     */
    React.useEffect(() => {
      let textFieldValue = formValues?.filter((values) => values.id === id);
      setFieldValue(textFieldValue?.[0]?.text ?? '');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    // Fn to show the field to edit textfield and multiline text field, stored the data in redux stare
    const editViewTextField = () => {
      return (
        <React.Fragment>
          <TextField
            id={id}
            maxRows={4}
            fullWidth={true}
            variant="outlined"
            placeholder={placeholder}
            multiline={isMultilineTextField}
            error={Boolean(currentFieldError)}
            value={fieldValue ? fieldValue : ''}
            FormHelperTextProps={{
              style: {marginLeft: 0, marginRight: 0},
            }}
            helperText={currentFieldError?.errorMsg}
            color={isMultilineTextField && isMLTFLengthOutOfLimit ? 'secondary' : 'primary'}
            onChange={(e) => setFieldValue(e.target.value)}
            onBlur={(_) => store.dispatch(actions.setMessageTemplateFormValues({id, text: fieldValue}))}
          />
          {isMultilineTextField && !isNil(maxLength) && (
            <FieldInputLabel
              style={{textAlign: 'right'}}
              fontcolor={isMLTFLengthOutOfLimit ? muiTheme.colors.watermelon : muiTheme.colors.greyishBrown}
            >
              {fieldValue?.length || '0'}/{maxLength}
            </FieldInputLabel>
          )}
        </React.Fragment>
      );
    };

    // Fn to review the data filled in the template form, shows the data from redux store
    const previewTextField = () => {
      const filterFieldObject = formValues.filter((value) => value.id === id),
        currentFieldObject = filterFieldObject.length > 0 ? filterFieldObject[0] : null;
      return (
        <div style={{overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>
          {currentFieldObject ? currentFieldObject?.text : FIELDDATANOTAVAILABLE}
        </div>
      );
    };

    //Fn to preview the response, shows the data from the response template json.
    const reviewResponseTextField = () => {
      return <div style={{overflowWrap: 'break-word'}}>{text || FIELDDATANOTAVAILABLE}</div>;
    };

    return (
      <StyledGrid
        item
        key={this.elementIndex}
        lg={isMultilineTextField ? 12 : 6}
        md={isMultilineTextField ? 12 : 6}
        sm={12}
      >
        <TemplateMessageFieldInput
          templateview={this.templateView}
          htmlFor={id}
          fontcolor={
            isMultilineTextField && isMLTFLengthOutOfLimit ? muiTheme.colors.watermelon : muiTheme.colors.greyishBrown
          }
        >
          {title}
          {(this.templateView === TemplateViews.RequestEditView ||
            this.templateView === TemplateViews.DetailResponseEditView) &&
            isRequired && <span style={{color: 'red'}}>&nbsp; *</span>}
        </TemplateMessageFieldInput>
        {(this.templateView === TemplateViews.RequestEditView ||
          this.templateView === TemplateViews.DetailResponseEditView) &&
          editViewTextField()}
        {(this.templateView === TemplateViews.RequestReview ||
          this.templateView === TemplateViews.DetailResponseReview) &&
          previewTextField()}
        {(this.templateView === TemplateViews.ReviewResponse ||
          this.templateView === TemplateViews.RespondedResponse) &&
          reviewResponseTextField()}
      </StyledGrid>
    );
  };

  public renderDropdownElement = () => {
    const {id, options, title, selectedOption, isRequired} = this.elementJson,
      reduxStateFormValues = useSelector((state: RootState) => state.messageTemplate),
      reduxDropdownSelection = reduxStateFormValues?.formValues?.filter((values) => values.id === id),
      {templateFormErrors} = reduxStateFormValues;

    let currentFieldError: any = templateFormErrors.filter((fieldError) => fieldError?.id === id);
    currentFieldError = !isEmpty(currentFieldError) ? currentFieldError[0] : null;

    // Hook used to set the initial state of dropdown value to array on component load to prevent undefined error
    React.useEffect(() => {
      store.dispatch(actions.setInitialMessageTemplateFormValues({id, selectedOption: selectedOption}));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fn to show the dropdown element for the edit message template form, stores the data in redux stare
    const editviewDropdownElement = () => {
      return (
        <TextField
          select
          id={id}
          color="primary"
          fullWidth={true}
          variant={'outlined'}
          placeholder={'Select...'}
          error={Boolean(currentFieldError)}
          FormHelperTextProps={{
            style: {marginLeft: 0, marginRight: 0},
          }}
          helperText={currentFieldError?.errorMsg}
          value={reduxDropdownSelection[0]?.selectedOption?.toString() ? reduxDropdownSelection[0]?.selectedOption : ''}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            },
          }}
          onChange={(e) =>
            store.dispatch(actions.setMessageTemplateFormValues({id, selectedOption: parseInt(e.target.value)}))
          }
        >
          {options.map((option, index) => {
            return (
              <MenuItem key={`${id}-${index}`} value={index}>
                {option}
              </MenuItem>
            );
          })}
        </TextField>
      );
    };

    // Fn to review the data filled in the template form, shows the data from redux store
    const previewDropdownElement = () => {
      const filterFieldObject = reduxStateFormValues.formValues.filter((value) => value.id === id),
        currentFieldObject = filterFieldObject.length > 0 ? filterFieldObject[0] : null;
      return (
        <React.Fragment>
          {currentFieldObject?.selectedOption?.toString()
            ? options[currentFieldObject?.selectedOption]
            : FIELDDATANOTAVAILABLE}
        </React.Fragment>
      );
    };

    //Fn to preview the response, shows the data from the response template json.
    const reviewResponseDropdownField = () => {
      return (
        <React.Fragment>
          {selectedOption?.toString() ? options[parseInt(selectedOption)] : FIELDDATANOTAVAILABLE}
        </React.Fragment>
      );
    };

    return (
      <Grid item key={this.elementIndex} lg={6} md={6} sm={12}>
        <TemplateMessageFieldInput templateview={this.templateView} htmlFor={id}>
          {title}
          {(this.templateView === TemplateViews.RequestEditView ||
            this.templateView === TemplateViews.DetailResponseEditView) &&
            isRequired && <span style={{color: 'red'}}>&nbsp; *</span>}
        </TemplateMessageFieldInput>
        {(this.templateView === TemplateViews.RequestEditView ||
          this.templateView === TemplateViews.DetailResponseEditView) &&
          editviewDropdownElement()}
        {(this.templateView === TemplateViews.RequestReview ||
          this.templateView === TemplateViews.DetailResponseReview) &&
          previewDropdownElement()}
        {(this.templateView === TemplateViews.ReviewResponse ||
          this.templateView === TemplateViews.RespondedResponse) &&
          reviewResponseDropdownField()}
      </Grid>
    );
  };

  public renderDatePickerElement = () => {
    const {id, title, dateTime, isRequired} = this.elementJson,
      reduxStateFormValues = useSelector((state: RootState) => state.messageTemplate),
      reduxDatePickerSelection = reduxStateFormValues?.formValues?.filter((values) => values.id === id);

    React.useEffect(() => {
      store.dispatch(
        actions.setInitialMessageTemplateFormValues({id, dateTime: dateTime ? moment(dateTime) : moment()}),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editViewDatePickerElement = () => {
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            mask={'__/__/____'}
            inputFormat="DD MMM YYYY"
            value={reduxDatePickerSelection[0]?.dateTime}
            onChange={(date) => store.dispatch(actions.setMessageTemplateFormValues({id, dateTime: date}))}
            renderInput={(props) => <TextField {...props} />}
          />
        </LocalizationProvider>
      );
    };

    const previewDatePickerElement = () => {
      const filterFieldObject = reduxStateFormValues.formValues.filter((value) => value.id === id),
        currentFieldObject = filterFieldObject.length > 0 ? filterFieldObject[0] : null;
      return (
        <React.Fragment>
          {currentFieldObject?.dateTime?.toString()
            ? moment(currentFieldObject?.dateTime).format('DD MMM YYYY')
            : FIELDDATANOTAVAILABLE}
        </React.Fragment>
      );
    };

    const previewResponseDatePickerElement = () => {
      return (
        <React.Fragment>{dateTime ? moment(dateTime).format('DD MMM YYYY') : FIELDDATANOTAVAILABLE}</React.Fragment>
      );
    };

    return (
      <Grid item key={this.elementIndex} lg={6} md={6} sm={12}>
        <TemplateMessageFieldInput templateview={this.templateView} htmlFor={id}>
          {title}
          {(this.templateView === TemplateViews.RequestEditView ||
            this.templateView === TemplateViews.DetailResponseEditView) &&
            isRequired && <span style={{color: 'red'}}>&nbsp; *</span>}
        </TemplateMessageFieldInput>
        {(this.templateView === TemplateViews.RequestEditView ||
          this.templateView === TemplateViews.DetailResponseEditView) &&
          editViewDatePickerElement()}
        {(this.templateView === TemplateViews.RequestReview ||
          this.templateView === TemplateViews.DetailResponseReview) &&
          previewDatePickerElement()}
        {(this.templateView === TemplateViews.ReviewResponse ||
          this.templateView === TemplateViews.RespondedResponse) &&
          previewResponseDatePickerElement()}
      </Grid>
    );
  };

  public renderCheckboxElement = () => {
    const {id, options, title, state, isRequired} = this.elementJson,
      reduxStateFormValues = useSelector((state: RootState) => state.messageTemplate),
      reduxCheckboxSelection = reduxStateFormValues.formValues?.filter((values) => values.id === id),
      {templateFormErrors} = reduxStateFormValues;

    let currentFieldError: any = templateFormErrors.filter((fieldError) => fieldError?.id === id);
    currentFieldError = !isEmpty(currentFieldError) ? currentFieldError[0] : null;

    // Hook used to set the initial state of checkbox value to array on component load to prevent undefined error
    React.useEffect(() => {
      store.dispatch(
        actions.setInitialMessageTemplateFormValues({
          id,
          state: state ? state : Array.from(options, (_) => false),
        }),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Function to handle checkbox change event, initially the state after getting reset value of checkbox redux
     * state will be string added a check if value is string make it empty array. Then pulling out redux checkbox state
     * to check if it already has the changed checkbox if yes remove it if not add it and set it back to redux state
     * @param event React.ChangeEvent<HTMLInputElement>
     */
    const checkboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      let changeCheckboxIndex = options.findIndex((option) => option === event.target.name);

      let selectedOptionArray = [...Array(options.length)].fill(false);

      for (let i = 0; i < reduxCheckboxSelection[0].state.length; i++) {
        selectedOptionArray[i] = reduxCheckboxSelection[0].state[i];
      }

      selectedOptionArray[changeCheckboxIndex] = event.target.checked;

      store.dispatch(actions.setMessageTemplateFormValues({id, state: selectedOptionArray}));
    };

    // Fn to show the checkbox element for the edit message template form, stores the data in redux stare
    const editviewCheckboxElement = () => {
      return (
        <React.Fragment>
          <StyledFileControl required error={Boolean(currentFieldError)}>
            {options.map((option, index) => {
              const reduxCheckboxSelectionState = reduxCheckboxSelection[0]?.state;
              return (
                <FormControlLabel
                  key={`${id}-${index}`}
                  control={
                    <ThemedCheckbox
                      name={option}
                      onChange={checkboxChangeHandler}
                      checked={reduxCheckboxSelectionState?.length > 0 && reduxCheckboxSelectionState[parseInt(index)]}
                    />
                  }
                  label={option}
                />
              );
            })}
            <FormHelperText>{currentFieldError?.errorMsg}</FormHelperText>
          </StyledFileControl>
        </React.Fragment>
      );
    };

    // Fn to review the data filled in the template form, shows the data from redux store
    const previewCheckboxElement = () => {
      const filterFieldObject = reduxStateFormValues.formValues.filter((value) => value.id === id),
        currentFieldObject = filterFieldObject.length > 0 ? filterFieldObject[0] : null;

      let previewCheckboxSelection: string = '';
      currentFieldObject?.state.forEach((checkboxSelection, index) => {
        if (checkboxSelection)
          previewCheckboxSelection = previewCheckboxSelection
            .concat(options[index])
            .concat(currentFieldObject?.state.length === index + 1 ? '' : ', ');
      });
      return (
        <React.Fragment>
          {previewCheckboxSelection !== '' ? previewCheckboxSelection : FIELDDATANOTAVAILABLE}
        </React.Fragment>
      );
    };

    //Fn to preview the response, shows the data from the response template json.
    const previewResponseCheckboxElement = () => {
      let preview: any[] = [];
      state?.forEach((checkboxSelection, index) => {
        if (checkboxSelection) preview.push(options[index]);
      });
      return <React.Fragment>{state ? preview.join(', ') : FIELDDATANOTAVAILABLE}</React.Fragment>;
    };

    return (
      <Grid item key={this.elementIndex} lg={12} md={12} sm={12}>
        <TemplateMessageFieldInput templateview={this.templateView} htmlFor={id}>
          {title}
          {(this.templateView === TemplateViews.RequestEditView ||
            this.templateView === TemplateViews.DetailResponseEditView) &&
            isRequired && <span style={{color: 'red'}}>&nbsp; *</span>}
        </TemplateMessageFieldInput>
        {(this.templateView === TemplateViews.RequestEditView ||
          this.templateView === TemplateViews.DetailResponseEditView) &&
          editviewCheckboxElement()}
        {(this.templateView === TemplateViews.RequestReview ||
          this.templateView === TemplateViews.DetailResponseReview) &&
          previewCheckboxElement()}
        {(this.templateView === TemplateViews.ReviewResponse ||
          this.templateView === TemplateViews.RespondedResponse) &&
          previewResponseCheckboxElement()}
      </Grid>
    );
  };

  public renderRadioElement = () => {
    const {id, options, title, selectedOption, isRequired} = this.elementJson,
      reduxStateFormValues = useSelector((state: RootState) => state.messageTemplate),
      radioSelectionValue = reduxStateFormValues.formValues?.filter((values) => values.id === id),
      {templateFormErrors} = reduxStateFormValues;

    let currentFieldError: any = templateFormErrors.filter((fieldError) => fieldError?.id === id);
    currentFieldError = !isEmpty(currentFieldError) ? currentFieldError[0] : null;

    React.useEffect(() => {
      store.dispatch(
        actions.setInitialMessageTemplateFormValues({
          id,
          selectedOption: selectedOption?.toString() ? selectedOption : null,
        }),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ThemedRadioButton = Radio;

    // Fn to show the radio element for the edit message template form, stores the data in redux stare
    const editviewRadioElement = () => {
      return (
        <FormControl component="fieldset" error={Boolean(currentFieldError)}>
          <RadioGroup
            row
            id={id}
            name={id}
            value={radioSelectionValue.length > 0 ? radioSelectionValue[0].selectedOption : ''}
            aria-label={id}
            onChange={(e) =>
              store.dispatch(actions.setMessageTemplateFormValues({id, selectedOption: parseInt(e.target.value)}))
            }
          >
            {options.map((option, index) => {
              return (
                <FormControlLabel
                  value={index}
                  label={option}
                  control={
                    <ThemedRadioButton
                      classes={{
                        root: classes.root,
                        checked: classes.checked,
                      }}
                    />
                  }
                  key={`${id}-${index}`}
                />
              );
            })}
          </RadioGroup>
          <FormHelperText>{currentFieldError?.errorMsg}</FormHelperText>
        </FormControl>
      );
    };

    // Fn to review the data filled in the template form, shows the data from redux store
    const previewRadioElement = () => {
      const filterFieldObject = reduxStateFormValues.formValues.filter((value) => value.id === id),
        currentFieldObject = filterFieldObject.length > 0 ? filterFieldObject[0] : null;
      return (
        <React.Fragment>
          {currentFieldObject?.selectedOption?.toString()
            ? options[currentFieldObject?.selectedOption]
            : FIELDDATANOTAVAILABLE}
        </React.Fragment>
      );
    };

    //Fn to preview the response, shows the data from the response template json.
    const reviewResponseRadioElement = () => {
      return <React.Fragment>{selectedOption ? options[selectedOption] : FIELDDATANOTAVAILABLE}</React.Fragment>;
    };

    return (
      <Grid item key={this.elementIndex} lg={12} md={12} sm={12}>
        <TemplateMessageFieldInput templateview={this.templateView} htmlFor={id}>
          {title}
          {(this.templateView === TemplateViews.RequestEditView ||
            this.templateView === TemplateViews.DetailResponseEditView) &&
            isRequired && <span style={{color: 'red'}}>&nbsp;*</span>}
        </TemplateMessageFieldInput>
        {(this.templateView === TemplateViews.RequestEditView ||
          this.templateView === TemplateViews.DetailResponseEditView) &&
          editviewRadioElement()}
        {(this.templateView === TemplateViews.RequestReview ||
          this.templateView === TemplateViews.DetailResponseReview) &&
          previewRadioElement()}
        {(this.templateView === TemplateViews.ReviewResponse ||
          this.templateView === TemplateViews.RespondedResponse) &&
          reviewResponseRadioElement()}
      </Grid>
    );
  };

  public renderToggleElement = () => {
    const {id, title, toggleState, isRequired} = this.elementJson,
      toggleStateFormValues = useSelector((state: RootState) => state.messageTemplate),
      toggleSelectionValue = toggleStateFormValues.formValues?.filter((values) => values.id === id);

    React.useEffect(() => {
      store.dispatch(
        actions.setInitialMessageTemplateFormValues({id, toggleState: toggleState?.toString() ? toggleState : false}),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fn to show the switch element for the edit message template form, stores the data in redux stare
    const editviewSwitchElement = () => {
      return (
        <FormControlLabel
          id={id}
          control={
            <StyledSwitch
              name={title}
              disableRipple
              checked={toggleSelectionValue[0]?.toggleState === true}
              onChange={(e) =>
                store.dispatch(actions.setMessageTemplateFormValues({id, toggleState: e.target.checked}))
              }
            />
          }
          label={title}
        />
      );
    };

    // Fn to review the data filled in the template form, shows the data from redux store
    const previewSwitchElement = () => {
      const filterFieldObject = toggleStateFormValues.formValues.filter((value) => value.id === id),
        currentFieldObject = filterFieldObject.length > 0 ? filterFieldObject[0] : null;
      return <React.Fragment>{currentFieldObject?.toggleState?.toString()}</React.Fragment>;
    };

    //Fn to preview the response, shows the data from the response template json.
    const reviewResponseSwitchElement = () => {
      return <React.Fragment>{toggleState.toString()}</React.Fragment>;
    };

    return (
      <Grid item key={this.elementIndex} lg={6} md={6} sm={12}>
        <TemplateMessageFieldInput templateview={this.templateView} htmlFor={id}>
          {title}
          {(this.templateView === TemplateViews.RequestEditView ||
            this.templateView === TemplateViews.DetailResponseEditView) &&
            isRequired && <span style={{color: 'red'}}>&nbsp; *</span>}
        </TemplateMessageFieldInput>
        {(this.templateView === TemplateViews.RequestEditView ||
          this.templateView === TemplateViews.DetailResponseEditView) &&
          editviewSwitchElement()}
        {(this.templateView === TemplateViews.RequestReview ||
          this.templateView === TemplateViews.DetailResponseReview) &&
          previewSwitchElement()}
        {(this.templateView === TemplateViews.ReviewResponse ||
          this.templateView === TemplateViews.RespondedResponse) &&
          reviewResponseSwitchElement()}
      </Grid>
    );
  };

  public renderAttachmentsElement = () => {
    const {id, validation, isRequired} = this.elementJson,
      storedAttachments = useSelector((state: RootState) => state.filedrop.files),
      [attachmentError, setAttachmentError] = React.useState(false),
      [attachmentErrorMessage, setAttachmentErrorMessage] = React.useState(''),
      [showImageModal, setImageModalVisibility] = React.useState(false),
      maxAttachments = validation?.maxAttachments || DEFAULTMAXTEMPLATEATTACHMENTS,
      supportedAttachments = validation?.supportedAttachments || FILETYPEARRAY,
      {templateFormErrors} = useSelector((state: RootState) => state.messageTemplate);

    React.useEffect(() => {
      store.dispatch(
        actions.setInitialMessageTemplateFormValues({
          id,
          attachmentIds: [],
        }),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      let currentFieldError: any = templateFormErrors.filter((fieldError) => fieldError?.id === id);
      currentFieldError = !isEmpty(currentFieldError) ? currentFieldError[0] : null;
      if (currentFieldError) {
        setAttachmentErrorMessage(currentFieldError?.errorMsg);
        setAttachmentError(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateFormErrors]);

    const TemplateMessageAttachmentDropzone = (props) => {
      const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: supportedAttachments,
        noClick: true,
        noKeyboard: true,
        noDragEventsBubbling: true,
        onDrop: (acceptedFiles) => {
          setAttachmentError(false);
          const currentFiles = store.getState().filedrop.files;
          if (currentFiles.length + acceptedFiles.length > maxAttachments) {
            setAttachmentError(true);
            toast.error(`You can not select more than ${maxAttachments} files.`);
          } else {
            store.dispatch(FiledropActions.appendDroppedFiles(acceptedFiles));
          }
        },
        onDropRejected<T extends File>(files: T[], event) {
          if (files.length > 0) setAttachmentError(true);
        },
      });

      const activeStyle = {
        borderColor: '#00859A',
        backgroundColor: '#CCE7EB',
      };

      const style = React.useMemo(
        () => ({
          ...(isDragActive ? activeStyle : {}),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDragActive],
      );

      return (
        <DropzoneContainer {...getRootProps({style})}>
          <input {...getInputProps()} />
          <span style={{visibility: isDragActive ? 'hidden' : 'visible', display: 'contents'}}>{props.children}</span>
          <DragActiveContent isDragActive={isDragActive}>Drop attachments to upload</DragActiveContent>
        </DropzoneContainer>
      );
    };

    /**
     * Function to handle the file upload and store the files in the redux stare, supported file types are
     * .pdf, .docx, .xls, .jpg, .png. and only 10MB files are allowed
     * @param files Array of files to be validated and stored in the redux store
     */
    const handleFileUpload = async (files: File[]) => {
      setAttachmentError(false);
      const currentFiles = store.getState().filedrop.files;
      if (currentFiles.length + files.length > maxAttachments) {
        setAttachmentError(true);
        toast.error(`You can not select more than ${maxAttachments} files.`);
        return;
      }

      const uploadedFiles: File[] = [...files];
      store.dispatch(FiledropActions.appendDroppedFiles(uploadedFiles));
    };

    // Attachment component to show uploaded attachments in edit(with a X to delete that attachment) and review forms
    const Attachment = ({attachmentArray}) => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

      return (
        <Grid container spacing={1} style={{marginTop: 8}}>
          {(attachmentArray as any)?.length > 0 &&
            (attachmentArray as any)?.map((attachment, index) => {
              let filename = attachment?.filename || attachment?.fileName || null,
                filesize = attachment?.filesize || null,
                url = attachment?.url || null,
                mimeType = attachment?.mimeType || attachment?.type || null,
                exactSize;
              // calculate the size of the file to show in the design.
              if (filesize) {
                if (filesize === 0) exactSize = '0 Byte';
                let i = parseInt(String(Math.floor(Math.log(filesize) / Math.log(1024))));
                exactSize = Math.round(filesize / Math.pow(1024, i)) + ' ' + sizes[i];
              }

              return (
                <Grid item lg={6} key={index}>
                  {showImageModal && (
                    <Lightbox
                      large={url}
                      hideZoom={true}
                      showRotate={true}
                      imageBackgroundColor={'transparent'}
                      onClose={() => setImageModalVisibility(false)}
                    />
                  )}
                  <PreviewAttachmentWrapper
                    onClick={() => {
                      if (
                        this.templateView === TemplateViews.ReviewResponse ||
                        this.templateView === TemplateViews.RespondedResponse
                      )
                        if (IMAGETYPEARRAY.includes(mimeType)) setImageModalVisibility(true);
                        else window.open(url, '_blank', 'noopener,noreferrer');
                    }}
                  >
                    <div style={{display: 'flex'}}>
                      <div style={{marginRight: 12, alignSelf: 'center'}}>
                        {IMAGEFILETYPES.includes(mimeType) && attachment?.preview ? (
                          <img src={attachment.preview} alt={`attachment-${index}`} height={24} width={'24px'} />
                        ) : Boolean(mapMimeTypeToIconName[mimeType]) ? (
                          <FileIconIndicator
                            alt="file_type_icon"
                            src={`/assets/${mapMimeTypeToIconName[mimeType]}.svg`}
                          />
                        ) : (
                          <DefaultFileIcon />
                        )}
                      </div>
                      <div style={{display: 'grid'}}>
                        <PreviewAttachmentFilename>{filename}</PreviewAttachmentFilename>
                        {filesize && <PreviewAttachmentFilesize>{exactSize}</PreviewAttachmentFilesize>}
                      </div>
                    </div>
                    <div style={{alignSelf: 'center'}}>
                      {(this.templateView === TemplateViews.RequestEditView ||
                        this.templateView === TemplateViews.DetailResponseEditView) && (
                        <IconButton
                          edge={'end'}
                          size={'small'}
                          aria-label="close"
                          onClick={() => store.dispatch(FiledropActions.removeDroppedFiles(index))}
                        >
                          <Closemark />
                        </IconButton>
                      )}
                    </div>
                  </PreviewAttachmentWrapper>
                </Grid>
              );
            })}
        </Grid>
      );
    };

    const editviewAttachmentElement = () => {
      return (
        <React.Fragment>
          {attachmentError && (
            <FileUploadErrorWrapper>
              <div>
                <WarningIcon />
              </div>
              <div>
                <Typography>
                  {isEmpty(attachmentErrorMessage)
                    ? 'Upload error. Please check the requirements below and try again.'
                    : attachmentErrorMessage}
                </Typography>
              </div>
            </FileUploadErrorWrapper>
          )}
          <StyledUL>
            {maxAttachments && (
              <li>
                <StyledTypography variant="body2">Max. files allowed: {maxAttachments}</StyledTypography>
              </li>
            )}
            {
              <li>
                <StyledTypography variant="body2">Max. file sizes: 50</StyledTypography>
              </li>
            }
            <li>
              <StyledTypography variant="body2">
                Supported file types:{' '}
                {Array.from(
                  new Set(
                    supportedAttachments
                      .map((_supattach) => '.' + mimeToExtension[_supattach])
                      .join(', ')
                      .split(','),
                  ),
                ).toString()}
              </StyledTypography>
            </li>
          </StyledUL>
          <TemplateMessageAttachmentDropzone>
            <AttachementsIcon />
            <FileuploadButtonWrapper>
              <FileSelector
                allowedFileTypesArray={supportedAttachments}
                allowedFileTypes={supportedAttachments.join(', ')}
                onSelectFiles={handleFileUpload}
              />
              <FileuploadButton>Add Attachments</FileuploadButton>
            </FileuploadButtonWrapper>
            <StyledTypography variant="body2" style={{marginTop: 12}}>
              or drop attachments{' '}
              {Array.from(
                new Set(
                  supportedAttachments
                    .map((_supattach) => '.' + mimeToExtension[_supattach])
                    .join(', ')
                    .split(','),
                ),
              ).toString()}{' '}
              to upload
            </StyledTypography>
          </TemplateMessageAttachmentDropzone>
          {(storedAttachments as any)?.length > 0 && <Attachment attachmentArray={storedAttachments} />}
        </React.Fragment>
      );
    };

    const previewAttachmentsElement = () => {
      return (storedAttachments as any)?.length > 0 && <Attachment attachmentArray={storedAttachments} />;
    };

    const reviewAttachmentElement = () => {
      const {attachments} = this.elementJson || [];
      if (attachments?.length === 0) return FIELDDATANOTAVAILABLE;
      else return <Attachment attachmentArray={attachments} />;
    };

    return (
      <Grid item key={this.elementIndex} lg={12} id={id}>
        <TemplateMessageFieldInput templateview={this.templateView}>
          Attachments
          {(this.templateView === TemplateViews.RequestEditView ||
            this.templateView === TemplateViews.DetailResponseEditView) &&
            isRequired && <span style={{color: 'red'}}>&nbsp; *</span>}
        </TemplateMessageFieldInput>
        {(this.templateView === TemplateViews.RequestEditView ||
          this.templateView === TemplateViews.DetailResponseEditView) &&
          editviewAttachmentElement()}
        {(this.templateView === TemplateViews.RequestReview ||
          this.templateView === TemplateViews.DetailResponseReview) &&
          previewAttachmentsElement()}
        {(this.templateView === TemplateViews.ReviewResponse ||
          this.templateView === TemplateViews.RespondedResponse) &&
          reviewAttachmentElement()}
      </Grid>
    );
  };

  public renderLabelElement = () => {
    const {id, title} = this.elementJson;

    const LabelTypography = Typography;
    return (
      <Grid item key={this.elementIndex} lg={12} md={12} sm={12} id={id}>
        <LabelTypography
          classes={{
            root: classes.root3,
          }}
        >
          {title}
        </LabelTypography>
      </Grid>
    );
  };
}
