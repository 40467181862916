import Popup from 'reactjs-popup';
import React, {useState} from 'react';
import {NumberBadge, TableFilterContainer} from './TableFilters';
import StatusIcon from 'src/svgs/StatusIcon';
import {HCFilterSimple} from 'src/@hc-ui/components/filters/variants/HCFilterSimple';
import {HCPill} from 'src/@hc-ui/components/pill/HCPill';
import ChevronUpIcon from 'src/svgs/ChevronUpIcon';
import ChevronDownIcon from 'src/svgs/ChevronDownIcon';
import {HCFilterOption} from 'src/@hc-ui/components/filters/variants/HCFilter';

export const EventStatusFilter = ({auditLogFilters}) => {
  const {selectedStatusIds, setSelectedStatusIds} = auditLogFilters;
  const [isOpen, setIsOpen] = useState(false);

  const StatusFilter = React.forwardRef(({...props}, ref) => (
    <TableFilterContainer ref={ref as any} {...props} hasSelection={selectedStatusIds.length > 0} isOpen={isOpen}>
      <StatusIcon /> Status {selectedStatusIds.length > 0 && <NumberBadge>{selectedStatusIds.length}</NumberBadge>}
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </TableFilterContainer>
  ));

  return (
    <Popup
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={() => <StatusFilter />}
      position="bottom left"
      closeOnDocumentClick
    >
      {(close) => (
        <HCFilterSimple
          title="Status"
          onClose={close}
          options={[
            {
              title: 'Status',
              options: [
                {
                  id: 'failure',
                  label: 'Failed',
                  value: 'Failed',
                },
                {
                  id: 'success',
                  label: 'Sent',
                  value: 'Success',
                },
              ],
            },
          ]}
          onToggle={(option: HCFilterOption) => {
            const found = selectedStatusIds.find((selectedId) => selectedId === option.id);
            if (found) {
              setSelectedStatusIds([...selectedStatusIds.filter((selectedId) => selectedId !== option.id)]);
            } else {
              setSelectedStatusIds([...selectedStatusIds, option.id]);
            }
          }}
          optionRender={(option) => {
            if (option.id === 'failure') {
              return <HCPill variant="danger" text={option.label} />;
            } else if (option.id === 'in_progress') {
              return <HCPill variant="warning" text={option.label} />;
            }
            return <HCPill variant="success" text={option.label} />;
          }}
          onClear={() => {
            setSelectedStatusIds([]);
            close();
          }}
          selectedIds={selectedStatusIds}
        />
      )}
    </Popup>
  );
};
