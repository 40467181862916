import React from 'react';
import {WideWidthStyleModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import styled from '@emotion/styled';
import {UserOrganizationSwitcherPayload} from 'src/types';

import EscalationLadderPreview from './EscalationLadderPreview';
import EscalationProgressDetail from './EscalationProgressDetail';
import trySwitchOrganization from 'src/utils/organizationHelper/trySwitchOrganization';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';

const ModifiedWideWidthStyleModal = styled(WideWidthStyleModal)`
  &.ReactModal__Content {
    max-height: 880px !important;
  }
`;
interface Props {
  isOpen: boolean;
  activeEscalationID: number;
  closeModal: () => void;
}

const EscalationProgressModal = ({isOpen, closeModal, activeEscalationID}: Props) => {
  const [targetEscalationID, setTargetEscalationID] = React.useState<number>(activeEscalationID);

  const shouldShowLadderStatusDetail = !!targetEscalationID;
  const MODAL_TITLE = targetEscalationID ? 'Escalation status' : 'Escalations in progress';

  const handleViewChat = (chatId: string, organization: UserOrganizationSwitcherPayload) => {
    let chatUrl = `/messenger/${chatId}`;
    const currentOrganizationID = getCurrentOrganizationId();
    if (currentOrganizationID !== organization.organizationId) {
      trySwitchOrganization(organization, undefined, chatUrl);
    } else {
      window.routerHistory.push(chatUrl);
      closeModal();
    }
  };

  return (
    <ModifiedWideWidthStyleModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <Header>
        <span>{MODAL_TITLE}</span>
        <CloseBtn onClick={closeModal} />
      </Header>

      {shouldShowLadderStatusDetail ? (
        <EscalationProgressDetail
          closeModal={closeModal}
          targetEscalationID={targetEscalationID}
          handleViewChat={handleViewChat}
        />
      ) : (
        <EscalationLadderPreview setTargetEscalationID={setTargetEscalationID} handleViewChat={handleViewChat} />
      )}
    </ModifiedWideWidthStyleModal>
  );
};

export default EscalationProgressModal;
