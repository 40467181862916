import * as React from 'react';
import styled from '@emotion/styled';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import FilterControlSubHeader from 'src/pages/SchedulingPage/action-calendar/filter-section/FilterControlSubHeader';
import {AppDispatch, Role, ScheduleLayoutRouterParams} from 'src/types';
import {Divider} from '@mui/material';
import FilterShiftBlock from 'src/pages/SchedulingPage/action-calendar/filter-section/FilterShiftBlock';
import FilterControlButtons from 'src/pages/SchedulingPage/action-calendar/filter-section/FilterControlButtons';
import UserOptionsDropdown from 'src/pages/SchedulingPage/action-calendar/filter-section/FilterUserOptionsDropdown';
import PreferredRolesList from 'src/pages/SchedulingPage/action-calendar/filter-section/FilterPreferredRolesList';
import {
  FilterControlSectionWrapper,
  FilteroptionsWrapper,
  SectionTitle,
  StyledOverflowWrapper,
} from 'src/pages/SchedulingPage/action-calendar/filter-section/SharedFilterSectionStyles';
import {
  SchedulingVariableContext,
  ScheduleQueryVariables,
} from 'src/pages/SchedulingPage/scheduling/SchedulingVariableProvider';
import DepartmentSwitcher from 'src/pages/SchedulingPage/schedule-layout/DepartmentSwitcher';
import {SWAPSCHEDULINGVIEW} from 'src/constants/scheduler';
import {DepartmentSwitcherV2} from '../../schedule-layout/DepartmentSwitcherV2';
import {IsFeatureFlagEnabled} from '../../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../../utils/FeatureFlags';
import {UserSwitcher} from '../../schedule-layout/UserSwitcher';

const Title = styled.h2`
  margin-top: 1em;
`;

const StyledDivider = styled(Divider)`
  margin-top: 1em !important;
`;

interface Props {
  roles: Role[];
  currentDepartmentId: number;
  setScheduleQueryVariables: React.Dispatch<React.SetStateAction<ScheduleQueryVariables>>;
  userToColorCodes: {
    [userId: string]: string;
  };
  scheduleId: number;
}

const SwapFilterControlSection = ({
  roles,
  userToColorCodes,
  setScheduleQueryVariables,
  currentDepartmentId,
  scheduleId,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  let params = useParams() as ScheduleLayoutRouterParams;

  const newSearchableDropdownFF = IsFeatureFlagEnabled(FeatureFlagResult.newSearchableDepartmentAndUserList);
  function onSelectDepartment(targetDepartmentId: number) {
    dispatch({type: 'tradeShift/RESET'});
    setScheduleQueryVariables((prevState) => ({
      ...prevState,
      currentDepartmentId: targetDepartmentId,
    }));
  }

  return (
    <FilterControlSectionWrapper>
      {params && params.actionType === SWAPSCHEDULINGVIEW && <FilterControlSubHeader />}

      <FilteroptionsWrapper>
        {params && params.actionType !== SWAPSCHEDULINGVIEW && <SectionTitle>Select department:</SectionTitle>}

        {newSearchableDropdownFF ? (
          <DepartmentSwitcherV2
            departmentId={currentDepartmentId}
            onDepartmentSelect={onSelectDepartment}
            isSelfScoped={params?.actionType === SWAPSCHEDULINGVIEW}
          />
        ) : (
          <DepartmentSwitcher
            departmentId={currentDepartmentId}
            onSelectDepartment={onSelectDepartment}
            isSelfScoped={params?.actionType === SWAPSCHEDULINGVIEW}
          />
        )}

        {params && params.actionType === SWAPSCHEDULINGVIEW && (
          <React.Fragment>
            <Title>Swapping shift</Title>
            <SectionTitle>I want to trade my following shift</SectionTitle>
            <FilterShiftBlock isSelf={true} userToColorCodes={userToColorCodes} />
            <StyledDivider />
            <SectionTitle>For another user's shift</SectionTitle>
            <FilterShiftBlock isSelf={false} userToColorCodes={userToColorCodes} />
          </React.Fragment>
        )}

        <SectionTitle>View schedules for:</SectionTitle>

        {newSearchableDropdownFF ? (
          <UserSwitcher departmentId={currentDepartmentId} />
        ) : (
          <UserOptionsDropdown departmentId={currentDepartmentId} />
        )}

        <SectionTitle>Preferred roles</SectionTitle>
        <StyledOverflowWrapper>
          <PreferredRolesList roles={roles} departmentId={currentDepartmentId} scheduleId={scheduleId} />
        </StyledOverflowWrapper>

        <FilterControlButtons userToColorCodes={userToColorCodes} />
      </FilteroptionsWrapper>
    </FilterControlSectionWrapper>
  );
};

export default (props) => (
  <SchedulingVariableContext.Consumer>
    {({currentDepartmentId, setScheduleQueryVariables}) => (
      <SwapFilterControlSection
        currentDepartmentId={currentDepartmentId}
        setScheduleQueryVariables={setScheduleQueryVariables}
        {...props}
      />
    )}
  </SchedulingVariableContext.Consumer>
);
