import {muiTheme} from 'src/styles/theme';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width="40" height="40" fill={props.color || muiTheme.colors.messageActionIconGrey} rx="20" />
      <g stroke="#FFF" strokeWidth="1.6">
        <path d="M12.774 10.4a1.2 1.2 0 0 0-1.2 1.2v17.6a1.2 1.2 0 0 0 1.2 1.2h12.4a3.2 3.2 0 0 0 3.2-3.2V13.6a3.2 3.2 0 0 0-3.2-3.2h-12.4z" />
        <path d="M25.2 10.6a.2.2 0 0 0-.2-.2h-2a.2.2 0 0 0-.2.2v6.834a.2.2 0 0 0 .303.171l.897-.538.897.538a.2.2 0 0 0 .303-.171V10.6z" />
      </g>
    </g>
  </svg>
);
