import {useState} from 'react';
import {Manager, Reference, Popper} from 'react-popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TripleDot from 'src/svgs/TripleDot';
import {MenuButton, OptionsBoxNotes, OptionNotes} from 'src/styles/styled-components/PopoverMenuComponents';
import styled from '@emotion/styled';
import {toast} from 'react-toastify';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import 'src/components/index.css';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {
  DELETE_NOTE,
  DELETE_NOTE_MODAL_ACTION_TEXT,
  DELETE_NOTE_MODAL_TEXT,
  EDIT_NOTE,
  NOTE_DELETED,
} from 'src/constants/hiddenNotes';

const OptionHolder = styled.div`
  padding: 10px 3px;
  cursor: pointer;
`;

const StyledTripleDot = styled(TripleDot)`
  transform: translateX(5px);
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  margin-right: 0px;
`;

const ModalContentWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 20px;
  white-space: pre-line;
  word-wrap: break-word;
`;

interface Props {
  setEditMode: any;
  editmode: boolean;
  dataid?: String;
  setIsEditMode?: (e: boolean) => void;
  id?: string;
  disabled?: boolean;
  note?: String;
  handleDeleteProfileNote: (noteId: string) => Promise<any>;
}

const NoteOptionButton = ({
  setEditMode,
  editmode,
  dataid,
  setIsEditMode,
  id,
  disabled = false,
  note,
  handleDeleteProfileNote,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDeleteBox, setShowDeleteBox] = useState(false);

  const clickedOutside = () => {
    setIsMenuOpen(false);
  };
  const onClickMenu = () => {
    setIsMenuOpen(true);
  };

  const deleteNoteOptionButtonPressed = () => {
    setShowDeleteBox(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileNoteDeleteMenuOptionPressed,
    });
  };

  const editNoteOptionButtonPressed = () => {
    setIsEditMode?.(!editmode);
    setEditMode(!editmode);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileNoteEditMenuOptionPressed,
    });
  };

  const DeleteProfileNote = async () => {
    if (!id) {
      console.error('missing profile note id');
      return;
    }
    const result = await handleDeleteProfileNote(id);

    if (result?.result) {
      toast.success(NOTE_DELETED, {
        className: 'toast-discard ',
        autoClose: 5000,
      });
    }

    if (result?.error) {
      toast.error(result.error, {
        className: 'Toast-Container',
        autoClose: 10000,
      });
    }

    setIsMenuOpen(false);
    setShowDeleteBox(false);
  };
  return (
    <>
      <ButtonWrapper>
        <ClickAwayListener onClickAway={clickedOutside}>
          <OptionHolder data-testid={`${dataid}-menubtn`} onClick={!disabled ? onClickMenu : undefined}>
            <Manager>
              <Reference>
                {({ref}) => (
                  <MenuButton ref={ref}>
                    <StyledTripleDot />
                  </MenuButton>
                )}
              </Reference>
              <Popper>
                {({ref, style, placement}) =>
                  isMenuOpen && (
                    <OptionsBoxNotes ref={ref} style={style} data-placement={placement}>
                      <OptionNotes onClick={editNoteOptionButtonPressed} data-testid={`${dataid}-editbtn`}>
                        {EDIT_NOTE}
                      </OptionNotes>
                      <OptionNotes
                        onClick={deleteNoteOptionButtonPressed}
                        color="red"
                        data-testid={`${dataid}-deletebtn`}
                      >
                        {DELETE_NOTE}
                      </OptionNotes>
                    </OptionsBoxNotes>
                  )
                }
              </Popper>
            </Manager>
          </OptionHolder>
        </ClickAwayListener>
      </ButtonWrapper>
      {showDeleteBox && (
        <AlertModal
          id="discard-modal"
          width="sm"
          title="Delete this note?"
          titleFontSize="21px"
          subtitle=""
          closeAlertModal={() => setShowDeleteBox(false)}
          isAlertModalVisible={showDeleteBox}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Cancel',
              onClickHandler: () => setShowDeleteBox(false),
              id: 'discard-nevermind',
            },
            {
              type: 'primary',
              buttonLabel: 'Delete',
              onClickHandler: () => DeleteProfileNote(),
              id: 'discard-confirm',
            },
          ]}
          modalContent={
            <>
              <ModalContentWrapper>{DELETE_NOTE_MODAL_TEXT}</ModalContentWrapper>
              <ModalContentWrapper>
                <i>"{note}”</i>
              </ModalContentWrapper>
              <ModalContentWrapper>{DELETE_NOTE_MODAL_ACTION_TEXT}</ModalContentWrapper>
            </>
          }
        />
      )}
    </>
  );
};

export default NoteOptionButton;
