import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import {Button, CircularProgress, Typography} from '@mui/material';

const InputsWrapper = styled.div<{$size: 'sm' | 'lg'}>`
  display: flex;
  margin: 12px 0;
  input {
    margin: 0 5px;
    text-align: center;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;
    padding: 10px 0;
    font-size: 16px;
    outline: none;
    height: ${(props) => (props.$size === 'sm' ? '48px' : '64px')};
    width: ${(props) => (props.$size === 'sm' ? '48px' : '64px')};
    min-width: 10%;
    color: ${(props) => props.theme.colors.text};
    transition: all 0.2s ease-in-out;
    border: 1px solid #d8d8d8;
    box-shadow: 0 0 1px lightgrey inset;
    border-radius: 4px;

    &:focus {
      border-color: ${(props) => props.theme.colors.chatTeal};
      box-shadow: 0 0 2px ${(props) => props.theme.colors.chatTeal} inset;
    }
    &:disabled {
      cursor: not-allowed;
    }
    &::selection {
      background: transparent;
    }

    @media only screen and (max-width: 480px) {
      height: 48px;
      width: 48px;
    }
  }
`;

const HelperMessageWrapper = styled.div<{disabled: boolean}>`
  margin-top: 12px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  button {
    color: ${(props) => (props.disabled ? props.theme.colors.text : props.theme.colors.chatTeal)};
  }
  p {
    font-size: 14px;
  }
`;

interface Props {
  length: number;
  size: 'sm' | 'lg';
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
  handleResend?: (email?) => Promise<void>;
  renderError?: React.ReactNode;
}

const IdPrefix = 'validation-input-';
const regex = /[0-9]/;

const NewVerificationCodeInput: React.FC<Props> = ({
  size,
  length,
  value,
  disabled,
  onChange,
  handleResend,
  renderError,
}) => {
  const [validationCodeArray, setValidationCodeArray] = useState<any[]>([]);

  useEffect(() => {
    window.addEventListener('paste', onPaste);
    return function clean() {
      window.removeEventListener('paste', onPaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!length) {
      setValidationCodeArray([]);
      return;
    }

    const valueArray = new Array(length).fill('');

    for (let i = 0; Boolean(value) && i < value.length && i < length; i++) {
      valueArray[i] = value[i] === ' ' ? '' : value[i];
    }

    setValidationCodeArray(valueArray);
  }, [length, value]);

  const onPaste = (event: Event) => {
    // @ts-ignore
    const rawPaste = (event.clipboardData || window.clipboardData).getData('text');
    const strippedPasteNumber = rawPaste.replace(/\D+/g, '');

    if (strippedPasteNumber.length === length && parseInt(strippedPasteNumber)) {
      onChange(strippedPasteNumber);
    }
  };

  const handleChangeKey = (index: number, value: string) => {
    const arr = validationCodeArray.map((x) => (!x ? ' ' : x));
    arr[index] = value || ' ';
    onChange(arr.join(''));
  };

  const handleKeyUp = (index: number, e: React.KeyboardEvent) => {
    if (regex.test(e.key)) {
      handleChangeKey(index, e.key);
      focusNextInput(index);
    } else if (e.key === 'ArrowRight') {
      focusNextInput(index);
    } else if (e.key === 'Backspace') {
      handleChangeKey(index, ' ');
      focusPrevInput(index);
    } else if (e.key === 'ArrowLeft') {
      focusPrevInput(index);
    } else {
      e.preventDefault();
    }
  };

  const focusNextInput = (index: number) => {
    let nextElement: HTMLElement | null = null;
    index + 1 >= validationCodeArray.length
      ? (nextElement = document.getElementById(`${IdPrefix}${validationCodeArray.length - 1}`))
      : (nextElement = document.getElementById(`${IdPrefix}${index + 1}`));
    const nextNode = ReactDOM.findDOMNode(nextElement) as HTMLElement;
    if (nextNode) nextNode.focus();
  };

  const focusPrevInput = (index: number) => {
    let prevElement: HTMLElement | null;
    index - 1 >= 0
      ? (prevElement = document.getElementById(`${IdPrefix}${index - 1}`))
      : (prevElement = document.getElementById(`${IdPrefix}${0}`));
    const nextNode = ReactDOM.findDOMNode(prevElement) as HTMLElement;
    if (nextNode) nextNode.focus();
  };

  return (
    <React.Fragment>
      <InputsWrapper $size={size}>
        {validationCodeArray.map((value, index) => (
          <input
            type="text"
            autoComplete="off"
            maxLength={1}
            size={1}
            disabled={disabled}
            autoFocus={index === 0}
            id={`${IdPrefix}${index}`}
            key={`${IdPrefix}${index}`}
            min="0"
            max="9"
            pattern="[0-9]{1}"
            onChange={(e) => e.preventDefault()}
            onKeyUp={(e) => handleKeyUp(index, e)}
            value={value}
          />
        ))}
      </InputsWrapper>
      {renderError}
      {!!handleResend && (
        <HelperMessageWrapper disabled={disabled}>
          <Typography style={{fontWeight: 400, fontFamily: 'Open Sans', fontSize: 14}}>Didn't receive it?</Typography>
          <Button disableElevation disableRipple size="small" disabled={disabled} onClick={handleResend}>
            {disabled ? <CircularProgress color="inherit" size={16} /> : 'Resend code'}
          </Button>
        </HelperMessageWrapper>
      )}
    </React.Fragment>
  );
};

export default NewVerificationCodeInput;
