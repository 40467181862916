import {Chat} from 'src/types';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';

export default function hasUnreadPriorityMessages(chat: Chat): boolean {
  const {unreadPriorityMessages, lastMessage} = chat;
  return (
    unreadPriorityMessages.length > 0 &&
    !!lastMessage &&
    [StatMessagePriority, UrgentMessagePriority].includes(lastMessage.priorityType)
  );
}
