// @flow
import * as React from 'react';
import {PrimaryButton} from '../../../styles/styled-components/StyledMaterialComponents';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {getBaseClientURL} from 'src/utils/getBaseClientUrl';

export const SSORedirectMobile: React.FC = () => {
  const history = useHistory();

  const getCodeFromURLParam = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('code') || '';
  };

  return (
    <>
      <PrimaryButton type="button" onClick={() => history.push(`/ssoredirect?code=${getCodeFromURLParam()}`)}>
        Click me with react router
      </PrimaryButton>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${getBaseClientURL()}/ssoredirect?code=${getCodeFromURLParam()}`}
      >
        Click me with anchor
      </a>
    </>
  );
};
