import React, {ReactNode, useState} from 'react';
import styled from '@emotion/styled';
import {UserAddress} from 'src/types';
import {Formik, FieldArray, FieldArrayRenderProps, FormikProps} from 'formik';
import EmailIcon from 'src/svgs/EmailIcon';
import PhoneIcon from 'src/svgs/PhoneIcon';
import PagerIcon from 'src/svgs/PagerIcon';
import formatNationalNumber from 'src/utils/locating/formatNationalNumber';
import convertTypeToReadbleText from 'src/utils/locating/convertAddressTypeToText';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ProfileAddAddressStepFormModal from 'src/pages/LocatingPage/locating/profile-section/ProfileAddAddressStepFormModalV2';
import {
  ChangeAccessActionInput,
  DeleteAddressActionIcon,
} from 'src/pages/LocatingPage/locating/profile-section/contact-info-partials';
import {PagerType, PagerTypeEvent} from 'src/constants/user';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import convertAddressTypeToString from 'src/utils/convertAddressTypeToString';
import {ProfileAddressView} from '../../components/HypercareProfileAddressView';
import {PROFILE_PAGE_ADDRESS_INFORMATION_TITLE} from 'src/constants/strings';
import {HCTextContext, HCTextContextTwo} from 'src/components/HypercareComponents';
import {IconButton} from '@mui/material';
import {EditOutlined} from '@mui/icons-material';

const StyledTextField = styled(TextField)`
  min-width: 180px !important;
`;

const ContactActionIcons = styled.div`
  display: flex;
  width: 3em;
  align-items: center;
  margin-left: 10px;
  a {
    display: flex;
    align-items: flex-end;
    text-decoration: none;
  }
  i,
  svg {
    color: ${(props) => props.theme.colors.main};
  }
`;

const UserAddressesContainer = styled.div`
  position: relative;
  margin-top: 1em;
  margin-bottom: 0.5em;
  .MuiFormLabel-root {
    text-transform: capitalize;
  }
  .MuiFormControlLabel-label {
    color: ${(props) => props.theme.colors.greyishBrown};
    transform: translateY(-1.5px);
    font-size: 12px;
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 10px;
  }
  .MuiCheckbox-root {
    transform: translateY(-6.5px);
  }
`;

const UserAddressesWrapper = styled.div`
  display: flex;
  position: relative;
  input,
  select {
    color: black;
  }
`;

const PlusSignHolder = styled.div`
  button {
    text-transform: none;
  }
  i {
    font-size: 1em;
  }
`;

const InputFlexWrapper = styled.div`
  display: flex;
`;

const StyledFormControl = styled(FormControl)<{isdisabled: number}>`
  width: 200px !important;
  margin-left: 10px !important;
  .MuiNativeSelect-icon on {
    display: ${(props) => (props.isdisabled ? `none` : `inherit`)};
  }
`;

const UserAddressesLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 2em;
  margin-bottom: 10px;
`;

interface Props {
  isEditMode: boolean;
  isLoading: boolean;
  setEditProfileFormLoading: (flag: boolean) => void;
  addresses: UserAddress[] | any;
  setShowCallbackPageModal: () => void;
  setShowPagerModal?: () => void;
  setPagerSelected?: (string) => void;
  setAddressSelected?: (string) => void;
  onModeChange: (mode: 'view' | 'edit') => void;
  isEditButtonVisible: boolean;
  setCurrentAddresses?: React.Dispatch<React.SetStateAction<UserAddress[]>>;
  isSelf: boolean;
}

interface PorpsWithFormik extends Props {
  formikProps: FormikProps<any>;
}

const ContactInfoList = ({
  formikProps,
  setEditProfileFormLoading,
  isEditMode,
  isLoading,
  setShowCallbackPageModal,
  setShowPagerModal,
  setPagerSelected,
  setAddressSelected,
  setCurrentAddresses,
  addresses,
  onModeChange,
  isEditButtonVisible,
  isSelf,
}: PorpsWithFormik) => {
  const {touched, errors, handleChange, setFieldValue} = formikProps;
  const [openAddressModal, setAddressModal] = useState(false);
  const isInputDisabled = !isEditMode || isLoading;

  const orderPreferenceContacts = IsFeatureFlagEnabled('orderPreferenceContacts');

  const handlePagerMixpanel = (pagerType: string, address: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.contactsSpecificPagerButtonPressed,
      params: {
        address: address,
        addressType:
          pagerType === PagerType.ALPHANUMERIC_PAGER ? PagerTypeEvent.ALPHANUMERIC_PAGER : PagerTypeEvent.NUMERIC_PAGER,
      },
    });
  };

  const handlePhoneMixpanel = (address: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.phoneButtonPressed,
      params: {
        address: address,
      },
    });
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.contactsSpecificPhoneButtonPressed,
      params: {
        address: address,
      },
    });
  };

  const renderAddress = (address: UserAddress, index: number, arrayHelpers: FieldArrayRenderProps): ReactNode => {
    if (address.type === PagerType.ALPHANUMERIC_PAGER) return null;
    return (
      <UserAddressesWrapper key={`address-${index}`} data-testid={`address-${address.type}`}>
        <StyledTextField
          fullWidth
          disabled={true}
          style={{marginTop: 0}}
          id={`addresses.${index}.address`}
          name={`addresses.${index}.address`}
          label={address.type === convertTypeToReadbleText(address.type!)}
          value={
            address.type === 'phoneNumber'
              ? isEditMode || isInputDisabled
                ? formatNationalNumber(address.address)
                : address.address
              : address.address
          }
          InputProps={
            address.type === 'phoneNumber'
              ? {
                  startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                }
              : undefined
          }
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          onChange={handleChange}
          helperText={
            errors.addresses && errors.addresses[index] && touched.addresses && touched.addresses[index]
              ? errors.addresses[index].address
              : ''
          }
          error={Boolean(
            errors.addresses &&
              errors.addresses[index] &&
              errors.addresses[index].address &&
              touched.addresses &&
              touched.addresses[index],
          )}
        />

        <InputFlexWrapper>
          <StyledFormControl isdisabled={1}>
            <InputLabel shrink htmlFor={`addresses.${index}.type`}>
              Type
            </InputLabel>
            <NativeSelect
              value={address.type}
              disabled={true}
              onChange={handleChange}
              inputProps={{
                id: `addresses.${index}.type`,
                name: `addresses.${index}.type`,
              }}
            >
              <option value={'phoneNumber'}>Phone</option>
              <option value={'email'}>Email</option>
              <option value={PagerType.ALPHANUMERIC_PAGER}>Alphanumeric Pager</option>
              <option value={PagerType.NUMERIC_PAGER}>{'Numeric Pager'}</option>
            </NativeSelect>
          </StyledFormControl>

          <ChangeAccessActionInput
            isInputDisabled={isInputDisabled}
            index={index}
            setFieldValue={setFieldValue}
            currentAddress={address}
            isEditProfileFormLoading={isLoading}
            setEditProfileFormLoading={setEditProfileFormLoading}
          />
        </InputFlexWrapper>

        {addresses.length >= 1 && !isInputDisabled && (
          <DeleteAddressActionIcon
            arrayHelpers={arrayHelpers}
            index={index}
            currentAddress={address}
            setEditProfileFormLoading={setEditProfileFormLoading}
          />
        )}

        {!isEditMode && (
          <ContactActionIcons data-testid={`addressIcon-${address.type}`}>
            <a
              rel="noreferrer"
              target={address.type === 'email' ? '_blank' : undefined}
              title={`${convertAddressTypeToString(address.type!)} this person`}
              href={
                address.type === PagerType.NUMERIC_PAGER
                  ? undefined
                  : `${address.type === 'email' ? 'mailto' : 'callto'}:${address.address}`
              }
              style={{cursor: 'pointer'}}
              onClick={
                address.type === PagerType.NUMERIC_PAGER
                  ? () => {
                      setShowPagerModal?.();
                      setPagerSelected?.(address.type);
                      setAddressSelected?.(address.address);
                      handlePagerMixpanel?.(address.type!, address.address);
                    }
                  : address.type === 'phoneNumber'
                  ? () => handlePhoneMixpanel(address.address)
                  : undefined
              }
            >
              {address.type === 'email' && <EmailIcon />}
              {address.type === 'phoneNumber' && <PhoneIcon width="24" height="24" onClick={null} />}
              {address.type === PagerType.NUMERIC_PAGER && <PagerIcon />}
            </a>
          </ContactActionIcons>
        )}
      </UserAddressesWrapper>
    );
  };

  return (
    <FieldArray
      name="addresses"
      render={(arrayHelpers) => (
        <React.Fragment>
          {openAddressModal && (
            <ProfileAddAddressStepFormModal
              addresses={addresses}
              onCurrentAddressesChanged={(a) => setCurrentAddresses?.(a)}
              isOpen={openAddressModal}
              index={addresses.length}
              setParentAddress={setFieldValue}
              closeModal={() => setAddressModal(false)}
            />
          )}

          <UserAddressesContainer>
            <UserAddressesLabel>
              <HCTextContextTwo>{PROFILE_PAGE_ADDRESS_INFORMATION_TITLE}</HCTextContextTwo>
              {isEditButtonVisible && isSelf && (
                <IconButton style={{marginLeft: 8}} size="small" onClick={() => onModeChange('edit')}>
                  <EditOutlined fontSize="small" />
                </IconButton>
              )}
            </UserAddressesLabel>
            {!orderPreferenceContacts ? (
              addresses.map((address: UserAddress, index: number) => renderAddress(address, index, arrayHelpers))
            ) : (
              <ProfileAddressView
                addresses={addresses || []}
                isEditMode={isEditMode}
                isInputDisabled={isInputDisabled}
                arrayHelpers={arrayHelpers}
                onCurrentAddressesChange={(a) => setCurrentAddresses?.(a)}
                onShowAddressModal={setAddressModal}
                setShowCallbackPageModal={setShowCallbackPageModal}
                setShowPagerModal={() => setShowPagerModal?.()}
                setPagerSelected={(addressType) => setPagerSelected?.(addressType)}
                setAddressSelected={(address) => setAddressSelected?.(address)}
                onModeChange={onModeChange}
              />
            )}

            {!isInputDisabled && Boolean(errors.addresses) && typeof errors.addresses === 'string' && (
              <FormHelperText error>{errors.addresses}</FormHelperText>
            )}

            {!isInputDisabled && !orderPreferenceContacts && (
              <PlusSignHolder>
                <Button aria-label="add" disableTouchRipple variant="outlined" onClick={() => setAddressModal(true)}>
                  <i className="material-icons">add</i>
                  Add Address
                </Button>
              </PlusSignHolder>
            )}
          </UserAddressesContainer>
        </React.Fragment>
      )}
    />
  );
};

const ContactInfoListWrapper = (props: Props) => {
  const {addresses} = props;
  return (
    <Formik
      initialValues={{
        addresses,
      }}
      onSubmit={() => {}}
    >
      {(formikProps) => <ContactInfoList formikProps={formikProps} {...props} />}
    </Formik>
  );
};

export default ContactInfoListWrapper;
