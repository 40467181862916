import styled from '@emotion/styled';
import {RoleHolderStyledComponentProps} from 'src/types';
import {muiTheme} from 'src/styles/theme';

export const scrollIndicatorHeight = `4px`;
export const cellWidth = `200px`;
export const cellGap = `1em`;
export const dateFieldWidth = `130px`;

const borderBottomColor = muiTheme.colors.borderBottomLight;
const rowMinHeight = `100px`;

interface GridProps {
  gridBodyWidth: string;
}

// doc: https://www.notion.so/Scheduling-0d37f6f90d694f32aaa2deb2bf5df635
export const allowedSchedulingCellBackgroundColors = [
  '#B6BADB',
  '#DDE862',
  '#47C9F2',
  '#FFE498',
  '#FFBEB7',
  '#4B65E4',
  '#7BD77B',
  '#27E1B5',
  '#FFBF0C',
  '#FF364B',
  '#864D9D',
  '#0B664B',
  '#007A8F',
  '#FF984E',
  '#E15599',
  '#2A2893',
  '#99AA6A',
  '#134C76',
  '#C3291C',
  '#8B3C23',
];

export const GridScrollProgressIndicatorWrapper = styled.div`
  position: sticky;
  top: 0;
  height: ${scrollIndicatorHeight};
  width: 100%;
  background-color: white;
  z-index: 2;
`;

export const GridScrollProgressIndicator = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 150, 137, max(var(--scroll), 30%)) var(--scroll), transparent 0);
  z-index: 2;
`;

export const GridSettings = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

export const GridContainer = styled.div`
  display: grid;
  cursor: ew-resize;
  user-select: none;
  //grid-gap: 1rem;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  grid-template-columns: ${dateFieldWidth} auto;
  grid-template-rows: 1fr auto-fit;
  grid-template-areas:
    'role-header role-header'
    'role-body role-body';
`;

/** Role Cell Holder and Assignee Details */

export const RoleHolder = styled.div`
  color: ${(props) => props.theme.colors.blackFontColor};
  width: ${cellWidth};
  min-height: ${rowMinHeight};
  .notelist {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 96px;
  }
`;

export const RoleHolderDetailTime = styled.div`
  font-size: 12px;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

export const RoleHolderDetailTitle = styled.div`
  padding-bottom: 8px;
  font-size: 13px;
  font-family: Nunito;
  font-weight: bold;
  overflow-wrap: break-word;
  line-height: 1.23;
  letter-spacing: normal;
  width: 100%;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

export const RoleHolderDetail = styled.div`
  margin-left: 8px;
  width: 70%;
`;

export const RoleHolderWrapper = styled.div<RoleHolderStyledComponentProps>`
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 5px;
  background-color: ${(props) => props.assignedColorBackground};
  border-left: 5px solid ${(props) => props.assignedColor};
  display: flex;
  align-items: center;
`;

/** Body Area */

export const GridBodyArea = styled.div<GridProps>`
  grid-area: role-body;
  display: grid;
  max-width: ${(props) => props.gridBodyWidth};
  overflow-x: scroll;
  overflow-y: hidden;
  min-width: 100%;
  grid-gap: 1rem;
  position: relative;
  padding-bottom: 20px;
  cursor: pointer;
`;

export const GridBodyRowRole = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${cellGap};
  padding-left: ${cellGap};
`;

export const GridBodyRowDate = styled.div`
  z-index: 1;
  width: ${dateFieldWidth};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
`;

export const RowDateHolder = styled.div`
  margin-top: 5px;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  overflow-wrap: break-word;
  font-size: 14px;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

export const BoldDate = styled.div`
  font-family: Nunito;
  font-size: 18px;
  margin-top: 5px;
  font-weight: 600;
`;

export const GridBodyRow = styled.div<GridProps>`
  display: grid;
  font-family: Nunito;
  width: ${(props) => props.gridBodyWidth};
  position: relative;
  grid-template-columns: ${dateFieldWidth} 1px auto;
  border-bottom: 0.5px solid ${borderBottomColor};
  min-width: 100%;
  padding-bottom: ${cellGap};

  &:hover ${GridBodyRowDate} ${RowDateHolder} {
    color: ${(props) => props.theme.colors.androidGreen} !important;
    font-weight: bold;
    font-size: 16px;
  }
  .popper__container {
    z-index: 9;
    .popper__content__wrapper {
      cursor: pointer !important;
      ul {
        padding-top: 0;
        padding-bottom: 0;
      }
      li {
        font-size: 15px;
        line-height: 2em;
      }
    }
  }
  ul {
    list-style-type: '*  ';
  }
  li {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
`;

/** Header Area */

export const GridHeaderArea = styled.div`
  grid-area: role-header / role-header / role-header / role-header;
  overflow: hidden;
  position: sticky;
  top: ${scrollIndicatorHeight};
  background-color: white;
  z-index: 2;
  cursor: auto;
  border-bottom: 1px solid ${borderBottomColor};
`;

export const GridHeader = styled.div<GridProps>`
  display: grid;
  min-width: 100vw;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: ${cellGap};
  width: ${(props) => props.gridBodyWidth};
  z-index: 1;
  padding-left: calc(${dateFieldWidth} + ${cellGap});
`;

export const GridHeaderRoleName = styled.div`
  padding: 0 10px;
  font-size: 16px;
  font-family: Nunito;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.colors.blackFontColor};
  word-break: break-all;
`;

export const GridHeaderRepeatRule = styled.div`
  color: dimgrey;
  font-size: 14px;
`;

export const GridHeaderNameHolder = styled.div`
  background-color: white;
  color: black;
  width: ${cellWidth};
  text-align: center;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const RoleNoteGridHeader = styled.div<GridProps>`
  display: grid;
  min-width: 100vw;
  border-bottom: 1px solid #d8d8d8;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: 1em;
  width: ${(props) => props.gridBodyWidth};
  z-index: 1;
  height: 100px;
  background-color: #fafafa;
  top: 4px;
  position: sticky;
  top: ${scrollIndicatorHeight};
`;

export const GridHeaderRoleNotes = styled.div`
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: ${cellWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  height: 5.9em; /* Approximate height for four lines */
  gap: 2px;
  flex-direction: column;
  overflow-wrap: break-word;
`;
export const GridHeaderRoleNotesTitle = styled.div`
  width: 130px;
  text-align: center;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
`;

export const AddRoleNoteButtonContainer = styled.div`
  width: ${cellWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
