import * as React from 'react';
import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import {useQuery} from '@apollo/react-hooks';
import GetOrganizationInvitesForUser from 'src/gql/query/GetOrganizationInvitesForUser';
import PendingInvitesModal from 'src/components/PendingInvitesModal';
import {GetOrganizationInviteResult} from 'src/types';
import {ORGANIZATION_INVITE_BANNER_TITLE} from 'src/constants/strings';

const StyledAlert = styled(Alert)`
  border-bottom: 1px solid lightgrey;
  border-radius: 0 !important;
  cursor: pointer;
`;

const OrganizationInviteBanner = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const {loading, error, data} = useQuery<GetOrganizationInviteResult>(GetOrganizationInvitesForUser);

  if (loading || error) return null;

  const invites = data?.me?.invites || [];

  if (invites.length > 0) {
    return (
      <React.Fragment>
        <StyledAlert severity="info" onClick={() => setModalOpen(true)}>
          {ORGANIZATION_INVITE_BANNER_TITLE(invites.length)}
        </StyledAlert>
        <PendingInvitesModal isOpen={isModalOpen} invitationList={invites} closeModal={() => setModalOpen(false)} />
      </React.Fragment>
    );
  }
  return null;
};

export default OrganizationInviteBanner;
