import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import styled from '@emotion/styled';
import {InputAdornment} from '@mui/material';
import SearchIcon from 'src/svgs/SearchIcon';
import {StyledFormControl} from 'src/components/StepFormPartials';
import {muiTheme} from 'src/styles/theme';

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  z-index: 1;
  width: 1em;
  height: 1em;
`;

const SearchBarEndAdornment = styled.div`
  display: inherit;
  position: absolute;
  right: 1em;
  border-radius: 8px;
  opacity: 1;
  transition: 0.2s;
`;
interface ContactsSearchBarProps {
  error?: boolean;
  searchError?: string;
  clearSearchText: () => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  localSearchText: string;
  placeHolder: string;
  inputWidth?: string;
  inputHeight?: string;
}

const ContactsSearchBar: React.FC<ContactsSearchBarProps> = ({
  error,
  searchError,
  clearSearchText,
  handleKeyDown,
  handleChange,
  localSearchText,
  placeHolder,
  inputWidth,
  inputHeight,
}) => {
  return (
    <StyledFormControl>
      <StyledTextField
        error={error}
        helperText={error && searchError}
        variant="outlined"
        id="outlined-adornment-search"
        style={{background: 'white'}}
        inputProps={{
          style: {
            padding: '0px 2px 0px 8px',
            width: inputWidth,
            height: inputHeight ? inputHeight : '36px',
            borderRadius: '2px',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StyledSearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <SearchBarEndAdornment
              onClick={clearSearchText}
              style={{
                cursor: 'pointer',
                visibility: localSearchText === '' ? 'hidden' : 'visible',
              }}
            >
              <ClearIcon width={20} height={20} stroke={muiTheme.colors.greyishBrown} />
            </SearchBarEndAdornment>
          ),
          onKeyDown: handleKeyDown,
        }}
        placeholder={placeHolder}
        value={localSearchText}
        onChange={handleChange}
      />
    </StyledFormControl>
  );
};

export default ContactsSearchBar;
