import React from 'react';
import {UserEmail, ErrorMessage} from 'src/pages/JoinOrganizationPage/join-organization/JoinOrgFormStepOne';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import JoinOrganizationStepController from 'src/pages/JoinOrganizationPage/join-organization/JoinOrganizationStepController';
import {FormikProps} from 'formik';
import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import StepFormErrorMessage, {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import {muiTheme} from 'src/styles/theme';

const AgreementHolder = styled.div`
  color: grey;
  font-size: 14px;
  margin-top: 1em;
  a {
    padding: 0 5px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text} !important;
  }
`;

const JoinOrgStepTwo = ({
  joinOrganizationErrMsg,
  intendedOrganization,
  formikProps,
  isLoading,
  isJoinUsingInviteCode,
}) => {
  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.onboardingJoinOrganization);
  }, []);

  const {values, errors, touched, isSubmitting, handleChange, handleBlur} = formikProps as FormikProps<any>;

  const isCreate = JoinOrganizationStepController.getCreatingNewOrgFalg();
  const {termsOfServiceUrl, privacyPolicyUrl} = JoinOrganizationStepController.getJoinOrganizationAgreementLinks();

  if (isCreate) {
    const hasError = touched.organizationName && Boolean(errors.organizationName);

    return (
      <div>
        <div>Input your organization name for us to create your organization.</div>
        <StyledFormControl disabled={isSubmitting || isLoading}>
          <InputLabel
            shrink
            htmlFor="organizationName"
            style={hasError ? undefined : {color: muiTheme.colors.greyishBrown}}
            error={hasError}
          >
            Organization name
          </InputLabel>
          <StyledInputBase
            fullWidth
            autoFocus
            id="organizationName"
            name="organizationName"
            placeholder="Enter organization name here"
            value={values.organizationName}
            onBlur={handleBlur}
            onChange={handleChange}
            error={hasError}
          />
          <StepFormErrorMessage name="organizationName" />
        </StyledFormControl>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        Is your verified {isJoinUsingInviteCode ? 'organization code' : 'email'} for&nbsp;
        <UserEmail>{intendedOrganization && intendedOrganization.name}</UserEmail>?
      </div>
      {termsOfServiceUrl && (
        <AgreementHolder>
          By clicking on 'Confirm Organization', you agree to the
          <a href={termsOfServiceUrl!} rel="noopener noreferrer" target="_blank">
            Terms of Service
          </a>
          {privacyPolicyUrl && (
            <React.Fragment>
              and
              <a href={privacyPolicyUrl!} rel="noopener noreferrer" target="_blank">
                Private Policy
              </a>
            </React.Fragment>
          )}
          set out by the organization. You also grant your organization ability to manage your acccount
        </AgreementHolder>
      )}
      {joinOrganizationErrMsg && <ErrorMessage>{joinOrganizationErrMsg}</ErrorMessage>}
    </React.Fragment>
  );
};

export default JoinOrgStepTwo;
