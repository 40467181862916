import * as React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import LoadingDiv from 'src/components/LoadingDiv';
import SchedulingGridWrapper from 'src/pages/SchedulingPage/schedule-layout/SchedulingGridWrapper';
import {useQuery} from '@apollo/react-hooks';
import {QueryResult} from 'react-apollo';
import {GetScopedSelfDepartmentsResult} from 'src/types';
import {GridSettings} from 'src/pages/SchedulingPage/scheduling/FullCalendarGridSystem';
import MonthSwitcher from 'src/pages/SchedulingPage/schedule-layout/MonthSwitcher';
import DepartmentSwitcher from 'src/pages/SchedulingPage/schedule-layout/DepartmentSwitcher';
import {minAppWidth} from 'src/styles/utils';
import {useLocation} from 'react-router-dom';
import {useScheduleViewModelData} from 'src/pages/SchedulingPage/scheduling/hooks';
import {FetchSelfScopedDepartments} from 'src/gql/query/GetUserOrganizations';
import {
  SchedulingVariableContextInterface,
  SchedulingVariableContext,
} from 'src/pages/SchedulingPage/scheduling/SchedulingVariableProvider';
import {AppDispatch} from 'src/redux';
import {useDispatch} from 'react-redux';
import SchedulingGridSwapSectionContainer from 'src/pages/SchedulingPage/schedule-layout/SchedulingGridSwapSectionContainer';
import getUserIdSet from 'src/utils/scheduling/getUserIdSet';
import tryDispatchUserIdForColorAssignement from 'src/utils/scheduling/tryDispatchUserIdForColorAssignement';
import {SCHEDULING} from 'src/constants/routerPathName';
import SchedulingToggleButton from 'src/components/SchedulingToggleButton';
import {LISTSCHEDULINGVIEW} from 'src/constants/scheduler';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FETCH_SCHEDULES} from 'src/gql/query/FetchSchedule';
import {FeatureFlagResult} from '../../../utils/FeatureFlags';
import {DepartmentSwitcherV2} from '../schedule-layout/DepartmentSwitcherV2';

const SchedulingBodyWrapper = styled.div`
  padding: 0 3%;
  width: max(98vw, ${minAppWidth}px);
  height: auto;
  max-width: 100%;
`;

interface Props extends SchedulingVariableContextInterface {}

const SchedulingGridQueryContainer = ({
  currentDepartmentId,
  startOfMonthUTC,
  endOfMonthUTC,
  setScheduleQueryVariables,
}: Props) => {
  const {search} = useLocation();
  let urlSearchParams = new URLSearchParams(search);
  const dispatch: AppDispatch = useDispatch();

  const newSearchableDropdownFF = IsFeatureFlagEnabled(FeatureFlagResult.newSearchableDepartmentAndUserList);

  React.useEffect(() => {
    return () => {
      dispatch({type: 'tradeShift/RESET'});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectDepartment = (departmentId: number) => {
    setScheduleQueryVariables((prevState) => ({
      ...prevState,
      currentDepartmentId: departmentId,
    }));
  };

  function onSelectMonth(startTime: string, endTime: string) {
    setScheduleQueryVariables((prevState) => ({
      ...prevState,
      startOfMonthUTC: startTime,
      endOfMonthUTC: endTime,
    }));
  }

  const fetchScopedSelfDepartmentsResult: QueryResult<GetScopedSelfDepartmentsResult> = useQuery(
    FetchSelfScopedDepartments,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const FETCH_SCHEDULES_RESULT = useQuery(FETCH_SCHEDULES, {
    variables: {
      departmentId: currentDepartmentId,
      shiftStartDate: startOfMonthUTC,
      shiftEndDate: endOfMonthUTC,
      month: moment(startOfMonthUTC).local().format('MMMM').toLowerCase(),
      year: moment(startOfMonthUTC).local().year(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const {loading, variables, data} = FETCH_SCHEDULES_RESULT;

  const viewModelScheduleData = useScheduleViewModelData({
    fetchRolesAndShiftsResult: FETCH_SCHEDULES_RESULT,
    currentDepartmentId: currentDepartmentId ?? 0,
    preventSplit: false,
  });

  if (loading || !fetchScopedSelfDepartmentsResult || !viewModelScheduleData) return <LoadingDiv />;

  const momentUTCStartDate = moment(variables.shiftStartDate).utc();

  const {roles} = data.locating.department;
  const newRolesData = data.locating.department?.schedule?.roles;

  tryDispatchUserIdForColorAssignement(getUserIdSet(roles || newRolesData));

  const departments = fetchScopedSelfDepartmentsResult.data?.me?.departments ?? [];
  // none selfScoped schedule cannot request swap shift
  const isSelfScoped = Boolean(departments.find((department) => currentDepartmentId === department.id));

  if (urlSearchParams.has('view') && urlSearchParams.get('view') === 'list') {
    return (
      <SchedulingBodyWrapper>
        <GridSettings>
          {newSearchableDropdownFF ? (
            <DepartmentSwitcherV2
              departmentId={currentDepartmentId ?? -1}
              onDepartmentSelect={onSelectDepartment}
              isSelfScoped={false}
            />
          ) : (
            <DepartmentSwitcher
              departmentId={currentDepartmentId}
              onSelectDepartment={onSelectDepartment}
              isSelfScoped={false}
            />
          )}
          <MonthSwitcher onSelectMonth={onSelectMonth} startDate={momentUTCStartDate} />
          <SchedulingToggleButton
            value={LISTSCHEDULINGVIEW}
            onChange={() => window.routerHistory.push(`/${SCHEDULING}`)}
          />
        </GridSettings>

        <SchedulingGridWrapper
          scheduleData={viewModelScheduleData}
          isInteractable={isSelfScoped}
          currentDepartmentId={currentDepartmentId ?? 0}
        />
      </SchedulingBodyWrapper>
    );
  }

  return (
    <SchedulingGridSwapSectionContainer
      fetchRolesAndShiftsResult={FETCH_SCHEDULES_RESULT}
      isSelfScoped={isSelfScoped}
      currentDepartmentId={currentDepartmentId ?? 0}
      onSelectMonth={onSelectMonth}
      momentUTCStartDate={momentUTCStartDate}
    />
  );
};

export default (props) => (
  <SchedulingVariableContext.Consumer>
    {(contextProps) => <SchedulingGridQueryContainer {...contextProps} {...props} />}
  </SchedulingVariableContext.Consumer>
);
