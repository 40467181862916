import React from 'react';
import {FormikProps} from 'formik';
import InputLabel from '@mui/material/InputLabel';
import ErrorMessage, {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import InputAdornment, {InputAdornmentProps} from '@mui/material/InputAdornment';
import styled from '@emotion/styled';
import PasswordCheckList from 'src/components/PasswordCheckList';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {ResetPasswordFormValues} from 'src/types';
import {muiTheme} from 'src/styles/theme';

const StyledPasswordCheckListWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  ul {
    margin-bottom: 5px !important;
    transform: translateX(-14px);
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  position: absolute;
  cursor: pointer;
  right: 1em;
  p {
    font-size: 0.9rem !important;
  }
  i {
    color: lightgrey;
  }
` as React.ComponentType<InputAdornmentProps>;

interface Props {
  formikProps: FormikProps<ResetPasswordFormValues>;
  isLoading: boolean;
}

const ResetPasswordFormStepTwo = ({formikProps, isLoading}: Props) => {
  const {values, errors, touched, isSubmitting, handleChange, handleBlur} = formikProps as FormikProps<any>;

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.recoverPasswordNewPassword);
  }, []);

  const [showPassword, setPassword] = React.useState(false);
  const hasError = touched.password && Boolean(errors.password);

  return (
    <React.Fragment>
      <div>Time to reset your new password</div>
      <StyledFormControl disabled={isSubmitting || isLoading}>
        <InputLabel
          shrink
          htmlFor="password"
          style={hasError ? undefined : {color: muiTheme.colors.greyishBrown}}
          error={hasError}
        >
          Password
        </InputLabel>
        <StyledInputBase
          fullWidth
          autoFocus
          id="password"
          name="password"
          placeholder="Enter password"
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
          error={hasError}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            // tslint:disable jsx-no-lambda
            <StyledInputAdornment position="end" onClick={() => setPassword((prev) => !prev)}>
              {showPassword ? (
                <i className="material-icons">visibility_off</i>
              ) : (
                <i className="material-icons">visibility</i>
              )}
            </StyledInputAdornment>
          }
        />
        <ErrorMessage name="password" />
      </StyledFormControl>

      <StyledPasswordCheckListWrapper>
        <PasswordCheckList showConfirm={false} newpassword={values.password} />
      </StyledPasswordCheckListWrapper>
    </React.Fragment>
  );
};

export default ResetPasswordFormStepTwo;
