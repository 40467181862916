import React, {useEffect} from 'react';
import {AuthContext} from 'src/auth';
import {defaultTime} from 'src/constants/sessionTimeout';
import FirebaseRemoteConfigModule from 'src/components/SessionTimeout/FirebaseRemoteConfigModule';
import {isFirebaseRemoteConfigSupported} from 'src/firebase/firebaseSetup';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

interface Props {
  logout: () => void;
}

const ForcedLogoutTimerHandler = ({logout}: Props) => {
  const firebaseRemoteConfigModule = FirebaseRemoteConfigModule();
  const currentOrganizationId = getCurrentOrganizationId();

  const handleLogout = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.sessionTimeoutForceLogout,
    });
    logout();
    window.location.reload();
  };

  useEffect(() => {
    let forcedLogoutTimeout = setTimeout(handleLogout, defaultTime.FORCED_TIMEOUT);

    const getForcedTimeoutData = async () => {
      const result = await isFirebaseRemoteConfigSupported();
      if (result) {
        const forcedTimeout = (await firebaseRemoteConfigModule.getOrganizationConfig(String(currentOrganizationId)))
          ?.forced_timeout;

        if (forcedTimeout) {
          clearTimeout(forcedLogoutTimeout);
          forcedLogoutTimeout = setTimeout(handleLogout, forcedTimeout);
        }
      }
    };

    getForcedTimeoutData();

    return () => clearTimeout(forcedLogoutTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default () => (
  <AuthContext.Consumer>
    {({isLoggedIn, logout}) => (isLoggedIn ? <ForcedLogoutTimerHandler logout={logout} /> : null)}
  </AuthContext.Consumer>
);
