import React from 'react';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import ResetPasswordForm from 'src/pages/ResetPasswordPage/reset-password/ResetPasswordFormContainer';
import {BackToPreviousLink} from 'src/styles/styled-components/Link';

const HorizontalLogoWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
  padding-top: 140px;
  padding-bottom: 80px;
`;

const ResetPwdTitle = styled.h1`
  font-family: Nunito;
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.2px;
  text-align: center;
  color: dimgrey;
  margin-bottom: 35px;
`;

const PageWrapper = styled.div`
  max-width: 480px;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
`;

const ResetPwdContainer = styled.div`
  width: 100%;
  min-width: 310px;
`;

class ResetPasswordPage extends React.Component<any> {
  public render() {
    return (
      <PageWrapper>
        <Helmet>
          <title>Reset Password - Hypercare</title>
        </Helmet>
        <ResetPwdContainer>
          <HorizontalLogoWrapper>
            <img src="/assets/horizontal-logo-red.svg" alt="hypercare logo with text" />
          </HorizontalLogoWrapper>
          <ResetPwdTitle>Reset Password</ResetPwdTitle>
          <ResetPasswordForm onResetComplete={null} />
        </ResetPwdContainer>
        <BackToPreviousLink>
          <span className="material-icons">chevron_left</span>
          <Link to={`/login`}>Back to Login Page</Link>
        </BackToPreviousLink>
      </PageWrapper>
    );
  }
}

export default ResetPasswordPage;
