import {memo} from 'react';
import Chevron from 'src/svgs/Chevron';
import styled from '@emotion/styled';
import {LocatingRole, typedUseSelector} from 'src/types';
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import {ADMIN_NOTES} from 'src/constants/strings';
import {RoleNoteLoader} from 'src/components/loaders/RoleNotesLoader';
import Divider from '@mui/material/Divider';
import {BasicNoteField, HiddenNotes} from 'src/types/HiddenNotes';

const PREFIX = 'NoteDetailsExpansionPanel';

const classes = {
  expandWrapper: `${PREFIX}-expandWrapper`,
  summaryHeaderWrapper: `${PREFIX}-summaryHeaderWrapper`,
  summaryNoteWrapper: `${PREFIX}-summaryNoteWrapper`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.expandWrapper}`]: {
    boxShadow: 'none',
    margin: '0 !important',
  },

  [`& .${classes.summaryHeaderWrapper}`]: {
    backgroundColor: 'white',
  },

  [`& .${classes.summaryNoteWrapper}`]: {
    padding: 0,
    overflowWrap: 'break-word',
  },
}));

const NoteDetails = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

const RoleNote = styled.li`
  color: #4a4a4a;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

interface INoteDetailsExpansionPanel {
  role: LocatingRole;
  departmentId: number;
  isGivenRoleFavourite: (role: LocatingRole) => boolean;
}
const NoteDetailsExpansionPanel = ({role, departmentId, isGivenRoleFavourite}: INoteDetailsExpansionPanel) => {
  const onCallFavouriteRoleNotesStoreData = typedUseSelector((store) => store.onCall.OnCallFavouriteRoleNotes);
  const onCallRoleNotesStoreData = typedUseSelector((store) => store.onCall.OnCallRoleNotes);

  const notes = onCallFavouriteRoleNotesStoreData[role?.id];
  const favouriteNotes = onCallRoleNotesStoreData[departmentId]?.[role?.id];

  const notesForCurrentRole = notes ?? favouriteNotes;

  return (
    <Root>
      {notesForCurrentRole?.notes?.length >= 1 ? (
        <>
          <Divider />
          <Accordion defaultExpanded={true} className={classes.expandWrapper} key={`department`}>
            <AccordionSummary
              className={classes.summaryHeaderWrapper}
              expandIcon={<Chevron />}
              aria-controls="department-content"
            >
              <NoteDetails>{ADMIN_NOTES(notesForCurrentRole)}</NoteDetails>
            </AccordionSummary>
            <AccordionDetails className={classes.summaryNoteWrapper}>
              {!notesForCurrentRole ? (
                <div style={{width: '100%', height: '100%'}}>
                  <RoleNoteLoader viewBox="0 0 400 50" />
                </div>
              ) : (
                <ul style={{overflow: 'hidden', margin: 0}}>
                  {notesForCurrentRole &&
                    notesForCurrentRole.notes.map((note: HiddenNotes) => {
                      return <RoleNote>{note?.note}</RoleNote>;
                    })}
                </ul>
              )}
            </AccordionDetails>
          </Accordion>
        </>
      ) : null}
    </Root>
  );
};

export default memo(NoteDetailsExpansionPanel);
