import * as React from 'react';
import styled from '@emotion/styled';
import {ApolloConsumer} from 'react-apollo';
import {AuthContext} from 'src/auth/AuthProvider';
import {User} from 'src/types';
import {getFullName} from 'src/utils/user';
import handleGotoAlternativeContact from 'src/utils/messengerHelper/SwitchChatToAlternateContact';
import {ON_SHIFT, BUSY, UNAVAILABLE} from 'src/constants/workStatus';
import InfoIcon from 'src/svgs/InfoIcon';
import AlertTriangleIcon from 'src/svgs/AlertTriangleIcon';
import moment from 'moment';
import {DAY} from 'src/constants/strings';

const BannerHolder = styled.div<{
  status?: string;
}>`
  display: flex;
  padding: 10px;
  align-items: center;
  color: ${(props) => props.theme.colors.greyishBrown};
  background-color: ${(props) => (props.status === ON_SHIFT ? '#CCE7EB' : '#FFF4CD')};
  font-family: Nunito;
  font-size: 14px;
  margin: 10px;
  border-radius: 5px;
`;

const ContextArea = styled.div<{showCursor: boolean}>`
  cursor: ${(props) => (props.showCursor ? 'pointer' : 'unset')};
  &::first-letter {
    text-transform: capitalize;
  }
`;

const StatusDescription = styled.div<{statusDescription: string}>`
  span {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const IconWrapper = styled.div`
  width: 5vh;
  height: 100%;
`;

const AlternativeContact = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;

  &::first-letter {
    text-transform: capitalize;
  }
`;

const BannerHeading = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
`;

const AlternativeContactBanner = ({authInfo, chat}) => {
  const selfId = authInfo.user.id;
  const targetUser: User = chat.members.filter((member) => member.id !== selfId)[0];

  if (!targetUser) return null;

  const {workStatus, workStatusProxy, statusDescription, firstname, lastname, statusExpiryDate} = targetUser;

  const isToday = moment().isSame(statusExpiryDate, DAY);

  if (
    (workStatus === 'on_shift' && statusDescription && statusDescription.toLowerCase() === 'available') ||
    (workStatus === 'on_shift' && !statusDescription && !workStatusProxy)
  ) {
    return null;
  }

  const BannerHeader = () => {
    let header: string | null = null;
    if (workStatusProxy && workStatus === ON_SHIFT)
      header = `This user has assigned ${getFullName(workStatusProxy)} as their alternate contact.`;
    else if (workStatus === BUSY)
      header = `This user is set to Busy and will not receive any general message notifications until ${moment(
        statusExpiryDate,
      ).format('HH:mm')} ${
        isToday ? 'today' : `at ${moment(statusExpiryDate).format('DD MMM YYYY')}`
      }. Notifications for Urgent and STAT messages will still go through. ${
        Boolean(workStatusProxy) ? `They have assigned ${getFullName(workStatusProxy)} as alternate contact.` : ''
      }`;
    else if (workStatus === UNAVAILABLE)
      header = `This user is set to Unavailable and will not receive ANY notifications (including STAT and Urgent messages) until ${moment(
        statusExpiryDate,
      ).format('HH:mm')} ${isToday ? 'today' : `at ${moment(statusExpiryDate).format('DD MMM YYYY')}`}. ${
        Boolean(workStatusProxy) ? `They have assigned ${getFullName(workStatusProxy)} as alternate contact.` : ''
      }`;

    return header ? <BannerHeading>{header}</BannerHeading> : null;
  };

  return (
    <BannerHolder status={workStatus}>
      <IconWrapper>{workStatus === ON_SHIFT ? <InfoIcon /> : <AlertTriangleIcon stroke={'#FF3E55'} />}</IconWrapper>
      <ContextArea showCursor={Boolean(workStatusProxy)}>
        {BannerHeader()}
        {statusDescription && (
          <StatusDescription statusDescription={statusDescription}>
            <span>{`${firstname} ${lastname}'s status: `}</span>
            {statusDescription}
          </StatusDescription>
        )}
        {workStatusProxy && (
          <AlternativeContact onClick={() => handleGotoAlternativeContact(workStatusProxy)}>
            Message {getFullName(workStatusProxy)}
          </AlternativeContact>
        )}
      </ContextArea>
    </BannerHolder>
  );
};

export default (props) => (
  <AuthContext.Consumer>
    {({authInfo}) => (
      <ApolloConsumer>{() => <AlternativeContactBanner {...props} authInfo={authInfo} />}</ApolloConsumer>
    )}
  </AuthContext.Consumer>
);
