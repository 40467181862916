import * as React from 'react';
import ChatList from 'src/pages/MessengerPage/messenger/chat-list-layout/ChatList';
import LoadingDiv from 'src/components/LoadingDiv';
import ConnectionStatusIndicator from 'src/components/ConnectionStatusIndicator';
import styled from '@emotion/styled';
import store from 'src/redux';
import {JOINORGANIZATION} from 'src/constants/routerPathName';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import ChatListLoader from 'src/components/loaders/ChatListLoader';

const NoMessageContainer = styled.div`
  text-align: center;
`;

const MainMessage = styled.p`
  font-size: 20px;
`;

const SecondaryMessage = styled.p`
  color: grey;
  padding: 0 2em;
  font-size: 15px;
  strong {
    cursor: pointer;
    color: black;
    text-decoration: underline;
    font-weight: 600;
  }
`;

/**
 * Network status doc:
 * https://www.apollographql.com/docs/react/api/react-apollo/#datanetworkstatus
 * 3 indicates that fetchmore was called
 */

export default (props) => {
  const {
    loading,
    continuationId,
    fetchMore,
    chats,
    networkStatus,
    isSearch,
    handleIsSearch,
    handleJumpToMessage,
    isJumpToMessageEnable,
  } = props;

  return (
    <React.Fragment>
      <ConnectionStatusIndicator />
      {loading && networkStatus !== 3 ? (
        <div style={{width: '100%', height: '100%'}}>
          <ChatListLoader viewBox="0 0 400 750" />
        </div>
      ) : chats.length === 0 ? (
        <NoMessageContainer>
          {!store.getState().organization.organizationId ? (
            <React.Fragment>
              <MainMessage>No organization selected</MainMessage>
              <SecondaryMessage>Click the dropdown icon at the top to select an organization.</SecondaryMessage>
              <SecondaryMessage>
                If there is no dropdown icon, join an organization by clicking&nbsp;
                <strong
                  onClick={() => {
                    window.routerHistory.push(`/${JOINORGANIZATION}`);
                  }}
                >
                  here
                </strong>
                &nbsp;or by clicking your name and selecting "Join Organization".
              </SecondaryMessage>
              <SecondaryMessage>If you need help, please contact support for further assistance.</SecondaryMessage>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <MainMessage>No messages yet</MainMessage>
              <SecondaryMessage>Message your contacts by clicking the icon above to create new chat</SecondaryMessage>
            </React.Fragment>
          )}
        </NoMessageContainer>
      ) : (
        <React.Fragment>
          <ChatList
            continuationId={continuationId}
            fetchMore={fetchMore}
            chats={chats}
            isFetchMoreLoading={networkStatus === 3}
            isSearch={isSearch}
            handleIsSearch={handleIsSearch}
            handleJumpToMessage={handleJumpToMessage}
            searchThroughChat={true}
            isJumpToMessageEnable={isJumpToMessageEnable}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
