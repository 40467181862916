import React, {useState} from 'react';
import {ACCESS, VISIBILITY} from 'src/constants/hiddenNotes';
import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import Chevron from 'src/svgs/Chevron';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import {UserAddress, UserAddressAccess} from 'src/types';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import styled from '@emotion/styled';
import {StyledInputBase} from 'src/components/StepFormPartials';
import {HCLabelOne} from 'src/components/HypercareComponents';

const PREFIX = 'ChangeAccessActionInput';

const classes = {
  root: `${PREFIX}-root`,
  select: `${PREFIX}-select`,
};

const StyledSelect = styled(Select)(({theme}) => {
  return {
    [`&.${classes.root}`]: {
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
      padding: 0,
      width: '180px',
      '&:hover': {
        backgroundColor: theme.colors.ghostWhite,
      },
    },

    [`& .${classes.select}`]: {
      '&&': {
        paddingRight: 0,
      },
      '& ul': {
        borderRadius: '4px',
        backgroundColor: theme.colors.messageWhite,
        display: 'flex',
        padding: '8px',
        flexDirection: 'column',
        alignItems: 'flexStart',
      },
      '& li': {
        padding: '10px 8px',
      },
    },
  };
});

const StyledMenuItem = styled(MenuItem)`
  .Mui-selected {
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.ghostWhite};
  }
`;

interface Props {
  isInputDisabled: boolean;
  index: number;
  setFieldValue: (filedId: string, fieldValue: string) => void;
  currentAddress: UserAddress;
  isEditProfileFormLoading: boolean;
  setEditProfileFormLoading: (flag: boolean) => void;
  setCurrentAddresses?: (addresses: UserAddress[]) => void;
  addresses: UserAddress[];
}

const ChangeAccessActionInput = ({
  isInputDisabled,
  index,
  currentAddress,
  addresses,
  isEditProfileFormLoading,
  setEditProfileFormLoading,
  setCurrentAddresses,
}: Props) => {
  const {access, id} = currentAddress;
  const [currentAddressAccess, setCurrentAddressAccess] = useState<string>(access);
  const {handleAddressVisibilityChange} = HypercareSelfProfileViewModel();

  const handleChangeAccess = async (e: SelectChangeEvent<any>) => {
    setEditProfileFormLoading(true);
    const updatedAccess = e.target.value as UserAddressAccess;
    AnalyticsManager.applyAnalytics({
      eventName:
        updatedAccess === ACCESS.PRIVATE
          ? EVENTS.editProfileVisibilityPrivateOptionPressed
          : EVENTS.editProfileVisibilityPublicOptionPressed,
    });
    setCurrentAddressAccess(updatedAccess);
    handleAddressVisibilityChange(currentAddress, updatedAccess);

    const newAddressArray = addresses.map((address) =>
      address.id === currentAddress.id ? {...address, access: updatedAccess} : address,
    );
    setCurrentAddresses?.(newAddressArray);
  };

  const handleSelectOpen = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileChangeVisibilityOptionPressed,
    });
  };

  return (
    <StyledSelect
      onClick={handleSelectOpen}
      id={id}
      inputProps={{
        id: `addresses.${index}.access`,
        name: `addresses.${index}.access`,
      }}
      disabled={isInputDisabled || isEditProfileFormLoading}
      variant="standard"
      value={currentAddressAccess}
      onChange={handleChangeAccess}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        disablePortal: true,
      }}
      IconComponent={!isInputDisabled ? Chevron : () => null}
      input={<StyledInputBase />}
    >
      <StyledMenuItem value={'public'}>
        <HCLabelOne>{VISIBILITY.PUBLIC}</HCLabelOne>
      </StyledMenuItem>
      <StyledMenuItem value={'private'}>
        <HCLabelOne>{VISIBILITY.PRIVATE}</HCLabelOne>
      </StyledMenuItem>
    </StyledSelect>
  );
};

export default ChangeAccessActionInput;
