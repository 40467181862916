import {Dialog, DialogContent, DialogContentText, DialogActions, IconButton, DialogTitle} from '@mui/material';
import CloseIcon from 'src/svgs/CloseIcon';
import styled from '@emotion/styled';
import {PrimaryButton, SecondaryButton} from 'src/styles/styled-components/StyledMaterialComponents';

const PREFIX = 'TemplateModal';

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  dialogDescription: `${PREFIX}-dialogDescription`,
  dialogAction: `${PREFIX}-dialogAction`,
  dialogIconButton: `${PREFIX}-dialogIconButton`,
  dialogContent: `${PREFIX}-dialogContent`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.dialogTitle}`]: {
    margin: '24px 24px 16px 24px',
    padding: '0px',
  },

  [`& .${classes.dialogDescription}`]: {
    fontFamily: 'open sans',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#4A4A4A',
    marginBottom: '0px',
  },

  [`& .${classes.dialogAction}`]: {
    margin: '16px 24px 16px 24px',
    padding: '0px',
  },

  [`& .${classes.dialogIconButton}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    float: 'right',
  },

  [`& .${classes.dialogContent}`]: {
    padding: '0px 24px 40px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

export const DialogBoxTitle = styled(DialogTitle)`
  h2 {
    font-family: 'Nunito Sans' !important;
    font-weight: 700 !important;
    font-size: 28px;
    line-height: 36px !important;
    color: #ff3e55 !important;
  }
`;

const TemplateModal = ({openModal, handleCloseModal}) => {
  return (
    <StyledDialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogBoxTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {'Quit editing?'}
        <IconButton onClick={handleCloseModal} className={classes.dialogIconButton} size="large">
          <CloseIcon color="#4A4A4A" width="15" height="15" />
        </IconButton>
      </DialogBoxTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogDescription} id="alert-dialog-description">
          All the information that you have entered so far will not saved. This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <SecondaryButton type="submit" onClick={handleCloseModal}>
          Back to editing
        </SecondaryButton>
        <PrimaryButton type="button" onClick={() => window.routerHistory.push('/messenger/home')}>
          Quit anyway
        </PrimaryButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default TemplateModal;
