import {Box} from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

const PREFIX = 'SignupProgressCopy';

const classes = {
  container: `${PREFIX}-container`,
  progress: `${PREFIX}-progress`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`&.${classes.container}`]: {
    height: 10,
    backgroundColor: theme.colors.lightGrey,
    borderRadius: '8px 8px 0px 0px',
  },

  [`& .${classes.progress}`]: {
    height: 10,
    backgroundColor: theme.colors.watermelon,
    borderRadius: '8px 8px 0px 0px',
    transition: 'width .8s',
  },
}));

interface ISignupProgressProps {
  value: number;
}

export const SignupProgressCopy: React.FC<ISignupProgressProps> = ({value}) => {
  return (
    <StyledBox className={classes.container}>
      <Box className={classes.progress} style={{width: `${value}px`}} />
    </StyledBox>
  );
};
