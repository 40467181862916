import React from 'react';
import client from 'src/apollo';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import CircularProgress from '@mui/material/CircularProgress';
import {toast} from 'react-toastify';
import {PickUpMarketplaceShiftMutation} from 'src/gql/mutation/OfferMarketplaceMutation';
import {ButtonsWrapper, ModalBodyWrapper, ErrorText} from 'src/styles/styled-components/SharedModalStyles';
import {MarketplaceRefetchRequestType} from './MarketplaceRefetchProvider';

const ReasonText = styled.p`
  line-height: 1.5em;
  margin-bottom: 2em;
`;

const StyledButtonsWrapper = styled(ButtonsWrapper)`
  button {
    width: 180px !important;
  }
`;

interface Props {
  isOpen: boolean;
  postId?: number;
  note: string;
  refetch: MarketplaceRefetchRequestType | null;
  closeModal: () => void;
  closeAll: () => void;
  warningReason: string;
  customizedToast: () => JSX.Element;
}

const MarketplaceDoubleConfirmationModal = ({
  isOpen,
  postId,
  note,
  refetch,
  closeAll,
  closeModal,
  warningReason,
  customizedToast,
}: Props) => {
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleSendRequest = async () => {
    try {
      setLoading(true);
      setError('');
      await client.mutate({
        mutation: PickUpMarketplaceShiftMutation,
        variables: {
          postId,
          note,
          shouldIgnoreWarnings: true,
        },
      });
      toast.info(customizedToast(), {
        autoClose: false,
      });
      closeAll();
      refetch?.();
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(`Failed to take shift from marketplace, please check your internet connection and try again`);
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={closeModal}
    >
      <Header>
        <span>Warning</span>
        {!isLoading && <CloseBtn onClick={closeModal} />}
      </Header>
      <ModalBodyWrapper>
        <ReasonText>{warningReason}. Do you still wish to proceed?</ReasonText>

        <ErrorText>{error}</ErrorText>

        <StyledButtonsWrapper>
          <Button variant="contained" disabled={isLoading} disableTouchRipple onClick={closeModal}>
            nevermind
          </Button>
          <Button
            variant="contained"
            onClick={handleSendRequest}
            disabled={isLoading}
            color="secondary"
            disableTouchRipple
          >
            {isLoading ? <CircularProgress size={20} /> : <span>proceed anyway</span>}
          </Button>
        </StyledButtonsWrapper>
      </ModalBodyWrapper>
    </StyledModal>
  );
};

export default MarketplaceDoubleConfirmationModal;
