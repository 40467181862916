import {createAction, ActionType, getType} from 'typesafe-actions';
import {DepartmentRoleNoteObject, HiddenNotes, MultipleDepartmentRoleNoteObject} from 'src/types/HiddenNotes';
import {LocatingDepartmentScope, OnCallDepartmentScope, Schedule} from '../../types';
import {Actions} from '../reducers';

export const HypercareOnCallActions = {
  setOnCallRoleNotes: createAction(
    'scheduling/SET_ON_CALL_ROLE_NOTES',
    (resolve) => (values: MultipleDepartmentRoleNoteObject) => resolve({values}),
  ),
  setOnCallFavouriteRoleNotes: createAction(
    'scheduling/SET_ON_CALL_FAVOURITE_ROLE_NOTES',
    (resolve) => (values: DepartmentRoleNoteObject) => resolve({values}),
  ),
  setOnCallDepartments: createAction(
    'scheduling/SET_ON_CALL_DEPARTMENTS',
    (resolve) => (values: LocatingDepartmentScope[]) => resolve({values}),
  ),
  setCurrentSelectedSite: createAction(
    'scheduling/SET_CURRENT_SELECTED_SITE',
    (resolve) => (values: {id: number; name: string}) => resolve({values}),
  ),
  setOnCallSites: createAction('scheduling/SET_CURRENT_ON_CALL_SITE', (resolve) => (values) => resolve({values})),
};

export type onCallActions = ActionType<typeof HypercareOnCallActions>;

export interface HypercareOnCallState {
  OnCallRoleNotes: {
    [key: string]: {
      [innerKey: string]: {
        notes: HiddenNotes[];
        roleName: string;
      };
    };
  };
  OnCallFavouriteRoleNotes: {
    [key: string]: {
      notes: HiddenNotes[];
      roleName: string;
    };
  };

  OnCallDepartments: LocatingDepartmentScope[];
  currentSelectedSite: {
    id?: number;
    name?: string;
    image?: string;
  };
  OnCallSites: {
    [key: string]: LocatingDepartmentScope[];
  };
}

export const initialState: HypercareOnCallState = {
  OnCallRoleNotes: {},
  OnCallFavouriteRoleNotes: {},
  OnCallDepartments: [],
  OnCallSites: {},
  currentSelectedSite: {},
};

const hypercareOnCallReducer = (state: HypercareOnCallState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(HypercareOnCallActions.setOnCallRoleNotes):
      return {
        ...state,
        OnCallRoleNotes: {...action.payload.values},
      };
    case getType(HypercareOnCallActions.setOnCallFavouriteRoleNotes):
      return {
        ...state,
        OnCallFavouriteRoleNotes: {...action.payload.values},
      };

    case getType(HypercareOnCallActions.setOnCallDepartments):
      return {
        ...state,
        OnCallDepartments: [...action.payload.values],
      };

    case getType(HypercareOnCallActions.setCurrentSelectedSite):
      return {
        ...state,
        currentSelectedSite: {...action.payload.values},
      };

    case getType(HypercareOnCallActions.setOnCallSites):
      return {
        ...state,
        OnCallSites: {...action.payload.values},
      };
    default:
      return state;
  }
};

export default hypercareOnCallReducer;
