import {createAction, ActionType, getType} from 'typesafe-actions';
import {UserOrganizationSwitcherPayload} from 'src/types';
import {Actions} from '../reducers';

export const actions = {
  setCurrentOrganization: createAction(
    'organization/SET',
    (resolve) => (currentOrganization) => resolve({currentOrganization}),
  ),
};

export type OrganizationActions = ActionType<typeof actions>;

export const initialState: OrganizationState = {
  type: '',
  image: '',
  name: '',
  organizationId: null,
};

export interface OrganizationState extends UserOrganizationSwitcherPayload {}

export default (state: OrganizationState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(actions.setCurrentOrganization):
      const newState = {...action.payload.currentOrganization};
      return {...newState};
    default:
      return state;
  }
};
