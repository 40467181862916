import React from 'react';
import moment from 'moment';
import {ActivatedLevel, EscalationLevel} from 'src/types';
import stringifyNumberSuffix from 'src/utils/stringifyNumberSuffix';
import {getFullName} from 'src/utils/user';
import styled from '@emotion/styled';

const EscalationLevelResponderProgressionWrapper = styled.div<{
  flag: boolean;
}>`
  filter: ${(props) => (props.flag ? `opacity(0.5)` : 'unset')};
`;

const EscalationLevelResponderNameCard = styled.div`
  border-radius: 8px;
  padding: 1em;
  background-color: lightgrey;
  margin-bottom: 4px;
  div:first-of-type {
    font-weight: bold;
    line-height: 1.5em;
  }
  div:last-of-type {
    font-size: 14px;
    line-height: 1.5em;
    color: ${(props) => props.theme.colors.greyishBrown};
  }
`;

const EscalationLevelResponderProgression = styled.div<{isResponded: boolean}>`
  font-size: 13px;
  line-height: 2em;
  color: ${(props) => (props.isResponded ? props.theme.colors.chatTeal : props.theme.colors.greyishBrown)};
`;

const AlertingMessage = styled.span`
  color: ${(props) => props.theme.colors.redPink};
  font-size: 14px;
  line-height: 1.5em;
`;
interface Props {
  acknowledgedAt: string;
  selfLevel: EscalationLevel;
  activatedLevel: ActivatedLevel;
  activatedLevels: ActivatedLevel[];
  isGreaterThanResponderLevel: boolean;
  isLessThanStartingLevel: boolean;
  isEscalationActive: boolean;
  isResponded: boolean;
}

const EscalationDetailContent = ({
  acknowledgedAt,
  selfLevel,
  activatedLevel,
  activatedLevels,
  isGreaterThanResponderLevel,
  isEscalationActive,
  isLessThanStartingLevel,
  isResponded,
}: Props) => {
  const StatusTextDecider = () => {
    if (!activatedLevel) return 'Starting escalation...';
    if (isLessThanStartingLevel) return;
    if (isResponded) return `Acknowledged at ${moment(acknowledgedAt).format('HH:mm')}`;
    if (!isEscalationActive) return 'Escalation has ended';
    if (selfLevel.level > activatedLevel.level) return 'escalation has not started';

    // at this point, escalation is active and currently trying escalating someone
    const hasBeenEscalated = !!activatedLevels.find((level) => level.id === selfLevel.id);

    if (hasBeenEscalated && activatedLevel.id !== selfLevel.id) {
      return `No response from level ${selfLevel.level}, escalating to level ${selfLevel.level + 1}`;
    }
    if (activatedLevel.id === selfLevel.id) {
      return `${stringifyNumberSuffix(activatedLevel.currentAttempt)} attempt in progress - next attempt at 
      ${moment(activatedLevel.nextAttemptAt).format('HH:mm')}`;
    }
    return '';
  };

  return (
    <React.Fragment>
      <EscalationLevelResponderProgressionWrapper
        flag={Boolean(isGreaterThanResponderLevel || isLessThanStartingLevel)}
      >
        {selfLevel.escalationMembers.map((member, index) => (
          <React.Fragment key={index}>
            <EscalationLevelResponderNameCard>
              <div>
                {member.user?.id && getFullName(member.user)}
                {member.role?.id && member.role.name}
              </div>
              <div>
                {member.user?.id && (member.user.role ? member.user.role : '--')}
                {member.role?.id &&
                  (member.role.currentShift?.user ? getFullName(member.role.currentShift.user) : '--')}
              </div>
            </EscalationLevelResponderNameCard>
          </React.Fragment>
        ))}
        <EscalationLevelResponderProgression isResponded={isResponded}>
          {StatusTextDecider()}
        </EscalationLevelResponderProgression>
      </EscalationLevelResponderProgressionWrapper>
      {isLessThanStartingLevel && <AlertingMessage>The message will not be escalated to this level</AlertingMessage>}
    </React.Fragment>
  );
};

export default EscalationDetailContent;
