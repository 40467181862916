import React, {useState} from 'react';
import RedirectingToADFSStep from '../SignupPage/steps/redirecting-to-adfs.step';
import {HypercareSignupEmailView} from './HypercareSignupEmail/HypercareSignupEmailView';
import HypercareSignupEmailViewModel from './HypercareSignupEmail/HypercareSignupEmailViewModel';
import HypercareSignupRepository from './HypercareSignupRepository';

import {AuthContext} from 'src/auth';
import {Link, Redirect} from 'react-router-dom';
import styled from '@emotion/styled';
import HypercareLogoSVG from 'src/svgs/HypercareLogoSVG';
import HypercareSignupVerifyAddressViewModel from './HypercareSignupVerifyEmailAddress/HypercareSignupVerifyAddressViewModel';
import {HypercareSignupVerifyEmailAddressView} from './HypercareSignupVerifyEmailAddress/HypercareSignupVerifyAddressView';
import HypercareSignupPhoneNumberViewModel from './HypercareSignupPhoneNumber/HypercareSignupPhoneNumberViewModel';
import {HypercareSignupPhoneNumberView} from './HypercareSignupPhoneNumber/HypercareSignupPhoneNumberView';
import {HypercareSignupPersonalDetailsView} from './HypercareSignupPersonalDetails/HypercareSignupPersonalDetailsView';
import HypercareSignupPersonalDetailsViewModel from './HypercareSignupPersonalDetails/HypercareSignupPersonalDetailsViewModel';
import {HypercareSignupCreateAccountView} from './HypercareSignupCreateAccount/HypercareSignupCreateAccountView';
import {AutoLogin} from './components/AutoLogin';
import HypercareSignupCreateAccountViewModel from './HypercareSignupCreateAccount/HypercareSignupCreateAccountViewModel';
import AlertModal from '../MessengerPage/messenger/messages-layout/message-template/AlertModal';

const SignupPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  overflow-x: hidden;
`;

const SignupPageHypercareLogoContainer = styled.div`
  margin-top: 5%;
  width: 250px !important;
  height: 48px !important;
`;

const FooterText = styled.div`
  margin-top: -40px;
  height: 28px;
  line-height: 2em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.greyishBrown};
  a {
    color: ${(props) => props.theme.colors.chatTeal};
    text-decoration: none;
    font-weight: bold;
  }
`;

const HypercareSignupCoordinator = ({isLoggedIn}) => {
  const [currentStep, setCurrentStep] = useState(1);

  const [autoLogin, setAutoLogin] = useState(false);
  const [auth0Id, setAuth0Id] = useState<any>();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [emailVerificationChallengeId, setEmailVerificationChallengeId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [challengeId, setChallengeId] = useState('');

  const [showExitModal, setShowExitModal] = useState(false);

  const changeExitModal = () => {
    setShowExitModal((prevState) => !prevState);
  };

  const handleContinueSignupEmail = (values) => {
    setChallengeId(values.challengeId);
    setEmail(values.email);
    if (values.auth0Id) {
      setAuth0Id(values.auth0Id);
    } else {
      setCurrentStep((prevValue) => prevValue + 1);
    }
  };

  const handleContinueVerifyEmail = (values) => {
    setEmailVerificationChallengeId(values.challengeId);
    setCurrentStep((prevValue) => prevValue + 1);
  };

  const handleContinueSignupPhoneNumber = (values) => {
    setPhoneNumber(values.phoneNumber);
    setCurrentStep((prevValue) => prevValue + 1);
  };

  const handleContinuePersonalDetails = (values) => {
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setJobTitle(values.jobTitle);
    setCurrentStep((prevValue) => prevValue + 1);
  };

  const handleGoBackADFS = () => {
    setCurrentStep(1);
    setAuth0Id(null);
  };

  const handleGoBack = () => {
    switch (currentStep) {
      case 1:
        window.routerHistory.push('/login');
        break;
      case 2:
      case 4:
      case 5:
        setCurrentStep((prevValue) => prevValue - 1);
        break;
      default:
        break;
    }
  };

  const handleLogin = (values) => {
    setUsername(values.username);
    setPassword(values.password);
    setAutoLogin(true);
  };

  const showSignupEmailView = () => {
    const EmailViewModel = new HypercareSignupEmailViewModel(handleContinueSignupEmail);
    const repository = new HypercareSignupRepository();
    EmailViewModel.repository = repository;
    return <HypercareSignupEmailView email={email} viewModel={EmailViewModel} onBackButtonClick={handleGoBack} />;
  };

  const showSignupEmailValidationView = () => {
    const EmailValidationViewModel = new HypercareSignupVerifyAddressViewModel(handleContinueVerifyEmail);
    const repository = new HypercareSignupRepository();
    EmailValidationViewModel.repository = repository;
    return (
      <HypercareSignupVerifyEmailAddressView
        inputData={{email}}
        viewModel={EmailValidationViewModel}
        onBackButtonClick={handleGoBack}
        setChallengeId={setChallengeId}
        challengeId={challengeId}
      />
    );
  };

  const showSignupPhoneNumberView = () => {
    const PhoneNumberViewModel = new HypercareSignupPhoneNumberViewModel(handleContinueSignupPhoneNumber);
    const repository = new HypercareSignupRepository();
    PhoneNumberViewModel.repository = repository;
    return (
      <HypercareSignupPhoneNumberView
        viewModel={PhoneNumberViewModel}
        phoneNumber={phoneNumber}
        onBackButtonClick={changeExitModal}
      />
    );
  };

  const showSignupPersonalDetailsView = () => {
    const AboutYourselfViewModel = new HypercareSignupPersonalDetailsViewModel(handleContinuePersonalDetails);
    const repository = new HypercareSignupRepository();
    AboutYourselfViewModel.repository = repository;

    return (
      <HypercareSignupPersonalDetailsView
        onBackButtonClick={handleGoBack}
        viewModel={AboutYourselfViewModel}
        inputData={{firstName, lastName, jobTitle}}
      />
    );
  };

  const showCreateAccountView = () => {
    const CreateAccountViewModel = new HypercareSignupCreateAccountViewModel(handleLogin);
    const repository = new HypercareSignupRepository();
    CreateAccountViewModel.repository = repository;

    return (
      <HypercareSignupCreateAccountView
        inputData={{
          email,
          phoneNumber,
          firstName,
          lastName,
          jobTitle,
          emailVerificationChallengeId,
        }}
        viewModel={CreateAccountViewModel}
        onBackButtonClick={handleGoBack}
      />
    );
  };

  if (isLoggedIn) {
    return <Redirect to="/messenger/home" />;
  }

  if (autoLogin) {
    return (
      <>
        <AuthContext.Consumer>
          {({login}) => <AutoLogin login={login} username={username} password={password} />}
        </AuthContext.Consumer>
      </>
    );
  }

  return (
    <SignupPageContainer>
      <SignupPageHypercareLogoContainer>
        <HypercareLogoSVG />
      </SignupPageHypercareLogoContainer>

      {currentStep === 1 && !Boolean(auth0Id) && showSignupEmailView()}
      {Boolean(auth0Id) && (
        <RedirectingToADFSStep
          onBackButtonClick={handleGoBackADFS}
          onContinue={() => null}
          inputData={{
            email,
          }}
          auth0Id={`${auth0Id}`}
        />
      )}
      {currentStep === 2 && showSignupEmailValidationView()}
      {currentStep === 3 && showSignupPhoneNumberView()}
      {currentStep === 4 && showSignupPersonalDetailsView()}
      {currentStep === 5 && showCreateAccountView()}

      {showExitModal ? (
        <AlertModal
          id="exit-modal"
          width="xs"
          title="Leave this page?"
          titleFontSize="21px"
          subtitle="Information you have entered will not be saved if you leave this page."
          isAlertModalVisible={showExitModal}
          closeAlertModal={() => setShowExitModal(false)}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Stay',
              onClickHandler: () => changeExitModal(),
              id: 'exit-stay',
            },
            {
              type: 'primary',
              buttonLabel: 'Leave',
              onClickHandler: () => window.location.assign('/'),
              id: 'exit-leave',
            },
          ]}
        />
      ) : null}

      {currentStep === 1 && (
        <FooterText>
          Already using Hypercare? <Link to="/login">Log in</Link>
        </FooterText>
      )}
    </SignupPageContainer>
  );
};

export default () => (
  <AuthContext.Consumer>
    {({isLoggedIn}) => <HypercareSignupCoordinator isLoggedIn={isLoggedIn} />}
  </AuthContext.Consumer>
);
