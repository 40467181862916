import React, {useEffect} from 'react';
import {IADFSOrganization, IStepProps} from 'src/types';
import {AuthContext} from 'src/auth';
import {Auth0ContextInterface} from '@auth0/auth0-react';

import StepHeader from '../components/step-header.component';
import {StepContainerCopy} from 'src/pages/newSignupFlow/components/step-container-copy';

interface IRedirectToADFSProps {
  delay: number;
  connection: string;
  auth0props: Auth0ContextInterface;
}

const RedirectToADFS: React.FC<IRedirectToADFSProps> = ({delay, connection, auth0props}) => {
  useEffect(() => {
    let interval = setTimeout(() => {
      auth0props.loginWithRedirect({
        connection,
        scope: 'openid profile email',
      });
    }, delay);

    return function clean() {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return null;
};

export interface ISignupEmailStepInputData {
  email: string;
  adfsOrganization?: IADFSOrganization;
  auth0Id?: string;
}

const RedirectingToADFSStep: React.FC<IStepProps<ISignupEmailStepInputData, null>> = ({
  inputData,
  onBackButtonClick,
  onContinue,
  auth0Id,
}) => {
  return (
    <AuthContext.Consumer>
      {({auth0props}) => (
        <StepContainerCopy progress={true}>
          <RedirectToADFS
            delay={1000}
            connection={auth0Id ?? inputData?.adfsOrganization?.auth0Id ?? ''}
            auth0props={auth0props}
          />
          <StepHeader
            isDisabled={true}
            title="Taking you to your organization's sign-in page"
            description={`We detect that your work email can be used for secure organization sign on. You will be taken to ${inputData?.adfsOrganization?.auth0Id} in a second...`}
          />
        </StepContainerCopy>
      )}
    </AuthContext.Consumer>
  );
};

export default RedirectingToADFSStep;
