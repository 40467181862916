import React from 'react';
import {NO_SEARCH_RESULT_FOUND_BODY, NO_SEARCH_RESULT_FOUND_TITLE} from 'src/constants/strings';
import styled from '@emotion/styled';
import {HCHeadingThree, HCLabelOne} from 'src/components/HypercareComponents';
import {muiTheme} from 'src/styles/theme';

const NoSearchResultFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
  gap: 8px;
`;

export const NoSearchResultsFound = () => {
  return (
    <NoSearchResultFoundContainer>
      <HCHeadingThree color={muiTheme.colors.greyishBrown}>{NO_SEARCH_RESULT_FOUND_TITLE}</HCHeadingThree>
      <HCLabelOne color={muiTheme.colors.textLightTertiary}>{NO_SEARCH_RESULT_FOUND_BODY}</HCLabelOne>
    </NoSearchResultFoundContainer>
  );
};
