import styled from '@emotion/styled';

export const Layout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0.5em;
  flex-basis: 300px;
`;

export const UserDescription = styled.div`
  line-height: 1em;
  margin: 0 1em;
  width: 100%;
  max-width: 200px;
`;

export const UserName = styled.div`
  font-size: 0.8em;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

export const UserRole = styled.div`
  font-size: 0.6em;
  color: ${(props) => props.theme.colors.iconGrey};
`;
