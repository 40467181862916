import React, {useEffect} from 'react';
import {typedUseSelector, AppDispatch} from 'src/redux';
import styled from '@emotion/styled';
import {useDispatch} from 'react-redux';
import ModalImage from 'react-modal-image';
import mapMimeTypeToIconName from 'src/utils/mapMimeTypeToIconName';

const OverrideModalImage = styled(ModalImage)`
  display: block;
  width: 100%;
  object-fit: contain;
  height: 100%;
`;

const ImagePreviewListWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 10px 6px;
  border-top: 1px solid lightgrey;
  overflow-x: auto;
  height: 130px;
`;

const HorizontalScrollWrapper = styled.div`
  display: flex;
  position: absolute;
`;

const PreviewImageWrapper = styled.div`
  display: flex;
  position: relative;
  overflow-x: auto;
  display: 'inline-flex';
  border-radius: 2px;
  border: 1px solid lightgrey;
  margin-right: 8px;
  width: 160px;
  min-width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  overflow: hidden;
  i {
    user-select: none;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    border: 1px solid lightgrey;
    background-color: white;
    display: none;
    right: 5px;
    font-size: 14px;
    padding: 5px;
  }
  &:hover i {
    display: block;
  }
`;

const PreviewFileWrapper = styled(PreviewImageWrapper)`
  width: 160px;
  min-width: 160px;
`;

const FileNameAndType = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const FileName = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  font-size: 14px;
  padding-bottom: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
`;

const FileType = styled.div`
  font-size: 18px;
  font-family: Roboto;
  font-weight: bolder;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.main};
`;

const PreviewThumbs = () => {
  const files = typedUseSelector((state) => state.filedrop.files);
  const {selectedTemplate} = typedUseSelector((state) => state.messageTemplate);
  const dispatch: AppDispatch = useDispatch();

  const hasFiles = React.useRef(false);

  useEffect(() => {
    if (files && files.length > 0) {
      hasFiles.current = true;
    } else {
      hasFiles.current = false;
    }
  }, [files]);

  useEffect(() => {
    if (hasFiles.current) dispatch({type: 'filedrop/CLEAR'});
    return () => {
      if (hasFiles.current) dispatch({type: 'filedrop/CLEAR'});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedTemplate && files && files.length > 0) {
    return (
      <ImagePreviewListWrapper>
        <HorizontalScrollWrapper>
          {files.map((file, index) =>
            file.type.includes('image') ? (
              <PreviewImageWrapper key={file.name + '-' + index}>
                <OverrideModalImage
                  small={file.preview}
                  large={file.preview}
                  hideZoom={true}
                  showRotate={true}
                  imageBackgroundColor={'transparent'}
                />
                <i
                  className="material-icons"
                  onClick={() =>
                    dispatch({
                      type: 'filedrop/REMOVE',
                      payload: {index},
                    })
                  }
                >
                  close
                </i>
              </PreviewImageWrapper>
            ) : (
              <PreviewFileWrapper key={file.name + '-' + index}>
                <FileNameAndType>
                  <FileName>{file.name}</FileName>
                  <FileType>
                    {Boolean(mapMimeTypeToIconName[file.type]) && (
                      <img alt="file_type_icon" src={`/assets/${mapMimeTypeToIconName[file.type]}.svg`} />
                    )}
                  </FileType>
                </FileNameAndType>
                <i
                  className="material-icons"
                  onClick={() =>
                    dispatch({
                      type: 'filedrop/REMOVE',
                      payload: {index},
                    })
                  }
                >
                  close
                </i>
              </PreviewFileWrapper>
            ),
          )}
        </HorizontalScrollWrapper>
      </ImagePreviewListWrapper>
    );
  }
  return null;
};

export default PreviewThumbs;
