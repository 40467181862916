import TemplateViewModal from './TemplateViewModal';
import {TemplateViews} from 'src/types';
import {MessageTemplate, TemplateElements} from 'src/types/MessageTemplate';

/**
 * Class created to use Factory builder approach to create UI from JSON.
 * This class parses the elements JSON array and renders the specific element for that specific element on the
 * basis of element's JSON type key
 */
export default class MessageTemplateFactory {
  messageTemplateJson: MessageTemplate;
  templateView: TemplateViews;
  constructor(messageTemplateJson: MessageTemplate, templateView: TemplateViews) {
    this.messageTemplateJson = messageTemplateJson;
    this.templateView = templateView;
  }

  /**
   * Function parses through the JSON UI elements and inflates a template view modal class object and passes the
   * json for that particular element into the contructor and for each object type - specific HTML element is created
   * and pushed into the childNodes array which is returned into the UI.
   */
  public createUIWithJSONParser(): Array<React.ReactNode> {
    let elementsJsonArray =
      this.templateView === TemplateViews.RequestEditView ||
      this.templateView === TemplateViews.RequestReview ||
      this.templateView === TemplateViews.ReviewResponse
        ? this.messageTemplateJson.request.children
        : this.messageTemplateJson.response.children;
    let childNodes: React.ReactNode[] = [];

    elementsJsonArray?.forEach((elementJson, index) => {
      const templateViewModal = new TemplateViewModal(elementJson, index, this.templateView);

      switch (elementJson.type) {
        case TemplateElements.Textfield:
        case TemplateElements.MultilineTextfield:
          childNodes.push(templateViewModal.renderTextfieldElement());
          break;
        case TemplateElements.Label:
          childNodes.push(templateViewModal.renderLabelElement());
          break;
        case TemplateElements.Dropdown:
          childNodes.push(templateViewModal.renderDropdownElement());
          break;
        case TemplateElements.Checkbox:
          childNodes.push(templateViewModal.renderCheckboxElement());
          break;
        case TemplateElements.Radio:
          childNodes.push(templateViewModal.renderRadioElement());
          break;
        case TemplateElements.Toggle:
          childNodes.push(templateViewModal.renderToggleElement());
          break;
        case TemplateElements.Attachments:
          childNodes.push(templateViewModal.renderAttachmentsElement());
          break;
        case TemplateElements.Datepicker:
          childNodes.push(templateViewModal.renderDatePickerElement());
          break;
        default:
          break;
      }
    });

    return childNodes;
  }
}
