import React from 'react';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Chevron from 'src/svgs/Chevron';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {useDepartmentList} from 'src/pages/SchedulingPage/scheduling/hooks';

const dropdownWidth = 300;
const lineHeight = 45;

const StyledPopper = styled(Popper)`
  width: ${dropdownWidth}px;
  margin-top: 5px;
  z-index: 3;
`;

const StyledTextField = styled(TextField)`
  width: ${dropdownWidth}px;
  .MuiInputBase-root {
    height: ${lineHeight}px;
    .MuiOutlinedInput-input {
      padding: 0 1em;
      height: min-content;
      line-height: ${lineHeight}px;
    }
  }
`;

const StyledMenuPaper = styled(Paper)`
  position: relative;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const MenuItem = styled.div<{isCurrent: boolean}>`
  width: ${dropdownWidth}px;
  height: ${lineHeight}px;
  line-height: ${lineHeight}px;
  padding: 0 1em;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(props) => (props.isCurrent ? 'lightgrey' : 'unset')};
  &:hover {
    background-color: whitesmoke;
  }
`;

interface Props {
  departmentId: number | null;
  isSelfScoped: boolean;
  onSelectDepartment: (departmentId: number) => void;
}

const DepartmentSwitcher = ({departmentId, onSelectDepartment, isSelfScoped}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  // TODO: department switcher should also show sites information as well, change the result scope?
  const {fullDepartmentList, selfDepartmentList} = useDepartmentList();
  const departmentList = (isSelfScoped ? selfDepartmentList : fullDepartmentList).sort((departmentA, departmentB) =>
    departmentA.name.toLowerCase().localeCompare(departmentB.name.toLowerCase()),
  );

  const isViewOnly = (targetDepartmentId: number) =>
    !selfDepartmentList.find((department) => department.id === targetDepartmentId);
  const currentDepartment = departmentList.find((department) => department.id === departmentId);

  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleChange = (departmentId: number) => {
    if (currentDepartment?.id === departmentId) {
      handleClickAway();
    } else {
      onSelectDepartment(departmentId);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    document.getElementById('dorpdownInput')?.blur();
  };

  return (
    <React.Fragment>
      <AlertModal
        title={'No departments'}
        subtitle={
          'There are no departments defined in your organization. To use scheduling, please set up departments first.'
        }
        closeAlertModal={() => {}}
        isAlertModalVisible={!currentDepartment}
        showCloseButton={false}
        alertModalButtons={[
          {
            buttonLabel: 'Go Back',
            type: 'primary',
            onClickHandler: () => {
              window.history.go(-1);
              return false;
            },
          },
        ]}
      />
      <StyledTextField
        id="dorpdownInput"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Chevron />
            </InputAdornment>
          ),
        }}
        value={currentDepartment?.name}
        onClick={handleInputClick}
        variant="outlined"
      />
      <StyledPopper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom'} transition>
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <StyledMenuPaper>
                {departmentList.map((department) => (
                  <MenuItem
                    isCurrent={currentDepartment?.id === department.id}
                    key={`department-${department.id}`}
                    onClick={() => handleChange(department.id)}
                    title={department.name}
                  >
                    {department.name}
                    {isViewOnly(department.id) && ' (view only)'}
                  </MenuItem>
                ))}
              </StyledMenuPaper>
            </Fade>
          </ClickAwayListener>
        )}
      </StyledPopper>
    </React.Fragment>
  );
};

export default DepartmentSwitcher;
