import React from 'react';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {Container, ContactSupportLink} from '../styled/signup.styled';
import {HCLabelTwo} from 'src/components/HypercareComponents';
import {muiTheme} from 'src/styles/theme';
import {getBaseClientURL} from 'src/utils/getBaseClientUrl';

interface ISignupFooterProps {
  recoverAccount?: boolean;
}

const SignupFooter = (props: ISignupFooterProps) => {
  const onContactSupport = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signUpContactSupport,
    });
  };

  return (
    <Container>
      {!props.recoverAccount ? (
        <HCLabelTwo color={muiTheme.colors.greyishBrown}>
          Phone number is already in use
          <ContactSupportLink
            href={`${getBaseClientURL()}/resetpassword`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onContactSupport}
          >
            Recover account
          </ContactSupportLink>
        </HCLabelTwo>
      ) : null}
      <HCLabelTwo color={muiTheme.colors.greyishBrown}>
        Need help?
        <ContactSupportLink
          href="https://www.hypercare.com/contact"
          target="_blank"
          rel="noopener noreferrer"
          onClick={onContactSupport}
        >
          Contact support
        </ContactSupportLink>
      </HCLabelTwo>
    </Container>
  );
};

export default SignupFooter;
