import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from '@emotion/styled';
import {muiTheme} from 'src/styles/theme';

const StyledTabs = styled(Tabs)`
  .MuiTabs-root {
    padding: 0 20px;
    border-bottom: 1px solid ${muiTheme.colors.borderBottomLight};
  }

  .MuiTabs-indicator {
    background-color: ${muiTheme.colors.androidGreen};
  }
`;

// DOC: https://material-ui.com/components/tabs/#customized-tabs

const StyledTab = styled(Tab)`
  .MuiTab-root {
    margin-right: 24px !important;
    min-width: 72px;
    padding-top: 0;
    padding-bottom: 0;

    & .MuiTab-wrapper {
      text-transform: capitalize;
      font-weight: 400;
      font-family: 'Nunito';
    }

    &:hover .MuiTab-wrapper {
      color: ${muiTheme.colors.androidGreen};
      opacity: 1;
    }

    &:focus .MuiTab-wrapper {
      color: ${muiTheme.colors.androidGreen};
    }

    &.Mui-selected .MuiTab-wrapper {
      color: ${muiTheme.colors.androidGreen};
      font-weight: 500;
    }
  }
`;

interface Props {
  value: string;
  values: string[];
  setValue: (value: string) => void;
}

const CustomizedTabs = ({value, values, setValue}: Props) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    newValue = newValue.replace(' ', '');
    setValue(newValue);
  };

  return (
    <StyledTabs value={value} onChange={handleChange} aria-label="tabs">
      {values.map((currentValue) => {
        let currentValueWithoutSpace = currentValue.replace(' ', '');
        return <StyledTab key={currentValueWithoutSpace} value={currentValueWithoutSpace} label={currentValue} />;
      })}
    </StyledTabs>
  );
};

export default CustomizedTabs;
