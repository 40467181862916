import React, {PureComponent} from 'react';
import styled from '@emotion/styled';
import ProfilePic from 'src/components/ProfilePic';
import {User} from 'src/types';
import {Layout, UserDescription, UserName, UserRole} from 'src/pages/MessengerPage/messenger/StyledUserList';

export const StyledLayout = styled(Layout)`
  max-width: 300px;
`;

interface UserListItemV2Props {
  colleague: {data: User};
  onClick?: (colleague: User) => void;
}

const UserListItemV2 = ({colleague, onClick}: UserListItemV2Props) => {
  const handleClick = () => {
    if (onClick) {
      onClick(colleague.data);
    }
  };

  if (!colleague.data) console.log(colleague);
  if (!colleague.data) return null;

  return (
    <StyledLayout onClick={handleClick}>
      <ProfilePic users={[colleague.data]} />
      <UserDescription>
        <UserName>{colleague.data.label}</UserName>
        <UserRole>{colleague.data.role}</UserRole>
      </UserDescription>
    </StyledLayout>
  );
};

export default UserListItemV2;
