import React from 'react';
import {HCLabelOne} from '../../components/HypercareComponents';
import {INVITE_USER_MODAL_FLOW_SEARCH_PLACEHOLDER_TEXT, NO_SEARCH_RESULT_FOUND_TITLE} from '../../constants/strings';
import styled from '@emotion/styled';
import CustomSearchField from '../../components/shared/CustomSearchField';
import {CircularProgress} from '@mui/material';

export const LoadingSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserSearchContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  padding: 8px 0px;
`;

const FAKE_ORG_DATA = [
  {
    id: '57104b3e-9a85-4d67-ab3a-',
    label: 'Sick kids',
    url: 'string',
    name: 'string',
    logo: 'https://s3-alpha-sig.figma.com/img/0b39/b904/891775d06cfce5282651d194ad11bc68?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YeWeiH-QNfmgp5eUDazzWXlrTAsMyMVZIVmU2CfhKT95vjydhuVmdERiOZQ73lbAZ7DH8CsrCNpIECdB-BXBrYK9T-9muosU3SR9V0RElsKpFb9Ed797IGQ4tG7Od3vy8~4t6ticUA-aDhiQtTYJWmcuJvKpkgqJddQKaaMWX7i8BFiy~uKLT3aC80YAxlNPSNO7EO8Hrd3b0iKzn58Qq7kSwOgkyCb4yOQ5uPCID9cS4feh-1SeQT3mKpYokCYmIkGJmrsFIJDUulIv8ws65M2Tl5DnogQ1WGqF9iaFd9PSrE7MY3GZl6gFRtQ3Cb60Ca8WnVsC7gzUq55x2uXTmQ__',
  },
  {
    id: '57104b3e-9a85-4d67--9dcf01301d68',
    label: 'Acme Teaching Hopsital',
    url: undefined,
    name: 'Acme Teaching Hopsital',
    logo: undefined,
  },
  {
    id: '-9a85-4d67-ab3a-9dcf01301d68',
    label: 'Hypercare',
    url: undefined,
    name: 'Hypercare',
    logo: undefined,
  },
  {
    id: '-9a85-3a-9dcf01301d68',
    label: 'AapplyBy',
    url: undefined,
    name: 'ApplyBy',
    logo: undefined,
  },
  {
    id: '-9a85-3a-9dcf01301d68',
    label: 'AapplyBy',
    url: undefined,
    name: 'ApplyBy',
    logo: undefined,
  },
  {
    id: '-9a85-3a-9dcf01301d68',
    label: 'AapplyBy',
    url: undefined,
    name: 'ApplyBy',
    logo: undefined,
  },
];

export interface HCClickFilterOption {
  id: string;
  label: string;
  url?: string;
  name: string;
  logo?: string;
}
interface Props<TOption> {
  onClear: () => void;
  searchText: string;
  handleSearch: (e) => void;
  isSearchLoading: boolean;
  options: {
    title: string;
    options: TOption[];
  };
  optionRender: (option: TOption) => React.ReactNode;
}

export const HCClickFilter = <TOption,>({
  isSearchLoading,
  handleSearch,
  optionRender,
  searchText,
  options,
  onClear,
}: Props<TOption>) => {
  const renderData = (rows, noRowsString = 'No results found') => {
    return rows.length > 0 ? (
      rows.map((row) => (
        <div style={{display: 'flex', alignItems: 'center'}}>{optionRender ? optionRender(row) : row.label}</div>
      ))
    ) : (
      <div>{noRowsString}</div>
    );
  };

  const dropdownResults = () => {
    if (searchText.length === 0) {
      return null;
    }

    if (searchText.length > 0 && options.options.filter((opt) => options.options.length > 0).length <= 0) {
      return <div>{NO_SEARCH_RESULT_FOUND_TITLE}</div>;
    }

    return (
      <div>
        <div style={{marginTop: 0}}>
          {options.options.length === 0 ? (
            <div style={{paddingBottom: 10}}>
              <HCLabelOne>{NO_SEARCH_RESULT_FOUND_TITLE}</HCLabelOne>
            </div>
          ) : (
            renderData(options.options)
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomSearchField
        clearSearchText={onClear}
        handleChange={(e) => handleSearch(e.target.value)}
        localSearchText={searchText}
        placeHolder={'Enter atleast two characters to initiate search'}
        inputWidth={'600px'}
        inputHeight={'40px'}
      />
      {isSearchLoading ? (
        <LoadingSearchContainer>
          <CircularProgress />
        </LoadingSearchContainer>
      ) : (
        <UserSearchContainer>{dropdownResults()}</UserSearchContainer>
      )}
    </>
  );
};
