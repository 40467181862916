import React from 'react';
import {FormikProps} from 'formik';
import InputLabel from '@mui/material/InputLabel';
import ErrorMessage, {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {ResetPasswordFormValues} from 'src/types';
import {muiTheme} from 'src/styles/theme';

interface Props {
  formikProps: FormikProps<ResetPasswordFormValues>;
  isLoading: boolean;
}

const ResetPasswordFormStepZero = ({formikProps, isLoading}: Props) => {
  const {values, errors, touched, isSubmitting, handleChange, handleBlur} = formikProps;

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.recoverPasswordUsername);
  }, []);

  const hasError = touched.username && Boolean(errors.username);

  return (
    <div>
      <div>
        <h4 style={{marginBottom: '8px'}}>Whoops, forgot something?</h4>
        No worries, let us make sure you are registered with Hypercare first.
      </div>
      <StyledFormControl disabled={isSubmitting || isLoading}>
        <InputLabel
          shrink
          htmlFor="username"
          style={hasError ? undefined : {color: muiTheme.colors.greyishBrown}}
          error={hasError}
        >
          Username
        </InputLabel>
        <StyledInputBase
          fullWidth
          autoFocus
          id="username"
          name="username"
          placeholder="Enter User Name"
          value={values.username}
          onBlur={handleBlur}
          onChange={handleChange}
          error={hasError}
        />
        <ErrorMessage name="username" />
      </StyledFormControl>
    </div>
  );
};

export default ResetPasswordFormStepZero;
