import React, {useState} from 'react';
import {Formik, FormikErrors, FormikTouched} from 'formik';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {phoneNumberValidationSchema} from 'src/utils/validations';
import {StepContainerCopy} from '../components/step-container-copy';
import NewStepHeader from '../components/step-header-copy';
import NewNextStepButton from '../components/NextStepButton';
import NewStepFooter from '../components/step-footer-copy';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import store from 'src/redux';
import {CANADA_ABBREVIATION, EUROPE_ABBREVIATION, UNITED_STATES_ABBREVIATION} from 'src/constants/strings';
import {ADDRESS_IN_USE} from 'src/constants/networkError';
import styled from '@emotion/styled';

interface IPhoneInputField {
  value: string;
  name: string;
  setFieldValue?: (field: string, value: string) => void;
  touched?: FormikTouched<{phoneNumber: boolean}>;
  errors?: FormikErrors<{phoneNumber: string}>;
}

const ErrorSpan = styled.span`
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

const StyledInputField = styled(PhoneInput)<{$isError: boolean}>`
  .form-control {
    border-color: ${(props) => (props.$isError ? '#f44336' : '')};
    &:hover {
      border-color: ${(props) => (props.$isError ? '#f44336' : '')};
    }
  }
  .special-label {
    color: ${(props) => (props.$isError ? '#f44336' : '')};
  }
`;

const PhoneInputField = ({value, name, touched, errors, setFieldValue}: IPhoneInputField) => {
  const currentAuthRegion = store.getState().hypercareRegion.currentAuthRegion;
  const displayCurrentRegionFlag = () => {
    let currentRegion: string;
    switch (currentAuthRegion) {
      case CANADA_ABBREVIATION:
        currentRegion = 'ca';
        break;
      case UNITED_STATES_ABBREVIATION:
        currentRegion = 'us';
        break;
      case EUROPE_ABBREVIATION:
        currentRegion = 'es';
        break;
      default:
        currentRegion = 'ca';
        break;
    }
    return currentRegion;
  };

  const onValueChange = (phoneNumber: string) => {
    setFieldValue?.(name, phoneNumber);
  };
  return (
    <StyledInputField
      $isError={!!touched?.phoneNumber && !!errors && Object.keys(errors).length > 0}
      disableCountryGuess={true}
      placeholder="123-456-7890"
      inputProps={{
        name: 'phoneNumber',
      }}
      country={displayCurrentRegionFlag()}
      value={value}
      onChange={onValueChange}
      inputStyle={{
        width: '100%',
      }}
      enableSearch={true}
    />
  );
};

export const HypercareSignupPhoneNumberView = ({phoneNumber, onBackButtonClick, viewModel}) => {
  const [recoverAccount, setRecoverAccount] = useState(false);

  const handleRecoverAccountText = (value) => setRecoverAccount(value);

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupRequestAddressVerification);
  }, []);

  const onNextClick = (phone: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupPhoneNextPressed,
      params: {
        phone_number: phone,
      },
    });
  };

  return (
    <StepContainerCopy currentStep={3} showOverflow={false}>
      <NewStepHeader
        isDisabled={false}
        title="Step 3 of 5: Enter your phone number"
        description="Add your phone number to aid in account recovery if you ever need to reset your password"
        onBackButtonClick={onBackButtonClick}
        discard={true}
      />
      <Formik
        initialValues={{phoneNumber}}
        validationSchema={phoneNumberValidationSchema}
        validateOnMount
        onSubmit={async (values, actions) => {
          let trimmedPhoneNumber = values.phoneNumber.trim();
          return phoneNumberValidationSchema
            .validate(trimmedPhoneNumber.replace(/\s/g, '').replace('(', '').replace(')', ''))
            .then(async () => {
              AnalyticsManager.applyAnalytics({
                eventName: EVENTS.signUpPhoneEntered,
                params: {
                  phone_number: trimmedPhoneNumber,
                },
              });
              const result = await viewModel.handleNextButtonPressed({phoneNumber: trimmedPhoneNumber});

              if (result.error) {
                if (result.error.code === ADDRESS_IN_USE) {
                  handleRecoverAccountText(true);
                } else {
                  actions.setFieldError('phoneNumber', result.error.message);
                }
              }
            })
            .catch((err) => {
              actions.setFieldError('phoneNumber', err?.errors[0]);
            });
        }}
      >
        {({handleSubmit, values, touched, errors, isSubmitting, setFieldValue}) => (
          <form onSubmit={handleSubmit}>
            <PhoneInputField
              value={values.phoneNumber}
              name="phoneNumber"
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
            />
            {touched.phoneNumber && errors.phoneNumber && <ErrorSpan>{errors.phoneNumber}</ErrorSpan>}{' '}
            <NewNextStepButton
              label={'Next'}
              onClick={() => onNextClick(values.phoneNumber)}
              disabled={isSubmitting}
              loading={isSubmitting}
              loadingLabel="Verifying availability..."
            />
          </form>
        )}
      </Formik>
      <NewStepFooter recoverAccount={recoverAccount} />
    </StepContainerCopy>
  );
};
