import styled from '@emotion/styled';
import {RoleHolderStyledComponentProps} from 'src/types';

interface RoleHolderStyledComponentPropsWithSwap extends RoleHolderStyledComponentProps {
  isSelecting: boolean;
  shouldGreyOut: boolean;
}

/** grid areas */

export const DayOfWeek = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;
  align-items: flex-end;
  justify-content: center;
  padding-left: 2px;
  z-index: 2;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
`;

export const DateGrid = styled.div`
  background-color: lightgray;
  border: 1px solid lightgray;
  border-top: unset;
`;

export const ActionCalendar = styled.div`
  width: 100%;
  min-width: min-content;
  ${DayOfWeek}, ${DateGrid} {
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(7, 1fr);
  }
`;

/** general */

export const Weekday = styled.div`
  font-family: Nunito;
  line-height: 1.5;
  padding-top: 2px;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

export const DateHolder = styled.div`
  min-width: 165px;
  min-height: 185px;
  padding: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

export const DateIndicator = styled.div`
  align-self: flex-start;
  font-size: 14px;
  width: 100%;
  margin-bottom: 4px;
`;

/** Assignee Details */

export const AssigneeWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const RoleHolderTitle = styled.div`
  padding-bottom: 6px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  width: 100%;
`;

export const RoleHolderTitleTime = styled.div`
  letter-spacing: normal;
  padding-left: 2px;
  min-width: max-content;
`;

export const RoleHolderTitleName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
`;

export const RoleHolderWrapper = styled.div<RoleHolderStyledComponentPropsWithSwap>`
  padding: 4px 6px;
  position: relative;
  user-select: none;
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${(props) => (props.shouldGreyOut ? 'lightgrey' : 'inherit')};
  border-left: 5px solid ${(props) => (props.shouldGreyOut ? 'rgba(182, 186, 219, 0.15)' : props.assignedColor)};
  background-color: ${(props) =>
    props.shouldGreyOut ? 'rgba(182, 186, 219, 0.08)' : props.assignedColorBackground || 'lightgrey'};

  &:hover {
    background-color: ${(props) => props.assignedColorBackground || 'lightgrey'};
    border-left: 5px solid ${(props) => props.assignedColor};
    color: ${(props) => props.theme.colors.blackFontColor};
  }
`;

export const RoleHolderDetail = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-break: anywhere;
  overflow-wrap: anywhere;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RoleIconHolder = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

export const SwapShiftIconHolder = styled.div`
  position: relative;
  bottom: 0;
  right: -2px;
  height: 1em;
  width: 20px;
  padding: 0 4px;
  background-color: ${(props) => props.theme.colors.priorityYellow};
  border-radius: 2px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const MarketplaceIconHolder = styled.div`
  display: flex;
  height: 100%;
  width: 28px;
  justify-content: flex-end;
  svg {
    margin-left: 2px;
    &:nth-child(2) {
      margin-left: 4px;
    }
  }
`;
