import {createAction, ActionType, getType} from 'typesafe-actions';
import {Message, PriorityType} from 'src/types';
import {Actions} from '../reducers';

export const actions = {
  updateDraftMessage: createAction(
    'drafts/UPDATE_MESSAGE',
    (resolve) => (chatId: string, input: string) => resolve({chatId, input}),
  ),
  updateDraftRepliedTo: createAction(
    'drafts/UPDATE_REPLY',
    (resolve) => (chatId: string, repliedTo: Message) => resolve({chatId, repliedTo}),
  ),
  clearDraftMessage: createAction('drafts/CLEAR_MESSAGE', (resolve) => (chatId: string) => resolve({chatId})),
  clearDraftRepliedTo: createAction('drafts/CLEAR_REPLY', (resolve) => (chatId: string) => resolve({chatId})),
  deleteRepliedTo: createAction('drafts/DELETE_REPLY', (resolve) => (chatId: string) => resolve({chatId})),
  updatePriorityType: createAction(
    'drafts/UPDATE_PRIORITY',
    (resolve) => (chatId: string, priorityType: PriorityType) => resolve({chatId, priorityType}),
  ),
};

export type DraftsActions = ActionType<typeof actions>;

export const initialState: DraftsState = {
  draftMsgToChatId: {},
};

export interface DraftsState {
  draftMsgToChatId: {
    [chatId: string]: {
      input: string;
      repliedTo: Message | null;
      priorityType: PriorityType;
    };
  };
}

export default (state: DraftsState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(actions.updatePriorityType):
      let targetPriorityTypeChatId = action.payload.chatId;
      return {
        ...state,
        draftMsgToChatId: {
          ...state.draftMsgToChatId,
          [targetPriorityTypeChatId]: {
            ...state.draftMsgToChatId[targetPriorityTypeChatId],
            priorityType: action.payload.priorityType,
          },
        },
      };

    case getType(actions.updateDraftMessage):
      let messageChatId = action.payload.chatId;
      return {
        ...state,
        draftMsgToChatId: {
          ...state.draftMsgToChatId,
          [messageChatId]: {
            ...state.draftMsgToChatId[messageChatId],
            input: action.payload.input,
          },
        },
      };

    case getType(actions.updateDraftRepliedTo):
      let replyChatId = action.payload.chatId;
      return {
        ...state,
        draftMsgToChatId: {
          ...state.draftMsgToChatId,
          [replyChatId]: {
            ...state.draftMsgToChatId[replyChatId],
            repliedTo: action.payload.repliedTo,
          },
        },
      };

    case getType(actions.clearDraftMessage):
      let clearDraftChatId = action.payload.chatId;
      return {
        ...state,
        draftMsgToChatId: {
          ...state.draftMsgToChatId,
          [clearDraftChatId]: {
            ...state.draftMsgToChatId[clearDraftChatId],
            input: '',
          },
        },
      };

    case getType(actions.clearDraftRepliedTo):
      let clearReplyChatId = action.payload.chatId;
      return {
        ...state,
        draftMsgToChatId: {
          ...state.draftMsgToChatId,
          [clearReplyChatId]: {
            ...state.draftMsgToChatId[clearReplyChatId],
            repliedTo: null,
          },
        },
      };

    case getType(actions.deleteRepliedTo):
      let deleteReplyChatId = action.payload.chatId;
      const stateClone = {...state};
      delete stateClone.draftMsgToChatId[deleteReplyChatId];
      return {
        ...stateClone,
      };

    default:
      return state;
  }
};
