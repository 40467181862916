import * as React from 'react';
import moment from 'moment';
import {ScheduleViewModelData, Role} from 'src/types';
import {
  cellGap,
  cellWidth,
  dateFieldWidth,
  GridContainer,
  GridBodyArea,
  GridHeaderArea,
  GridHeader,
  GridBodyRow,
  GridBodyRowDate,
  GridBodyRowRole,
  GridHeaderNameHolder,
  GridHeaderRoleName,
  GridHeaderRepeatRule,
  RowDateHolder,
  BoldDate,
  RoleHolder,
  GridScrollProgressIndicatorWrapper,
  GridScrollProgressIndicator,
  RoleNoteGridHeader,
} from 'src/pages/SchedulingPage/scheduling/FullCalendarGridSystem';
import SchedulingShiftAssignees from 'src/pages/SchedulingPage/schedule-layout/SchedulingShiftAssignees';
import LoadingDiv from 'src/components/LoadingDiv';
import {PUBLISHED} from 'src/constants/scheduler';
import {typedUseSelector} from 'src/redux';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import GridRoleNoteHeader from 'src/pages/LocatingPage/locating/profile-section/notes-section/GridRoleNoteHeader';
import {useEffect} from 'react';
import HypercareSchedulingViewModel from 'src/pages/LocatingPage/viewModels/HypercareSchedulingViewModel';

interface ISchedulingGridWrapper {
  scheduleData: ScheduleViewModelData;
  isInteractable: boolean;
  currentDepartmentId: number;
}

// TODO: indicate which color representation is the current user
const SchedulingGridWrapper = ({scheduleData, isInteractable, currentDepartmentId}: ISchedulingGridWrapper) => {
  const [isMouseMoving, setMouseMoving] = React.useState(false);
  const {monthlyCalendar, roleContainer} = scheduleData;

  const userToHexColorCode = typedUseSelector((state) => state.scheduling.userToHexColorCode);
  const roleHiddenNotes = IsFeatureFlagEnabled('roleHiddenNotes');
  const {getRoleNotesForDepartment} = HypercareSchedulingViewModel();

  React.useEffect(() => {
    setGridHorizontalDragScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numberOfRoles = roleContainer.length;
  const gridBodyWidth = `calc(${cellWidth} * ${numberOfRoles} + ${cellGap} * ${numberOfRoles} + ${dateFieldWidth})`;

  const handleScroll = (event: React.FormEvent<HTMLElement>) => {
    const {currentTarget} = event;
    const headerElement = document.getElementById('gridHeaderArea');
    const bodyElement = document.getElementById('gridBodyArea');

    if (!bodyElement) return;
    let maxScrollLeft = bodyElement.scrollWidth - bodyElement.clientWidth;
    let scrollPercent = (bodyElement.scrollLeft / maxScrollLeft) * 100 + '%';
    document.getElementById('GridScrollProgressIndicator')?.style.setProperty('--scroll', scrollPercent);

    if (!headerElement) return;
    headerElement.scrollLeft = currentTarget.scrollLeft;
  };

  const setGridHorizontalDragScroll = () => {
    const slider = document.getElementById('gridBodyArea') as HTMLElement;
    if (!slider) return;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;
    let mousemoveFlag = false;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      mousemoveFlag = false;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      if (mousemoveFlag) {
        if (!isMouseMoving) setMouseMoving(true);
      } else {
        if (isMouseMoving) setMouseMoving(false);
      }
    });
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      mousemoveFlag = true;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      // velocity
      const walk = (x - startX) * 1.3;
      slider.scrollLeft = scrollLeft - walk;
    });
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getRoleNotesForDepartment(scheduleData.scheduleId!, currentDepartmentId);
    };
    getData();
  }, []);

  return (
    <React.Fragment>
      <GridScrollProgressIndicatorWrapper>
        <GridScrollProgressIndicator id="GridScrollProgressIndicator" />
      </GridScrollProgressIndicatorWrapper>
      <GridContainer id="gridContainer">
        <GridHeaderArea id="gridHeaderArea">
          <GridHeader gridBodyWidth={gridBodyWidth}>
            {roleContainer.map((role: Role, index: number) => (
              <GridHeaderNameHolder key={index} title={`${role.roleId}`}>
                <GridHeaderRoleName>{role.roleName}</GridHeaderRoleName>
                <GridHeaderRepeatRule>
                  {role.repeatRule.charAt(0).toUpperCase() + role.repeatRule.slice(1)} (
                  {`${role.startTime.format('HH:mm')} - ${role.endTime.format('HH:mm')}`})
                </GridHeaderRepeatRule>
                <GridHeaderRepeatRule>{role.duration === 86400 && <span>(24 hour shift)</span>}</GridHeaderRepeatRule>
              </GridHeaderNameHolder>
            ))}
          </GridHeader>
          {roleHiddenNotes && (
            <RoleNoteGridHeader gridBodyWidth={gridBodyWidth}>
              <GridRoleNoteHeader scheduleData={scheduleData} />
            </RoleNoteGridHeader>
          )}
        </GridHeaderArea>

        <GridBodyArea id="gridBodyArea" gridBodyWidth={gridBodyWidth} onScroll={handleScroll}>
          {!userToHexColorCode ? (
            <LoadingDiv />
          ) : (
            Object.keys(monthlyCalendar).map((date, index) => {
              const rolesPerDate = monthlyCalendar[date];
              return (
                <GridBodyRow gridBodyWidth={gridBodyWidth} key={index}>
                  <GridBodyRowDate>
                    <RowDateHolder title={`${moment(date).format('YYYY-MM-DD')}`}>
                      {moment(date, 'YYYY-MM-DD').format('ddd')}
                      <br />
                      <BoldDate>{moment(date, 'YYYY-MM-DD').format('DD')}</BoldDate>
                    </RowDateHolder>
                  </GridBodyRowDate>

                  <GridBodyRowRole>
                    {scheduleData.scheduleState === PUBLISHED &&
                      rolesPerDate.map((monthlyCalendarShift, index) => {
                        const {assignee, role} = monthlyCalendarShift;
                        return (
                          <RoleHolder key={index}>
                            <SchedulingShiftAssignees
                              role={role}
                              isInteractable={isInteractable}
                              assignees={assignee}
                              userToColorCodes={userToHexColorCode}
                            />
                          </RoleHolder>
                        );
                      })}
                  </GridBodyRowRole>
                </GridBodyRow>
              );
            })
          )}
        </GridBodyArea>
      </GridContainer>
    </React.Fragment>
  );
};

export default SchedulingGridWrapper;
