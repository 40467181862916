import React, {useState} from 'react';
import {toast} from 'react-toastify';
import ProfilePic from 'src/components/ProfilePic';
import LockIcon from 'src/svgs/LockIconHiddenNotes';
import {User} from 'src/types';
import {Select, MenuItem, TextField} from '@mui/material';
import EyeIcon from 'src/svgs/EyeIcon';
import {Button} from '@mui/material';
import NoteOptionButton from 'src/pages/LocatingPage/locating/profile-section/notes-section/NoteOptionButton';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import moment from 'moment';
import {
  AvatarMetaDataWrapper,
  ButtonWrapper,
  LockIconWrapper,
  MetaData,
  MetaDataWithName,
  NoteData,
  NoteDataUser,
  TextFeildLabel,
  UserName,
  Visibility,
  VisibilityData,
  VisibilityStatus,
  Wrapper,
} from 'src/styles/styled-components/NotesProfilePage';
import {HiddenNotes} from 'src/types/HiddenNotes';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {
  ACCESS,
  EDIT_NOTE_FORM_LABEL,
  NOTE_ADDED,
  NOTE_UPDATED,
  NOTE_VISIBILITY_PRIVATE_TEXT,
  NOTE_VISIBILITY_PUBLIC_TEXT,
  VISIBILITY,
} from 'src/constants/hiddenNotes';
import store from 'src/redux';
import NoteVisibilityWrapper from 'src/pages/LocatingPage/locating/profile-section/notes-section/NoteVisibilityWrapper';
import styled from '@emotion/styled';
import {SuccessToast} from 'src/components/CustomToasts';
import {muiTheme} from 'src/styles/theme';

const PREFIX = 'NoteCard';

const classes = {
  iconClassName: `${PREFIX}-iconClassName`,
  input: `${PREFIX}-input`,
};

const StyledWrapper = styled(Wrapper)<{visibilityColor: string}>(({visibilityColor}) => ({
  [`& .${classes.iconClassName}`]: {
    borderRadius: '22px',
    paddingRight: '7px',
    height: '28px',
    backgroundColor: visibilityColor,
  },

  [`& .${classes.input}`]: {
    fontFamily: 'Open Sans',
  },
}));

const StyledSelect = styled(Select)<{isEditMode: boolean}>(({isEditMode}) => ({
  ['.Mui-select']: isEditMode
    ? {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 16,
        '&&': {
          paddingRight: 0,
        },
      }
    : {},
}));

const NoteDescription = styled(TextField)`
  width: 100%;
  & .MuiFormHelperText-contained {
    align-self: self-end;
    height: 14px !important;
  }
`;

const StyledButtons = styled(Button)`
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  min-width: 74px;
  height: 36px;
  border-radius: 4px;
  & .MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    text-transform: capitalize;
  }
`;

interface Prop {
  noteData?: HiddenNotes;
  user: User;
  setIsEditMode: (e: boolean) => void;
  mode?: boolean;
  dataid?: String;
  type?: 'self' | 'admin';
  isEditMode: boolean;
  setCurrentNote: (any) => void;
  updateMode: boolean;
  isSelf: Boolean;
  handleCreateProfileNotes: (values: {description: string; access: string; noteId?: string}) => Promise<any>;
  handleUpdateProfileNote: (values: {description: string; access: string; noteId: string}) => Promise<any>;
  handleDeleteProfileNote: (noteId: string) => Promise<any>;
}
const NoteCard = ({
  noteData,
  setIsEditMode,
  mode = false,
  dataid,
  setCurrentNote,
  updateMode,
  isSelf,
  handleCreateProfileNotes,
  handleUpdateProfileNote,
  handleDeleteProfileNote,
}: Prop) => {
  const LOCKED = 'locked';
  const [isOrganizationMember, setisOrganizationMember] = useState(false);
  const [visibility, setVisibility] = useState(noteData ? noteData.access : ACCESS.PRIVATE);
  const [visibilityColor, setVisibilityColor] = useState(
    visibility === ACCESS.PRIVATE ? muiTheme.colors.paleBlue : muiTheme.colors.etherealMoodBlue,
  );
  const [editMode, setEditMode] = useState(mode);
  const [showDiscardChanges, setShowDiscardChanges] = useState(false);
  const [note, setNote] = useState(noteData?.note);

  const [inputNoteLength, setInputNoteLength] = useState(note ? note?.length : 0);
  const MaxInput = '1000';

  const discardSubmit = () => {
    setShowDiscardChanges(false);
    !mode ? setEditMode(false) : setIsEditMode(false);
    toast('Discarded!', {
      className: 'toast-discard-notes',
      autoClose: 2000,
    });
    setIsEditMode(false);
    setCurrentNote(null);
    setNote(noteData?.note);
    setVisibility(noteData?.access!);
  };

  const setVisibilityContent = (e) => {
    setVisibility(e.target.value);
    if (e.target.value === ACCESS.PRIVATE) {
      setVisibilityColor(muiTheme.colors.paleBlue);
    } else {
      setVisibilityColor(muiTheme.colors.etherealMoodBlue);
    }
  };

  React.useEffect(() => {
    setNote(noteData?.note);
    setVisibility(noteData ? noteData.access : ACCESS.PRIVATE);
    if (noteData === undefined || noteData?.access === ACCESS.PRIVATE) {
      setVisibilityColor(muiTheme.colors.paleBlue);
    } else {
      setVisibilityColor(muiTheme.colors.etherealMoodBlue);
    }
    setInputNoteLength(noteData ? noteData?.note?.length : 0);
  }, [noteData]);

  const AddNoteSubmit = async (mode) => {
    const payload = {
      description: note?.trim()!,
      access: visibility,
    };
    const result = await handleCreateProfileNotes(payload);

    if (result?.result) {
      toast.success(<SuccessToast title={NOTE_ADDED} />, {
        className: 'toast-message',
        autoClose: 5000,
      });
    }

    if (result?.error) {
      toast.error(result.error);
    }

    mode ? setIsEditMode(false) : setEditMode(false);
  };

  const updateNoteSubmit = async () => {
    let payload = {
      noteId: noteData?.id!,
      description: note?.trim() ?? '',
      access: visibility,
    };

    const result = await handleUpdateProfileNote(payload);

    if (result?.result) {
      toast.success(<SuccessToast title={NOTE_UPDATED} />, {
        className: 'toast-message',
        autoClose: 5000,
      });

      setEditMode(false);
      setIsEditMode(false);
      setCurrentNote(null);
    }

    if (result?.error) {
      toast.error(result.error);
    }
  };

  const isToday = (value) => {
    return moment(value).isSame(moment(), 'day');
  };

  const getName = (firstName, lastName, id) => {
    return getParsedAuthInfo()?.user.id === id ? `${firstName} ${lastName} (You)` : `${firstName} ${lastName}`;
  };

  if (!isSelf) {
    return (
      <StyledWrapper mode={!editMode} visibilityColor={visibilityColor}>
        <NoteDataUser>{noteData?.note?.trim()}</NoteDataUser>
      </StyledWrapper>
    );
  }
  return (
    <Wrapper mode={!editMode}>
      {!editMode ? (
        <div style={{width: '100%'}}>
          {
            <AvatarMetaDataWrapper>
              <ProfilePic
                size={40}
                fontSize={16}
                users={[
                  {
                    profilePic: {url: noteData?.createdBy?.avatar?.url},
                    firstname: noteData?.createdBy?.firstName,
                    lastname: noteData?.createdBy?.lastName,
                  },
                ]}
                status={isOrganizationMember ? '' : LOCKED}
                avatarLocked={false}
                data-testid="profile-pic"
              />
              <MetaDataWithName>
                <UserName>
                  {getName(noteData?.createdBy?.firstName, noteData?.createdBy?.lastName, noteData?.createdBy?.id)}
                </UserName>
                <MetaData>
                  Added{' '}
                  {`${
                    isToday(noteData?.createdAt)
                      ? 'today ' + moment(noteData?.createdAt).format('HH:mm')
                      : moment(noteData?.createdAt).format('DD/MMM/YYYY HH:mm')
                  }`}{' '}
                  {noteData?.editedBy &&
                    `• Last updated by ${getName(
                      noteData?.editedBy?.firstName,
                      noteData?.editedBy?.lastName,
                      noteData?.editedBy?.id,
                    )} ${
                      isToday(noteData.updatedAt)
                        ? 'today ' + moment(noteData.updatedAt).format('HH:mm')
                        : moment(noteData.updatedAt).format('DD/MMM/YYYY HH:mm')
                    }`}
                </MetaData>
              </MetaDataWithName>
              {isSelf && (
                <NoteOptionButton
                  handleDeleteProfileNote={handleDeleteProfileNote}
                  setIsEditMode={setIsEditMode}
                  setEditMode={setEditMode}
                  editmode={editMode}
                  dataid={dataid}
                  id={noteData?.id}
                  disabled={editMode || updateMode}
                  note={note}
                />
              )}
            </AvatarMetaDataWrapper>
          }
          <NoteData>{noteData?.note?.trim()}</NoteData>
        </div>
      ) : (
        <>
          <TextFeildLabel>{EDIT_NOTE_FORM_LABEL}</TextFeildLabel>
          <NoteDescription
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="note"
            defaultValue={note}
            color={inputNoteLength >= parseInt(MaxInput) ? 'secondary' : 'primary'}
            InputProps={{
              className: classes.input,
              //@ts-ignore
              'data-testid': `${dataid}-notetext`,
            }}
            helperText={`${inputNoteLength}/${MaxInput}`}
            onChange={(e) => {
              setNote(e.target.value);
              setInputNoteLength(e.target.value.trim().length);
            }}
            inputRef={(input) => input && input.focus()}
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
            }
          />
        </>
      )}
      {editMode ? (
        <NoteVisibilityWrapper
          mode={mode}
          updateMode={updateMode}
          noteData={noteData}
          visibility={visibility}
          setVisibility={setVisibility}
        />
      ) : (
        <StyledSelect
          variant="outlined"
          className={classes.iconClassName}
          value={visibility}
          onChange={setVisibilityContent}
          disabled={!editMode}
          data-testid={`${dataid}-visibility`}
          MenuProps={{disablePortal: true}}
          isEditMode={!!editMode}
          IconComponent={!editMode ? () => null : undefined}
        >
          <MenuItem value={ACCESS.PRIVATE}>
            <Visibility>
              <LockIconWrapper>
                <LockIcon color={'#000'} width={'10px'} height={'14px'} />
              </LockIconWrapper>
              <VisibilityData>
                <VisibilityStatus>{VISIBILITY.PRIVATE}</VisibilityStatus>: {NOTE_VISIBILITY_PRIVATE_TEXT}{' '}
                {store.getState().organization.name}
              </VisibilityData>
            </Visibility>
          </MenuItem>
          <MenuItem value={ACCESS.PUBLIC}>
            <Visibility>
              <LockIconWrapper>
                <EyeIcon style={{color: 'black', width: 10, height: 14}} />
              </LockIconWrapper>
              <VisibilityData>
                <VisibilityStatus>{VISIBILITY.PUBLIC}</VisibilityStatus>: {NOTE_VISIBILITY_PUBLIC_TEXT}{' '}
                {store.getState().organization.name}
              </VisibilityData>
            </Visibility>
          </MenuItem>
        </StyledSelect>
      )}
      {editMode && (
        <ButtonWrapper data-testid={`${dataid}-buttonswrapper`}>
          <StyledButtons variant="outlined" color="primary" onClick={() => setShowDiscardChanges(true)}>
            Discard
          </StyledButtons>
          <StyledButtons
            variant="contained"
            color="secondary"
            onClick={() => (mode && !updateMode ? AddNoteSubmit(mode) : updateNoteSubmit())}
            data-testid={`${dataid}-updatebtn`}
            disabled={inputNoteLength >= parseInt(MaxInput) || inputNoteLength === 0}
          >
            {mode && !updateMode ? 'Add' : 'Update'} Note
          </StyledButtons>
        </ButtonWrapper>
      )}
      {showDiscardChanges && (
        <AlertModal
          id="discard-modal"
          width="sm"
          title="Discard unsaved changes?"
          titleFontSize="21px"
          subtitle=""
          closeAlertModal={() => setShowDiscardChanges(false)}
          isAlertModalVisible={showDiscardChanges}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Keed editing',
              onClickHandler: () => setShowDiscardChanges(false),
              id: 'discard-nevermind',
            },
            {
              type: 'primary',
              buttonLabel: 'Discard',
              onClickHandler: () => discardSubmit(),
              id: 'discard-confirm',
            },
          ]}
        />
      )}
    </Wrapper>
  );
};

export default NoteCard;
