import * as React from 'react';
import styled from '@emotion/styled';
import ChangeStatusModal from 'src/pages/MessengerPage/messenger/change-status/ChangeStatusModal';
import client from 'src/apollo';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux';
import GetSupportUser from 'src/gql/query/FetchSupportUser';
import createNewChat from 'src/utils/messengerHelper/createNewChat';
import {toast} from 'react-toastify';
import {WorkStatus, User, UserOrganizationSwitcherPayload, AuthRegion} from 'src/types';
import RightSectionKeyEventsListener from 'src/pages/MessengerPage/messenger/page-header/RightSectionKeyEventsListener';
import RightSectionUserBlock from 'src/pages/MessengerPage/messenger/page-header/RightSectionUserBlockWrapper';
import RightSectionItems from 'src/pages/MessengerPage/messenger/page-header/RightSectionItemsWrapper';
import {actions} from 'src/redux/actions/sidebar';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
  height: 40px;
`;

interface Props {
  user: User;
  currentOrganization: UserOrganizationSwitcherPayload;
  logout: () => void;
  authRegion: AuthRegion;
  showStatusSettings: boolean;
  toggleStatusSettings: (visibility: boolean) => void;
}

interface State {
  statusProps: WorkStatus | null;
  isMenuOpen: boolean;
  showChangeStatusModal: boolean;
}

class RightSectionWrapper extends React.PureComponent<Props, State> {
  public state = {
    statusProps: null,
    isMenuOpen: false,
    showChangeStatusModal: false,
  };

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    if (nextProps.showStatusSettings !== this.props.showStatusSettings && nextProps.showStatusSettings === true) {
      this.openChangeStatusModal();
    }
  }

  public render() {
    const {statusProps, isMenuOpen, showChangeStatusModal} = this.state;

    const {user, currentOrganization, authRegion} = this.props;

    return (
      <RightSection>
        <RightSectionKeyEventsListener
          tryMessageSupportUser={this.tryMessageSupportUser}
          openChangeStatusModal={this.openChangeStatusModal}
        />
        <RightSectionItems currentOrganization={currentOrganization} />
        <RightSectionUserBlock
          user={user}
          authRegion={authRegion}
          isMenuOpen={isMenuOpen}
          clickedOutside={this.clickedOutside}
          onClickMenu={this.onClickMenu}
          openChangeStatusModal={this.openChangeStatusModal}
          tryMessageSupportUser={this.tryMessageSupportUser}
          logout={this.logout}
        />
        {showChangeStatusModal && (
          <ChangeStatusModal
            workStatusProps={statusProps}
            closeModal={this.closeChangeStatusModal}
            reactModalProps={{isOpen: showChangeStatusModal}}
          />
        )}
      </RightSection>
    );
  }

  private closeChangeStatusModal = () => {
    // Added to allow scroll on status modal close.
    document.body.style.position = '';
    this.setState({
      isMenuOpen: false,
      showChangeStatusModal: false,
    });
    this.props.toggleStatusSettings(false);
  };

  private tryMessageSupportUser = async () => {
    try {
      this.clickedOutside();
      toast.warn('Creating Chat with Support...', {
        autoClose: false,
      });
      const fetchSupportResult = await client.query({query: GetSupportUser});
      const {supportUser} = fetchSupportResult.data;
      const newChatId = await createNewChat([supportUser]);
      window.routerHistory.push(`/messenger/${newChatId}`);
      toast.dismiss();
    } catch (e) {
      console.error(e);
      toast.dismiss();
      toast.error('Failed to start chat with support, please check your internet connection and try again');
    }
  };

  private openChangeStatusModal = (workStatus: WorkStatus | null = null) => {
    this.setState({
      isMenuOpen: false,
      statusProps: workStatus,
      showChangeStatusModal: true,
    });
  };

  private clickedOutside = () => this.setState({isMenuOpen: false});

  private onClickMenu = () => {
    this.setState({isMenuOpen: !this.state.isMenuOpen});
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileDropdownMenuOpened,
    });
  };

  private logout = () => {
    this.props.logout();
    window.location.assign('/login');
  };
}

const mapStateToProps = (state: any) => ({
  currentOrganization: state.organization,
  showStatusSettings: state.sidebar.showStatusSettings,
});

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    toggleStatusSettings: (visibility: boolean) => {
      dispatch(actions.toggleStatusSettings(visibility));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightSectionWrapper);
