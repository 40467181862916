import React from 'react';
import {Field, getIn} from 'formik';
import InputBase, {InputBaseProps} from '@mui/material/InputBase';
import {alpha} from '@mui/material/styles';
import FormControl, {FormControlProps} from '@mui/material/FormControl';
import styled from '@emotion/styled';

// styles
const StyledErrorSpan = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.errorRed};
`;

interface ErrorMessageProps {
  name: string;
}

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-top: 15px !important;
  padding: 0.5em 1em !important;
` as React.ComponentType<FormControlProps>;

export const StyledInputBase = styled(InputBase)(({theme}) => ({
  [`.MuiInputBase-root`]: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },

  [`& .Mui-error`]: {
    borderRadius: 4,
    border: `1px solid ${theme.colors.errorRed}`,
    '& input': {
      boxShadow: `${alpha(theme.colors.errorRed, 0.25)} 0 0 0 0.2rem !important`,
      borderColor: `${theme.colors.errorRed} !important`,
    },
  },

  [`& .MuiInputBase-input`]: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha(theme.colors.chatTeal, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main
      borderColor: theme.colors.chatTeal,
    },
  },
}));

// Views
const ErrorMessage = (props: ErrorMessageProps) => {
  const {name} = props;
  return (
    <Field name={name}>
      {({form}) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        // console.log(form)
        return touch && error ? <StyledErrorSpan>{error}</StyledErrorSpan> : null;
      }}
    </Field>
  );
};

export default ErrorMessage;
