import React from 'react';
import styled from '@emotion/styled';
import {StyledModal, Header, CloseBtn} from 'src/styles/styled-components/ModalStyleComponents';
import {toast} from 'react-toastify';
import ImageCropperWrapper from 'src/components/ChangeImageModal/ImageCropperWrapper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ApiHelper from 'src/api';
import UpdateChatPictureMutation from 'src/gql/mutation/UpdateChatPictureMutation';
import UpdateProfilePictureMutation from 'src/gql/mutation/UpdateProfilePictureMutation';
import client from 'src/apollo';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {UPLOAD_PROFILE_PICTURE, UPLOAD_CHAT_PICTURE} from 'src/constants/fileUpload';

const StyledCircularProgress = styled(CircularProgress)`
  color: darkgrey;
  margin-right: 0.5em;
`;

const StyledButton = styled(Button)`
  width: 300px !important;
  margin: auto !important;
  margin-top: 1.5em !important;
`;

const ChangeImageModal = ({isOpen, closeModal, uploadType, uploadPayload}) => {
  const [imageBlob, setImageBlob] = React.useState<any>(null);
  const [isLoading, setLoading] = React.useState(false);

  async function handleConfirmUpload() {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append(`${uploadType}-picture`, imageBlob);

      const result = await ApiHelper.PrivateEndpoints.uploadFile(formData);
      const {
        data: {response},
      } = result;
      const fileId = response[0].id;

      let mutation;
      let variables;
      if (uploadType === UPLOAD_PROFILE_PICTURE) {
        mutation = UpdateProfilePictureMutation;
        variables = {
          imageId: fileId,
        };
      } else if (uploadType === UPLOAD_CHAT_PICTURE) {
        const {chatId} = uploadPayload;
        mutation = UpdateChatPictureMutation;
        variables = {
          chatId,
          fileId,
        };
      }

      await client.mutate({
        mutation,
        variables,
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profilePicChanged,
      });

      toast.success('Successfully updated photo');
      setLoading(false);
      closeModal();
    } catch {
      toast.error('Failed to upload the photo, please check your internet connection and try again');
      setLoading(false);
    }
  }

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={closeModal}
    >
      <Header>
        <span>
          Update
          {uploadType === UPLOAD_PROFILE_PICTURE && ' Profile Photo'}
          {uploadType === UPLOAD_CHAT_PICTURE && ' Chat Photo'}
        </span>
        <CloseBtn onClick={closeModal} />
      </Header>

      <ImageCropperWrapper isLoading={isLoading} setImageBlob={setImageBlob} />

      <StyledButton
        variant="contained"
        color="secondary"
        disableTouchRipple
        disabled={!imageBlob || isLoading}
        onClick={handleConfirmUpload}
      >
        {isLoading ? (
          <React.Fragment>
            <StyledCircularProgress color="inherit" size={20} />
            uploading...
          </React.Fragment>
        ) : (
          `Set as ${uploadType} photo`
        )}
      </StyledButton>
    </StyledModal>
  );
};

export default ChangeImageModal;
