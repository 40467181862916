import React from 'react';
import {AuthContext} from 'src/auth/AuthProvider';
import {getParsedSessionID, updateScopeToken, trySetSessionID} from 'src/utils/localStorageHandler';
import ApiHelper from 'src/api';
import client from 'src/apollo';
import store from 'src/redux';
import {actions} from 'src/redux/actions/organization';
import getCachedOrganizationById from 'src/utils/organizationHelper/getCachedOrganizationById';
import GetSelfOrganizations from 'src/gql/query/GetUserOrganizations';
import LocalNotificationManager from 'src/notifications/LocalNotificationManager';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';
import {RedirectPayload, PlayNotificationPayload, ClientNotificationMessageType} from 'src/types/Notification';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';

const NotificationHandler = ({isLoggedIn}) => {
  React.useEffect(() => {
    const handleRegistration = async () => {
      if (isLoggedIn) {
        if (!getParsedSessionID()) trySetSessionID();
        LocalNotificationManager.requestPermission();
        // set client payloads for worker to post acknowledgment to backend
        navigator.serviceWorker.ready.then(() => {
          if (navigator.serviceWorker && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
              type: 'set_clientPayloads' as ClientNotificationMessageType,
              payload: {
                deviceId: getParsedSessionID(),
                accessToken: ApiHelper.PrivateEndpoints.getAuthToken('accessToken'),
                gqlEndpoint: ApiHelper.PrivateEndpoints.getPrivateEndpoint(),
              },
            });
          }
        });
        navigator.serviceWorker.ready.then(function () {
          navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
        });
      } else {
        navigator.serviceWorker.ready.then(() => {
          if (navigator.serviceWorker && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
              type: 'clear_keys' as ClientNotificationMessageType,
              payload: null,
            });
          }
        });
      }
    };
    handleRegistration();
    return () => navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  async function handleServiceWorkerMessage(event: MessageEvent) {
    // redirect route (and switch organization if necessary)
    if (!event.data) return;

    const ServiceWorkerMessageType: ClientNotificationMessageType = event.data.type;

    if (ServiceWorkerMessageType === 'redirect_route') {
      if (!event.data.payload) return;

      const {chatId, organizationId} = event.data.payload as RedirectPayload;

      const currentOrganizationID = getCurrentOrganizationId();

      if (String(currentOrganizationID) !== organizationId) {
        await client.query({
          query: GetSelfOrganizations,
        });
        const notificationOrganization = getCachedOrganizationById(organizationId);
        store.dispatch(actions.setCurrentOrganization(notificationOrganization));
        updateScopeToken({organizationId});

        window.routerHistory.push(`/messenger/${chatId}`);
        window.location.reload();
      } else {
        window.routerHistory.push(`/messenger/${chatId}`);
      }
    }
    // let client play notification sound from push
    if (ServiceWorkerMessageType === 'play_notification') {
      const {priorityType} = event.data.payload as PlayNotificationPayload;

      priorityType === StatMessagePriority
        ? LocalNotificationManager.playStatPriorityNotificationSound()
        : priorityType === UrgentMessagePriority
        ? LocalNotificationManager.playUrgentPriorityNotificationSound()
        : LocalNotificationManager.playRegularNotificationSound();
    }
  }

  return (
    <React.Fragment>
      <audio id="priority-notification-player" autoPlay muted>
        <source type="audio/wav" id="priority-notification-src" src="/sounds/priority-notification.wav" />
      </audio>
      <audio id="regular-notification-player" autoPlay muted>
        <source type="audio/wav" id="regular-notification-src" src="/sounds/regular-notification.mp3" />
      </audio>
      <audio id="urgent-notification-player" autoPlay muted>
        <source type="audio/wav" id="urgent-notification-src" src="/sounds/priority-urgent.mp3" />
      </audio>
    </React.Fragment>
  );
};

const RenderNotificationHandler = React.memo(NotificationHandler);

export default () => (
  <AuthContext.Consumer>{({isLoggedIn}) => <RenderNotificationHandler isLoggedIn={isLoggedIn} />}</AuthContext.Consumer>
);
