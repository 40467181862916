import React from 'react';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import JoinOrganizationContainer from 'src/pages/JoinOrganizationPage/join-organization/JoinOrganizationFormContainer';
import {MESSENGER} from 'src/constants/routerPathName';
import {BackToPreviousLink} from 'src/styles/styled-components/Link';

const HorizontalLogoWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 1em;
`;

const JoinOrgTitle = styled.h1`
  font-family: Nunito;
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.2px;
  text-align: center;
  color: dimgrey;
  margin-bottom: 35px;
`;

const PageWrapper = styled.div`
  max-width: 480px;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
`;

const JoinOrgContainer = styled.div`
  width: 100%;
  min-width: 310px;
`;

class JoinOrganizationPage extends React.Component<any> {
  public render() {
    return (
      <PageWrapper>
        <Helmet>
          <title>Join Organization - Hypercare</title>
        </Helmet>
        <JoinOrgContainer>
          <HorizontalLogoWrapper>
            <img src="/assets/horizontal-logo-red.svg" alt="hypercare logo with text" />
          </HorizontalLogoWrapper>
          <JoinOrgTitle>Join Organization</JoinOrgTitle>
          <JoinOrganizationContainer onComplete={null} />

          <BackToPreviousLink>
            <span className="material-icons">chevron_left</span>
            <Link to={`/${MESSENGER}/home`}>Back to Home Page</Link>
          </BackToPreviousLink>
        </JoinOrgContainer>
      </PageWrapper>
    );
  }
}

export default JoinOrganizationPage;
