/**
 * Overidden components are from material-ui intergration with react-select
 * Credit: https://material-ui.com/components/autocomplete/#react-select
 */
import React, {HTMLAttributes} from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import TextField, {BaseTextFieldProps} from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import {ValueContainerProps} from 'react-select/src/components/containers';
import {ControlProps} from 'react-select/src/components/Control';
import {MenuProps, NoticeProps} from 'react-select/src/components/Menu';
import {MultiValueProps} from 'react-select/src/components/MultiValue';
import {OptionProps} from 'react-select/src/components/Option';
import {PlaceholderProps} from 'react-select/src/components/Placeholder';
import {SingleValueProps} from 'react-select/src/components/SingleValue';
import {emphasize} from '@mui/material/styles';
import {UserSelectOptionType} from 'src/types';
import styled from '@emotion/styled';
import Select from 'react-select';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import ClearIcon from '@mui/icons-material/Clear';

const PREFIX = 'ReactSelectMUI';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  underline: `${PREFIX}-underline`,
  valueContainer: `${PREFIX}-valueContainer`,
  chip: `${PREFIX}-chip`,
  pcChip: `${PREFIX}-pcChip`,
  deleteIcon: `${PREFIX}-deleteIcon`,
  chipFocused: `${PREFIX}-chipFocused`,
  noOptionsMessage: `${PREFIX}-noOptionsMessage`,
  singleValue: `${PREFIX}-singleValue`,
  placeholder: `${PREFIX}-placeholder`,
  paper: `${PREFIX}-paper`,
  divider: `${PREFIX}-divider`,
};

export const StyledSelect = styled(Select)(({theme}) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.input}`]: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },

  [`& .${classes.underline}`]: {
    '&:after': {
      borderBottom: '2px solid dimgray',
    },
  },

  [`& .${classes.valueContainer}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },

  [`& .${classes.chip}`]: {
    margin: theme.spacing(0.5, 0.25),
  },

  [`& .${classes.pcChip}`]: {
    margin: theme.spacing(0.5, 0.25),
    borderRadius: '4px',
    border: '1px solid #D8D8D8',
    background: '#FFF',
  },

  [`& .${classes.deleteIcon}`]: {
    color: '#4a4a4a',
    width: '18px',
    height: '18px',
  },

  [`& .${classes.chipFocused}`]: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },

  [`& .${classes.noOptionsMessage}`]: {
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.singleValue}`]: {
    fontSize: 16,
  },

  [`& .${classes.placeholder}`]: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 14,
  },

  [`& .${classes.paper}`]: {
    position: 'absolute',
    boxShadow: 'none',
    zIndex: 1,
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxWidth: 440,
  },

  [`& .${classes.divider}`]: {
    height: theme.spacing(2),
  },
}));

/**
 * Customize Material Ui with React-select styles and themes here
 */

/**
 * Overide react-select components here
 */

function NoOptionsMessage(props: NoticeProps<UserSelectOptionType>) {
  return (
    <Typography color="textSecondary" className={classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

(NoOptionsMessage as any).propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

const inputComponent = React.forwardRef((props: InputComponentProps, ref: React.Ref<any>) => {
  return <div ref={ref} {...props} />;
});

function Control(props: ControlProps<UserSelectOptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {TextFieldProps},
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        classes: {
          underline: classes.underline,
        },
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

(Control as any).propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
} as any;

export const NoUnderlineControl = (props: ControlProps<UserSelectOptionType>) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {TextFieldProps},
  } = props;
  return (
    <TextField
      fullWidth
      InputProps={{
        disableUnderline: true,
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

(NoUnderlineControl as any).propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
} as any;

export function MaterialUiOption(props: OptionProps<UserSelectOptionType>) {
  return (
    <MenuItem
      disableTouchRipple
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        height: '70px',
        paddingLeft: '4px',
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

(MaterialUiOption as any).propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
} as any;

function Placeholder(props: PlaceholderProps<UserSelectOptionType>) {
  return (
    <Typography color="textSecondary" className={classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

(Placeholder as any).propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function SingleValue(props: SingleValueProps<UserSelectOptionType>) {
  return (
    <Typography className={classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

(SingleValue as any).propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function ValueContainer(props: ValueContainerProps<UserSelectOptionType>) {
  return <div className={classes.valueContainer}>{props.children}</div>;
}

(ValueContainer as any).propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
} as any;

function MultiValue(props: MultiValueProps<UserSelectOptionType>) {
  const paginatedContacts = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      classes={{deleteIcon: classes.deleteIcon}}
      className={
        paginatedContacts
          ? clsx(classes.pcChip, {
              [classes.chipFocused]: props.isFocused,
            })
          : clsx(classes.chip, {
              [classes.chipFocused]: props.isFocused,
            })
      }
      deleteIcon={<ClearIcon />}
      onDelete={props.removeProps.onClick}
    />
  );
}

(MultiValue as any).propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
} as any;

function Menu(props: MenuProps<UserSelectOptionType>) {
  return (
    <Paper square className={classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

(Menu as any).propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
} as any;

const overriddenComponents = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
};

export default overriddenComponents;
