import React from 'react';
import {Box, IconButton, Icon, Typography} from '@mui/material';
import {Closemark} from 'src/svgs/Closemark';
import styled from '@emotion/styled';

const PREFIX = 'NewStepHeader';

const classes = {
  root: `${PREFIX}-root`,
  backButton: `${PREFIX}-backButton`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  closeButton: `${PREFIX}-closeButton`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.backButton}`]: {
    color: 'black',
    backgroundColor: '#F2F2F2',
    width: 40,
    height: 40,
  },

  [`& .${classes.title}`]: {
    fontFamily: "'Nunito Sans', sans-serif",
    marginBottom: 16,
    marginTop: 16,
    fontSize: 28,
    fontWeight: 700,
    color: '#222222',
  },

  [`& .${classes.description}`]: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#4A4A4A',
    marginBottom: '16px',
  },

  [`& .${classes.closeButton}`]: {
    backgroundColor: '#F2F2F2',
    width: 40,
    height: 40,
  },
}));

interface IStepHeaderProps {
  title: string;
  isDisabled: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
  discard?: boolean;
}

const NewStepHeader: React.FC<IStepHeaderProps> = ({title, description, onBackButtonClick, isDisabled, discard}) => {
  return (
    <StyledBox className={classes.root}>
      {!isDisabled && !discard ? (
        <IconButton className={classes.backButton} onClick={onBackButtonClick} size="large">
          <Icon>arrow_back</Icon>
        </IconButton>
      ) : null}
      {!isDisabled && discard ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onBackButtonClick} size="large">
          <Closemark />
        </IconButton>
      ) : null}
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
    </StyledBox>
  );
};

export default NewStepHeader;
