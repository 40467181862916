import React, {useState} from 'react';
import {OrganizationViewModel} from '../view-models/OrganizationViewModel';
import {CircularProgress, IconButton, InputAdornment} from '@mui/material';
import NewLoginFormHeader from '../../ components/NewLoginFormHeader';
import {StyledTextField} from '../../../../styles/styled-components/StyledInputField';
import {NEXT, VERIFYING_AVAILABILITY} from '../../../../constants/login';
import {HelperBox, InputsWrapper, LoginContainer, StyledAnchor} from '../../../../microfrontend/styled/login.styled';
import {StyledSubmitAddressButton} from '../../styled/login.styled';
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {HCLabelTwo} from '../../../../components/HypercareComponents';
import {muiTheme} from 'src/styles/theme';

interface IAccountDiscoveryCoordinatorProps {
  showFindMyOrgView: () => void;
}

export const AccountDiscoveryCoordinator = ({showFindMyOrgView}: IAccountDiscoveryCoordinatorProps) => {
  const {handleAccountDiscoveryFlow} = OrganizationViewModel();
  const [error, setError] = useState('');
  const submitEmailAddress = () => {};

  let isSubmitting = false;

  return (
    <LoginContainer width={'600px'} padding={'32px'}>
      <NewLoginFormHeader isAccountLocked={false} />
      <Formik
        initialValues={{email: ''}}
        validationSchema={Yup.object().shape({
          email: Yup.string().required('Email is required').email('Invalid email address'),
        })}
        onSubmit={async (values, actions) => {
          const result = await handleAccountDiscoveryFlow(values.email);

          actions.setSubmitting(false);
        }}
      >
        {({handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting}) => (
          <form onSubmit={handleSubmit}>
            <InputsWrapper>
              <StyledTextField
                data-testid="emailInput"
                fullWidth
                type={'text'}
                variant="outlined"
                placeholder={'you@hypercare.com'}
                label={'Email'}
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                value={values.email}
                helperText={touched.email && errors.email ? errors.email : ''}
                error={touched.email && !!errors.email}
              />
            </InputsWrapper>

            <HelperBox paddingBottom={'12px'} onClick={showFindMyOrgView} style={{cursor: 'pointer'}}>
              <HCLabelTwo color={muiTheme.colors.chatTeal}>Manually login to an org</HCLabelTwo>
            </HelperBox>

            <StyledSubmitAddressButton data-testid="addressSubmitButton" type="submit">
              {isSubmitting ? (
                <>
                  <CircularProgress color="inherit" size={18} style={{marginRight: 16}} /> VERIFYING_AVAILABILITY
                </>
              ) : (
                'NEXT'
              )}
            </StyledSubmitAddressButton>
            <HelperBox>
              <StyledAnchor
                onClick={() => showFindMyOrgView}
                href="https://www.hypercare.com/contact"
                rel="noopener noreferrer"
                target="_blank"
              >
                Having some issues?
                <span>Contact us</span>
              </StyledAnchor>
            </HelperBox>
          </form>
        )}
      </Formik>
    </LoginContainer>
  );
};
