import styled from '@emotion/styled';
import {DefaultButton} from '../../styles/styled-components/Button';
import {Box} from '@mui/material';
import {muiTheme} from 'src/styles/theme';

interface LoginContainerProps {
  loading?: string;
  width?: string;
  padding?: string;
}

export const LoginContainer = styled.div<LoginContainerProps>`
  max-width: ${(props) => (props.width ? props.width : '600px')};
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 3px 5px 0px rgba(9, 30, 66, 0.2), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  z-index: 1;
  opacity: ${(props) => (props.loading ? '0.5' : '')};

  background-color: white;

  @media only screen and (max-width: 600px) {
    padding: 16px;
  }
  padding: ${(props) => (props.padding ? props.padding : '8px')};
`;

export const RowItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowItems = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
  &:hover {
    background-color: #f6f6f9;
  }
`;

export const RowItemsLeftSide = styled.div`
  padding: 0 0 0 16px;
  display: flex;
  gap: 16px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapperV2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  background-color: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;
`;

export const RowItemsLeftInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 2px;
  padding: 8px 0;
`;

export const RowItemsRightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  cursor: pointer;
`;

export const ActionButtonsContainer = styled.div`
  padding: 12px 0 12px 16px;
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;

  &:hover {
    background-color: #f6f6f9;
  }
`;

export const AccountSelectionTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;
`;

export const CenteredDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const NonWrappingSpan = styled.span`
  line-height: 24px;
  text-align: center;
`;

export const RegionSwitchActionText = styled.span`
  color: ${muiTheme.colors.chatTeal};
  cursor: pointer;
  padding: 4px 0;
  &:hover {
    text-decoration: underline;
  }
`;

export const InputsWrapper = styled.div`
  .MuiOutlinedInput-input {
    font-family: 'Nunito Sans';
    box-sizing: border-box;
    height: 52px;
    padding: 16px 14px;
  }
  .MuiTextField-root {
    &:first-child {
      margin-bottom: 1em;
    }
  }
  .MuiFormHelperText-root {
    margin-right: 0 !important;
    margin-left: 2px !important;
  }
`;

export const StyledSubmitAddressButton = styled(DefaultButton)`
  background-color: #ff3e55;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
`;

export const HelperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const StyledAnchor = styled.a`
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 400;
  color: #4a4a4a;
  margin-top: 16px;
  text-decoration: none;
  float: right;
  span {
    padding-left: 4px;
    color: ${muiTheme.colors.chatTeal};
  }
  line-height: 20px;
`;
