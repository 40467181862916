import {createAction, ActionType, getType} from 'typesafe-actions';
import hypercareOnCallReducer from './onCall';
import {Organization} from '../../microfrontend/types/login.types';
import {Action} from '@fullcalendar/react';
import {Actions} from '../reducers';

export const actions = {
  setCurrentAccount: createAction('currentAccount/SET_CURRENT_ACCOUNT', (resolve) => (account) => resolve({account})),
};

export type CurrentAccountActions = ActionType<typeof actions>;

export interface CurrentAccountState {
  email: string;
  organization: Organization;
}

export const initialState: CurrentAccountState = {
  email: '',
  organization: {
    imageUrl: '',
    loginMethods: [],
    name: '',
    url: '',
  },
};

const currentAccountReducer = (state: CurrentAccountState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(actions.setCurrentAccount):
      const newState = {...action.payload.account};
      return {...newState};
    default:
      return state;
  }
};

export default currentAccountReducer;
