import {isEmpty} from 'ramda';
import store from 'src/redux';
import {TemplateElements, TemplateMessageType} from 'src/types';
import {actions as TemplateMessageActions} from 'src/redux/actions/messageTemplates';

const TemplateFieldErrors = {
  requiredTextfield: `This is a required field`,
  requiredCheckbox: 'This is a required field',
  [TemplateElements.Textfield]: (minLength, maxLength) =>
    `Should have ${!!minLength && `min of ${minLength} ${!!maxLength && 'and'}`} ${
      !!maxLength && `max of ${maxLength}`
    }`,
  [TemplateElements.Checkbox]: (minSelection, maxSelection) =>
    `Should have ${!!minSelection && `min of ${minSelection} ${!!maxSelection && 'and'}`} ${
      !!maxSelection && `max of ${maxSelection}`
    } `,
  [TemplateElements.Radio]: (isRequired) => `This is a required field`,
  [TemplateElements.Dropdown]: (isRequired) => `Please select an option`,
  [TemplateElements.Attachments]: (isRequired) => 'This is a required field',
};

export default (selectedTemplate, formValues, messageType) => {
  let templateFormErrors: Array<{id: any; errorMsg: any}> = [];
  const templateFormArray = selectedTemplate[TemplateMessageType[messageType]].children,
    attachments = store.getState().filedrop.files;

  templateFormArray.forEach((formElement) => {
    const {id, isRequired, validation} = formElement,
      {minLength, maxLength, minSelection, maxSelection} = validation || {};
    let reduxFieldValue = formValues.filter((_fieldVal) => _fieldVal.id === id);
    switch (formElement.type) {
      case TemplateElements.Textfield:
      case TemplateElements.MultilineTextfield:
        reduxFieldValue = reduxFieldValue.length > 0 ? reduxFieldValue[0].text : '';
        if (isRequired && !reduxFieldValue)
          templateFormErrors.push({id, errorMsg: TemplateFieldErrors.requiredTextfield});
        else if (
          (!!minLength && reduxFieldValue?.length < minLength) ||
          (!!maxLength && reduxFieldValue?.length > maxLength)
        )
          templateFormErrors.push({
            id,
            errorMsg: TemplateFieldErrors[TemplateElements.Textfield](minLength, maxLength),
          });
        break;
      case TemplateElements.Checkbox:
        reduxFieldValue = !isEmpty(reduxFieldValue) ? reduxFieldValue[0].state : [];
        let chkboxSelection: number = 0;
        reduxFieldValue.forEach((value) => {
          if (value) chkboxSelection = chkboxSelection + 1;
        });
        if (isRequired && chkboxSelection <= 0)
          templateFormErrors.push({id, errorMsg: TemplateFieldErrors.requiredCheckbox});
        else if (
          (!!minSelection && chkboxSelection < minSelection) ||
          (!!maxSelection && chkboxSelection >= maxSelection)
        )
          templateFormErrors.push({
            id,
            errorMsg: TemplateFieldErrors[TemplateElements.Checkbox](minSelection, maxSelection),
          });
        break;
      case TemplateElements.Radio:
        reduxFieldValue = !isEmpty(reduxFieldValue) ? reduxFieldValue[0].selectedOption : -1;
        if (isRequired && parseInt(reduxFieldValue) < 0)
          templateFormErrors.push({id, errorMsg: TemplateFieldErrors[TemplateElements.Radio](isRequired)});
        break;
      case TemplateElements.Dropdown:
        reduxFieldValue = !isEmpty(reduxFieldValue) ? reduxFieldValue[0].selectedOption : -1;
        if (isRequired && (reduxFieldValue === null || reduxFieldValue < 0))
          templateFormErrors.push({id, errorMsg: TemplateFieldErrors[TemplateElements.Dropdown](isRequired)});
        break;
      case TemplateElements.Attachments:
        if (isRequired && attachments?.length === 0)
          templateFormErrors.push({id, errorMsg: TemplateFieldErrors[TemplateElements.Attachments](isRequired)});
        break;
    }
  });

  store.dispatch(TemplateMessageActions.setValidationErrors(templateFormErrors));

  return templateFormErrors;
};
