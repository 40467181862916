import React from 'react';
import styled from '@emotion/styled';
import {Role} from 'src/types';
import PreferredRolesList from 'src/pages/SchedulingPage/action-calendar/filter-section/FilterPreferredRolesList';
import FilterRequestType from 'src/pages/SchedulingPage/action-calendar/filter-section/FilterRequestType';
import {
  FilterControlSectionWrapper,
  FilteroptionsWrapper,
  SectionTitle,
  StyledOverflowWrapper,
} from 'src/pages/SchedulingPage/action-calendar/filter-section/SharedFilterSectionStyles';
import DepartmentSwitcher from 'src/pages/SchedulingPage/schedule-layout/DepartmentSwitcher';
import InfoIcon from 'src/svgs/Info';
import StyledTooltip from 'src/components/StyledTooltip';
import {
  SchedulingVariableContext,
  ScheduleQueryVariables,
} from 'src/pages/SchedulingPage/scheduling/SchedulingVariableProvider';
import {DepartmentSwitcherV2} from '../../schedule-layout/DepartmentSwitcherV2';
import {SWAPSCHEDULINGVIEW} from '../../../../constants/scheduler';
import {IsFeatureFlagEnabled} from '../../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../../utils/FeatureFlags';

const StyledInfo = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  overflow: visible;
`;

const FlexContainer = styled.div`
  display: flex;
  max-width: min-content;
  height: auto;
  position: relative;
  white-space: nowrap;
  svg {
    margin-left: 0.5em;
  }
`;

interface Props {
  roles: Role[];
  currentDepartmentId: number | null;
  setScheduleQueryVariables: React.Dispatch<React.SetStateAction<ScheduleQueryVariables>>;
}

const MarketplaceFilterControlSection = ({roles, currentDepartmentId, setScheduleQueryVariables}: Props) => {
  const newSearchableDropdownFF = IsFeatureFlagEnabled(FeatureFlagResult.newSearchableDepartmentAndUserList);

  function onSelectDepartment(targetDepartmentId: number) {
    setScheduleQueryVariables((prevState) => ({
      ...prevState,
      currentDepartmentId: targetDepartmentId,
    }));
  }

  return (
    <FilterControlSectionWrapper>
      <FilteroptionsWrapper>
        <SectionTitle>
          <StyledTooltip
            arrow
            title="You can only view marketplaces for the department(s) of which you are under"
            className="styledPopper"
            placement="right"
          >
            <FlexContainer>
              <span>Select department</span>
              <StyledInfo stroke={'dimgrey'} circlefill={'white'} strokefill={'dimgrey'} />
            </FlexContainer>
          </StyledTooltip>
        </SectionTitle>

        {newSearchableDropdownFF ? (
          <DepartmentSwitcherV2
            departmentId={currentDepartmentId!}
            onDepartmentSelect={onSelectDepartment}
            isSelfScoped={true}
          />
        ) : (
          <DepartmentSwitcher
            departmentId={currentDepartmentId}
            onSelectDepartment={onSelectDepartment}
            isSelfScoped={true}
          />
        )}

        <FilterRequestType />

        <SectionTitle>Preferred roles</SectionTitle>
        <StyledOverflowWrapper>
          <PreferredRolesList roles={roles} />
        </StyledOverflowWrapper>
      </FilteroptionsWrapper>
    </FilterControlSectionWrapper>
  );
};

export default (props: {roles: Role[]}) => (
  <SchedulingVariableContext.Consumer>
    {({currentDepartmentId, setScheduleQueryVariables}) => (
      <MarketplaceFilterControlSection
        currentDepartmentId={currentDepartmentId}
        setScheduleQueryVariables={setScheduleQueryVariables}
        {...props}
      />
    )}
  </SchedulingVariableContext.Consumer>
);
