import ApiHelper from 'src/api';

class CreateNewOrgViewModel {
  nextButtonPressed;

  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    console.log(values);
    const payload = {organizationDetails: {name: values.orgName}};
    try {
      const createOrgResult = await ApiHelper.PublicEndpoints.fetchOrganizationByName(values.orgName);
      console.log(createOrgResult.data?.findOrganizationByName);
      if (createOrgResult.data?.findOrganizationByName.organizations.length === 0) {
        this.nextButtonPressed(values.orgName);
      } else {
        console.log('An organization with this name already exists');
        return {error: 'An organization with this name already exists'};
      }
    } catch (error: any) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };
}

export default CreateNewOrgViewModel;
