import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import styled from '@emotion/styled';

const PREFIX = 'ModalWrapper';

const classes = {
  modalContent: `${PREFIX}-modalContent`,
};

const StyledTypography = styled(Typography)(() => ({
  [`& .${classes.modalContent}`]: {
    marginBottom: '24px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

interface Props {
  isOpen: boolean;
  onClose: () => void;
  logout: () => void;
}

const SessionTimeoutModalWrapper = ({isOpen, onClose, logout}: Props) => {
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleLogout = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.sessionTimeoutIdleDialogLogout,
    });
    logout();
    window.location.reload();
  };

  return (
    <AlertModal
      width="xs"
      title={`Session expiring`}
      titleFontSize="24px"
      isAlertModalVisible={isOpen}
      showCloseButton={false}
      alertModalButtons={[
        {
          type: 'secondary',
          buttonLabel: 'Logout',
          onClickHandler: () => handleLogout(),
          id: 'logout-btn',
        },
        {
          type: 'primary',
          buttonLabel: 'Continue session',
          onClickHandler: () => onClose(),
          id: 'continue-session-btn',
        },
      ]}
      modalContent={
        <StyledTypography classes={{root: classes.modalContent}}>
          You will be logged out in{' '}
          <b>
            {minutes === 0 && seconds === 0 ? null : (
              <>
                {' '}
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </>
            )}
          </b>{' '}
          due to inactivity.
          <br />
          To continue your session, click "Continue session" below.
        </StyledTypography>
      }
    />
  );
};

export const ModalWrapper = React.memo(SessionTimeoutModalWrapper);
