import React from 'react';
import {ScrollStyleModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import styled from '@emotion/styled';
import {LocatingEscalationPreviewStep, LocatingEscalationSelectionStep} from './steps';
import {DepartmentScope, EscalationLadder, SiteScope} from 'src/types';
import {MessageWrapper, MessageWrapperText, MessageWrapperTitle} from 'src/styles/styled-components/Escalation';
import {MinimalSiteData} from 'src/gql/query/FetchSitesForOrganization';

const StyledHeader = styled(Header)`
  font-size: 24px;
`;

interface Props {
  isOpen: boolean;
  sites: MinimalSiteData[];
  currentSite: MinimalSiteData;
  closeModal: () => void;
}

const LocatingEscalationModal = ({isOpen, closeModal, sites, currentSite}: Props) => {
  const [message, setMessage] = React.useState('');
  const [selectedSite, setSelectedSite] = React.useState<MinimalSiteData | null>(currentSite);
  // const [selectedDepartment, setSelectedDepartment] = React.useState<DepartmentScope | null>(
  //   currentSite?.departments
  //     ? currentSite.departments.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))[0]
  //     : null,
  // );

  const [selectedDepartment, setSelectedDepartment] = React.useState<DepartmentScope | null>(null);

  const [selectedEscalationLadder, setSelectedEscalationLadder] = React.useState<EscalationLadder | null>(null);

  const [currentStep, setCurrentStep] = React.useState<number>(1);

  const handlePrev = () => setCurrentStep((prev) => prev - 1);
  const handleNext = () => setCurrentStep((prev) => prev + 1);

  return (
    <ScrollStyleModal
      isOpen={isOpen}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeModal}
    >
      <StyledHeader>
        <span>Start a departmental escalation</span>
        <CloseBtn onClick={closeModal} />
      </StyledHeader>
      {currentStep === 1 && selectedDepartment && selectedEscalationLadder && (
        <LocatingEscalationSelectionStep
          selectedSite={selectedSite}
          onSiteSelected={setSelectedSite}
          siteSelectionList={sites}
          selectedDepartment={selectedDepartment}
          onDepartmentSelected={setSelectedDepartment}
          message={message}
          onMessageSet={setMessage}
          selectedEscalationLadder={selectedEscalationLadder}
          onEscalationLadderSelected={setSelectedEscalationLadder}
          handleNext={handleNext}
          closeModal={closeModal}
        />
      )}
      {currentStep === 2 && selectedEscalationLadder && (
        <React.Fragment>
          <MessageWrapper>
            <MessageWrapperTitle>Escalating this message</MessageWrapperTitle>
            <MessageWrapperText>{message}</MessageWrapperText>
          </MessageWrapper>
          <LocatingEscalationPreviewStep
            message={message}
            selectedEscalationLadder={selectedEscalationLadder}
            handlePrev={handlePrev}
            closeModal={closeModal}
          />
        </React.Fragment>
      )}
    </ScrollStyleModal>
  );
};

export default LocatingEscalationModal;
