import React from 'react';
import {AuthRegion} from 'src/types';
import styled from '@emotion/styled';
import {FlagIcons} from '../ChangeRegion/AuthRegionData';

export interface AuthRegionFlagProps {
  currentRegion: AuthRegion;
  size: 'small' | 'large';
}

const StyledFlagImage = styled.img<AuthRegionFlagProps>`
  width: ${(props) => (props.size === 'large' ? '32px' : '16px')};
  height: ${(props) => (props.size === 'large' ? '32px' : '16px')};
  margin-right: 4px;
  margin-left: 8px;
`;

export const AuthRegionFlag: React.FC<AuthRegionFlagProps> = ({currentRegion, size}) => {
  const src = FlagIcons[currentRegion];
  const alt = `${currentRegion} flag`;
  return <StyledFlagImage src={src} alt={alt} size={size} currentRegion={currentRegion} />;
};
