import {isEmpty} from 'ramda';
import {connect} from 'react-redux';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {MutationFunction} from 'react-apollo';
import {Dialog, DialogContent, Divider, Grid, LinearProgress} from '@mui/material';

import {
  Message,
  AuthPayload,
  TemplateViews,
  MessageRequestType,
  MessageTemplateStatus,
  MessageTemplateResponseType,
  SendMessageMutationVariables,
} from 'src/types';
import AlertModal from './AlertModal';
import {
  PrimaryButton,
  SecondaryButton,
  StyledDialogActions,
} from 'src/styles/styled-components/StyledMaterialComponents';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {actions as MessageActions} from 'src/redux/actions/messages';
import sendTemplateMessage from 'src/utils/messengerHelper/SendTemplateMessage';
import {MaterialDialogHeader, Transition} from 'src/components/HypercareComponents';
import MessageTemplateFactory from 'src/pages/MessengerPage/messenger/messages-layout/message-template/MessageTemplateFactory';

interface ResponseTemplateDetailProps {
  message: Message;
  chatId: string;
  isSelf: boolean;
  authInfo: AuthPayload;
  openResponseTemplateDetail: boolean;
  closeResponseTemplateDetail: () => void;
  reviewResponseDetailResponseBtnHandler: () => void;
  respondTemplateMessageMutation: MutationFunction<{}, SendMessageMutationVariables>;
}
const ResponseTemplateDetail = ({
  chatId,
  authInfo,
  message,
  isSelf,
  openResponseTemplateDetail,
  closeResponseTemplateDetail,
  respondTemplateMessageMutation,
  reviewResponseDetailResponseBtnHandler,
}: ResponseTemplateDetailProps) => {
  const parsedAuthInfo = getParsedAuthInfo();
  const user = parsedAuthInfo?.user;
  const PrimaryButtonText = {
    [MessageTemplateResponseType.acknowledge]: 'Acknowledge',
    [MessageTemplateResponseType.affirmation]: 'Yes',
    [MessageTemplateResponseType.detail]: 'Provide response',
  };
  const [acknowledgeConfirmationModal, setAcknowledgeConfirmationModal] = useState<boolean>(false);
  const [affirmationSelection, setAffirmationSelection] = useState<boolean>(false);
  const [affirmationConfirmationModal, setAffirmationConfirmationModal] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = React.useState({isLoading: false, loadingMessage: ''});
  const requestTitle = message?.template?.requestTitle || '';
  const formTitle = message.template?.formTitle || '';
  const isSenderSelf = message?.sender?.id === user?.id;
  const type = message?.template?.response?.type || null;

  // Function executed when clicked on primary button of response detail form which can be for Acknowledge, yes, providing detailed response.
  const primaryBtnHandler = () => {
    if (type === MessageTemplateResponseType.acknowledge) {
      setAcknowledgeConfirmationModal(true); // on click of Acknowledge consult button
    } else if (type === MessageTemplateResponseType.affirmation) {
      setAffirmationSelection(true);
      setAffirmationConfirmationModal(true);
    } else if (type === MessageTemplateResponseType.detail) {
      reviewResponseDetailResponseBtnHandler();
    }
  };

  // Function executed when clicked on secondary button of response detail form
  const secondaryBtnHandler = () => {
    if (type === MessageTemplateResponseType.affirmation) {
      setAffirmationSelection(false); // set the affirmation to false if no is clicked
      setAffirmationConfirmationModal(true); // open the affirmation confirmation modal to respond.
    } else {
      closeResponseTemplateDetail();
    }
  };

  const respondingTemplateNetworkCall = () => {
    let responseData;
    if (type === MessageTemplateResponseType.acknowledge) {
      setAcknowledgeConfirmationModal(false);
      responseData = Object.assign({}, {isAcknowledged: true});
    } else if (type === MessageTemplateResponseType.affirmation) {
      setAffirmationConfirmationModal(false);
      responseData = Object.assign({}, {isAffirmative: affirmationSelection});
    }

    sendTemplateMessage(
      MessageRequestType.respondingtoRequest,
      chatId,
      authInfo,
      respondTemplateMessageMutation,
      responseData,
      message,
      setLoadingStatus,
    )
      .then((_) => {
        if (type === MessageTemplateResponseType.acknowledge) {
          closeResponseTemplateDetail();
        } else if (type === MessageTemplateResponseType.affirmation) {
          closeResponseTemplateDetail();
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error('Failed to make the network request, please check your internet connection and try again');
      });
  };

  return (
    <React.Fragment>
      <Dialog maxWidth={'sm'} fullWidth={true} open={openResponseTemplateDetail} TransitionComponent={Transition}>
        {loadingStatus.isLoading && <LinearProgress />}
        <MaterialDialogHeader
          loading={loadingStatus.isLoading}
          dialogTitle={'Request details'}
          closeIconButtonClickHandler={() => closeResponseTemplateDetail()}
        />
        <DialogContent style={{marginBottom: 16}}>
          <Grid container spacing={2}>
            {message.template &&
              new MessageTemplateFactory(message.template, TemplateViews.ReviewResponse).createUIWithJSONParser()}
          </Grid>
        </DialogContent>
        <Divider />
        {message?.template?.status === MessageTemplateStatus.pending_response && !isSenderSelf && (
          <StyledDialogActions>
            <SecondaryButton
              disabled={loadingStatus.isLoading}
              onClick={() => secondaryBtnHandler()}
              style={{marginRight: 8}}
            >
              {MessageTemplateResponseType.affirmation === type ? 'No' : 'Cancel'}
            </SecondaryButton>
            <PrimaryButton disabled={loadingStatus.isLoading} onClick={() => primaryBtnHandler()}>
              {loadingStatus.isLoading ? loadingStatus.loadingMessage : PrimaryButtonText[type!]}
            </PrimaryButton>
          </StyledDialogActions>
        )}
      </Dialog>

      {/* If Response type is Acknowledge then confirmation dialog to acknowledge template message response*/}
      <AlertModal
        title={`Acknowledge ${formTitle} ${!isEmpty(requestTitle) ? `- ${requestTitle}` : ''}?`}
        subtitle={`This action cannot be undone. Please review the ${formTitle} ${
          !isEmpty(requestTitle) ? `- ${requestTitle}` : ''
        } carefully.`}
        closeAlertModal={setAcknowledgeConfirmationModal}
        isAlertModalVisible={acknowledgeConfirmationModal}
        alertModalButtons={[
          {
            buttonLabel: 'Cancel',
            type: 'secondary',
            onClickHandler: () => {
              setAcknowledgeConfirmationModal(false);
            },
          },
          {
            buttonLabel: 'Acknowledge anyway',
            type: 'primary',
            onClickHandler: () => respondingTemplateNetworkCall(),
          },
        ]}
      />

      <AlertModal
        title={`Respond ${affirmationSelection ? 'Yes' : 'No'} to ${formTitle} ${
          !isEmpty(requestTitle) ? `- ${requestTitle}` : ''
        }?`}
        subtitle={`This action cannot be undone. Please review the ${formTitle} ${
          !isEmpty(requestTitle) ? `- ${requestTitle}` : ''
        } carefully.`}
        closeAlertModal={setAffirmationConfirmationModal}
        isAlertModalVisible={affirmationConfirmationModal}
        alertModalButtons={[
          {
            buttonLabel: 'Cancel',
            type: 'secondary',
            onClickHandler: () => {
              setAffirmationConfirmationModal(false);
            },
          },
          {
            buttonLabel: 'Confirm',
            type: 'primary',
            onClickHandler: () => respondingTemplateNetworkCall(),
          },
        ]}
      />
    </React.Fragment>
  );
};

const ResponseTemplateDetailMemoized = React.memo(ResponseTemplateDetail);

export default connect<{}, {}, any>(null, {
  addOptimisticMessage: MessageActions.addOptimisticMessage,
  removeOptimisticMessage: MessageActions.removeOptimisticMessage,
  failOptimisticMessageMark: MessageActions.failOptimisticMessageMark,
})(ResponseTemplateDetailMemoized);
