import React from 'react';
import {useParams} from 'react-router-dom';
import LocatingPageRepository from '../LocatingPageRepository';

export default function HypercareUserProfileViewModel() {
  let {userId} = useParams<{userId: string}>();

  const repo = LocatingPageRepository();

  const useGetProfileInformation = (id?: string) => {
    const result = repo.useFetchUserProfile(id || userId);
    return {
      data: result.data?.organizationalUnitQuery.organizationalUnit.member,
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };

  const useGetUserAddresses = (userId: string) => {
    const result = repo.useFetchUserAddresses(userId);
    return {
      data: result.data?.organizationalUnitQuery.organizationalUnit.me,
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };

  return {
    useGetProfileInformation,
    useGetUserAddresses,
  };
}
