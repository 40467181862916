import {PreferenceCellWrapper, PreferencesCellIconWrapper, StyledSubmitAddressButton} from '../../styled/login.styled';
import {HCLabelTwo} from '../../../../components/HypercareComponents';
import {
  LOGIN_PAGE_ENTER_ORG_URL_INPUT_LABEL,
  LOGIN_PAGE_ENTER_ORG_URL_INPUT_PLACEHOLDER,
  LOGIN_PAGE_ORG_URL_TOOLTIP_TEXT,
} from '../../../../constants/strings';
import {StyledTooltipBlack} from '../../../../components/StyledTooltip';
import {BlackInfoIcon} from '../../../../svgs/BlackInfoIcon';
import CustomSearchField from '../../../../components/shared/CustomSearchField';
import {NEXT} from '../../../../constants/login';
import React from 'react';
import {muiTheme} from 'src/styles/theme';

export interface OrgUrlViewProps {
  orgUrl: string;
  setOrgUrl: (url: string) => void;
  handleNextButtonPressed: () => void;
  orgURLError: string;
}
export const OrganizationEnterURLView = ({
  orgUrl,
  setOrgUrl,
  handleNextButtonPressed,
  orgURLError,
}: OrgUrlViewProps) => (
  <>
    <PreferenceCellWrapper>
      <HCLabelTwo color={muiTheme.colors.text} lineHeight="20px">
        {LOGIN_PAGE_ENTER_ORG_URL_INPUT_LABEL}
      </HCLabelTwo>
      <StyledTooltipBlack arrow title={LOGIN_PAGE_ORG_URL_TOOLTIP_TEXT} className="styledPopper" placement="top">
        <PreferencesCellIconWrapper>
          <BlackInfoIcon />
        </PreferencesCellIconWrapper>
      </StyledTooltipBlack>
    </PreferenceCellWrapper>
    <CustomSearchField
      clearSearchText={() => setOrgUrl('')}
      handleChange={(e) => setOrgUrl(e.target.value)}
      localSearchText={orgUrl}
      placeHolder={LOGIN_PAGE_ENTER_ORG_URL_INPUT_PLACEHOLDER}
      inputWidth="600px"
      inputHeight="40px"
      showSearchIcon={false}
      error={!!orgURLError}
      searchError={orgURLError}
    />
    <StyledSubmitAddressButton disabled={orgUrl.length === 0} onClick={handleNextButtonPressed}>
      {NEXT}
    </StyledSubmitAddressButton>
  </>
);
