import styled from '@emotion/styled';
import TableContainer from '@mui/material/TableContainer';

export const TableWrapper = styled.div`
  min-width: 875px;
`;

export const TableCollapseHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  padding: 0 1em;
  font-family: Nunito;
  font-weight: bold;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px lightgrey;
`;

export const StyledTableContainer = styled(TableContainer)`
  box-shadow: unset;
  border-radius: 0 !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-left: solid 1px lightgrey;
  border-right: solid 1px lightgrey;
  th,
  td {
    width: 20%;
    line-break: normal;
    overflow-wrap: anywhere;
    vertical-align: top;
    color: ${(props) => props.theme.colors.greyishBrown};
  }
  th {
    line-height: 1.5em;
    .material-icons {
      font-size: 14px;
      padding-left: 8px;
      transform: translateY(2px);
      color: grey;
    }
  }
  td:last-of-type {
    vertical-align: middle !important;
  }
` as React.ComponentType<any>;

export const ChevronHolder = styled.div`
  display: flex;
  align-items: center;
  svg {
    transition: transform 200ms ease-in-out;
  }
`;

export const TableCellFlexContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  div:first-child {
    padding-top: 2px;
    margin-right: 8px;
  }
`;

export const TableCellFlexButtonsContainer = styled(TableCellFlexContainer)`
  flex-wrap: wrap;
  button {
    min-width: 100px;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: Nunito;
    font-weight: 600;
    &:first-of-type {
      color: ${(props) => props.theme.colors.androidGreen};
      margin-right: 8px;
    }
  }
`;

export const TableCellResultContainer = styled(TableCellFlexContainer)`
  overflow-wrap: anywhere;
  svg {
    transform: translateY(2px);
  }
  p {
    margin-bottom: 4px;
    font-style: italic;
  }
`;
