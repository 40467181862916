import React, {useState} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {emailValidationSchema} from 'src/utils/validations';
import {IADFSOrganization} from 'src/types';
import {ADDRESS_IN_USE} from 'src/constants/networkError';
import {Link} from 'react-router-dom';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewNextStepButton from 'src/pages/newSignupFlow/components/NextStepButton';
import SignupPage from 'src/microfrontend/signup/SignupPage';
import styled from '@emotion/styled';
import {Typography} from '@mui/material';
import {muiTheme} from 'src/styles/theme';

const RecoveryHelpText = styled(Typography)`
  .MuiTypography-root {
    margin-top: 16;
    font-family: "'Nunito Sans', sans-serif";
    font-weight: 400;
    font-size: 16;
    color: ${muiTheme.colors.text};

    & a {
      color: ${muiTheme.colors.primary};
    }
  }
`;

const validationSchema = yup.object().shape<ISignupEmailStepOutputData>({
  email: emailValidationSchema,
});

export interface ISignupEmailStepOutputData {
  email: string;
  adfsOrganization?: IADFSOrganization;
}

interface ISignupEmailViewProps {
  email: string;
  onBackButtonClick: () => void;
  viewModel: any;
}

export const SignupEmailView = ({email, onBackButtonClick, viewModel}: ISignupEmailViewProps) => {
  const [showRecoveryMessage, setShowRecoveryMessage] = useState(false);

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupRequestAddressVerification);
  }, []);

  const onNextClick = (email: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupEmailNextPressed,
      params: {
        email,
      },
    });
  };

  const onLoginInstead = (email: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signUpExistLoginInstead,
      params: {
        email,
      },
    });
  };

  return (
    <SignupPage
      currentStep={1}
      isDisabled={false}
      title="Enter your email address"
      description="We highly recommend registering with your organizations credentials or work email for faster and safer verification."
      onBackButtonClick={onBackButtonClick}
      content={
        <Formik
          initialValues={{email}}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={async (values, actions) => {
            const result = await viewModel.handleNextButtonPressed(values.email);
            if (result?.error) {
              if (result.error.code === ADDRESS_IN_USE) setShowRecoveryMessage(true);
              actions.setFieldError('email', result.error.message);
            }
          }}
        >
          {({handleSubmit, values, isValid, handleChange, handleBlur, touched, errors, isSubmitting}) => (
            <form onSubmit={handleSubmit}>
              <StyledTextField
                variant="outlined"
                fullWidth={true}
                margin="normal"
                disabled={isSubmitting}
                autoFocus={false}
                value={values.email}
                name="email"
                label="Email"
                placeholder="you@acme-hospital.com"
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email && errors.email}
                error={touched.email && Boolean(errors.email)}
              />
              {showRecoveryMessage && (
                <RecoveryHelpText>
                  Looks like there is already an account associated with this email, would you like to&nbsp;
                  <Link
                    onClick={() => onLoginInstead(values.email)}
                    to="/login"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    login instead
                  </Link>
                  ?
                </RecoveryHelpText>
              )}
              <NewNextStepButton
                label={'Next'}
                onClick={() => onNextClick(values.email)}
                disabled={isSubmitting}
                loading={isSubmitting}
                loadingLabel="Verifying availability..."
              />
            </form>
          )}
        </Formik>
      }
    />
  );
};
