import React, {useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {StyledTooltipWhite} from 'src/components/StyledTooltip';
import {
  ADD_LABEL,
  PROFILE_PAGE_ADDRESS_RE_ORDER_TOOLTIP_TEXT,
  ProfileAddressMap,
  ProfileVisibiltyMap,
} from 'src/constants/strings';
import {PagerType} from 'src/constants/user';
import {MenuDragIcon} from 'src/svgs/MenuDragIcon';
import {UserAddress} from 'src/types';
import convertAddressTypeToString from 'src/utils/convertAddressTypeToString';
import formatNationalNumber from 'src/utils/locating/formatNationalNumber';
import styled from '@emotion/styled';
import ChangeAccessActionInput from 'src/pages/LocatingPage/locating/profile-section/contact-info-partials/ChangeAccessActionInputV2';
import DeleteAddressActionIcon from 'src/pages/LocatingPage/locating/profile-section/contact-info-partials/DeleteAddressActionIconV2';
import {StyledAnchor} from 'src/styles/styled-components/component';
import {HCLabelOne} from 'src/components/HypercareComponents';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import {Dropdown} from 'src/components/@hc-ui/components/Dropdown/Dropdown';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import ChevrUp from 'src/svgs/ChevrUp';
import ChevrDown from 'src/svgs/ChevrDown';

import Button from '@mui/material/Button';
import {StyledLabelOption, TD} from 'src/pages/LocatingPage/styles/LocatingPageStyles';
import {muiTheme} from 'src/styles/theme';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 8px 0`,
  background: isDragging ? '#F2F2F2' : '',
  ...draggableStyle,
});

const DeleteIconWrapper = styled.div`
  width: 10px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PreferencesCellIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLabelOptionContainer = styled(Button)`
  border: none;
  display: flex;
  gap: 10px;
  align-self: stretch;
  padding: 0px;
  width: 180px;
  justify-content: space-between !important;
  text-transform: none !important;

  &:hover {
    background-color: ${(props) => props.theme.colors.ghostWhite};
  }
`;

interface Props {
  addresses: UserAddress[];
  address: UserAddress;
  isSelf: boolean;
  isAdmin: boolean;
  index: number;
  isEditMode: boolean;
  isInputDisabled: boolean;
  onCurrentAddressesChange: (addresses: UserAddress[]) => void;
  handleContactMethodsAddressAnchor: (address: UserAddress) => void;
  onModeChange: (mode: 'view' | 'edit') => void;
}

export const HypercareProfileAddressTableRows = ({
  address,
  index,
  isSelf,
  isAdmin,
  isEditMode,
  addresses,
  onCurrentAddressesChange: setCurrentAddresses,
  isInputDisabled,
  handleContactMethodsAddressAnchor,
  onModeChange,
}: Props) => {
  const [isHoverList, setIsHoverList] = useState(false);
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabel);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const viewModel = HypercareSelfProfileViewModel();

  const {data: organizationAddressLabelOptions, error, loading} = viewModel.useGetOrganizationLabelOptions();

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const linkTarget = (address: UserAddress): string | undefined => {
    if (address.type === PagerType.NUMERIC_PAGER || address.type === PagerType.ALPHANUMERIC_PAGER) {
      return undefined;
    } else if (address.type === 'email') {
      return `mailto:${address.address}`;
    } else {
      return `callto:${address.address}`;
    }
  };
  const handleAddressLabelChange = (o: string) => {
    viewModel.handleLabelChange(address, o);

    const newAddressArray = addresses.map((a) => (a.id === address.id ? {...a, label: o} : a));
    setCurrentAddresses(newAddressArray);
  };

  const onAddLabelClick = () => {
    if (isSelf) onModeChange('edit');
  };

  return (
    <Draggable
      key={address.address + index}
      draggableId={address.address + index}
      index={index}
      isDragDisabled={!isEditMode || !isHoverList}
    >
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <TD>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              {isEditMode && (
                <StyledTooltipWhite
                  arrow
                  title={PROFILE_PAGE_ADDRESS_RE_ORDER_TOOLTIP_TEXT}
                  placement="left"
                  className="styledPopper"
                >
                  <PreferencesCellIconWrapper
                    onMouseEnter={() => {
                      setIsHoverList(true);
                    }}
                    onMouseLeave={() => {
                      setIsHoverList(false);
                    }}
                  >
                    <MenuDragIcon />
                  </PreferencesCellIconWrapper>
                </StyledTooltipWhite>
              )}
              {index + 1}
            </div>
          </TD>
          {profileAddressLabelFlag && (
            <TD>
              {isEditMode ? (
                <>
                  <StyledLabelOptionContainer
                    ref={setAnchorElement}
                    onClick={() => setIsOptionsOpen(true)}
                    type={'button'}
                  >
                    <HCLabelOne color={address.label ? muiTheme.colors.blackFontColor : muiTheme.colors.watermelon}>
                      {address.label || 'Select'}
                    </HCLabelOne>
                    {isOptionsOpen ? (
                      <ChevrUp color={address.label ? muiTheme.colors.blackFontColor : muiTheme.colors.watermelon} />
                    ) : (
                      <ChevrDown color={address.label ? muiTheme.colors.blackFontColor : muiTheme.colors.watermelon} />
                    )}
                  </StyledLabelOptionContainer>
                  <Dropdown
                    isOpen={isOptionsOpen}
                    options={organizationAddressLabelOptions ?? []}
                    getOptionId={(o) => o}
                    getIsOptionDisabled={(o) => {
                      return false;
                    }}
                    getIsOptionSelected={(_o) => false}
                    onOptionClick={(o) => {
                      handleAddressLabelChange(o);
                      setIsOptionsOpen(false);
                    }}
                    onClickAway={() => setIsOptionsOpen(false)}
                    renderOptionContent={(o) => o}
                    anchorElement={anchorElement}
                  />
                </>
              ) : (
                <>
                  {address.label ? (
                    address.label
                  ) : (
                    <StyledLabelOption handleLabelOptionClick={onAddLabelClick} text={isSelf ? ADD_LABEL : '--'} />
                  )}
                </>
              )}
            </TD>
          )}
          <TD>{address.type && ProfileAddressMap[address.type]}</TD>
          <TD style={{width: '349px'}}>
            <StyledAnchor
              isEditMode={isEditMode}
              rel="noreferrer"
              target={address.type === 'email' ? '_blank' : undefined}
              title={`${convertAddressTypeToString(address.type)} this person`}
              href={linkTarget(address)}
              onClick={() => handleContactMethodsAddressAnchor(address)}
            >
              <HCLabelOne color={isEditMode ? '' : muiTheme.colors.primary}>
                {address?.type === 'phoneNumber' ? formatNationalNumber(address?.address) : address?.address}
              </HCLabelOne>
            </StyledAnchor>
          </TD>
          {(isSelf || isAdmin) && isEditMode ? (
            <TD>
              <ChangeAccessActionInput
                addresses={addresses}
                isInputDisabled={isInputDisabled}
                index={index}
                setFieldValue={() => {}}
                currentAddress={address}
                isEditProfileFormLoading={false}
                setEditProfileFormLoading={() => {}}
                setCurrentAddresses={setCurrentAddresses}
              />
            </TD>
          ) : (
            (isSelf || isAdmin) && <TD>{ProfileVisibiltyMap[address.access]}</TD>
          )}

          {isEditMode && (
            <TD>
              <DeleteIconWrapper>
                <DeleteAddressActionIcon
                  currentAddress={address}
                  addresses={addresses}
                  setCurrentAddresses={setCurrentAddresses}
                />
              </DeleteIconWrapper>
            </TD>
          )}
        </tr>
      )}
    </Draggable>
  );
};
