import React, {CSSProperties} from 'react';
import * as R from 'ramda';
import {createFilter} from 'react-select';
import {useTheme} from '@mui/material/styles';
import {ValueType} from 'react-select/src/types';
import overriddenComponents, {
  NoUnderlineControl as Control,
  StyledSelect,
} from 'src/pages/MessengerPage/auto-select/ReactSelectMaterialUI';
import {UserSelectOptionType} from 'src/types';
import {
  NoCircleOption as Option,
  AutoHeightMenuList as MenuList,
} from 'src/pages/MessengerPage/auto-select/ReactSelectSharedComponents';

/**
 * Search by label and role
 */
const stringify = (option) => {
  return option.label + option.data.role;
};
const filterOption = createFilter({ignoreCase: true, stringify});

const AddChatMemberListSelect = ({reduxStoreSelectedColleagues, setReduxSelectedColleagues, suggestions}) => {
  const theme = useTheme();
  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<UserSelectOptionType>>([]);

  // selectedColleage dependent on reduxStoreSelectedColleagues from reducer
  React.useEffect(() => {
    if (!R.equals(reduxStoreSelectedColleagues, selectedColleague)) {
      setSelectedColleague(reduxStoreSelectedColleagues);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxStoreSelectedColleagues]);

  function handleChangeMulti(value: ValueType<UserSelectOptionType>) {
    if (value || (reduxStoreSelectedColleagues && reduxStoreSelectedColleagues.length > 0)) {
      setReduxSelectedColleagues(value);
    }
  }

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div style={{flexGrow: 1}} onClick={(e) => e.stopPropagation()}>
      <StyledSelect
        autoFocus
        styles={selectStyles}
        inputId="new-chat-dropdown"
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: 'new-chat-dropdown',
            shrink: true,
          },
        }}
        placeholder={'Search a name...'}
        options={suggestions}
        components={{
          ...overriddenComponents,
          Option,
          Control,
          MenuList,
        }}
        value={selectedColleague}
        filterOption={filterOption}
        openMenuOnClick={false}
        onChange={handleChangeMulti}
        isMulti
      />
    </div>
  );
};

export default AddChatMemberListSelect;
