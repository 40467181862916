import React from 'react';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/react-hooks';
import {Dialog, DialogContent, Divider, Grid} from '@mui/material';

import LoadingDiv from 'src/components/LoadingDiv';
import {
  FieldInputLabel,
  SecondaryButton,
  StyledDialogActions,
} from 'src/styles/styled-components/StyledMaterialComponents';
import MessageTemplateFactory from './MessageTemplateFactory';
import {MessageTemplateResponseType, TemplateViews} from 'src/types';
import FetchMessageTemplate from 'src/gql/query/FetchMessageTemplate';
import {MaterialDialogHeader, Transition} from 'src/components/HypercareComponents';

const RequestName = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  margin-bottom: 16px;
  font-family: 'Nunito Sans', sans-serif;
`;

const Response = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const TemplateMessageResponse = styled(FieldInputLabel)`
  font-weight: 600 !important;
  text-transform: uppercase;
  margin-bottom: 0;
`;

const RespondedTemplateMessageResponse = ({
  chatId,
  message,
  respondedModal,
  setRespondedModal,
  viewRequestDetailsBtnHandler,
}) => {
  const requestTitle = message?.template?.requestTitle;

  const {data, loading} = useQuery(FetchMessageTemplate, {
    variables: {
      chatId,
      templateId: message.template.id,
    },
    fetchPolicy: 'no-cache',
  });

  const DetailedResponseModalContent = () => {
    const {formTitle, response} = data?.chat?.messageTemplates?.template;
    return (
      <React.Fragment>
        <Grid item lg={12}>
          <RequestName>
            {formTitle} {requestTitle ? `- ${requestTitle}` : ''}
          </RequestName>
        </Grid>
        {response.type === MessageTemplateResponseType.acknowledge && (
          <React.Fragment>
            <TemplateMessageResponse>RESPONSE</TemplateMessageResponse>
            <Response>{response.isAcknowledged ? 'Acknowledged' : 'Not Acknowledged'}</Response>
          </React.Fragment>
        )}
        {response.type === MessageTemplateResponseType.affirmation && (
          <React.Fragment>
            <TemplateMessageResponse>RESPONSE</TemplateMessageResponse>
            <Response>{response.isAffirmative ? 'Yes' : 'No'}</Response>
          </React.Fragment>
        )}
        {response.type === MessageTemplateResponseType.detail && (
          <Grid container spacing={2}>
            {new MessageTemplateFactory(
              data?.chat?.messageTemplates?.template,
              TemplateViews.RespondedResponse,
            ).createUIWithJSONParser()}
          </Grid>
        )}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Dialog maxWidth={'sm'} fullWidth={true} open={respondedModal} TransitionComponent={Transition}>
        <MaterialDialogHeader
          dialogTitle={'Detailed response'}
          closeIconButtonClickHandler={() => setRespondedModal(false)}
        />
        <DialogContent>
          {loading && <LoadingDiv />}
          {!loading && data?.chat?.messageTemplates?.template && <DetailedResponseModalContent />}
        </DialogContent>
        <Divider />
        <StyledDialogActions>
          <SecondaryButton onClick={() => viewRequestDetailsBtnHandler()}>View request details</SecondaryButton>
        </StyledDialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RespondedTemplateMessageResponse;
