import React from 'react';
import client from 'src/apollo';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import CircularProgress from '@mui/material/CircularProgress';
import {toast} from 'react-toastify';
import Checkmark from 'src/svgs/Checkmark';
import {SCHEDULING} from 'src/constants/routerPathName';
import GridCellAssignee from 'src/pages/SchedulingPage/action-calendar/GridCellAssignee';
import {
  CustomToastFlexContainer,
  ModalBodyWrapper,
  TextareaWrapper,
  WarningText,
  ErrorText,
  ButtonsWrapper,
  IndicatorTitle,
} from 'src/styles/styled-components/SharedModalStyles';
import {RoleHolderWrapper} from 'src/pages/SchedulingPage/scheduling/ActionCalendarGridSystem';
import {AssigneeWithRole} from 'src/types';
import {
  CancelMarketplaceShiftMutation,
  PickUpMarketplaceShiftMutation,
} from 'src/gql/mutation/OfferMarketplaceMutation';
import {
  MarketplaceRefetchContext,
  MarketplaceRefetchRequestType,
} from 'src/pages/SchedulingPage/action-calendar/marketplace-section/MarketplaceRefetchProvider';
import sleep from 'src/utils/sleep';
import {
  POST_NOT_AVAILABLE,
  POST_SHIFT_EXPIRED,
  CONFLICT_WITH_DESIRED_SHIFT,
  DESIRED_SHIFT_EXPIRED,
} from 'src/constants/networkError';
import MarketplaceDoubleConfirmationModal from 'src/pages/SchedulingPage/action-calendar/marketplace-section/MarketplaceDoubleConfirmationModal';

const IndicatorHolder = styled.div`
  width: 100%;
  ${RoleHolderWrapper} {
    width: 66%;
  }
  * {
    cursor: auto;
  }
`;

interface Props {
  isOpen: boolean;
  isSelf: boolean;
  isCancel: boolean;
  closeModal: () => void;
  targetShift: AssigneeWithRole;
  userToColorCodes: {
    [userId: string]: string;
  };
}

interface PropsWithRefetch extends Props {
  refetch: MarketplaceRefetchRequestType | null;
}

const MarketplaceConfirmationModal = ({
  refetch,
  isOpen,
  isSelf,
  isCancel,
  closeModal,
  targetShift,
  userToColorCodes,
}: PropsWithRefetch) => {
  const [noteValue, setNoteValue] = React.useState('I want to take because ');
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [showDoubleConfirmationModal, setShowDoubleConfirmationModal] = React.useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteValue(e.target.value);
  };

  const leadingText = isCancel ? 'remove' : 'take';

  const handleSendRequest = async () => {
    try {
      setLoading(true);
      setError('');
      await client.mutate({
        mutation: isCancel ? CancelMarketplaceShiftMutation : PickUpMarketplaceShiftMutation,
        variables: {
          postId: targetShift.assignee.postId,
          note: isCancel ? undefined : noteValue,
        },
      });
      await sleep(2000);
      toast.info(CustomizedActionToast(), {
        autoClose: false,
      });
      closeModal();
      setLoading(false);
      refetch?.();
    } catch (e: any) {
      console.error(e);
      setLoading(false);
      if (e.networkError && e.networkError.result && e.networkError.result.errors && e.networkError.result.errors[0]) {
        let errorCodeName = e.networkError.result.errors[0].code;
        if (
          errorCodeName === POST_SHIFT_EXPIRED ||
          errorCodeName === POST_NOT_AVAILABLE ||
          errorCodeName === DESIRED_SHIFT_EXPIRED
        ) {
          toast.error('The shift you are trying to take is either unavailable or has been expired');
          refetch?.();
        } else if (errorCodeName === CONFLICT_WITH_DESIRED_SHIFT) {
          setError('The desired shift conflicts with your current schedule');
          return setShowDoubleConfirmationModal(true);
        } else {
          setError(`Unable to complete your request due to unknown error (${errorCodeName})`);
        }
      } else {
        setError(
          `Failed to ${leadingText} shift from marketplace, please check your internet connection and try again`,
        );
      }
    }
  };

  const CustomizedActionToast = () => {
    const handleClick = () => {
      window.routerHistory.push(`/${SCHEDULING}`);
      toast.dismiss();
    };
    return (
      <CustomToastFlexContainer>
        <div>
          <Checkmark />
          You have {leadingText} the shift on your schedule
        </div>
        <button onClick={handleClick}>View schedule</button>
      </CustomToastFlexContainer>
    );
  };

  return (
    <React.Fragment>
      <StyledModal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnEsc={!isLoading}
        shouldCloseOnOverlayClick={!isLoading}
        onRequestClose={closeModal}
      >
        <Header>
          <span>Confirm</span>
          {!isLoading && <CloseBtn onClick={closeModal} />}
        </Header>
        <ModalBodyWrapper>
          <IndicatorHolder>
            <IndicatorTitle>You are about to {leadingText} the following shift from marketplace:</IndicatorTitle>
            <GridCellAssignee
              isSwap={false}
              showOfferTypes={false}
              timeformat="HH:mm"
              isSelf={isSelf}
              assignee={targetShift.assignee}
              role={targetShift.role}
              onCellClick={() => null}
              userToColorCodes={userToColorCodes}
            />
          </IndicatorHolder>

          {!isCancel && (
            <React.Fragment>
              <TextareaWrapper>
                <TextField
                  variant="outlined"
                  multiline={true}
                  rows={4}
                  fullWidth
                  value={noteValue}
                  onChange={handleChange}
                  label="Add an optional note"
                />
              </TextareaWrapper>
              <WarningText>
                <span>
                  Note: This shift can be requested without a shift in return and is offered based on a first come -
                  first serve basis.
                </span>
              </WarningText>
            </React.Fragment>
          )}

          <ErrorText>{error}</ErrorText>

          <ButtonsWrapper>
            <Button variant="contained" disabled={isLoading} disableTouchRipple onClick={closeModal}>
              cancel
            </Button>

            <Button
              variant="contained"
              onClick={handleSendRequest}
              disabled={isLoading}
              color="secondary"
              disableTouchRipple
            >
              {isLoading ? <CircularProgress size={20} /> : <span>{isCancel ? 'remove shift' : 'take shift'}</span>}
            </Button>
          </ButtonsWrapper>
        </ModalBodyWrapper>
      </StyledModal>
      {showDoubleConfirmationModal && isOpen && (
        <MarketplaceDoubleConfirmationModal
          isOpen={showDoubleConfirmationModal && isOpen}
          closeModal={() => setShowDoubleConfirmationModal(false)}
          closeAll={() => {
            setShowDoubleConfirmationModal(false);
            closeModal();
          }}
          refetch={refetch}
          postId={targetShift.assignee.postId}
          note={noteValue}
          warningReason={error}
          customizedToast={CustomizedActionToast}
        />
      )}
    </React.Fragment>
  );
};

export default (props: Props) => (
  <MarketplaceRefetchContext.Consumer>
    {({refetch}) => <MarketplaceConfirmationModal refetch={refetch} {...props} />}
  </MarketplaceRefetchContext.Consumer>
);
