import React from 'react';
import {MonthlyCalendarShift, AssigneeWithRole, AssigneeProp} from 'src/types';
import {AssigneeWrapper} from 'src/pages/SchedulingPage/scheduling/ActionCalendarGridSystem';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {useShiftsFilter} from 'src/pages/SchedulingPage/scheduling/hooks';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MarketplaceConfirmationModal from 'src/pages/SchedulingPage/action-calendar/marketplace-section/MarketplaceConfirmationModal';
import {MenuItem, StyledMenuPaper, StyledPopper} from 'src/pages/SchedulingPage/scheduling/SharedPopperStyles';
import {AppDispatch} from 'src/redux';
import {useDispatch} from 'react-redux';
import {SCHEDULING} from 'src/constants/routerPathName';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {CalenderShifts} from 'src/components/@hc-ui/components/CalendarView/components';

interface Props {
  shifts: MonthlyCalendarShift[];
  userToColorCodes: {
    [userId: string]: string;
  };
}

type ConfirmModalType = 'pick_up' | 'cancel';

const MarketplaceCalendarAssignees = ({shifts, userToColorCodes}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [targetShift, setTargetShift] = React.useState<AssigneeWithRole | null>(null);
  const [confirmModalType, setShowConfirmModalType] = React.useState<ConfirmModalType | null>(null);

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;

  const dispatch: AppDispatch = useDispatch();

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    // @ts-ignore
    if (anchorEl && anchorEl.contains(event.target)) return;
    setAnchorEl(null);
  };

  const handleCellClick = (event: React.MouseEvent<HTMLElement> | undefined, payload: AssigneeWithRole) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
    setTargetShift(payload);
  };

  const handleRequestSwap = () => {
    if (targetShift === null) return;
    setAnchorEl(null);
    dispatch({
      type: 'tradeShift/SET_OTHER',
      payload: {shift: targetShift},
    });
    window.routerHistory.push(`/${SCHEDULING}/swap`);
  };

  const handleShowConfirmation = (type: ConfirmModalType) => {
    setAnchorEl(null);
    setShowConfirmModalType(type);
  };

  const assigneeToRole = useShiftsFilter({shifts, calendarType: 'give_away'});
  const isSelectingSelf = targetShift && targetShift.assignee.userId === selfID;

  return (
    <AssigneeWrapper>
      {assigneeToRole.map((monthlyCalendarShift, index) => {
        const {assignee, role} = monthlyCalendarShift;
        const payload = {assignee, role};
        const isSelf = selfID === assignee.userId;
        const AssigneeData: AssigneeProp = {
          endTime: assignee.endTime,
          startTime: assignee.startTime,
          userId: assignee.userId,
          userFullName: assignee.userFullName,
          shiftId: assignee.shiftId,
          offerTypes: assignee.offerTypes,
        };

        const menuItem = [];
        return (
          <CalenderShifts
            key={`marketplace-${index}-${role.roleId}-${assignee.userId}`}
            isSelf={isSelf}
            assignee={AssigneeData}
            roleName={role.roleName}
            onCellClick={(e) => handleCellClick(e, payload)}
            userToColorCodes={userToColorCodes}
            menuItem={menuItem}
            anchorEl={anchorEl!}
            setAnchorEl={setAnchorEl}
            variant="TimeWithNameVariant"
            showOfferTypes={true}
          />
        );
      })}

      <StyledPopper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom-start'} transition>
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
            <Fade {...TransitionProps} timeout={350}>
              <StyledMenuPaper>
                {isSelectingSelf ? (
                  <MenuItem onClick={() => handleShowConfirmation('cancel')}>
                    <span>Remove from Marketplace</span>
                  </MenuItem>
                ) : (
                  <React.Fragment>
                    {targetShift?.assignee?.offerTypes?.includes('swap') && (
                      <MenuItem onClick={handleRequestSwap}>
                        <span>Request swap</span>
                      </MenuItem>
                    )}
                    {targetShift?.assignee?.offerTypes?.includes('give_away') && (
                      <MenuItem onClick={() => handleShowConfirmation('pick_up')}>
                        <span>Pick up this shift</span>
                      </MenuItem>
                    )}
                  </React.Fragment>
                )}
              </StyledMenuPaper>
            </Fade>
          </ClickAwayListener>
        )}
      </StyledPopper>

      {Boolean(confirmModalType) && targetShift && (
        <MarketplaceConfirmationModal
          isOpen={Boolean(confirmModalType)}
          isCancel={confirmModalType === 'cancel'}
          closeModal={() => setShowConfirmModalType(null)}
          targetShift={targetShift}
          isSelf={!!isSelectingSelf}
          userToColorCodes={userToColorCodes}
        />
      )}
    </AssigneeWrapper>
  );
};

export default MarketplaceCalendarAssignees;
