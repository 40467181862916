import * as React from 'react';
import {PageBody, PageContainer} from 'src/styles/styled-components/CommonPage';
import Helmet from 'react-helmet';
import {AuthContext} from 'src/auth/AuthProvider';
import ChatListLayout from 'src/pages/MessengerPage/messenger/ChatListLayout';
import CentralMessengerLayout from 'src/pages/MessengerPage/messenger/CentralMessengerLayout';
import Header from 'src/pages/MessengerPage/messenger/PageHeader';
import ChatInformation from 'src/pages/MessengerPage/messenger/ChatInformationLayout';
import {AuthPayload, AuthRegion} from 'src/types';
import {Redirect} from 'react-router-dom';
import PageBanner from 'src/components/PageBanner';
interface Props {
  logout: () => void;
  isLoggedIn: boolean;
  authInfo: AuthPayload;
  authRegion: AuthRegion;
}

class MessengerPage extends React.PureComponent<Props> {
  public state = {
    isSideSearchActive: true,
    isSearch: false,
    jumpToMessage: null,
    isJumpToMessageEnable: true,
  };

  public render() {
    const {isLoggedIn, authInfo, authRegion, logout} = this.props;
    const {isSearch, jumpToMessage, isSideSearchActive, isJumpToMessageEnable} = this.state;
    const user = authInfo?.user;

    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <PageContainer>
        <Helmet>
          <title>Inbox - Hypercare</title>
        </Helmet>

        <Header user={user} logout={logout} authRegion={authRegion} />

        <PageBanner />

        <PageBody>
          <ChatListLayout
            isSearch={isSearch}
            handleIsSearch={() => this.handleIsSearch(false)}
            handleJumpToMessage={this.handleJumpToMessage}
            isJumpToMessageEnable={isJumpToMessageEnable}
          />
          <CentralMessengerLayout
            isSearch={isSearch}
            handleIsSearch={this.handleIsSearch}
            jumpToMessage={jumpToMessage}
            isSideSearchActive={isSideSearchActive}
            handleIsJumpToMessageEnable={this.handleIsJumpToMessageEnable}
          />
          <ChatInformation userId={user.id} />
        </PageBody>
      </PageContainer>
    );
  }

  public handleIsSearch = (isVisible: boolean) => {
    this.setState({
      isSearch: isVisible,
      isSideSearchActive: !isVisible,
    });
    if (isVisible) {
      this.setState({
        jumpToMessage: null,
      });
    }
  };

  public handleJumpToMessage = (messageId: any) => {
    this.setState({
      jumpToMessage: messageId,
    });
  };

  public handleIsJumpToMessageEnable = (isEnable: boolean) => {
    this.setState({
      isJumpToMessageEnable: isEnable,
    });
  };
}

export default () => (
  <AuthContext.Consumer>
    {({logout, isLoggedIn, authRegion, authInfo}) => (
      <MessengerPage isLoggedIn={isLoggedIn} authInfo={authInfo} authRegion={authRegion} logout={logout} />
    )}
  </AuthContext.Consumer>
);
