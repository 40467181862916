import {GetAllActiveEscalationsResult} from 'src/types';
import client from 'src/apollo';
import {CheckActiveEscalationExists} from 'src/gql/query/FetchAllActiveEscalations';

export default (resultEscalationID: number) => {
  const existingActiveEscalations = client.readQuery<GetAllActiveEscalationsResult>({
    query: CheckActiveEscalationExists,
  });
  const newActiveEscalation = {
    id: resultEscalationID,
    state: 'active',
    __typename: 'ActiveEscalation',
  };

  client.writeQuery({
    query: CheckActiveEscalationExists,
    data: {
      ...existingActiveEscalations,
      me: {
        ...existingActiveEscalations?.me,
        activeEscalations: [...(existingActiveEscalations?.me.activeEscalations ?? []), newActiveEscalation],
      },
    },
  });
};
