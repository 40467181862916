import * as React from 'react';
import Helmet from 'react-helmet';
import Header from 'src/pages/MessengerPage/messenger/PageHeader';
import {AuthContext} from 'src/auth/AuthProvider';
import {useQuery} from '@apollo/react-hooks';
import {GetSelfOrganizationsResult, GetScopedSelfDepartmentsResult} from 'src/types';
import GetSelfOrganizations, {FetchSelfScopedDepartments} from 'src/gql/query/GetUserOrganizations';
import ScheduleLayout from 'src/pages/SchedulingPage/scheduling/ScheduleLayout';
import RequestLayout from 'src/pages/SchedulingPage/scheduling/RequestLayout';
import MarketplaceLayout from 'src/pages/SchedulingPage/scheduling/MarketplaceLayout';
import MyCalendarLayout from 'src/pages/SchedulingPage/scheduling/MyCalendarLayout';
import CustomizedTabs from 'src/pages/SchedulingPage/CustomizedTabs';
import {PageContainer} from 'src/styles/styled-components/CommonPage';
import {useLocation} from 'react-router-dom';
import {SCHEDULES, MARKETPLACE, REQUESTS, MYSCHEDULE} from 'src/constants/scheduler';
import {SCHEDULING} from 'src/constants/routerPathName';
import LoadingDiv from 'src/components/LoadingDiv';
import SchedulingEmptyStateView from 'src/pages/SchedulingPage/scheduling/SchedulingEmptyStateView';
import SchedulingVariableProvider from 'src/pages/SchedulingPage/scheduling/SchedulingVariableProvider';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';
import PageBanner from 'src/components/PageBanner';
import {QueryResult} from 'react-apollo';
import styled from '@emotion/styled';

const RouteOptionsWrapper = styled.div`
  max-width: ${window.outerWidth}px;
  overflow-x: scroll;
  height: 100%;
`;

type TabOptions = 'schedules' | 'requests' | 'marketplace' | 'myschedule';

const SchedulingPage = () => {
  const routeLocation = useLocation();
  const lastUrlSegmentString = routeLocation.pathname.substring(routeLocation.pathname.lastIndexOf('/') + 1);
  const currentRouteOption: TabOptions =
    lastUrlSegmentString === REQUESTS || lastUrlSegmentString === MARKETPLACE || lastUrlSegmentString === MYSCHEDULE
      ? lastUrlSegmentString
      : SCHEDULES;

  const {
    data: selfOrgData,
    loading: loadingOrg,
    error: errorOrg,
  }: QueryResult<GetSelfOrganizationsResult> = useQuery(GetSelfOrganizations, {
    fetchPolicy: 'cache-only',
  });

  const {
    data: scopedSelfDepartmentsData,
    loading,
    error,
  }: QueryResult<GetScopedSelfDepartmentsResult> = useQuery(FetchSelfScopedDepartments, {
    fetchPolicy: 'network-only',
  });

  const setTabOption = (value: string) => {
    let tabUrl = value.replace('-', '');
    window.routerHistory.push(value === SCHEDULES ? `/${SCHEDULING}` : `/${SCHEDULING}/${tabUrl}`);
  };

  if (loading || error || !scopedSelfDepartmentsData?.me || loadingOrg || errorOrg || !selfOrgData)
    return <LoadingDiv />;

  // i.e. this departments is the subset of entire department scope under GetSelfOrganizationsResult
  const {departments} = scopedSelfDepartmentsData?.me;
  const hasSelfscopedDeparment = departments && departments.length > 0;
  let firstOccurredDeparmentIdInFullScope: number = 0;
  let renderEmptyStateTypeString: 'site' | 'department' | null = null;

  if (selfOrgData.me && selfOrgData.me.organizations) {
    const currentOrganizationID = getCurrentOrganizationId();
    const selfOrganizations = selfOrgData.me.organizations;
    const targetSites = selfOrganizations.find((organization) => organization.id == currentOrganizationID)?.sites;
    if (!targetSites || targetSites.length === 0) {
      renderEmptyStateTypeString = 'site';
    } else {
      const targetDepartments = targetSites.find((site) => site.departments[0])?.departments;

      if (!targetDepartments || targetDepartments.length === 0) {
        renderEmptyStateTypeString = 'department';
      } else {
        firstOccurredDeparmentIdInFullScope = targetDepartments[0].id;
      }
    }
  }

  if (!firstOccurredDeparmentIdInFullScope && !renderEmptyStateTypeString) return <LoadingDiv />;

  return (
    <SchedulingVariableProvider
      defaultDepartmentId={departments[0] ? departments[0].id : firstOccurredDeparmentIdInFullScope}
    >
      <PageContainer>
        <CustomizedTabs
          value={currentRouteOption}
          values={['schedules', 'requests', 'marketplace', 'my schedule']}
          setValue={setTabOption}
        />
        <RouteOptionsWrapper>
          {currentRouteOption === 'schedules' && firstOccurredDeparmentIdInFullScope && <ScheduleLayout />}
          {currentRouteOption === 'schedules' && !firstOccurredDeparmentIdInFullScope && (
            <SchedulingEmptyStateView view={SCHEDULES} type={renderEmptyStateTypeString ?? undefined} />
          )}
          {currentRouteOption === 'requests' && <RequestLayout />}
          {currentRouteOption === MYSCHEDULE && <MyCalendarLayout />}
          {currentRouteOption === 'marketplace' && hasSelfscopedDeparment && <MarketplaceLayout />}
          {currentRouteOption === 'marketplace' && !hasSelfscopedDeparment && (
            <SchedulingEmptyStateView view={MARKETPLACE} type={'department'} />
          )}
        </RouteOptionsWrapper>
      </PageContainer>
    </SchedulingVariableProvider>
  );
};

export default () => (
  <AuthContext.Consumer>
    {({authInfo, authRegion, logout}) => (
      <React.Fragment>
        <Helmet>
          <title>Schedule - Hypercare</title>
        </Helmet>
        <Header user={authInfo.user} logout={logout} authRegion={authRegion} />
        <PageBanner />
        <SchedulingPage />
      </React.Fragment>
    )}
  </AuthContext.Consumer>
);
