import React, {useState} from 'react';
import {AuthContext} from 'src/auth';
import {Formik} from 'formik';
import styled from '@emotion/styled';
import {DefaultButton} from 'src/styles/styled-components/Button';
import {Box, CircularProgress, LinearProgress} from '@mui/material';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {AuthRegion} from 'src/types';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import {
  LOGIN_AUTHREGION_CA_ACCOUNT,
  LOGIN_AUTHREGION_SWITCH_CA,
  LOGIN_AUTHREGION_SWITCH_USA,
  LOGIN_AUTHREGION_USA_ACCOUNT,
  NEXT,
  VERIFYING_AVAILABILITY,
} from 'src/constants/login';
import {media} from 'src/styles/utils';
import * as Yup from 'yup';
import {HypercareLoginAddressViewModelProps} from './HypercareLoginAddressViewModel';
import NewLoginFormHeader from '../ components/NewLoginFormHeader';

interface LoginFormWrapperProps {
  loading?: string;
}

const LoginFormWrapper = styled.div<LoginFormWrapperProps>`
  position: relative;
  z-index: 1;
  background-color: white;
  ${media.sm`
    padding: 1em;
  `}
  border-radius: 8px;
  min-height: 387px !important;
  width: 600px;
  max-height: 800px;
  padding: 44px 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  filter: drop-shadow(0px 3px 5px rgba(9, 30, 66, 0.2)) drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
  opacity: ${(props) => (props.loading ? '0.5' : '')};
`;

const InputsWrapper = styled.div`
  .MuiOutlinedInput-input {
    font-family: 'Nunito Sans';
    box-sizing: border-box;
    height: 52px;
    padding: 16px 14px;
  }
  .MuiTextField-root {
    &:first-child {
      margin-bottom: 1em;
    }
  }
  .MuiFormHelperText-root {
    margin-right: 0 !important;
    margin-left: 2px !important;
  }
`;

const SwitchRegionSuggestion = styled.div`
  text-align: left;
  margin-top: 1em;
  color: red;
  font-size: 1em;
  span {
    padding: 8px 0;
    text-decoration: underline;
    line-height: 2em;
    cursor: pointer;
  }
`;

const StyledSubmitAddressButton = styled(DefaultButton)`
  background-color: #ff3e55;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
`;

const ProgressContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  & * {
    border-radius: 8px 8px 0px 0px;
  }
`;

const HelperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const StyledAnchor = styled.a`
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 400;
  color: #4a4a4a;
  margin-top: 16px;
  text-decoration: none;
  float: right;
  span {
    padding-left: 4px;
    color: ${(props) => props.theme.colors.chatTeal};
  }

  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: 400;
`;

interface Props {
  authRegion: AuthRegion;
  viewModel: HypercareLoginAddressViewModelProps;
  setAuthRegion: (region: AuthRegion) => void;
  isOpen: (open: boolean) => void;
  isLoading: boolean;
}

const HypercareLoginAddressView = ({viewModel, authRegion, setAuthRegion, isOpen, isLoading}: Props) => {
  const [isSuggestingRegion, setSuggestingRegion] = useState(false);

  const onClickContact = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.loginContactSupport,
    });
  };

  return (
    <Formik
      initialValues={{address: ''}}
      validationSchema={Yup.object().shape({
        address: Yup.string().required('Email or username is required'),
      })}
      onSubmit={async (values, actions) => {
        const result = await viewModel.completeAddressStep(values);
        if (result?.error) {
          actions.setFieldError('address', result.error);
        }
      }}
    >
      {({handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting}) => (
        <form onSubmit={handleSubmit}>
          <LoginFormWrapper loading={isSubmitting ? 'true' : undefined}>
            {(isSubmitting || isLoading) && (
              <ProgressContainer>
                <LinearProgress color="secondary" />
              </ProgressContainer>
            )}
            <NewLoginFormHeader isAccountLocked={false} />
            <InputsWrapper>
              <StyledTextField
                data-testid="addressInput"
                fullWidth
                variant="outlined"
                placeholder="you@acme-hospital.com"
                label="Email or username"
                onChange={handleChange}
                onBlur={handleBlur}
                name="address"
                value={values.address}
                helperText={touched.address && errors.address}
                error={touched.address && Boolean(errors.address)}
              />
            </InputsWrapper>

            {isSuggestingRegion && (
              <SwitchRegionSuggestion>
                {authRegion === 'CA' ? LOGIN_AUTHREGION_CA_ACCOUNT : LOGIN_AUTHREGION_USA_ACCOUNT}
                <span onClick={() => isOpen(true)}>
                  {authRegion === 'CA' ? LOGIN_AUTHREGION_SWITCH_USA : LOGIN_AUTHREGION_SWITCH_CA}
                </span>
              </SwitchRegionSuggestion>
            )}
            {
              <StyledSubmitAddressButton
                data-testid="addressSubmitButton"
                type="submit"
                disabled={isSubmitting || !values.address}
              >
                {isSubmitting && (
                  <>
                    <CircularProgress color="inherit" size={18} style={{marginRight: 16}} /> {VERIFYING_AVAILABILITY}
                  </>
                )}
                {!isSubmitting && NEXT}
              </StyledSubmitAddressButton>
            }

            <HelperBox>
              <StyledAnchor
                onClick={onClickContact}
                href="https://www.hypercare.com/contact"
                rel="noopener noreferrer"
                target="_blank"
              >
                Having some issues?
                <span>Contact us</span>
              </StyledAnchor>
            </HelperBox>
          </LoginFormWrapper>
        </form>
      )}
    </Formik>
  );
};

export default (props) => (
  <AuthContext.Consumer>
    {({login, authRegion, setAuthRegion, auth0props}) => (
      <HypercareLoginAddressView
        {...props}
        login={login}
        authRegion={authRegion}
        setAuthRegion={setAuthRegion}
        auth0props={auth0props}
      />
    )}
  </AuthContext.Consumer>
);
