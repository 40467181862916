import * as React from 'react';
import styled from '@emotion/styled';
import RequestTable from 'src/pages/SchedulingPage/request-layout/RequestTable';
import {useQuery} from '@apollo/react-hooks';
import FetchShiftRequestQuery from 'src/gql/query/FetchShiftRequestQuery';
import {QueryResult} from 'react-apollo';
import {FetchShiftsRequestsResult, ShiftRequestPayload, MarketplacePosts} from 'src/types';
import LoadingDiv from 'src/components/LoadingDiv';
import SchedulingEmptyStateView from 'src/pages/SchedulingPage/scheduling/SchedulingEmptyStateView';
import {REQUESTS} from 'src/constants/scheduler';
import RequestRefetchProvider from 'src/pages/SchedulingPage/request-layout/RequestRefetchProvider';

const RequestsLayoutWrapper = styled.div`
  padding: 3%;
`;

const Divider = styled.div`
  height: 0;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

function shiftRequestPartition(payloadArr: ShiftRequestPayload[]): ShiftRequestPayloadPartitions {
  const result: Array<Array<ShiftRequestPayload>> = payloadArr.reduce(
    ([activeAcc, inActiveAcc], current) => {
      return current.status === 'active'
        ? [[...activeAcc, current], inActiveAcc]
        : [activeAcc, [...inActiveAcc, current]];
    },
    [[], []] as Array<Array<ShiftRequestPayload>>,
  );
  return {
    activeShiftRequests: result[0],
    inactiveShiftRequests: result[1],
  };
}

function marketpostPartition(payloadArr: MarketplacePosts[]): ShiftRequestPayloadPartitions {
  const result: Array<Array<ShiftRequestPayload>> = payloadArr.reduce(
    ([activeAcc, inActiveAcc], current) => {
      const convertCurrentPostToRequest: ShiftRequestPayload = {
        id: current.id,
        createdAt: current.createdAt,
        updatedAt: current.updatedAt,
        desiredShift: null,
        surrenderingShift: current.shift,
        notes: current.notes,
        postedBy: current.user,
        status: current.status,
        type: 'give_away',
        offerTypes: current.offerTypes,
      };
      return current.status === 'active'
        ? [[...activeAcc, convertCurrentPostToRequest], inActiveAcc]
        : [activeAcc, [...inActiveAcc, convertCurrentPostToRequest]];
    },
    [[], []] as Array<Array<ShiftRequestPayload>>,
  );

  return {
    activeShiftRequests: result[0],
    inactiveShiftRequests: result[1],
  };
}

interface ShiftRequestPayloadPartitions {
  activeShiftRequests: ShiftRequestPayload[];
  inactiveShiftRequests: ShiftRequestPayload[];
}

const RequestLayout = () => {
  const [requestLayoutVM, setRequestLayoutVM] = React.useState<ShiftRequestPayloadPartitions | null>(null);

  // TODO: no-cache since incomplete {me...} fragment would casue error in apollo inmemoery cache
  // i.e. if landing direcly to requesttbale page
  const {loading, error, data, refetch}: QueryResult<FetchShiftsRequestsResult> = useQuery(FetchShiftRequestQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    function constructPartitionVM() {
      const shiftRequests = data?.me.shiftRequests ?? [];
      const marketplacePosts = data?.me.marketplacePosts ?? [];
      const shiftActivePartition = shiftRequestPartition(shiftRequests);
      const marketplacePostActivePartition = marketpostPartition(marketplacePosts);
      const resultPartition = {
        activeShiftRequests: [
          ...shiftActivePartition.activeShiftRequests,
          ...marketplacePostActivePartition.activeShiftRequests,
        ],
        inactiveShiftRequests: [
          ...shiftActivePartition.inactiveShiftRequests,
          ...marketplacePostActivePartition.inactiveShiftRequests,
        ],
      };
      setRequestLayoutVM(resultPartition);
    }
    if (loading || error) return;
    if (data) constructPartitionVM();
  }, [loading, error, data]);

  if (loading || error || !requestLayoutVM) return <LoadingDiv />;

  const {activeShiftRequests, inactiveShiftRequests} = requestLayoutVM;

  if (
    (!activeShiftRequests || activeShiftRequests.length === 0) &&
    (!inactiveShiftRequests || inactiveShiftRequests.length === 0)
  ) {
    return <SchedulingEmptyStateView view={REQUESTS} />;
  }

  const shouldDefaultOpen = activeShiftRequests && activeShiftRequests.length === 0;

  return (
    <RequestRefetchProvider refetch={refetch}>
      <RequestsLayoutWrapper>
        {activeShiftRequests && activeShiftRequests.length > 0 && (
          <React.Fragment>
            <RequestTable shiftRequestPayloads={activeShiftRequests} isPending={true} defaultOpen={true} />
            <Divider />
          </React.Fragment>
        )}
        {inactiveShiftRequests && inactiveShiftRequests.length > 0 && (
          <RequestTable
            shiftRequestPayloads={inactiveShiftRequests}
            isPending={false}
            defaultOpen={shouldDefaultOpen}
          />
        )}
      </RequestsLayoutWrapper>
    </RequestRefetchProvider>
  );
};

export default RequestLayout;
