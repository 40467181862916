import React from 'react';
import client from 'src/apollo';
import * as R from 'ramda';
import CancelEscalationMutation from 'src/gql/mutation/CancelEscalationMutation';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import {CheckActiveEscalationExists} from 'src/gql/query/FetchAllActiveEscalations';
import {CancelEscalationResult, GetAllActiveEscalationsResult} from 'src/types';
import {tryReadChatQuery} from 'src/utils/messengerHelper/getChatFromId';
interface Props {
  activatedEscalationId: number;
  chatId: string;
}

const useCancelEscalation = ({activatedEscalationId, chatId}: Props) => {
  const [isRequesting, setRequesting] = React.useState(false);

  const tryCancelEscalation = async () => {
    try {
      setRequesting(true);
      const {data} = await client.mutate({
        mutation: CancelEscalationMutation,
        variables: {
          activatedEscalationId,
        },
      });

      const result: CancelEscalationResult = data;

      // update query under cached chat (could be not existing in cache)
      const chatQuery = tryReadChatQuery(client, chatId);
      if (chatQuery) {
        const newChatQuery = R.evolve({
          chat: {
            escalation: result.escalation.activated.cancel,
          },
        })(chatQuery);

        client.writeQuery({
          query: GetChatQuery,
          variables: {chatId},
          data: newChatQuery,
        });
      }

      // update query under cached me
      const allActiveEscalationsQuery: GetAllActiveEscalationsResult | null = client.readQuery({
        query: CheckActiveEscalationExists,
      });

      const newAllActiveEscalationsQuery = R.evolve({
        me: {
          activeEscalations: allActiveEscalationsQuery?.me?.activeEscalations?.filter(
            (escalation) => escalation.id === result.escalation.activated.cancel.id,
          ),
        },
      })(allActiveEscalationsQuery);

      client.writeQuery({
        query: CheckActiveEscalationExists,
        data: newAllActiveEscalationsQuery,
      });
      return Promise.resolve('done');
    } catch (e) {
      console.error(e);
      setRequesting(false);
      return Promise.reject(e);
    }
  };

  return {
    isRequesting,
    tryCancelEscalation,
  };
};

export default useCancelEscalation;
