import styled from '@emotion/styled';
import BackIcon from 'src/svgs/BackIcon';
import {Grid} from '@mui/material';
import {GROUPS, GROUP, USERS} from 'src/constants/user';
import {MESSENGER} from 'src/constants/routerPathName';
import {InitialsPic, ProfilePicImg} from 'src/styles/styled-components/StyledMaterialComponents';
import {getInitials} from 'src/utils/messengerHelper/getChatTitle';
import {getTotalMembers} from 'src/utils/messengerHelper/getTotalMembers';
import ProfilePic from 'src/components/ProfilePic';

const PREFIX = 'SearchUsersList';

const classes = {
  container: `${PREFIX}-container`,
  backIcon: `${PREFIX}-backIcon`,
  backText: `${PREFIX}-backText`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.container}`]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },

  [`& .${classes.backIcon}`]: {
    padding: '11px 2px 0px 12px !important',
    flexBasis: '4%',
  },

  [`& .${classes.backText}`]: {
    paddingTop: '9px !important',
  },
}));

const ChatTypeText = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.blackFontColor};
  padding: 24px 16px 8px 16px;
`;

const ChatContainer = styled.div<{isChatActive: boolean}>`
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 16px;
  cursor: pointer;
  user-select: none;
  position: relative;
  color: ${(props) => props.theme.colors.blackFontColor};
  background-color: ${(props) => {
    if (props.isChatActive) {
      return props.theme.colors.lightGrey;
    }
    return 'white';
  }};
  &:hover {
    background: #f6f6f9;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 5px;
`;

const RightSection = styled.div`
  flex: 5;
  min-width: 0;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

const MessageDetails = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatTitle = styled.div`
  white-space: nowrap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

const MessageContent = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  min-width: 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.5em;
  text-overflow: ellipsis;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  font-weight: normal;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

const BackMessage = styled.div`
  cursor: pointer;
`;

const SearchUsersList = (props) => {
  const {chats, title, activeChat, handleViewAllBack, handleSetActiveChat} = props;

  const isActiveSearchChat = (chat, title) => {
    return activeChat === chat.id ? true : false;
  };

  return (
    <Root>
      {chats?.length > 0 && (
        <ChatTypeText>
          <BackMessage onClick={() => handleViewAllBack(0)}>
            <Grid container spacing={3}>
              <Grid className={classes.backIcon} item xs={3}>
                <BackIcon />
              </Grid>
              <Grid className={classes.backText} item xs={8}>
                Back
              </Grid>
            </Grid>
          </BackMessage>
        </ChatTypeText>
      )}
      {chats.map((searchChat, i) => {
        const chat = title === GROUPS ? searchChat.chat : searchChat.user;
        const charLimit = 24;
        const chatTitle =
          title === GROUPS ? getTotalMembers(chat, charLimit, true) : `${chat.firstName} ${chat.lastName}`;
        const isChatActive = isActiveSearchChat(chat, title);
        const chatImg = title === USERS ? chat.avatar?.url : chat.image?.url;

        return (
          <ChatContainer
            key={i}
            isChatActive={isChatActive}
            onClick={() => {
              title === GROUPS
                ? window.routerHistory.push(`/${MESSENGER}/${chat.id}`)
                : window.routerHistory.push(`/${MESSENGER}/user/${chat.id}`);
              handleSetActiveChat(chat.id);
            }}
          >
            <LeftSection>
              {chatImg ? (
                <ProfilePic users={[chat]} chatImageUrl={chatImg} />
              ) : chat.type === GROUP ? (
                <ProfilePicImg size={45} src={'/assets/group-chat.svg'} />
              ) : (
                <InitialsPic size={45} fontSize={14}>
                  {getInitials(chat)}
                </InitialsPic>
              )}
            </LeftSection>
            <RightSection>
              <MessageDetails>
                <ChatTitle>{chatTitle}</ChatTitle>
              </MessageDetails>
              <MessageContent>
                <div>{title === GROUPS ? getTotalMembers(chat, charLimit, false) : chat.role}</div>
              </MessageContent>
            </RightSection>
          </ChatContainer>
        );
      })}
    </Root>
  );
};

export default SearchUsersList;
