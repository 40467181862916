import React from 'react';
import {Link} from 'react-router-dom';
import {ScheduleSubscriptionUrl} from 'src/types';
import {useQuery} from '@apollo/react-hooks';
import FetchScheduleSubscriptionUrl from 'src/gql/query/FetchScheduleSubscriptionUrl';
import {Dialog, DialogContent, DialogContentText, IconButton, DialogTitle} from '@mui/material';
import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress';
import CloseIcon from 'src/svgs/CloseIcon';
import ChevrDown from 'src/svgs/ChevrDown';
import ChevrUp from 'src/svgs/ChevrUp';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import {Grid} from '@mui/material';
import {StyledInputBase} from 'src/components/StepFormPartials';
import Divider from '@mui/material/Divider';
import {toast} from 'react-toastify';
import {muiTheme} from 'src/styles/theme';

const PREFIX = 'SubscriptionModal';

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  dialogDescription: `${PREFIX}-dialogDescription`,
  dialogIconButton: `${PREFIX}-dialogIconButton`,
  dialogContent: `${PREFIX}-dialogContent`,
  linkInputContainer: `${PREFIX}-linkInputContainer`,
  copyLinkButtonContainer: `${PREFIX}-copyLinkButtonContainer`,
  divider: `${PREFIX}-divider`,
  instructionBody: `${PREFIX}-instructionBody`,
  instructionNoteBody: `${PREFIX}-instructionNoteBody`,
  link: `${PREFIX}-link`,
  instructionToggle: `${PREFIX}-instructionToggle`,
  iconUpDown: `${PREFIX}-iconUpDown`,
};

const StyledDialog = styled(Dialog)(({theme: Theme}) => ({
  [`& .${classes.dialogTitle}`]: {
    margin: '24px 24px 16px 24px',
    padding: '0px',
  },

  [`& .${classes.dialogDescription}`]: {
    fontFamily: 'open sans',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: '#4A4A4A',
    marginBottom: '0px',
    alignItems: 'center',
  },

  [`& .${classes.dialogIconButton}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    float: 'right',
  },

  [`& .${classes.dialogContent}`]: {
    padding: '0px 24px 24px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },

  [`& .${classes.linkInputContainer}`]: {
    padding: '0px 8px 0px 12px !important',
  },

  [`& .${classes.copyLinkButtonContainer}`]: {
    padding: '0px 12px 0px 0px  !important',
    marginLeft: '-24px  !important',
  },

  [`& .${classes.divider}`]: {
    border: '1px solid #D8D8D8  !important',
    marginBottom: '16px  !important',
  },

  [`& .${classes.instructionBody}`]: {
    marginTop: '4px',
  },

  [`& .${classes.instructionNoteBody}`]: {
    marginBottom: '24px',
  },

  [`& .${classes.link}`]: {
    color: '#00859a',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
  },

  [`& .${classes.instructionToggle}`]: {
    alignContent: 'center',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    color: '#00859A',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '0px',
    marginTop: '8px',
    marginLeft: '10.9rem',
    cursor: 'pointer',
    width: '15rem',
    display: 'inline-block',
  },

  [`& .${classes.iconUpDown}`]: {
    display: 'inline-block  !important',
    marginLeft: '11.75px  !important',
  },
}));

const DialogBoxTitle = styled(DialogTitle)`
  h2 {
    font-family: 'Nunito Sans' !important;
    font-weight: 700 !important;
    font-size: 28px;
    line-height: 36px !important;
    color: ${(props) => props.theme.colors.watermelon} !important;
  }
`;

const StyledInput = styled(StyledInputBase)`
  width: 95%;
  & .MuiInputBase-input {
    background: #f6f6f9;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #222222;
  }
`;

const StyledCopyLinkButton = styled(Button)`
  text-transform: none !important;
  min-width: 108px !important;
  .MuiButton-label {
    font-family: Nunito Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 24px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: ${(props) => props.theme.colors.primary};
    height: 29px !important;
  }
`;

const InstructionTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  margin-top: 16px;
`;

const InstructionBody = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: contents;
  align-items: center;
  color: #767676;
`;

const LoadingText = styled.div`
  color: dimgrey;
  margin-top: 1em;
  font-size: 12px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: 0.5em;
` as React.ComponentType<CircularProgressProps>;

const SubscriptionModal = ({openModal, handleCloseModal}) => {
  const [showInstruction, setShowInstruction] = React.useState(false);

  const subscriptionURL = useQuery<ScheduleSubscriptionUrl>(FetchScheduleSubscriptionUrl, {
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    subscriptionURL.refetch();
  }, [subscriptionURL]);

  function copySubscriptionLink(e) {
    (document.getElementById('subscriptionURL') as HTMLInputElement).select();
    document.execCommand('copy');
    toast.success('Successfully copied to clipboard.');
  }

  return (
    <StyledDialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogBoxTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {'Subscribe to personal schedule'}
        <IconButton onClick={handleCloseModal} className={classes.dialogIconButton} size="large">
          <CloseIcon color={muiTheme.colors.greyishBrown} width="15" height="15" />
        </IconButton>
      </DialogBoxTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogDescription} id="alert-dialog-description">
          Paste this link into your calendar application (Google Calendar, Apple Calendar, Microsoft Outlook) to
          subscribe to personal schedule.
        </DialogContentText>

        {subscriptionURL.loading || subscriptionURL.error || !subscriptionURL?.data?.me ? (
          <LoadingText>
            <StyledCircularProgress color="inherit" size={12} />
            Processing request...
          </LoadingText>
        ) : (
          <Grid container spacing={3} style={{marginTop: '8px', marginBottom: '16px'}}>
            <Grid item xs={10} className={classes.linkInputContainer}>
              <StyledInput type="text" id="subscriptionURL" value={subscriptionURL.data.me.scheduleSubscriptionUrl} />
            </Grid>
            <Grid className={classes.copyLinkButtonContainer} item xs={2}>
              <StyledCopyLinkButton variant="outlined" onClick={copySubscriptionLink}>
                Copy link
              </StyledCopyLinkButton>
            </Grid>
          </Grid>
        )}

        <Divider className={classes.divider} />
        {showInstruction && (
          <DialogContentText component={'div'} style={{marginBottom: '16px'}}>
            <InstructionTitle>Google Calendar</InstructionTitle>
            <InstructionBody className={classes.instructionBody}>
              Open application settings by following{' '}
              <Link
                className={classes.link}
                target={'_blank'}
                to={{pathname: 'https://calendar.google.com/calendar/r/settings/addbyurl'}}
              >
                this link
              </Link>
              , or click Other calendars &gt; From URL. Paste the link above and click “Add Calendar”.
            </InstructionBody>
            <InstructionTitle>Microsoft Outlook</InstructionTitle>
            <InstructionBody className={classes.instructionBody}>
              Open application settings by following{' '}
              <Link
                className={classes.link}
                target={'_blank'}
                to={{pathname: 'https://outlook.live.com/calendar/0/addcalendar'}}
              >
                this link
              </Link>
              , or click “Add calendar”. Click “Subscribe from web”, paste the link above and click “Import”.
            </InstructionBody>
            <InstructionTitle>Apple Calendar</InstructionTitle>
            <InstructionBody className={classes.instructionBody}>
              Open the application and select File &gt; New Calendar Subscription. Click “Subscribe from web”, paste the
              link above and click “Subscribe”.
            </InstructionBody>
            <InstructionTitle>Applications not listed above?</InstructionTitle>
            <InstructionBody className={classes.instructionNoteBody}>
              <Link
                className={classes.link}
                target={'_blank'}
                to={{pathname: 'https://support.hypercare.com/hc/en-us/articles/4421108578573'}}
              >
                Click here
              </Link>{' '}
              to view our FAQ article.
            </InstructionBody>
          </DialogContentText>
        )}
        <DialogContentText
          component={'div'}
          className={classes.instructionToggle}
          onClick={() => setShowInstruction(!showInstruction)}
        >
          {!showInstruction ? (
            <>
              <div>
                View detailed instructions
                <span className={classes.iconUpDown}>
                  <ChevrDown />
                </span>
              </div>
            </>
          ) : (
            <>
              <div>
                Hide detailed instructions
                <span className={classes.iconUpDown}>
                  <ChevrUp />
                </span>
              </div>
            </>
          )}
        </DialogContentText>
      </DialogContent>
    </StyledDialog>
  );
};

export default SubscriptionModal;
