import styled from '@emotion/styled';
import DefaultOrganizationIcon from 'src/svgs/DefaultOrganizationIcon';
import Button from '@mui/material/Button';
import {InvitesOrganization, OrganizationInvitation, User} from 'src/types';
import {getFullName} from 'src/utils/user';

const InviteCardContainer = styled.div`
  font-family: Nunito;
  padding: 20px 30px;
  background-color: #f8f8f8;
  border-radius: 10px;
  margin-bottom: 1em;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

const InviteInfoContainer = styled.div`
  display: flex;
`;

const OrganizationIconStyles = `
  width: 100px;
  height: 100px;
  min-width: 100px;
  object-fit: contain;
`;

const StyledDefaultOrgIcon = styled(DefaultOrganizationIcon)`
  ${OrganizationIconStyles}
`;

const OrganizationImage = styled.img`
  ${OrganizationIconStyles}
`;

const InviteDetailHolder = styled.div`
  margin-left: 2em;
`;

const InviteDetailItem = styled.div`
  font-size: 16px;
  padding: 5px 0;
  line-height: 1.3em;
  font-weight: bold;
  font-size: 14px;
`;

const InviteCardButtonsHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  button {
    width: 125px;
    &:last-child {
      margin-left: 2em;
    }
  }
`;

const OrganizationName = styled.span`
  font-family: Nunito;
  font-weight: bold;
  font-size: 18px;
`;

const InviteDetailItemTitle = styled.span`
  color: grey;
  font-size: 16px;
  font-weight: normal;
`;

const InvitesOverFlowWrapper = styled.div`
  max-height: 550px;
  overflow-y: auto;
`;

interface Props {
  isLoading: boolean;
  handleAccept: (organization: InvitesOrganization, inviteId: any, invitedBy: User) => Promise<void>;
  handleDecline: (id: any) => void;
  invitationList: OrganizationInvitation[];
  renderAdminsNames: (user: User[]) => string;
}

const PendingInviteContainer = ({isLoading, handleAccept, handleDecline, invitationList, renderAdminsNames}: Props) => {
  return (
    <InvitesOverFlowWrapper>
      {invitationList.map((invitation: OrganizationInvitation, i: number) => (
        <InviteCardContainer key={`organization-${i}`}>
          <InviteInfoContainer>
            {invitation.organization.image ? (
              <OrganizationImage src={invitation.organization.image} alt="organization logo" />
            ) : (
              <StyledDefaultOrgIcon />
            )}

            <InviteDetailHolder>
              <InviteDetailItem>
                <OrganizationName>{invitation.organization.name}</OrganizationName>
              </InviteDetailItem>
              <InviteDetailItem>
                <InviteDetailItemTitle>Administrator:&nbsp;</InviteDetailItemTitle>
                {renderAdminsNames(invitation.organization.admins)}
              </InviteDetailItem>
              <InviteDetailItem>
                <InviteDetailItemTitle>Invited by:&nbsp;</InviteDetailItemTitle>
                {getFullName(invitation.invitedBy)}
              </InviteDetailItem>
            </InviteDetailHolder>
          </InviteInfoContainer>

          <InviteCardButtonsHolder>
            <Button
              disableTouchRipple
              variant="outlined"
              color="primary"
              disabled={isLoading}
              onClick={() => handleDecline(invitation.id)}
            >
              Decline
            </Button>
            <Button
              disableTouchRipple
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => handleAccept(invitation.organization, invitation.id, invitation.invitedBy)}
            >
              Accept
            </Button>
          </InviteCardButtonsHolder>
        </InviteCardContainer>
      ))}
    </InvitesOverFlowWrapper>
  );
};

export default PendingInviteContainer;
