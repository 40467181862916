import * as React from 'react';
import {
  AssigneeWrapper,
  DateHolder,
  DateIndicator,
  RoleHolderHoliday,
  RoleHolderWrapperHoliday,
} from './ActionCalendarGridSystem';
import {CalenderViewDataSourceProtocol} from './CalenderViewDataSourceProtocol';
import moment from 'moment';
import {muiTheme} from 'src/styles/theme';

interface Props {
  dateIndicatorValue: string;
  content: React.ReactElement;
  color?: string;
  index?: number;
}
export function CalenderViewItem({dateIndicatorValue, content, color, index}: Props) {
  const [isHoliday, setisHoliday] = React.useState(false);
  const mainTealColor = muiTheme.colors.primary;
  const lightGrey = muiTheme.colors.lightGrey;

  React.useEffect(() => {
    let month = moment(CalenderViewDataSourceProtocol.startDateISOstring)
      .add(2, 'days')
      .toISOString()
      ?.toString()
      .split('-')[1];
    let holidaysthismonth: any[] = [];
    CalenderViewDataSourceProtocol.holidays?.map((holiday) => {
      if (holiday.date.split('-')[1] == month) {
        holidaysthismonth.push(parseInt(holiday.date.split('-')[2]));
      }
    });
    dateIndicatorValue === '' && setisHoliday(true);
    if (index !== undefined) {
      if (index % 7 === 0 || index % 7 === 6) {
        setisHoliday(true);
      }
    }
  }, [
    CalenderViewDataSourceProtocol.startDateISOstring,
    CalenderViewDataSourceProtocol.holidays,
    CalenderViewDataSourceProtocol.isHolidayMark,
  ]);

  function isHolidayDay(day, date, flag: Number) {
    let month = moment(CalenderViewDataSourceProtocol.startDateISOstring)
      .add(2, 'days')
      .toISOString()
      ?.toString()
      .split('-')[1];
    let holidaysthismonth: any = [];
    let isHolidayDate: any = null;
    CalenderViewDataSourceProtocol.holidays.map((holiday) => {
      if (holiday.date.split('-')[1] == month) {
        holidaysthismonth.push({day: parseInt(holiday.date.split('-')[2]), holidayName: holiday.nameEn});
      }
    });
    holidaysthismonth.map((holiday) => {
      if (holiday.day === parseInt(day)) {
        isHolidayDate = holiday;
      }
    });
    if (flag) {
      if (isHolidayDate === null) return false;
      else return true;
    }
    return isHolidayDate?.holidayName;
  }

  let date = CalenderViewDataSourceProtocol.startDateISOstring;
  return (
    <DateHolder dataholderColor={CalenderViewDataSourceProtocol.isHolidayMark && isHoliday ? lightGrey : 'white'}>
      <DateIndicator>{dateIndicatorValue}</DateIndicator>
      <AssigneeWrapper>
        {CalenderViewDataSourceProtocol.isHolidayMark && isHolidayDay(dateIndicatorValue, date, 1) && (
          <RoleHolderHoliday title={isHolidayDay(dateIndicatorValue, date, 0)}>
            <RoleHolderWrapperHoliday key={`index`} assignedColorBackground={''} assignedColor={mainTealColor}>
              {isHolidayDay(dateIndicatorValue, date, 0)}
            </RoleHolderWrapperHoliday>
          </RoleHolderHoliday>
        )}
        {content}
      </AssigneeWrapper>
    </DateHolder>
  );
}
