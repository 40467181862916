import {getToken, onMessage, deleteToken} from 'firebase/messaging';
import {Buffer} from 'buffer';
import {FirebaseNotificationBuilder} from 'src/notifications/FirebaseNotificationBuilder';
import {getMessagingInstance} from 'src/firebase/firebaseSetup';
import {registerDeviceId, registerPushToken} from 'src/utils/notificationRequests';

const crypto = require('crypto');
const AES_ALGORITHM = 'aes-256-ctr';

class FirebaseNotificationController {
  static secret;
  static token;

  private static registerFirebaseServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      if (navigator.serviceWorker?.controller?.scriptURL?.includes('firebase')) return;
      await navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  };

  private static subscribeToPushNotifications = async () => {
    if (FirebaseNotificationController.token && FirebaseNotificationController.secret) return;
    await FirebaseNotificationController.registerFirebaseServiceWorker();

    const firebaseConfig = new URLSearchParams({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
      appId: process.env.REACT_APP_FIREBASE_APP_ID!,
    }).toString();

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(`/firebase-messaging-sw.js?${firebaseConfig}`)
        .then((registration) => {
          getToken(getMessagingInstance(), {
            vapidKey: `${process.env.REACT_APP_FIREBASE_VAPID_KEY}`,
            serviceWorkerRegistration: registration,
          })
            .then(async (token) => {
              const secret = await registerPushToken(token);
              FirebaseNotificationController.secret = secret;
              FirebaseNotificationController.token = token;
            })
            .catch((err) => {
              console.error(err, 'Failed to register push token service with firebase');
            });
        })
        .catch((err) => {
          console.error(err, 'Failed to register firebase-messaging-sw.js file');
        });
    }
  };

  public static unsubscribeToPushNotifications = async () => {
    return await deleteToken(getMessagingInstance()).catch(console.error);
  };

  public static requestNotificationPermission = async () => {
    return await Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          if (!FirebaseNotificationController.secret) registerDeviceId();
          FirebaseNotificationController.subscribeToPushNotifications();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  public static decryptNotificationPayload = (encryptedPayload, secret) => {
    const textParts = encryptedPayload.split(':');
    const iv = Buffer.from(textParts.shift(), 'hex');
    const encryptedText = Buffer.from(textParts.join(':'), 'hex');
    const decipher = crypto.createDecipheriv(AES_ALGORITHM, Buffer.from(secret), iv);
    let decrypted = decipher.update(encryptedText);

    decrypted = Buffer.concat([decrypted, decipher.final()]);

    return JSON.parse(decrypted);
  };

  public static recieveForegroundMessages = () => {
    onMessage(getMessagingInstance(), (payload) => {
      const encryptedPayload = payload.data?.encryptedPayload;

      const notificationPayload = FirebaseNotificationController.decryptNotificationPayload(
        encryptedPayload,
        FirebaseNotificationController.secret,
      );

      FirebaseNotificationBuilder(notificationPayload, true);
    });
  };
}

export default FirebaseNotificationController;
