import React from 'react';
import styled from '@emotion/styled';
import SwapShiftIcon from 'src/svgs/SwapShiftIcon';
import GiveAwayShiftIcon from 'src/svgs/GiveAwayShiftIcon';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {IndicatorTitle} from 'src/styles/styled-components/SharedModalStyles';
import {ShiftRequestType} from 'src/types';
import StyledTooltip from 'src/components/StyledTooltip';

const CheckboxesWrapper = styled.div`
  display: flex;
  padding-left: 1em;
  flex-direction: column;
`;

const TypeSelectionArea = styled.div`
  margin-top: 1em;
  user-select: none;
`;

const UnderlinePopperTrigger = styled.div`
  border-bottom: 1px dashed dimgrey;
`;

const OverriddenStyledTooltip = styled(StyledTooltip)`
  & .tooltip {
    min-width: 210px !important;
    transform: translateY(1em) !important;
  }
`;

interface Props {
  offerTypes: Set<ShiftRequestType>;
  handleSetOfferTypes: (type: ShiftRequestType) => void;
}

const RequestTypeCheckboxPicker = ({offerTypes, handleSetOfferTypes}: Props) => {
  const hasSwap = offerTypes.has('swap');
  const hasGiveAway = offerTypes.has('give_away');

  const handleCheckboxChange = (type: ShiftRequestType) => {
    handleSetOfferTypes(type);
  };

  return (
    <TypeSelectionArea>
      <IndicatorTitle>Type of offer</IndicatorTitle>
      <CheckboxesWrapper>
        <FormControlLabel
          control={<Checkbox checked={hasSwap} onChange={() => handleCheckboxChange('swap')} />}
          label={
            <OverriddenStyledTooltip
              arrow
              title="Shift must be requested with another shift in return"
              className="styledPopper"
              placement="top-start"
            >
              <UnderlinePopperTrigger>
                <SwapShiftIcon /> <span>Swap Shift</span>
              </UnderlinePopperTrigger>
            </OverriddenStyledTooltip>
          }
        />
        <FormControlLabel
          control={<Checkbox checked={hasGiveAway} onChange={() => handleCheckboxChange('give_away')} />}
          label={
            <OverriddenStyledTooltip
              arrow
              title="No shift in return is required"
              className="styledPopper"
              placement="top-start"
            >
              <UnderlinePopperTrigger>
                <GiveAwayShiftIcon /> <span>Give away shift</span>
              </UnderlinePopperTrigger>
            </OverriddenStyledTooltip>
          }
        />
      </CheckboxesWrapper>
    </TypeSelectionArea>
  );
};

export default RequestTypeCheckboxPicker;
