import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {AuthContext} from 'src/auth/AuthProvider';
import LoadingDiv from 'src/components/LoadingDiv';
import {AuthSSOVendors, LoginViaSSO, StaSSOLogin} from 'src/types';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import sleep from '../../utils/sleep';
import {ORG_URL, SSO_VENDOR} from '../../constants/storageKeys';
import {IsFeatureFlagEnabled} from '../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../utils/FeatureFlags';
import {localStorageService} from '../../services/localStorageService';
import {LoginErrorSSOModal} from 'src/auth/LoginErrorSSOModal';

interface Props {
  isLoggedIn: boolean;
  loginViaSSO: LoginViaSSO;
  auth0props: Auth0ContextInterface;
  staSSOLogin: StaSSOLogin;
}

const SSORedirectLoadingPage = ({isLoggedIn, loginViaSSO, auth0props, staSSOLogin}: Props) => {
  const {isAuthenticated, getAccessTokenSilently, logout} = auth0props;
  const STAFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);

  React.useEffect(() => {
    const getSSOVendor = localStorageService.getItem<AuthSSOVendors>(SSO_VENDOR);
    const orgUrl = localStorageService.getItem<string>(ORG_URL);

    const getAccessToken = async () => {
      if (isAuthenticated && getSSOVendor === AuthSSOVendors.AUTH0) {
        getAccessTokenSilently()
          .then(async (token) => {
            STAFlag ? await staSSOLogin(token, getSSOVendor, orgUrl) : await loginViaSSO(token, getSSOVendor);
          })
          .catch(async (err) => {
            console.error(err);

            await sleep(3000);
            logout({federated: true});
          });
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code') || '';

        if (!getSSOVendor) return;
        STAFlag ? await staSSOLogin(code, getSSOVendor, orgUrl) : await loginViaSSO(code, getSSOVendor);
      }
    };

    getAccessToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoggedIn) return <Redirect to="/messenger" />;
  return <LoadingDiv />;
};

export default () => (
  <AuthContext.Consumer>
    {({isLoggedIn, loginViaSSO, auth0props, staSSOLogin}) => {
      const urlParams = new URLSearchParams(window.location.search);
      const urlErrorDescription = urlParams.get('error_description');
      if (urlErrorDescription) {
        return <LoginErrorSSOModal description={urlErrorDescription} />;
      }
      return (
        <SSORedirectLoadingPage
          staSSOLogin={staSSOLogin}
          auth0props={auth0props}
          isLoggedIn={isLoggedIn}
          loginViaSSO={loginViaSSO}
        />
      );
    }}
  </AuthContext.Consumer>
);
