import React from 'react';
import client from 'src/apollo';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {WideWidthStyleModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import ExclamationTriangle from 'src/svgs/ExclamationTriangle';
import CircularProgress from '@mui/material/CircularProgress';
import {AppDispatch, typedUseSelector} from 'src/redux';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import Checkmark from 'src/svgs/Checkmark';
import {SCHEDULING} from 'src/constants/routerPathName';
import {REQUESTS} from 'src/constants/scheduler';
import RequestSwapShiftMutation from 'src/gql/mutation/RequestSwapShiftMutation';
import GridCellAssignee from 'src/pages/SchedulingPage/action-calendar/GridCellAssignee';
import SwapShiftIcon from 'src/svgs/SwapShiftIcon';
import {
  CustomToastFlexContainer,
  ModalBodyWrapper,
  TextareaWrapper,
  WarningText,
  ErrorText,
  ButtonsWrapper,
  IndicatorTitle,
} from 'src/styles/styled-components/SharedModalStyles';
import {
  SWAP_REQUEST_ALREADY_ACTIVE,
  DESIRED_SHIFT_EXPIRED,
  SURRENDERING_SHIFT_EXPIRED,
  CONFLICT_WITH_DESIRED_SHIFT,
  CONFLICT_WITH_SURRENDERING_SHIFT,
} from 'src/constants/networkError';
import SwapDoubleConfirmationModal from 'src/pages/SchedulingPage/action-calendar/swap-section/SwapDoubleConfirmationModal';

const SwapHelperIndicator = styled.div`
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
`;

const IndicatorHolder = styled.div`
  width: 46%;
  * {
    cursor: auto;
  }
`;

const SwapIconHolder = styled.div`
  flex: 1;
  transform: translateY(7px);
  text-align: center;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  userToColorCodes: {
    [userId: string]: string;
  };
}

const SwapConfirmationModal = ({isOpen, closeModal, userToColorCodes}: Props) => {
  const [noteValue, setNoteValue] = React.useState('I want to swap because ');
  const [isLoading, setLoading] = React.useState(false);
  const [showDoubleConfirmationModal, setShowDoubleConfirmationModal] = React.useState(false);
  const [error, setError] = React.useState('');

  const dispatch: AppDispatch = useDispatch();

  const selfShift = typedUseSelector((state) => state.tradeShift.selfShift);
  const otherShift = typedUseSelector((state) => state.tradeShift.otherShift);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteValue(e.target.value);
  };

  const handleSendRequest = async () => {
    try {
      setLoading(true);
      setError('');
      await client.mutate({
        mutation: RequestSwapShiftMutation,
        variables: {
          desiredShiftId: otherShift?.assignee.shiftId,
          surrenderingShiftId: selfShift?.assignee.shiftId,
          note: noteValue,
        },
      });
      toast.info(CustomizedActionToast(), {
        autoClose: false,
      });
      closeModal();
      dispatch({type: 'tradeShift/CLEAR_SELF'});
      dispatch({type: 'tradeShift/CLEAR_OTHER'});
    } catch (e: any) {
      console.error(e);
      setLoading(false);
      if (e.networkError && e.networkError.result && e.networkError.result.errors && e.networkError.result.errors[0]) {
        let errorCodeName = e.networkError.result.errors[0].code;
        switch (errorCodeName) {
          case SWAP_REQUEST_ALREADY_ACTIVE:
            return setError('You have already requested this swap');

          case DESIRED_SHIFT_EXPIRED:
            return setError('The desired shift is in the past and is no longer available');

          case SURRENDERING_SHIFT_EXPIRED:
            return setError('Your shift is in the past and is no longer available');

          case CONFLICT_WITH_SURRENDERING_SHIFT:
            setError('Your shift conflicts with the user’s schedule');
            return setShowDoubleConfirmationModal(true);

          case CONFLICT_WITH_DESIRED_SHIFT:
            setError('The desired shift conflicts with your current schedule');
            return setShowDoubleConfirmationModal(true);

          default:
            return setError(`Unable to complete your request due to unknown error (${errorCodeName})`);
        }
      } else {
        setError('Failed to request swap shift, please check your internet connection and try again');
      }
    }
  };

  const CustomizedActionToast = () => {
    const handleClick = () => {
      window.routerHistory.push(`/${SCHEDULING}/${REQUESTS}`);
      toast.dismiss();
    };
    return (
      <CustomToastFlexContainer>
        <div>
          <Checkmark />
          Your request has been sent.
        </div>
        <button onClick={handleClick}>View request</button>
      </CustomToastFlexContainer>
    );
  };

  return (
    <React.Fragment>
      <WideWidthStyleModal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnEsc={!isLoading}
        shouldCloseOnOverlayClick={!isLoading}
        onRequestClose={closeModal}
      >
        <Header>
          <span>Review swap requests</span>
          {!isLoading && <CloseBtn onClick={closeModal} />}
        </Header>
        <ModalBodyWrapper>
          <SwapHelperIndicator>
            <IndicatorHolder>
              <IndicatorTitle>Swapping my following shift</IndicatorTitle>
              <GridCellAssignee
                isSwap={false}
                showOfferTypes={false}
                timeformat="MMM DD, YYYY / HH:mm"
                isSelf={true}
                assignee={selfShift?.assignee!}
                role={selfShift?.role!}
                onCellClick={() => null}
                userToColorCodes={userToColorCodes}
              />
            </IndicatorHolder>
            <SwapIconHolder>
              <SwapShiftIcon />
            </SwapIconHolder>
            <IndicatorHolder>
              <IndicatorTitle>For another user’s shift</IndicatorTitle>
              <GridCellAssignee
                isSwap={false}
                showOfferTypes={false}
                timeformat="MMM DD, YYYY / HH:mm"
                isSelf={false}
                assignee={otherShift?.assignee!}
                role={otherShift?.role!}
                onCellClick={() => null}
                userToColorCodes={userToColorCodes}
              />
            </IndicatorHolder>
          </SwapHelperIndicator>

          <TextareaWrapper>
            <TextField
              variant="outlined"
              multiline={true}
              rows={4}
              fullWidth
              value={noteValue}
              onChange={handleChange}
              label="Reason for request"
            />
          </TextareaWrapper>

          <WarningText>
            <ExclamationTriangle />
            <span>
              You are still responsible for this shift until {otherShift?.assignee.userFullName} has accepted your
              request
            </span>
          </WarningText>

          <ErrorText>{error}</ErrorText>

          <ButtonsWrapper>
            <Button variant="contained" disabled={isLoading} disableTouchRipple onClick={closeModal}>
              cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSendRequest}
              disabled={isLoading}
              color="secondary"
              disableTouchRipple
            >
              {isLoading ? <CircularProgress size={20} /> : <span>send request</span>}
            </Button>
          </ButtonsWrapper>
        </ModalBodyWrapper>
      </WideWidthStyleModal>

      {showDoubleConfirmationModal && isOpen && (
        <SwapDoubleConfirmationModal
          isOpen={showDoubleConfirmationModal && isOpen}
          closeModal={() => setShowDoubleConfirmationModal(false)}
          closeAll={() => {
            setShowDoubleConfirmationModal(false);
            closeModal();
          }}
          desiredShiftId={otherShift?.assignee.shiftId!}
          surrenderingShiftId={selfShift?.assignee.shiftId!}
          note={noteValue}
          warningReason={error}
          customizedToast={CustomizedActionToast}
        />
      )}
    </React.Fragment>
  );
};

export default SwapConfirmationModal;
