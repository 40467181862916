import React from 'react';
import styled from '@emotion/styled';
import {Message} from 'src/types';
import ReplyMessagePreviewWrapper from 'src/pages/MessengerPage/messenger/messages-layout/ReplyMessagePreviewWrapper';

const MessageReplyPreviewWrapper = styled.div`
  padding: 0.5em;
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightGrey};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.greyishBrown} !important;
  margin-top: 3px;
  margin-bottom: 5px;
`;

interface Props {
  chatId: string;
  message: Message;
}

const NestedMessageReplyPreview = ({chatId, message}: Props) => {
  return (
    <MessageReplyPreviewWrapper>
      <ReplyMessagePreviewWrapper isInMessageBubble={true} message={message} chatId={chatId} />
    </MessageReplyPreviewWrapper>
  );
};

export default NestedMessageReplyPreview;
