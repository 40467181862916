import React from 'react';
import {Box, IconButton, Icon, Typography} from '@mui/material';
import styled from '@emotion/styled';

const PREFIX = 'StepHeader';

const classes = {
  root: `${PREFIX}-root`,
  backButton: `${PREFIX}-backButton`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 32,
  },

  [`& .${classes.backButton}`]: {
    color: 'black',
    backgroundColor: '#F2F2F2',
    width: 40,
    height: 40,
  },

  [`& .${classes.title}`]: {
    fontFamily: "'Nunito Sans', sans-serif",
    marginBottom: 16,
    marginTop: 16,
    fontSize: 32,
    fontWeight: 700,
    color: '#222222',
  },

  [`& .${classes.description}`]: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    color: '#4A4A4A',
  },
}));

interface IStepHeaderProps {
  title: string;
  isDisabled: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
}

const StepHeader: React.FC<IStepHeaderProps> = ({title, description, onBackButtonClick, isDisabled}) => {
  return (
    <StyledBox className={classes.root}>
      {isDisabled ? (
        <IconButton className={classes.backButton} disabled={true} size="large" />
      ) : (
        <IconButton className={classes.backButton} onClick={onBackButtonClick} size="large">
          <Icon>arrow_back</Icon>
        </IconButton>
      )}
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
    </StyledBox>
  );
};

export default StepHeader;
