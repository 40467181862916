import React from 'react';
import styled from '@emotion/styled';
import {EscalationLadder} from 'src/types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Arrow from 'src/svgs/ArrowLeft';
import stringifyNumberSuffix from 'src/utils/stringifyNumberSuffix';
import {getFullName} from 'src/utils/user';
import moment from 'moment';

const ErrorIcon = styled.span`
  z-index: 1;
  position: relative;
  width: 18px;
  height: 18px;
  &:before,
  &:after {
    position: absolute;
    top: 1px;
    left: 8px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: white;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const StepIconHolder = styled.span<{$disabledFlag: boolean}>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${(props) => (props.$disabledFlag ? 'red' : 'grey')};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
    * {
      fill: white;
    }
  }
`;

const WhiteDot = styled.span`
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
`;

const StartingLevelLabel = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.colors.chatTeal};
`;

const HiddenStartingLevelLabel = styled(StartingLevelLabel)`
  display: none;
`;

const ResponderLabel = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 98%;
`;

const StyledStepLabel = styled(StepLabel)<{$disabledFlag: boolean}>`
  filter: ${(props) => (props.$disabledFlag ? `opacity(0.5)` : 'unset')};
`;

const ProgressDetailStepper = styled.div`
  overflow-y: auto;
  max-height: 480px;
  margin-top: 1em;
  margin-bottom: 1em;
  .MuiStepper-vertical {
    padding-left: 0.5em;
    padding-bottom: 0;
  }
  .MuiStepLabel-iconContainer {
    margin-right: 4px;
  }
  .MuiStepConnector-root {
    display: none !important;
  }
  .MuiStepContent-last {
    border: unset !important;
  }
  .MuiStepContent-root {
    border-left: 1px dotted grey;
  }
`;

const EscalationLevelResponderProgressionWrapper = styled.div<{$disabledFlag: boolean}>`
  filter: ${(props) => (props.$disabledFlag ? `opacity(0.5)` : 'unset')};
`;

const EscalationLevelResponderNameCard = styled.div`
  border-radius: 8px;
  padding: 1em;
  background-color: whitesmoke;
  margin-bottom: 4px;
  div:first-of-type {
    font-weight: bold;
    line-height: 1.5em;
  }
  div:last-of-type {
    font-size: 14px;
    line-height: 1.5em;
    color: ${(props) => props.theme.colors.greyishBrown};
  }
`;

const StyledProgressDetailStep = styled(Step)<{$disabledFlag: boolean}>`
  cursor: pointer;
  .MuiStepContent-root {
    padding-bottom: 4px;
    margin-bottom: 8px;
  }
  &:hover {
    ${HiddenStartingLevelLabel} {
      display: block;
    }
    ${EscalationLevelResponderProgressionWrapper}, ${StyledStepLabel} {
      filter: opacity(1) !important;
    }
    ${EscalationLevelResponderNameCard} {
      background-color: whitesmoke;
      box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.12);
    }
  }
`;

const AlertingMessage = styled.span<{$disabledFlag: boolean}>`
  color: ${(props) => (props.$disabledFlag ? `red` : 'auto')};
  font-size: 14px;
  line-height: 1.5em;
`;

interface Props {
  selectedEscalationLadder: EscalationLadder;
  startingLevel: number;
  setStartingLevel: React.Dispatch<React.SetStateAction<number>>;
}

const TriggerEscalationPreview = ({selectedEscalationLadder, startingLevel, setStartingLevel}: Props) => {
  const escalationLadderLevelList = selectedEscalationLadder.escalationLevels.sort((a, b) =>
    a.level < b.level ? -1 : 1,
  );

  return (
    <ProgressDetailStepper>
      <Stepper orientation="vertical">
        {escalationLadderLevelList.map((escalationLevel, index) => {
          const isLessThanStartingLevel = index + 1 < startingLevel;
          const isStartingLevel = index + 1 === startingLevel;
          const isGreaterThanStartingLevel = index + 1 > startingLevel;

          const levelPolicyDuration = moment.duration(escalationLevel.escalationPolicy.channels[0].timeBetweenAttempts);
          const readableDurationInMinutes = levelPolicyDuration.asMinutes();
          const levelPolicyAttempts = escalationLevel.escalationPolicy.channels[0].numAttempts;

          return (
            <StyledProgressDetailStep
              key={index}
              $disabledFlag={isLessThanStartingLevel}
              active={true}
              onClick={() => setStartingLevel(index + 1)}
            >
              <StyledStepLabel
                $disabledFlag={isLessThanStartingLevel}
                StepIconComponent={() => (
                  <StepIconHolder $disabledFlag={isLessThanStartingLevel}>
                    {isLessThanStartingLevel && <ErrorIcon />}
                    {isStartingLevel && <WhiteDot />}
                    {isGreaterThanStartingLevel && <Arrow />}
                  </StepIconHolder>
                )}
              >
                <ResponderLabel>
                  <div>{stringifyNumberSuffix(escalationLevel.level)} level responder</div>
                  {isStartingLevel && <StartingLevelLabel>Starting level</StartingLevelLabel>}
                  {!isStartingLevel && (
                    <HiddenStartingLevelLabel>Make This The Starting level</HiddenStartingLevelLabel>
                  )}
                </ResponderLabel>
              </StyledStepLabel>

              <StepContent>
                <EscalationLevelResponderProgressionWrapper $disabledFlag={isLessThanStartingLevel}>
                  {escalationLevel.escalationMembers.map((member, index) => (
                    <React.Fragment key={index}>
                      <EscalationLevelResponderNameCard>
                        <div>
                          {member.user?.id && getFullName(member.user)}
                          {member.role?.id && member.role.name}
                        </div>
                        <div>
                          {member.user?.id && (member.user.role ? member.user.role : '--')}
                          {member.role?.id &&
                            (member.role.currentShift?.user ? getFullName(member.role.currentShift.user) : '--')}
                        </div>
                      </EscalationLevelResponderNameCard>
                    </React.Fragment>
                  ))}
                </EscalationLevelResponderProgressionWrapper>
                {isLessThanStartingLevel ? (
                  <AlertingMessage $disabledFlag={true}>
                    An escalating message won’t be sent to this level
                  </AlertingMessage>
                ) : (
                  <AlertingMessage $disabledFlag={false}>
                    Try each channel every {readableDurationInMinutes} minutes for {levelPolicyAttempts} attempt
                    {levelPolicyAttempts > 1 && 's'}.
                  </AlertingMessage>
                )}
              </StepContent>
            </StyledProgressDetailStep>
          );
        })}
      </Stepper>
    </ProgressDetailStepper>
  );
};

export default TriggerEscalationPreview;
