import * as React from 'react';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {ActionEvents, NavigationEvents} from 'src/constants/hotkeyEvents';
import {MESSENGER, LOCATING, SCHEDULING, CONTACTS} from 'src/constants/routerPathName';

interface Props {
  openChangeStatusModal: () => void;
  tryMessageSupportUser: () => void;
}

const START_SUPPORT_CHAT_KEYS = 'g+shift+?';
const OPEN_CHANGE_STATUS_MODAL_KEYS = 'g+z';
const GO_TO_SCHEDULING_KEYS = 'g+s';
const GO_TO_MESSAGES_KEYS = 'g+m';
const GO_TO_ONCALL_KEYS = 'g+o';
const GO_TO_CONTACTS_KEYS = 'g+c';

class RightHeaderSectionKeyListener extends React.PureComponent<Props> {
  public state = {
    isOtherKeyBeingPressed: false,
  };

  public keysHeldDown = {};

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  public render() {
    return null;
  }

  private handleKeyUp = (evt) => {
    evt = evt || window.event;
    // false the existing key flag
    this.keysHeldDown[evt.key] = evt.type === 'keydown';
  };

  private handleKeyDown = (evt) => {
    let currentFocusedElTag = document.activeElement?.tagName;
    // dont want trigger any events shortcuts when user is typing
    if (currentFocusedElTag === 'TEXTAREA' || currentFocusedElTag === 'INPUT') return;

    evt = evt || window.event;
    this.keysHeldDown[evt.key] = evt.type === 'keydown';

    if (this.keysHeldDown['g'] && this.keysHeldDown['z']) {
      evt.preventDefault();
      // Detecting g + z for status
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.hotkeyActivated,
        params: {
          keys: OPEN_CHANGE_STATUS_MODAL_KEYS,
          action: ActionEvents.OPEN_CHANGE_STATUS_MODAL,
        },
      });
      this.props.openChangeStatusModal();
    } else if (this.keysHeldDown['g'] && evt.shiftKey && this.keysHeldDown['?']) {
      evt.preventDefault();
      // Detecting g + ? (g + shift + /) for messaging support
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.hotkeyActivated,
        params: {
          keys: START_SUPPORT_CHAT_KEYS,
          action: ActionEvents.START_SUPPORT_CHAT,
        },
      });
      this.props.tryMessageSupportUser();
    } else if (this.keysHeldDown['g'] && this.keysHeldDown['o']) {
      evt.preventDefault();
      // Detecting g + o for locating
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.hotkeyActivated,
        params: {
          keys: GO_TO_ONCALL_KEYS,
          action: NavigationEvents.GO_TO_ONCALL,
        },
      });
      window.routerHistory.push(`/${LOCATING}`);
    } else if (this.keysHeldDown['g'] && this.keysHeldDown['s']) {
      evt.preventDefault();
      // Detecting g + s for Scheduling
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.hotkeyActivated,
        params: {
          keys: GO_TO_SCHEDULING_KEYS,
          action: NavigationEvents.GO_TO_SCHEDULING,
        },
      });
      window.routerHistory.push(`/${SCHEDULING}`);
    } else if (this.keysHeldDown['g'] && this.keysHeldDown['m']) {
      evt.preventDefault();
      // Detecting g + m for messaging
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.hotkeyActivated,
        params: {
          keys: GO_TO_MESSAGES_KEYS,
          action: NavigationEvents.GO_TO_MESSAGES,
        },
      });
      window.routerHistory.push(`/${MESSENGER}/home`);
    } else if (this.keysHeldDown['g'] && this.keysHeldDown['c']) {
      evt.preventDefault();
      // Detecting g + c for contacts
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.hotkeyActivated,
        params: {
          keys: GO_TO_CONTACTS_KEYS,
          action: NavigationEvents.GO_TO_CONTACTS,
        },
      });
      window.routerHistory.push(`/${CONTACTS}`);
    }
  };
}

export default RightHeaderSectionKeyListener;
