import styled from '@emotion/styled';

const HRtext = styled.hr`
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.7;
  margin: 1em 0;
  &:before {
    content: '';
    background: linear-gradient(to right, transparent, grey, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: grey;
    background-color: white;
  }
`;

export default HRtext;
