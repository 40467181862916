import {Grid, Avatar, Paper, Typography} from '@mui/material';
import {CustomCheckbox} from 'src/styles/styled-components/StyledMaterialComponents';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ChevronDown from 'src/svgs/ChevronDown';
import CloseIcon from 'src/svgs/CloseIcon';
import ChevronUp from 'src/svgs/ChevronUp';
import {default as ReactSelect} from 'react-select';
import {components} from 'react-select';
import styled from '@emotion/styled';
import {muiTheme} from 'src/styles/theme';

const PREFIX = 'SelectAvatar';

const classes = {
  small: `${PREFIX}-small`,
  large: `${PREFIX}-large`,
  form: `${PREFIX}-form`,
  avatar: `${PREFIX}-avatar`,
  imgAvatar: `${PREFIX}-imgAvatar`,
  gridContainer: `${PREFIX}-gridContainer`,
  optionContainer: `${PREFIX}-optionContainer`,
  optionGrid: `${PREFIX}-optionGrid`,
  optionPaper: `${PREFIX}-optionPaper`,
  optionTitle: `${PREFIX}-optionTitle`,
  optionSubTitle: `${PREFIX}-optionSubTitle`,
  selectedAvatar: `${PREFIX}-selectedAvatar`,
  optionCheckbox: `${PREFIX}-optionCheckbox`,
  optionIcon: `${PREFIX}-optionIcon`,
  optionAvtarCheckbox: `${PREFIX}-optionAvtarCheckbox`,
  optionWithAvtar: `${PREFIX}-optionWithAvtar`,
  optionLabel: `${PREFIX}-optionLabel`,
  optionWithAvtarContainer: `${PREFIX}-optionWithAvtarContainer`,
  multivalueContainer: `${PREFIX}-multivalueContainer`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.small}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  [`& .${classes.large}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },

  [`& .${classes.avatar}`]: {
    width: '28.4px',
    height: '28.4px',
    left: 'calc(50% - 28.4px/2 - 0px)',
    top: 'calc(50% - 22.4px/2 - 0px)',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    background: '#BCBCBC',
  },

  [`& .${classes.imgAvatar}`]: {
    width: '100%',
    height: 'auto',
  },

  [`& .${classes.gridContainer}`]: {
    position: 'static',
    height: '44px',
    left: '8px',
    top: '8px',
    padding: '10px !important',
  },

  [`& .${classes.optionContainer}`]: {
    padding: '5px 16px 8px 8px !important',
    height: '60px',
  },

  [`& .${classes.optionGrid}`]: {
    flexBasis: '0px',
  },

  [`& .${classes.optionPaper}`]: {
    display: 'contents',
  },

  [`& .${classes.optionTitle}`]: {
    display: 'inline-block',
    width: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#222222',
  },

  [`& .${classes.optionSubTitle}`]: {
    display: 'inline-block',
    width: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#4A4A4A',
  },

  [`& .${classes.selectedAvatar}`]: {
    width: '24px',
    height: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    borderRadius: '50%',
    verticalAlign: 'middle',
    color: 'white',
    background: '#BCBCBC',
  },

  [`& .${classes.optionCheckbox}`]: {
    margin: '-8px 3px 0px 0px',
  },

  [`& .${classes.optionIcon}`]: {
    marginTop: '-6px',
    padding: '0px !important',
    maxWidth: '5% !important',
  },

  [`& .${classes.optionAvtarCheckbox}`]: {
    marginRight: '3 !important',
    left: '8.33% !important',
    right: '8.33% !important',
    top: '8.33% !important',
    bottom: '8.33% !important',
  },

  [`& .${classes.optionWithAvtar}`]: {
    padding: '4px 0px 0px 0px !important',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#222222',
  },

  [`& .${classes.optionLabel}`]: {
    padding: '0px 16px !important',
    whiteSpace: 'nowrap',
    width: '85% !important',
    textOverflow: 'ellipsis',
  },

  [`& .${classes.optionWithAvtarContainer}`]: {
    width: '100%',
    maxWidth: '100%',
    marginRight: '8px',
    padding: '4px 0px 0px 0px !important',
  },

  [`& .${classes.multivalueContainer}`]: {
    margin: '-3px -3px 0px 0px !important',
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiValueStyle = (props) => {
  return {
    ...props,
    backgroundColor: '#f6f6f9',
    borderRadius: '4px',
    height: '32px',
    padding: '4px 4px 4px 2px',
    margin: '0px 8px 0px 0px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#222222',
    border: '1px solid #D8D8D8',
  };
};

const MultiOptionStyle = (provided, state) => {
  return {
    ...provided,
    backgroundColor: '',
    color: '#222222',
    ':hover': {
      backgroundColor: '#F6F6F9',
    },
    padding: 20,
  };
};

const ValueContainerStyle = (props) => {
  return {
    ...props,
    padding: '8px 8px 8px 16px',
  };
};

const SelectPlaceholderStyle = (props, state) => {
  return {
    ...props,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: state.selectProps.error ? '#FF3E55' : '#BCBCBC',
  };
};

const ControlStyle = (props, state) => {
  return {
    ...props,
    borderColor:
      state.selectProps.error && !state.selectProps.isDisabled && !state.hasValue ? '#FF3E55' : 'hsl(0,0%,80%)',
    boxShadow: state.isFocused && '0 0 0 1px #00859a',
    ':hover': {
      borderColor: '#00859a',
    },
  };
};

const MultiValueRemoveStyle = (props) => {
  return {
    ...props,
    marginLeft: '8px',
  };
};

const SelectContainerStyle = (props, state) => {
  return {
    ...props,
    marginLeft: state.selectProps.name !== 'templateIds' && '4%',
    width: state.selectProps.name !== 'templateIds' && '96%',
  };
};

const Option = (props) => {
  return (
    <components.Option {...props} className={classes.gridContainer}>
      <Grid container>
        <Grid item xs={1} className={classes.optionGrid}>
          <CustomCheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            className={classes.optionCheckbox}
            checked={props.isSelected}
          />{' '}
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.optionTitle} style={{fontWeight: 'normal'}} noWrap>
            {props.label}
          </Typography>
        </Grid>
      </Grid>
    </components.Option>
  );
};

const OptionWithAvtar = (props) => {
  const optiopn =
    props.value === 'all' ? (
      <Option {...props} />
    ) : (
      <components.Option {...props} className={classes.optionContainer}>
        <Grid container>
          <Grid item xs={1} className={classes.optionGrid}>
            <Paper className={classes.optionPaper}>
              <CustomCheckbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.optionAvtarCheckbox}
                checked={props.isSelected}
              />
            </Paper>
          </Grid>
          <Grid item xs={1} className={classes.optionIcon}>
            <Paper className={classes.optionPaper}>
              <Avatar className={classes.avatar}>
                {props.data.avatar ? (
                  <img src={props.data.avatar.url} className={classes.imgAvatar} alt="" />
                ) : (
                  props.label.charAt(0)
                )}
              </Avatar>
            </Paper>
          </Grid>
          <Grid item xs className={classes.optionLabel}>
            <Typography className={classes.optionTitle} noWrap>
              {props.label}
            </Typography>
            <br />
            <Typography className={classes.optionSubTitle} noWrap>
              {props.data.title}
            </Typography>
          </Grid>
        </Grid>
      </components.Option>
    );

  return optiopn;
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon color={muiTheme.colors.greyishBrown} width="10" height="10" />
    </components.MultiValueRemove>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <ChevronUp /> : <ChevronDown />}
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props) => {
  const {
    children = <CloseIcon color={muiTheme.colors.greyishBrown} width="12" height="12" />,
    innerProps: {ref, ...restInnerProps},
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <div>{children}</div>
    </div>
  );
};

const MultiValueLabelWithAvtar = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      <Grid container spacing={3} className={classes.multivalueContainer}>
        {props.data.value !== 'all' && (
          <Grid item xs={1} className={classes.optionWithAvtarContainer}>
            <Paper className={classes.optionPaper}>
              <Avatar className={classes.selectedAvatar}>
                {props.data.avatar ? (
                  <img src={props.data.avatar.url} className={classes.imgAvatar} alt="" />
                ) : (
                  props.data.label.charAt(0)
                )}
              </Avatar>
            </Paper>
          </Grid>
        )}
        <Grid item xs={1} className={classes.optionWithAvtar}>
          <Paper className={classes.optionPaper}>{props.data.label}</Paper>
        </Grid>
      </Grid>
    </components.MultiValueLabel>
  );
};

const SelectAvatar = ({
  name,
  option,
  selectedValue,
  type,
  placeholder,
  touched,
  isSubmitting,
  handleSelect,
  setFieldValue,
}) => {
  const selectComponent = {
    Option: type === 'normal' ? Option : OptionWithAvtar,
    MultiValueRemove,
    IndicatorSeparator: () => null,
    DropdownIndicator,
    ClearIndicator,
  };
  if (type === 'withAvtar') {
    selectComponent['MultiValueLabel'] = MultiValueLabelWithAvtar;
  }

  return (
    <Root>
      <ReactSelect
        options={option}
        name={name}
        isMulti
        fullWidth
        placeholder={placeholder}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        error={touched}
        styles={{
          container: SelectContainerStyle,
          multiValue: MultiValueStyle,
          option: MultiOptionStyle,
          valueContainer: ValueContainerStyle,
          placeholder: SelectPlaceholderStyle,
          control: ControlStyle,
          multiValueRemove: MultiValueRemoveStyle,
        }}
        components={selectComponent}
        isDisabled={isSubmitting ? true : false}
        onChange={(field, value) => handleSelect(field, value, setFieldValue, option)}
        allowSelectAll={true}
        value={selectedValue}
      />
    </Root>
  );
};

export default SelectAvatar;
