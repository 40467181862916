import moment from 'moment';
import {ShiftRequestPayload, ShiftRequestNote, ShiftResultInfo, ShiftRequestType} from 'src/types';
import {getFullName} from 'src/utils/user';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import parseDate from 'src/utils/parseDate';
import isEndTimeMinutesBeforeStartTime from 'src/utils/scheduling/isEndTimeMinutesBeforeStartTime';
import {getDetailedPrettyReadableDate} from 'src/utils/getPrettyReadableDate';

export function convertSwapDetailString(surrenderingShift: ShiftResultInfo, desiredShift: ShiftResultInfo) {
  const isSurrenderingCrossDay = isEndTimeMinutesBeforeStartTime(
    moment(surrenderingShift.startDate),
    moment(surrenderingShift.endDate),
  );
  const isDesiredShiftCrossDay = isEndTimeMinutesBeforeStartTime(
    moment(desiredShift.startDate),
    moment(desiredShift.endDate),
  );

  return `${surrenderingShift.role?.name} at 
    ${moment(surrenderingShift.startDate).format('MMM DD, YYYY')} 
    (${moment(surrenderingShift.startDate).format('HH:mm')} - ${moment(surrenderingShift.endDate).format('HH:mm')}${
    isSurrenderingCrossDay ? ' next day' : ''
  }) 
    with ${desiredShift.role?.name} at 
    ${moment(desiredShift.startDate).format('MMM DD, YYYY')}
    (${moment(desiredShift.startDate).format('HH:mm')} - ${moment(desiredShift.endDate).format('HH:mm')}${
    isDesiredShiftCrossDay ? ' next day' : ''
  }) 
    from ${getFullName(desiredShift.user)}`;
}

export function convertMarketplaceDetailString(shift: ShiftResultInfo, offerTypes: ShiftRequestType[]) {
  const isCrossDay = isEndTimeMinutesBeforeStartTime(moment(shift.startDate), moment(shift.endDate));

  return `${shift.role?.name} at ${moment(shift.startDate).format('MMM DD, YYYY')}
    (${moment(shift.startDate).format('HH:mm')} - ${moment(shift.endDate).format('HH:mm')}${
    isCrossDay ? ' next day' : ''
  }) 
    to the marketplace as a ${offerTypes.join(' and ').replace('swap', 'Swap Offer').replace('give_away', 'Give Away')}
  `;
}

const convertRequestTableData = (shiftRequest: ShiftRequestPayload) => {
  const isSwap = shiftRequest.type === 'swap';

  const getReason = (array: ShiftRequestNote[]) => {
    if (!array || array.length === 0) return '';

    let filteredNotes = array.filter((note) => note.status === 'active');
    if (filteredNotes.length === 0) return '';

    const selectedNote = filteredNotes.reduce((acc, cur) =>
      new Date(acc.createdAt) < new Date(cur.createdAt) ? cur : acc,
    );

    return selectedNote.note;
  };

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;

  const details = isSwap
    ? 'Swapping: ' + convertSwapDetailString(shiftRequest.surrenderingShift, shiftRequest.desiredShift!)
    : 'Offering: ' + convertMarketplaceDetailString(shiftRequest.surrenderingShift, shiftRequest.offerTypes ?? []);
  const dateRequested = getDetailedPrettyReadableDate(parseDate(shiftRequest.createdAt));
  const isSelf = selfID === shiftRequest.postedBy.id;
  const requestedBy = isSelf ? 'Me' : getFullName(shiftRequest.postedBy);

  const reason = getReason(shiftRequest.notes);

  return {
    details,
    dateRequested,
    requestedBy,
    reason,
    isSelf,
  };
};

export default convertRequestTableData;
