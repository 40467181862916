import * as React from 'react';
import styled from '@emotion/styled';
import {Message} from 'src/types';
import ConsultMessageModal from 'src/pages/MessengerPage/messenger/messages-layout/ConsultMessageModal';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';
import moment from 'moment';
import PriorityIndicator from 'src/pages/MessengerPage/messenger/messages-layout/message-item/PriorityIndicator';

const ConsultMessage = styled.div<BubbleProps>`
  background-color: ${(props) =>
    props.priorityType === UrgentMessagePriority
      ? props.theme.colors.priorityYellow
      : props.priorityType === StatMessagePriority
      ? props.theme.colors.statRed
      : props.theme.colors.greyishBrown};
  border: ${(props) =>
    [StatMessagePriority, UrgentMessagePriority].includes(props.priorityType) ? `1px solid white` : `unset`};
  color: ${(props) => (props.priorityType === UrgentMessagePriority ? 'black' : 'white')};
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
`;

const ConsultMessageInfoContainer = styled.div`
  margin: 0 10px;
  flex-grow: 1;
  white-space: normal;
  min-width: 0px;
`;

const ConsultMessageInfoDetail = styled.div`
  margin: 10px 0;
  padding-right: 5px;
  overflow-wrap: anywhere;
  line-break: anywhere;
  &:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1.5px solid grey;
  }
  & > span {
    font-size: 16px;
    display: block;
    overflow-wrap: break-word;
  }
  & > :first-child {
    font-size: 14px;
    margin-bottom: 0.2em;
  }
`;

const MessageTime = styled.div`
  font-size: 12px;
  align-self: flex-end;
  text-align: right;
`;

interface BubbleProps {
  priorityType: string;
}

interface Props {
  message: Message;
}

interface State {
  showModal: boolean;
}

class ConsultMessageBubble extends React.PureComponent<Props, State> {
  public state = {
    showModal: false,
  };

  public render() {
    const {message} = this.props;
    const {priorityType} = message;
    const {showModal} = this.state;
    return (
      <React.Fragment>
        <ConsultMessage priorityType={priorityType} onClick={this.showConsultMessageModal}>
          <ConsultMessageInfoContainer>
            {!message.repliedTo && <PriorityIndicator priorityType={priorityType} isConsultMessage={true} />}
            <ConsultMessageInfoDetail>
              <span>MRN</span>
              <span>{message.data ? message.data!.mrn : '--'}</span>
            </ConsultMessageInfoDetail>
            <ConsultMessageInfoDetail>
              <span>FIRST NAME</span>
              <span>{message.data && message!.data!.firstname ? message!.data!.firstname : '--'}</span>
            </ConsultMessageInfoDetail>
            <ConsultMessageInfoDetail>
              <span>LAST NAME</span>
              <span>{message.data && message!.data!.lastname ? message!.data!.lastname : '--'}</span>
            </ConsultMessageInfoDetail>
            <ConsultMessageInfoDetail>
              <span>DETAILS</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: message.data && message.data!.details ? message.data!.details.replace(/\n/g, '<br />') : '--',
                }}
              />
            </ConsultMessageInfoDetail>
            <MessageTime>{moment(message.dateCreated).format('hh:mm A')}</MessageTime>
          </ConsultMessageInfoContainer>
        </ConsultMessage>
        {showModal && (
          <ConsultMessageModal
            message={message}
            readOnly={true}
            closeModal={this.closeConsultMessageModal}
            reactModalProps={{isOpen: showModal}}
          />
        )}
      </React.Fragment>
    );
  }

  private closeConsultMessageModal = () => {
    this.setState({
      showModal: false,
    });
  };

  private showConsultMessageModal = () => {
    this.setState({
      showModal: true,
    });
  };
}

export default ConsultMessageBubble;
