import React from 'react';
import SearchPanelContainer from 'src/pages/LocatingPage/locating/SearchPanelContainer';
import {ProfileLayoutContainer} from './ProfileLayoutContainer';
// import styled from '@emotion/styled';

const LocatingLayout = () => {
  return (
    <React.Fragment>
      <SearchPanelContainer />
      <ProfileLayoutContainer />
    </React.Fragment>
  );
};

export default LocatingLayout;
