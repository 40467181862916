import {Box, Typography} from '@mui/material';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import styled from '@emotion/styled';
import {getBaseClientURL} from 'src/utils/getBaseClientUrl';

const PREFIX = 'NewStepFooter';

const classes = {
  helpText: `${PREFIX}-helpText`,
  recoverAccountText: `${PREFIX}-recoverAccountText`,
  contactSupportLink: `${PREFIX}-contactSupportLink`,
  container: `${PREFIX}-container`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.helpText}`]: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: '#4A4A4A',
  },

  [`& .${classes.recoverAccountText}`]: {
    color: 'red',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },

  [`& .${classes.contactSupportLink}`]: {
    color: theme.colors.primary,
    textDecoration: 'none',
    marginLeft: theme.spacing(),
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  [`&.${classes.container}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '16px',
    marginBotton: '16px',
  },
}));

interface Props {
  recoverAccount?: boolean;
}

const NewStepFooter = ({recoverAccount}: Props) => {
  const onContactSupport = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signUpContactSupport,
    });
  };

  return (
    <StyledBox className={classes.container}>
      {recoverAccount ? (
        <Typography variant="body1" color="textSecondary" className={classes.recoverAccountText}>
          Phone number is already in use
          <a
            href={`${getBaseClientURL()}/resetpassword`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.contactSupportLink}
            onClick={onContactSupport}
          >
            Recover account
          </a>
        </Typography>
      ) : null}
      <Typography variant="body1" color="textSecondary" className={classes.helpText}>
        Need help?
        <a
          href="https://www.hypercare.com/contact"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.contactSupportLink}
          onClick={onContactSupport}
        >
          Contact support
        </a>
      </Typography>
    </StyledBox>
  );
};

export default NewStepFooter;
