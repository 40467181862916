import {Box, IconButton, BoxProps} from '@mui/material';
import styled from '@emotion/styled';
import {muiTheme} from 'src/styles/theme';

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled(IconButton)`
  && {
    color: black;
    background-color: #f2f2f2;
    width: 40px;
    height: 40px;
  }
`;

export const CloseButton = styled(IconButton)`
  && {
    background-color: #f2f2f2;
    width: 40px;
    height: 40px;
  }
`;

interface OverflowBoxProps extends BoxProps {
  showOverflow?: boolean;
}

export const StyledStepContainer = styled(Box)`
  max-width: 600px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    padding: 16px;
  }
`;

export const StyledInner = styled(Box)<OverflowBoxProps>(({showOverflow}) => ({
  maxHeight: '700px',
  overflowY: showOverflow ? 'scroll' : 'initial',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  padding: '44px 32px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  filter: 'drop-shadow(0px 3px 5px rgba(9, 30, 66, 0.2)) drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31))',
  marginBottom: '50px',
}));

export const StyledDotContainer = styled(Box)`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 34;
  & > * {
    border-radius: 8px 8px 0px 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ContactSupportLink = styled.a`
  color: ${muiTheme.colors.primary};
  text-decoration: none;
  margin-left: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

export const SignupProgressContainer = styled.div`
  height: 10px;
  background-color: ${muiTheme.colors.lightGrey};
  border-radius: 8px 8px 0 0;
`;

export const Progress = styled.div`
  height: 10px;
  background-color: ${muiTheme.colors.watermelon};
  border-radius: 8px 8px 0 0;
  transition: width 0.8s;
`;
