import styled from '@emotion/styled';

export const StyledKbd = styled.kbd`
  font-family: Roboto;
  display: inline-block;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  font-size: 11px;
  line-height: 1.4;
  color: dimgray;
  text-shadow: 0 1px 0 #fff;
  background-color: #e1e3e5;
  border: 1px solid #adb3b9;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(12, 13, 14, 0.2), 0 0 0 2px #fff inset;
  white-space: nowrap;
`;
