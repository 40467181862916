import * as R from 'ramda';
import {CONSULT, ATTACHMENT, IMAGE, TEMPLATE} from 'src/constants/messageTypes';
import {ApolloClient} from 'apollo-client';
import {tryGetMessageFragment} from 'src/utils/messengerHelper/getMessageFragment';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';
import {MessageWsPayload, Message, TemplateElements, MessageTemplateField} from 'src/types';
import fetchRepliedToMessage from 'src/utils/messengerHelper/fetchRepliedToMessage';

export default async function (messagePayload: MessageWsPayload, client: ApolloClient<any>): Promise<Message> {
  const tryReadSender = client.readFragment({
    id: `User:${messagePayload.sender.id}`,
    fragment: GeneralUserFragment,
  });

  // Ideally socket message payload should also contain all the information for replied message.
  // Currently due to backend capacity its only the repliedTo messageId;
  // There could be a case - where such message is not fetch yet, or, message is fetched only from another message's repliedTo,
  // that repliedTo message fragment is not a complete message Fragment as a whole (missing nested repliedTo, dateCreated, ...etc)
  // to ignore these warnings and errors, try catch messageFragment when reading it from apollo cache.
  let repliedToMessage: Message | null = null;
  if (messagePayload.repliedTo) {
    const message = await tryGetMessageFragment(messagePayload.repliedTo.toString(), client);
    if (message) {
      repliedToMessage = message;
    } else {
      // we dont want to delay showing any incoming message, asynchronously fetch that repliedTo message and write to update its parent message later
      setTimeout(
        () =>
          fetchRepliedToMessage({
            chatId: messagePayload.chatId,
            repliedToId: messagePayload.repliedTo?.toString() ?? '',
            originalMessageId: messagePayload.id.toString(),
          }),
        0,
      );
    }
  }

  let sender;
  if (!tryReadSender) {
    const senderTypeKeys = ['firstname', 'id', 'lastname', 'profilePic', 'role', 'username'];
    const formatSenderFragment = R.pick(senderTypeKeys, messagePayload.sender);
    sender = {
      ...formatSenderFragment,
      profilePic: {
        ...formatSenderFragment.profilePic,
        __typename: 'Image',
      },
      __typename: 'ChatMember',
    };
  } else {
    sender = tryReadSender;
  }
  const readReceipt = {
    user: sender,
    messageId: messagePayload.id,
    timestamp: messagePayload.dateCreated,
    __typename: 'ReadReceipt',
  };

  const transformedMessage: Message = R.pipe(
    R.evolve({
      sender: () => sender,
    }),
    R.assoc('repliedTo', repliedToMessage),
    R.assoc('deliveredTo', []),
    R.assoc('readBy', [readReceipt]),
    R.assoc('__typename', 'Message'),
    R.assoc('image', messagePayload.image || null),
  )(messagePayload);

  const transformWsTemplateMessageToGraphQL = (child: MessageTemplateField) => {
    if (!child.validation) child.validation = null;
    if (child.type === TemplateElements.MultilineTextfield) {
      if (!child.text) child.text = null;
      child.__typename = 'MessageTemplateMultilineTextField';
    } else if (child.type === TemplateElements.Textfield) {
      if (!child.text) child.text = null;
      child.__typename = 'MessageTemplateTextField';
    } else if (child.type === TemplateElements.Dropdown) child.__typename = 'MessageTemplateDropdown';
    else if (child.type === TemplateElements.Checkbox) child.__typename = 'MessageTemplateCheckbox';
    else if (child.type === TemplateElements.Toggle) child.__typename = 'MessageTemplateToggle';
    else if (child.type === TemplateElements.Radio) child.__typename = 'MessageTemplateRadioButton';
    else if (child.type === TemplateElements.Datepicker) child.__typename = 'MessageTemplateDatePicker';
    else if (child.type === TemplateElements.Attachments) {
      child.__typename = 'MessageTemplateAttachments';
      child.attachments?.forEach((file) => {
        file.__typename = 'File';
      });
    }
    return child;
  };

  // Note: add missing __typename of other potential message option types
  if (transformedMessage.type === TEMPLATE && transformedMessage.template) {
    transformedMessage.template!.__typename = 'MessageTemplateForm';

    if (transformedMessage?.template?.disclaimer)
      transformedMessage.template!.disclaimer!.__typename = 'MessageTemplateDisclaimer';
    else transformedMessage.template!.disclaimer = null;

    transformedMessage.template!.request!.__typename = 'MessageTemplateFormRequest';
    if (transformedMessage?.template?.response) {
      transformedMessage.template!.response!.__typename = 'MessageTemplateFormResponse';
      if (!transformedMessage?.template?.response?.isAffirmative)
        transformedMessage.template!.response!.isAffirmative = undefined;
      if (!transformedMessage?.template?.response?.isAcknowledged)
        transformedMessage.template!.response!.isAcknowledged = undefined;
      transformedMessage.template!.response!.children?.forEach((child) => {
        child = transformWsTemplateMessageToGraphQL(child);
      });
    }

    transformedMessage.template!.request!.children!.forEach((child) => {
      child = transformWsTemplateMessageToGraphQL(child);
    });
  }
  if (transformedMessage.type === CONSULT && transformedMessage.data)
    transformedMessage.data!.__typename = 'ConsultMessageData';
  if ((transformedMessage.type === ATTACHMENT || transformedMessage.type === IMAGE) && transformedMessage.attachment) {
    transformedMessage.attachment.__typename = 'File';
  } else {
    transformedMessage.attachment = null;
  }

  return transformedMessage;
}
