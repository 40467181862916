import {Box, LinearProgress} from '@mui/material';
import styled from '@emotion/styled';
import {SignupProgressCopy} from './signup-progress.component-copy';
import cx from 'clsx';

const PREFIX = 'StepContainerCopy';

const classes = {
  root: `${PREFIX}-root`,
  inner: `${PREFIX}-inner`,
  dotContainer: `${PREFIX}-dotContainer`,
};

const StyledBox = styled(Box)<PropsStyle>(({theme, showOverflow}) => ({
  [`&.${classes.root}`]: {
    flex: '1',
    position: 'relative',
    width: '600px',
  },

  [`& .${classes.inner}`]: {
    maxHeight: '700px',
    overflowY: showOverflow,
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '44px 32px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    filter: 'drop-shadow(0px 3px 5px rgba(9, 30, 66, 0.2)) drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31))',
    marginBottom: '50px',

    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },

  [`& .${classes.dotContainer}`]: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 34,

    '& > *': {
      borderRadius: '8px 8px 0px 0px',
    },
  },
}));

interface PropsStyle {
  showOverflow: 'scroll' | 'initial';
}

export const StepContainerCopy = (props) => {
  const {children, progress, currentStep, showOverflow, className} = props;

  return (
    <StyledBox className={cx(classes.root, className)} showOverflow={showOverflow ? 'scroll' : 'initial'}>
      <Box className={classes.inner}>{children}</Box>
      {progress ? (
        <Box className={classes.dotContainer}>
          <LinearProgress color="secondary" />
        </Box>
      ) : (
        <Box className={classes.dotContainer}>
          <SignupProgressCopy value={(currentStep * 600) / 5} />
        </Box>
      )}
    </StyledBox>
  );
};
