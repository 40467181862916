import * as React from 'react';
import styled from '@emotion/styled';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {IsFeatureFlagEnabled} from '../../../../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../../../../utils/FeatureFlags';

const StyledPopper = styled(Popper)`
  z-index: 1;
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  min-width: 180px;
  line-height: 20px;
`;

interface Props {
  moreOptionAnchorEl: HTMLButtonElement | null;
  setMoreOptionAnchorEl: (el: HTMLButtonElement | null) => void;
  isSelfMessage: boolean;
  isTextMessage: boolean;
  isSingleChat: boolean;
  setShowMessageDetailModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEscalationTriggerModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DetailOptionMoreOptions = ({
  isSelfMessage,
  isTextMessage,
  isSingleChat,
  moreOptionAnchorEl,
  setMoreOptionAnchorEl,
  setShowMessageDetailModal,
  setShowEscalationTriggerModal,
}: Props) => {
  const esclationFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.escalationFeatureFlag);
  const handleMoreOptionClose = () => {
    setMoreOptionAnchorEl(null);
  };

  const handleTriggerEscalationModal = () => {
    handleMoreOptionClose();
    setShowEscalationTriggerModal(true);
  };

  const handleMessageDetail = () => {
    handleMoreOptionClose();
    setShowMessageDetailModal(true);
  };

  const shouldShowEscalationOption = esclationFeatureFlag && isSelfMessage && isSingleChat && isTextMessage;

  return (
    <React.Fragment>
      <StyledPopper
        open={Boolean(moreOptionAnchorEl)}
        anchorEl={moreOptionAnchorEl}
        onMouseLeave={handleMoreOptionClose}
        placement="bottom"
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow {...TransitionProps} style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
            <Paper>
              <ClickAwayListener onClickAway={handleMoreOptionClose}>
                <MenuList>
                  {shouldShowEscalationOption && (
                    <StyledMenuItem onClick={handleTriggerEscalationModal}>Escalate...</StyledMenuItem>
                  )}
                  <StyledMenuItem onClick={handleMessageDetail}>Message Detail</StyledMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </React.Fragment>
  );
};

export default React.memo(DetailOptionMoreOptions);
