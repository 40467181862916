import React from 'react';
import {ApolloQueryResult} from 'apollo-client';
import {FetchMarketplaceShiftsResult} from 'src/types';

export type MarketplaceRefetchRequestType = (variables?: Record<string, any>) => Promise<ApolloQueryResult<any>>;

export interface MarketplaceRefetchContextInterface {
  refetch: MarketplaceRefetchRequestType | null;
}

export interface ScheduleQueryVariables {
  currentDepartmentId: number;
  startOfMonthUTC: string;
  endOfMonthUTC: string;
}

export const MarketplaceRefetchContext = React.createContext<MarketplaceRefetchContextInterface>({
  refetch: null,
});

const MarketplaceRefetchProvider = (props: {refetch: MarketplaceRefetchRequestType; children: React.ReactElement}) => {
  const {refetch, children} = props;

  return <MarketplaceRefetchContext.Provider value={{refetch}}>{children}</MarketplaceRefetchContext.Provider>;
};

export default MarketplaceRefetchProvider;
