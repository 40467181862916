import * as React from 'react';
import {AutoCompleteDropDown} from '../../../@hc-ui/components/autocomplete/AutoCompleteDropDown';
import {useDepartmentList} from '../scheduling/hooks';
import styled from '@emotion/styled';
import Chevron from '../../../svgs/Chevron';
import TextField from '@mui/material/TextField';
import {DepartmentScope} from '../../../types';
import {AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import {
  SCHEDULING_PAGE_DEPARTMENT_SELECTOR_INPUT_PLACEHOLDER,
  SCHEDULING_PAGE_NO_DEPARTMENT_FOUND_ERROR,
} from 'src/constants/strings';
import {FilterOptionsState} from '@mui/material/useAutocomplete';

const dropdownWidth = 300;
const lineHeight = 45;
const MenuItem = styled.div`
  width: ${dropdownWidth}px;
  height: ${lineHeight}px;
  line-height: ${lineHeight}px;
  padding: 0 1em;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background-color: whitesmoke;
  }
`;

interface DepartmentSwitcherV2Props {
  isSelfScoped: boolean;
  departmentId: number;
  onDepartmentSelect: (departmentId: number) => void;
}

const NoDepartmentFound: DepartmentScope = {
  id: 1,
  image: '',
  isAdmin: false,
  name: SCHEDULING_PAGE_NO_DEPARTMENT_FOUND_ERROR,
};

export const DepartmentSwitcherV2 = ({isSelfScoped, departmentId, onDepartmentSelect}: DepartmentSwitcherV2Props) => {
  const {fullDepartmentList, selfDepartmentList} = useDepartmentList();
  const departmentList = (isSelfScoped ? selfDepartmentList : fullDepartmentList).sort((departmentA, departmentB) =>
    departmentA.name.toLowerCase().localeCompare(departmentB.name.toLowerCase()),
  );

  const isViewOnly = (targetDepartmentId: number) =>
    !selfDepartmentList.find((department) => department.id === targetDepartmentId);

  const currentDepartment = departmentList.find((department) => department.id === departmentId) || NoDepartmentFound;

  const renderDepartmentListItem = (department: DepartmentScope) => {
    console.log(department);
    return (
      <MenuItem
        key={`department-${department.id}`}
        onClick={() => onDepartmentSelect(department.id)}
        title={department.name}
      >
        {department.name}
        {isViewOnly(department.id) && ' (view only)'}
      </MenuItem>
    );
  };

  const renderInputField = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      color={'primary'}
      variant="outlined"
      placeholder={SCHEDULING_PAGE_DEPARTMENT_SELECTOR_INPUT_PLACEHOLDER}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
    />
  );

  const handleChange = (event, value) => {
    onDepartmentSelect(value.id);
  };

  const filterDropdownOptions = (option: DepartmentScope[], state: FilterOptionsState<DepartmentScope>) => {
    const inputValue = state.inputValue.split(' ');

    return option.filter((optionItem) => {
      return inputValue.every((value: string) => optionItem.name.toLowerCase().includes(value.toLowerCase()));
    });
  };

  console.log(departmentList);
  return (
    <AutoCompleteDropDown
      filterOptions={filterDropdownOptions}
      onChange={handleChange}
      options={departmentList}
      popupIcon={<Chevron />}
      renderInput={renderInputField}
      renderOptionLabel={(option: DepartmentScope) => `${option.name}`}
      currentOption={currentDepartment}
      renderOptions={renderDepartmentListItem}
    />
  );
};
