import React from 'react';
import styled from '@emotion/styled';
import ReplyIcon from 'src/svgs/ReplyIcon';
import {Message, MessageTemplateStatus} from 'src/types';
import {StyledReplyToTitle} from 'src/styles/styled-components/StyledReplyToTitle';
import ReplyMessagePreviewContent from 'src/pages/MessengerPage/messenger/messages-layout/ReplyMessagePreviewContent';
import {getFullName} from 'src/utils/user';
import {AuthContext} from 'src/auth/AuthProvider';
import {
  ATTACHMENT,
  CONSULT,
  IMAGE,
  StatMessagePriority,
  TEMPLATE,
  TEXT,
  UrgentMessagePriority,
} from 'src/constants/messageTypes';
import isValidImageAttachment from 'src/utils/messengerHelper/isValidImageAttachment';
import ConsultMessageModal from 'src/pages/MessengerPage/messenger/messages-layout/ConsultMessageModal';
import DetailMessageModal from 'src/pages/MessengerPage/messenger/messages-layout/DetailMessageModal';
import ModalImage from 'react-modal-image';
import store from 'src/redux';
import {actions as MessageActions} from 'src/redux/actions/messageTemplates';
import StatDoubleExclamation from 'src/svgs/StatDoubleExclamation';
import UrgentSingleExclamation from 'src/svgs/UrgentSingleExclamation';
import ResponseTemplateDetail from './message-template/ResponseTemplateDetail';
import RespondedTemplateMessageResponse from './message-template/RespondedTemplateMessageResponse';
import {muiTheme} from 'src/styles/theme';

const PreviewInnerContent = styled.div<{
  isInMessageBubble: boolean;
}>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.isInMessageBubble
      ? ``
      : `  
      margin-top: 6px;
      margin-right: 1.5em;
    `}
`;

const ImageShrinkPreviewHolder = styled.div<{
  isInMessageBubble: boolean;
}>`
  overflow: hidden;
  width: 3.5em;
  height: 3.5em;
  margin-left: ${(props) => (props.isInMessageBubble ? '1em' : '0')};
  margin-right: ${(props) => (props.isInMessageBubble ? '0' : '2.5em')};
  cursor: pointer;
  div {
    height: 100%;
    width: 100%;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`;

const StatMessageIcon = styled(StatDoubleExclamation)`
  margin-right: 10px;
`;

const UrgentMessageIcon = styled(UrgentSingleExclamation)`
  margin-right: 10px;
`;

const MessagePreviewContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const CursorWrapper = styled.div<{
  shouldShowPointer: boolean;
}>`
  cursor: ${(props) => (props.shouldShowPointer ? 'pointer' : 'unset')};
  flex-grow: 1;
`;

const PriorityIndicatorWrapper = styled.div`
  transform: scale(1) translateX(-10px);
  margin-left: 5px;
  margin-right: -5px;
`;

interface Props {
  message: Message;
  userId: string;
  chatId: string;
  isInMessageBubble: boolean;
}

const ReplyMessagePreviewWrapper = ({message, userId, chatId, isInMessageBubble}: Props) => {
  const {sender} = message,
    [showConsult, setShowConsult] = React.useState<boolean>(false),
    [showDetail, setShowDetail] = React.useState<boolean>(false),
    [showTemplateResponseDetail, setTemplateResponseDetail] = React.useState<boolean>(false),
    [showTemplateRequestDetail, setTemplateRequestDetail] = React.useState<boolean>(false);

  const handleOnClick = () => {
    if (message.type === CONSULT) return setShowConsult(true);
    if (message.type === TEXT) return setShowDetail(true);
    if (message.type === TEMPLATE) {
      if (message?.template?.status === MessageTemplateStatus.pending_response) return setTemplateRequestDetail(true);
      else if (message?.template?.status === MessageTemplateStatus.responded) return setTemplateResponseDetail(true);
      else if (message?.template?.status === MessageTemplateStatus.response_not_required)
        return setTemplateRequestDetail(true);
    }
  };

  const closeTemplateDetail = (status: boolean) => {
    setTemplateRequestDetail(status);
    store.dispatch(MessageActions.resetToInitalState());
  };

  const detailResponseEditViewReviewConsultBtnHandler = () => {
    setTemplateResponseDetail(false);
    setTemplateRequestDetail(true);
  };

  return (
    <>
      <MessagePreviewContentContainer>
        <CursorWrapper
          shouldShowPointer={message.type === TEXT || message.type === CONSULT || message.type === TEMPLATE}
          onClick={handleOnClick}
        >
          <StyledReplyToTitle isSelfQuotedMessage={userId === sender.id}>
            <ReplyIcon />
            {userId === sender.id ? 'You' : getFullName(sender)}
          </StyledReplyToTitle>
          <PreviewInnerContent isInMessageBubble={isInMessageBubble}>
            <PriorityIndicatorWrapper>
              {message.priorityType === StatMessagePriority ? (
                <StatMessageIcon fillColor={muiTheme.colors.statRed} />
              ) : message.priorityType === UrgentMessagePriority ? (
                <UrgentMessageIcon />
              ) : null}
            </PriorityIndicatorWrapper>
            <ReplyMessagePreviewContent message={message} chatId={chatId} />
          </PreviewInnerContent>
        </CursorWrapper>

        {(message.type === IMAGE && (message.image || message.attachment)) ||
        (message.type === ATTACHMENT && isValidImageAttachment(message)) ? (
          <ImageShrinkPreviewHolder isInMessageBubble={isInMessageBubble}>
            <ModalImage
              small={message.image || message.attachment?.url}
              large={message.image || message.attachment?.url}
              hideZoom={true}
              showRotate={true}
              imageBackgroundColor={'transparent'}
            />
          </ImageShrinkPreviewHolder>
        ) : null}
      </MessagePreviewContentContainer>

      {showConsult && (
        <ConsultMessageModal
          message={message}
          readOnly={true}
          isMessagePriority={message.priority}
          closeModal={() => setShowConsult(false)}
          reactModalProps={{isOpen: showConsult}}
        />
      )}
      {showTemplateRequestDetail && (
        <ResponseTemplateDetail
          chatId={chatId}
          message={message}
          openResponseTemplateDetail={showTemplateRequestDetail}
          closeResponseTemplateDetail={closeTemplateDetail}
          respondTemplateMessageMutation={null}
          reviewResponseDetailResponseBtnHandler={() => {}}
        />
      )}
      {showTemplateResponseDetail && (
        <RespondedTemplateMessageResponse
          chatId={chatId}
          message={message}
          respondedModal={showTemplateResponseDetail}
          setRespondedModal={setTemplateResponseDetail}
          viewRequestDetailsBtnHandler={detailResponseEditViewReviewConsultBtnHandler}
        />
      )}
      {showDetail && (
        <DetailMessageModal
          message={message}
          closeModal={() => setShowDetail(false)}
          reactModalProps={{isOpen: showDetail}}
        />
      )}
    </>
  );
};

export default (props) => (
  <AuthContext.Consumer>
    {({authInfo}) => <ReplyMessagePreviewWrapper {...props} userId={authInfo.user.id} />}
  </AuthContext.Consumer>
);
