import React from 'react';
import client from 'src/apollo';
import styled from '@emotion/styled';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import Button from '@mui/material/Button';
import {toast} from 'react-toastify';
import {MESSENGER} from 'src/constants/routerPathName';
import ActivateCodeMutation from 'src/gql/mutation/ActivateCodeMutation';

const StyledTextAreaInput = styled(TextareaAutosize)`
  border-color: ${(props) => props.theme.colors.borderColor};
  max-height: 450px;
  resize: none;
  margin-top: 0.5em;
  margin-bottom: 2em;
  width: 100%;
  font-size: 1em;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  padding: 12px;
  outline: none;
  overflow: auto !important;
  &:focus {
    border-color: ${(props) => props.theme.colors.redPink};
  }
`;

const ErrorMsg = styled.span`
  color: red;
  font-size: 14px;
  padding-top: 1em;
`;

interface Props {
  showModal: boolean;
  codeName: string;
  codeId: number;
  handleCloseModal: () => void;
}

const CodeStemiModal = ({showModal, codeId, codeName, handleCloseModal}: Props) => {
  const [input, setInput] = React.useState('');
  const [error, setError] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setInput(inputValue);
  };

  const handleSubmitCodeStemi = async () => {
    try {
      setLoading(true);
      setError('');

      const {data} = await client.mutate({
        mutation: ActivateCodeMutation,
        variables: {
          codeId,
          message: input,
        },
        fetchPolicy: 'no-cache',
      });

      let chatId = data.locating.activateCode.chat.id;
      toast.success(`${codeName} activation request has been sent`);
      window.routerHistory.push(`/${MESSENGER}/${chatId}`);
    } catch (e) {
      console.error(e);
      window.alert(`Failed to send ${codeName} activation request, please try again`);
      setError(`Failed to send ${codeName} activation request, please try again`);
      document.getElementById('STEMI-input')?.focus();
      setLoading(false);
    }
  };

  return (
    <StyledModal
      isOpen={showModal}
      shouldCloseOnEsc={!isLoading}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={handleCloseModal}
    >
      <Header>
        <span>Activate {codeName}</span>
        <CloseBtn onClick={() => (isLoading ? null : handleCloseModal())} />
      </Header>

      <StyledTextAreaInput
        autoFocus
        minRows={5}
        id="STEMI-input"
        onChange={onChange}
        placeholder="ETA, Location, etc."
      />

      <Button variant="contained" color="secondary" onClick={handleSubmitCodeStemi} disabled={isLoading}>
        {isLoading ? 'Sending Request...' : 'Activate'}
      </Button>

      {error && <ErrorMsg>{error}</ErrorMsg>}
    </StyledModal>
  );
};

export default CodeStemiModal;
