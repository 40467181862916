import HypercareSignupRepository from 'src/pages/newSignupFlow/HypercareSignupRepository';
import {TOO_MANY_CHALLENGES} from 'src/constants/networkError';
import ApiHelper from 'src/api';
import {AuthSSOVendors} from '../../../types';
import {SSO_VENDOR} from '../../../constants/storageKeys';

class HypercareSignupEmailViewModel {
  nextButtonPressed;
  repository: HypercareSignupRepository;

  constructor(nextButtonPressed: (email: string) => void) {
    this.nextButtonPressed = nextButtonPressed;
  }

  checkForEmailForSSO = async (email: string) => {
    const result = await this.repository.checkIfAddressIsSSO(email);

    if (result?.success && result.data?.ssoProfileForUser) {
      const {auth0Id, ssoId, ssoVendor} = result.data.ssoProfileForUser;
      return {auth0Id, ssoId, ssoVendor};
    }

    if (result?.error) {
      let error = result.error?.response?.data?.errors[0]?.message || result.error[0]?.message;
      return {error};
    }
  };

  handleNextButtonPressed = async (email: string) => {
    const checkForSSO = await this.checkForEmailForSSO(email);
    sessionStorage.setItem('email', email);

    if (checkForSSO?.auth0Id) {
      localStorage.setItem(SSO_VENDOR, checkForSSO?.ssoVendor);

      if (checkForSSO.ssoVendor === AuthSSOVendors.WORKOS) {
        await this.getSSOAuthorizationUrl(checkForSSO.auth0Id, checkForSSO.ssoVendor);
      } else {
        this.nextButtonPressed({auth0Id: checkForSSO.auth0Id, email});
      }
    } else {
      const requestValidationCode = await this.requestEmailValidationCode(email);

      if (requestValidationCode && requestValidationCode?.data?.response) {
        const challengeId = requestValidationCode.data.response.challengeId;
        this.nextButtonPressed({challengeId, email});
      } else {
        if (requestValidationCode && requestValidationCode?.data.errors[0].name === TOO_MANY_CHALLENGES) {
          return {
            success: false,
            error: {
              message: 'Too many challenges, please wait before starting another challenge',
              code: TOO_MANY_CHALLENGES,
            },
          };
        } else {
          return {
            success: false,
            error: {
              message: 'Failed to request validation code, please check your internet connection and try again',
              code: '',
            },
          };
        }
      }
    }
  };

  requestEmailValidationCode = async (email: string) => {
    const type = 'email';
    const result = await this.repository.requestAddressToken(email, type);
    return result;
  };

  getSSOAuthorizationUrl = async (connectionId: string, provider: AuthSSOVendors) => {
    const result = await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(connectionId, provider);
    return result;
  };
}

export default HypercareSignupEmailViewModel;
