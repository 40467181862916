import React, {useEffect, useState} from 'react';
import Chevron from 'src/svgs/Chevron';
import styled from '@emotion/styled';
import {LocatingDepartmentScope, LocatingRole, OnCallDepartmentScope, typedUseSelector} from 'src/types';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import locatingKeywordSearch from 'src/utils/locating/locatingKeywordSearch';
import {Accordion, AccordionSummary} from '@mui/material';
import HypercareSchedulingViewModel from 'src/pages/LocatingPage/viewModels/HypercareSchedulingViewModel';
import LoadingDiv from '../../../../components/LoadingDiv';
import store from '../../../../redux';
import AccordionDetails from '@mui/material/AccordionActions';
import List from '@mui/material/List';
import TeamListItems from './TeamListItems';
import sortRoles from '../../../../utils/sortRoles';
import RoleListItems from './RoleListItems';
import {ON_CALL_PAGE_NO_SCHEDULE_FOUND} from '../../../../constants/strings';
import {muiTheme} from 'src/styles/theme';
import AccordionDetailsWrapper from './ExpansionPanelDetailsWrapper';

const PREFIX = 'DepartmentsExpansionWrapper';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  expandWrapper: `${PREFIX}-expandWrapper`,
  summaryHeaderWrapper: `${PREFIX}-summaryHeaderWrapper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.expandWrapper}`]: {
    boxShadow: 'none',
    margin: '0 !important',
    '& .MuiAccordionSummary-root.Mui-expanded ': {
      borderBottom: 'none',
      minHeight: '40px !important',
      height: '40px !important',
    },
  },

  [`& .${classes.summaryHeaderWrapper}`]: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'white',
    borderBottom: '1px dashed lightgrey',
    '& .MuiAccordionSummary-content': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const DepartmentsWrapper = styled.div``;

const StyledLabel = styled.span`
  font-size: 12px;
  color: grey;
  margin-top: 1em;
  margin-bottom: 8px;
`;

const DepartmentName = styled.span`
  font-weight: bold;
  font-family: Nunito;
  font-size: 0.9375rem;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const NoResult = styled.p`
  margin-top: 1em;
  font-size: 16px;
  color: ${muiTheme.colors.greyishBrown};
`;

interface Props {
  searchKeyword: string;
  favouriteRolesList: LocatingRole[];
  departmentLists: LocatingDepartmentScope[];
  setFavouriteRolesList: React.Dispatch<React.SetStateAction<LocatingRole[]>>;
}

const DepartmentsExpansionWrapper = ({
  searchKeyword,
  favouriteRolesList,
  departmentLists,
  setFavouriteRolesList,
}: Props) => {
  /**
   * only allowing one exapnsion at at time, but if there exists search keyword
   * hide the chevron toggle and deafult expand all result
   */
  const [expanded, setExpanded] = React.useState('');

  const currentSite = typedUseSelector((state) => state.onCall.currentSelectedSite);

  const {getRoleNotesForDepartmentArray, getScheduleForDepartment} = HypercareSchedulingViewModel();

  const [loader, setLoader] = useState(false);

  async function onExpansionPanelClick(index: number, department: LocatingDepartmentScope) {
    let expandedToggleText = `panel-${index}`;
    if (expanded === expandedToggleText) {
      setExpanded('');
    } else {
      AnalyticsManager.recordPageVisited(PAGE_VIEWS.departmentView);
      setExpanded(expandedToggleText);

      setLoader(true);
      await getScheduleForDepartment({departmentId: department.id, siteId: currentSite.id ?? 0});
      setLoader(false);
    }
  }

  const tryFilterDepartments = () => {
    if (Boolean(searchKeyword)) {
      const departmentList = store.getState().onCall.OnCallSites[currentSite.id!] || [];

      const inputValue = searchKeyword.split(' ');

      return departmentList.filter((department) => {
        return inputValue.every((value: string) => department.name.toLowerCase().includes(value.toLowerCase()));
      });
    }
    return departmentLists;
  };

  const departmentResultList = tryFilterDepartments();

  const noSearchResult = departmentResultList.length === 0 && Boolean(searchKeyword);

  useEffect(() => {
    const getData = async () => {
      const departmentArray = departmentLists.map((department) => ({
        id: department.id,
        scheduleId: department.schedule?.id,
      }));
      await getRoleNotesForDepartmentArray(departmentArray);
    };

    getData();
  }, [departmentLists]);

  const renderNoScheduleFound = () => (
    <AccordionDetails style={{padding: '5px 20px'}}>
      <List component="nav" style={{width: '100%'}} disablePadding aria-label="role expandable detail">
        {ON_CALL_PAGE_NO_SCHEDULE_FOUND}
      </List>
    </AccordionDetails>
  );

  return (
    <Root>
      <StyledLabel>Departments</StyledLabel>
      <DepartmentsWrapper className={classes.root}>
        {noSearchResult && <NoResult>No results found</NoResult>}
        {departmentResultList && departmentResultList.length === 0 && !noSearchResult && (
          <NoResult>Please contact your organization administrator if you would like to create a department.</NoResult>
        )}
        {departmentResultList.map((department, i) => (
          <Accordion
            expanded={expanded === `panel-${i}`}
            className={classes.expandWrapper}
            key={`department${department.id}`}
          >
            <AccordionSummary
              onClick={() => onExpansionPanelClick(i, department)}
              className={classes.summaryHeaderWrapper}
              expandIcon={<Chevron />}
              aria-controls="department-content"
            >
              <DepartmentName>{department.name}</DepartmentName>
            </AccordionSummary>
            {loader && <LoadingDiv />}

            {!loader &&
              department.teams?.length === 0 &&
              Object.keys(department?.schedule || {}).length === 0 &&
              renderNoScheduleFound()}

            <AccordionDetailsWrapper
              isAllFavourite={false}
              setFavouriteRolesList={setFavouriteRolesList}
              roleList={department.schedule?.roles || department?.roles || []}
              teamList={department?.teams ?? []}
              searchKeyword={searchKeyword}
              favouriteRolesList={favouriteRolesList}
              departmentId={department.id}
            />
          </Accordion>
        ))}
      </DepartmentsWrapper>
    </Root>
  );
};

export default DepartmentsExpansionWrapper;
