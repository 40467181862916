import React, {CSSProperties} from 'react';
import Select, {createFilter} from 'react-select';
import {useTheme} from '@mui/material/styles';
import {ValueType} from 'react-select/src/types';
import overriddenComponents, {StyledSelect} from 'src/pages/MessengerPage/auto-select/ReactSelectMaterialUI';
import {UserSelectOptionType} from 'src/types';
import {
  CircleOption as Option,
  DropdownIndicator,
  FixedHeightMenuList as MenuList,
} from 'src/pages/MessengerPage/auto-select/ReactSelectSharedComponents';
import styled from '@emotion/styled';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

const StyledDropdownWrapper = styled.div`
  flex-grow: 1;
  .Mui-focused {
    color: black !important;
  }
`;

/**
 * Search by label and role
 */
const stringify = (option) => {
  return option.label + option.data.role;
};
const filterOption = createFilter({ignoreCase: true, stringify});

const AddChatMemberListSelect = ({onClickColleague, suggestions}) => {
  const theme = useTheme();
  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<UserSelectOptionType>>([]);

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.selectContactsList);
  }, []);

  function handleChangeMulti(value: ValueType<UserSelectOptionType>) {
    onClickColleague(value);
    setSelectedColleague(value);
  }

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    // tslint:disable jsx-no-lambda
    <StyledDropdownWrapper onClick={(e) => e.stopPropagation()}>
      <StyledSelect
        autoFocus
        styles={selectStyles}
        inputId="react-select-multiple"
        TextFieldProps={{
          label:
            selectedColleague && selectedColleague.length >= 1
              ? 'Click Add when finished'
              : 'Type the name of a colleague',
          InputLabelProps: {
            htmlFor: 'react-select-multiple',
            shrink: true,
          },
        }}
        id="search-input-field"
        placeholder={'Search a name...'}
        options={suggestions}
        components={{
          ...overriddenComponents,
          Option,
          DropdownIndicator,
          MenuList,
        }}
        value={selectedColleague}
        onChange={handleChangeMulti}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        filterOption={filterOption}
        isMulti
        menuIsOpen
      />
    </StyledDropdownWrapper>
  );
};

export default AddChatMemberListSelect;
