import {Message} from 'src/types';
import {DataProxy} from 'apollo-cache';
import MessageFragment from 'src/gql/fragment/MessageFragment';

export async function tryGetMessageFragment(messageId: string, client: DataProxy): Promise<Message | null> {
  try {
    const messageFragment = client.readFragment({
      id: `Message:${messageId}`,
      fragment: MessageFragment,
      fragmentName: 'MessageFragment',
    }) as Message;

    return messageFragment;
  } catch {
    return null;
  }
}

export default function getMessageFragment(messageId: string, client: DataProxy): Message | null {
  const messageFragment = client.readFragment({
    id: `Message:${messageId}`,
    fragment: MessageFragment,
    fragmentName: 'MessageFragment',
  }) as Message;
  return messageFragment || null;
}
