import {Chat, Message, User} from 'src/types';
import getMessages from 'src/utils/messengerHelper/getMessagesFromChat';

export function getUnreadMessages(messages: Message[], curUser?: User): Message[] {
  if (!curUser) return [];

  const unreadMessages = messages.filter((msg) => {
    const {readBy} = msg;
    if (readBy.some((receipt) => receipt.user.id === curUser.id)) {
      return false;
    }
    return true;
  });
  return unreadMessages;
}

export default function getUnreadMessagesFromChat(chat: Chat, curUser: User): Message[] {
  const messages = getMessages(chat);
  return getUnreadMessages(messages, curUser);
}
