import styled from '@emotion/styled';

export const StyledReplyToTitle = styled.div<{isSelfQuotedMessage: boolean}>`
  display: flex;
  color: ${(props) => props.theme.colors.chatTeal};
  font-weight: bold;
  align-items: center;
  font-family: sans-serif;
  font-size: 14px;
  svg {
    transform: translateY(-1px);
    fill: ${(props) => props.theme.colors.chatTeal};
  }
  ${(props) =>
    !props.isSelfQuotedMessage &&
    `
    min-width: 150px;    
  `}
`;
