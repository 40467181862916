import React from 'react';
import {QueryResult} from 'react-apollo';
import {useQuery} from '@apollo/react-hooks';
import {GetSelfOrganizationsResult, GetScopedSelfDepartmentsResult, DepartmentScope} from 'src/types';
import GetSelfOrganizations, {FetchSelfScopedDepartments} from 'src/gql/query/GetUserOrganizations';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';

// TODO: instead of reduce into departmentScope[], reduce into one siteScope[] instead
// where full canlendar view does not limited to self scope, but marketplace will only show self scoepd ones

const useDepartmentList = () => {
  const memorizedOrganizationsLength = React.useRef(0);
  const memorizedDepartmentList = React.useRef<DepartmentScope[]>([]);

  const {data: fullScopedData}: QueryResult<GetSelfOrganizationsResult> = useQuery(GetSelfOrganizations, {
    fetchPolicy: 'cache-only',
  });

  const {data: selfScopedData}: QueryResult<GetScopedSelfDepartmentsResult> = useQuery(FetchSelfScopedDepartments, {
    fetchPolicy: 'cache-only',
  });

  const selfDepartmentList = selfScopedData?.me.departments ?? [];

  const fullDepartmentList: DepartmentScope[] = React.useMemo(() => {
    if (!fullScopedData?.me.organizations) return [];
    const orgList = fullScopedData.me.organizations;
    if (memorizedOrganizationsLength.current === orgList.length) return memorizedDepartmentList.current;
    memorizedOrganizationsLength.current = orgList.length;

    const currentOrganizationID = getCurrentOrganizationId();
    const currentOrg = orgList.find((organization) => organization.id == currentOrganizationID);
    const departmentIdSet = new Set();

    const reducedUniqueDepartments: DepartmentScope[] =
      currentOrg?.sites.reduce((acc: DepartmentScope[], current) => {
        let result: DepartmentScope[] = [];
        current.departments.forEach((department) => {
          let sizeRef = departmentIdSet.size;
          departmentIdSet.add(department.id);
          if (sizeRef !== departmentIdSet.size) result.push(department);
        });
        return [...acc, ...result];
      }, []) ?? [];

    memorizedDepartmentList.current = reducedUniqueDepartments;
    return reducedUniqueDepartments;
  }, [fullScopedData]);

  return {
    fullDepartmentList,
    selfDepartmentList,
  };
};

export default useDepartmentList;
