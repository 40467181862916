import MomentUtils from '@date-io/moment';
import {Dialog, Grid, TextField} from '@mui/material';
import moment from 'moment';
import React, {useState} from 'react';
import CalendarIcon from 'src/svgs/CalendarIcon';
import styled from '@emotion/styled';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';

type FormFieldInputDateProps = {
  value?: Date;
  placeholder: string;
  onChange(value: Date): void;
  disabled: boolean;
  mode: 'view' | 'edit';
  error?: string;
};

const StyledTextField = styled(TextField)<{showDisabledInput: boolean}>`
  .Mui-disabled {
    color: ${(props) => (props.showDisabledInput ? 'black !important' : '')};
  }
`;
const getFormattedDate = (value?: Date) => {
  if (!value) return null;

  return moment(value).format('DD/MM/YYYY');
};

export const FormFieldInputDate = (props: FormFieldInputDateProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const inputRef = React.useRef<HTMLDivElement | null>(null);
  const isInputDisabled = isDatePickerOpen || props.disabled || props.mode === 'view';
  const startAdornment = <CalendarIcon style={{width: 16, height: 16, marginRight: 4}} />;
  return (
    <Grid container xs={12}>
      <StyledTextField
        variant="standard"
        ref={inputRef}
        showDisabledInput={props.disabled && props.mode === 'view'}
        value={getFormattedDate(props.value)}
        placeholder={props.placeholder}
        InputProps={{
          startAdornment,
        }}
        onFocus={() => setIsDatePickerOpen(true)}
        disabled={isInputDisabled}
        error={!!props.error}
        helperText={props.error}
        fullWidth
      />
      <Dialog open={isDatePickerOpen}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            open={true}
            PopperProps={{
              anchorEl: inputRef.current,
              placement: 'bottom-start',
            }}
            openTo="day"
            views={['day']}
            value={props.value || new Date()}
            onChange={(newDate) => {
              setIsDatePickerOpen(false);
              if (!newDate) return;
              props.onChange(newDate);
            }}
            onClose={() => setIsDatePickerOpen(false)}
            renderInput={(props) => <></>}
          />
        </LocalizationProvider>
      </Dialog>
    </Grid>
  );
};
