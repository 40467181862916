import moment from 'moment';

export class CalenderViewDataSourceProtocol {
  public static gridCount = 0;
  public static proceedingArr = 1;
  public static succeedingArr = 1;
  public static startDateISOstring = '';
  public static holidays: any[] = [];
  public static isHolidayMark = false;
  constructor(startDateISOstring: string) {
    CalenderViewDataSourceProtocol.startDateISOstring = startDateISOstring;
    CalenderViewDataSourceProtocol.gridCount = moment(startDateISOstring).daysInMonth();
    CalenderViewDataSourceProtocol.proceedingArr = moment(startDateISOstring).add(2, 'days').startOf('month').day();
    CalenderViewDataSourceProtocol.succeedingArr =
      (42 - CalenderViewDataSourceProtocol.proceedingArr - CalenderViewDataSourceProtocol.gridCount) % 7;
  }
}
