import React from 'react';
import WarningAltIcon from 'src/svgs/WarningAltIcon';
import styled from '@emotion/styled';

interface Props {
  title: string;
  description: React.ReactNode;
}

const Banner = styled.div`
  border: 1px solid #ffc705;
  background: #fff4cd;
  border-radius: 3px;
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  position: relative;
  margin-bottom: 16px;
`;

const StyledContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

const StyledTitle = styled.div`
  color: #222;
  font-family: Nunito Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
  gap: 8px;
  margin-top: -1px;
  position: relative;
  padding-bottom: 8px;
`;

const StyledDescription = styled.div`
  color: #00859a;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  align-self: stretch;
  position: relative;
`;

const WarningBanner = ({title, description}: Props) => {
  return (
    <Banner>
      <WarningAltIcon />
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </StyledContent>
    </Banner>
  );
};

export default WarningBanner;
