import React from 'react';
import {HCFilterPaginatedSearch} from 'src/@hc-ui/components/filters/variants/HCFilterPaginatedSearch';
import {HCFilterOption} from 'src/@hc-ui/components/filters/variants/HCFilter';
import {MultiUserInputUser} from 'src/types/PaginatedTypes';
import {FilterOption, SitesFilter} from 'src/pages/ContactsPage/filters/SitesFilter';
import {IsFeatureFlagEnabled} from '../../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../../utils/FeatureFlags';

interface UserAccordionTextProps {
  userOptions: MultiUserInputUser[];
  searchText: string;
  handleSearch: (searchValue: string) => Promise<void>;
  selectedUsers: HCFilterOption[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<HCFilterOption[]>>;
  setLocalSearchText: React.Dispatch<React.SetStateAction<string>>;
  fetchMorePaginatedSearchData: () => Promise<void>;
  isDoneRows: boolean;
  isSearchLoading: boolean;
  setReduxSelectedColleagues: (reduxStoreSelectedColleagues) => void;
  selectedSitesIds: number[];
  setSelectedSitesIds: (selectedSitesIds) => void;
  siteOptions: FilterOption[];
}

export const UserAccordionText = ({
  userOptions,
  searchText,
  handleSearch,
  selectedUsers,
  setSelectedUsers,
  setLocalSearchText,
  fetchMorePaginatedSearchData,
  isDoneRows,
  isSearchLoading,
  setReduxSelectedColleagues,
  selectedSitesIds,
  setSelectedSitesIds,
  siteOptions,
}: UserAccordionTextProps) => {
  const pcSiteFiltersFlagEnabled = IsFeatureFlagEnabled(FeatureFlagResult.pcSiteFilters);

  const handleOnUserOptionClick = (option: HCFilterOption) => {
    const found = selectedUsers.find((user: HCFilterOption) => user.username === option.username);
    if (found) {
      const newFilteredArray = selectedUsers.filter((user) => user.username !== option.username);
      setSelectedUsers(newFilteredArray);
      setReduxSelectedColleagues(newFilteredArray);
    } else {
      setSelectedUsers([...selectedUsers, option]);
      setReduxSelectedColleagues([...selectedUsers, option]);
    }
  };
  return (
    <HCFilterPaginatedSearch
      handleSearch={handleSearch}
      searchText={searchText}
      title="Users"
      options={{
        title: 'Users',
        options: userOptions || [],
      }}
      onToggle={(option) => {
        handleOnUserOptionClick(option);
      }}
      optionRender={(option) => {
        return (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{paddingBottom: 4}}>{option.label}</div>
            <div style={{fontWeight: 400, fontSize: 14, color: '#4A4A4A'}}>{option.role}</div>
          </div>
        );
      }}
      onClear={() => {
        setLocalSearchText('');
      }}
      selectedUsers={selectedUsers}
      filterOptions={
        pcSiteFiltersFlagEnabled && (
          <div style={{marginLeft: '100px !important'}}>
            <SitesFilter
              selectedSitesIds={selectedSitesIds}
              setSelectedSitesIds={setSelectedSitesIds}
              siteOptions={siteOptions}
              setLocalSearchText={() => {
                setLocalSearchText('');
              }}
            />
          </div>
        )
      }
      selectedIds={selectedUsers.map((u) => u.id)}
      fetchMorePaginatedData={fetchMorePaginatedSearchData}
      isDoneFetchingMoreData={isDoneRows}
      isSearchLoading={isSearchLoading}
      selectedSitesIds={selectedSitesIds}
      setSelectedSitesIds={setSelectedSitesIds}
      siteOptions={siteOptions}
    />
  );
};
