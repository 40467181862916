import React, {useState} from 'react';
import {typedUseSelector} from '../../../../redux';
import {useParams, useLocation} from 'react-router-dom';
import {OrganizationViewModel} from '../view-models/OrganizationViewModel';
import {AuthContext} from '../../../../auth';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {STALogin} from '../../../../types';
import {LoginPasswordView} from '../../../../microfrontend/login/LoginPasswordView';
import {LoginOTPView} from '../../../../microfrontend/login/LoginOTPView';
import {PrimaryButton} from '../../../../styles/styled-components/StyledMaterialComponents';
import {OrgLoginMethods, OrgViews} from '../../../../types/sta';
import HypercareLogoSVG from '../../../../svgs/HypercareLogoSVG';
import {LoginPageContainer, LoginPageHypercareLogoContainer} from '../../styled/login.styled';
import {DEBUG} from '../../../../constants/storageKeys';
import {FindUserOrganizationView} from '../login/FindUserOrganizationView';
import {LoginEmailAddressView} from '../../../../microfrontend/login/LoginEmailAddressView';

interface ISwitchAccountsProps {
  isLoggedIn: boolean;
  auth0props: Auth0ContextInterface;
  STALogin: STALogin;
  changeCurrentStep?: number;
}

const SwitchAccounts = ({isLoggedIn, auth0props, STALogin}: ISwitchAccountsProps) => {
  const {view} = useParams<{view: OrgViews | OrgLoginMethods}>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentSelectedAccount = typedUseSelector((state) => state.currentSelectedAccount);

  const email = queryParams.get('email') || '';
  const challengeId = queryParams.get('challengeId') || '';

  const [currentOrg, setCurrentOrg] = useState(currentSelectedAccount.organization ?? {});
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [OTPError, setOTPError] = useState('');
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const {submitEmailAddress, submitOTP, submitPassword} = OrganizationViewModel();

  const [currentEmail, setCurrentEmail] = useState('');

  const handleSubmitPassword = async (value: string) => {
    setIsSubmittingPassword(true);
    const res = await submitPassword(currentOrg.url || '', email, value);

    if (res?.error) {
      setPasswordError(res.error);
    }

    if (res?.data) {
      await STALogin(currentOrg, res.data, email || currentEmail);
    }
    setIsSubmittingPassword(false);
  };

  const handleSubmitOTP = async (value: string) => {
    const res = await submitOTP(challengeId, value, currentOrg.url || '');

    if (res.error) {
      setOTPError(res.error.toString());
    }

    if (res.data) {
      await STALogin(currentOrg, res.data, email || currentEmail);
    }
  };

  const showDebugMenu = () => {
    window.routerHistory.push(`/${DEBUG}`);
  };

  const resendOTPCode = () => {
    // request a new code
  };

  const handleGoBack = () => {
    window.routerHistory.push(`/`);
  };

  const handleSubmitEmailAddress = async (value: string) => {
    setCurrentEmail(value);
    setIsSubmittingEmail(true);
    const res = await submitEmailAddress(value, currentOrg?.url || '');
    if (res.error) {
      setEmailError(res.error);
    }
    if (res?.auth0Id) {
      await auth0props.loginWithRedirect({
        connection: res.auth0Id,
        scope: 'openid profile email',
      });
    }

    setIsSubmittingEmail(false);
    switch (res.screen) {
      case OrgLoginMethods.OTP:
        window.routerHistory.push(`/accountSwitch/otp?challengeId=${res.challengeId}`);
        return;
      case OrgLoginMethods.PASSWORD:
        window.routerHistory.push(`/accountSwitch/password?email=${value.replace('+', '%2b')}`);
        return;
      default:
        break;
    }
  };

  const handleGoToPreviousStep = () => {
    window.routerHistory.push(`/accountSwitch/findMyOrganization`);
  };

  const renderStep = () => {
    switch (view) {
      case OrgViews.EMAIL:
        return (
          <LoginEmailAddressView
            handleGoBack={handleGoToPreviousStep}
            handleNext={handleSubmitEmailAddress}
            organization={currentOrg}
            error={emailError}
            isLoading={isSubmittingEmail}
          />
        );
      case OrgLoginMethods.OTP:
        return (
          <LoginOTPView
            handleGoBack={handleGoBack}
            handleNext={handleSubmitOTP}
            organization={currentSelectedAccount.organization}
            resend={resendOTPCode}
            isResending={false}
            error={OTPError}
            currentSelectedEmail={currentSelectedAccount?.email || ''}
          />
        );

      case OrgLoginMethods.PASSWORD:
        return (
          <LoginPasswordView
            handleGoBack={handleGoBack}
            handleNext={handleSubmitPassword}
            organization={currentSelectedAccount.organization}
            error={passwordError}
            isLoading={isSubmittingPassword}
          />
        );

      case OrgViews.FIND_MY_ORGANIZATION:
        return (
          <FindUserOrganizationView
            handleGoBack={() => window.routerHistory.push('/')}
            handleShowEmailView={() => window.routerHistory.push('/accountSwitch/email')}
            setCurrentSelectedOrg={setCurrentOrg}
          />
        );
      default:
        return (
          <div>
            Unknown login methods
            <PrimaryButton onClick={() => window.routerHistory.push('/')}>Click to go back</PrimaryButton>
          </div>
        );
    }
  };

  return (
    <LoginPageContainer>
      <LoginPageHypercareLogoContainer onClick={showDebugMenu}>
        <HypercareLogoSVG />
      </LoginPageHypercareLogoContainer>

      {renderStep()}
    </LoginPageContainer>
  );
};

export const AccountSwitchPage = () => {
  return (
    <AuthContext.Consumer>
      {({isLoggedIn, auth0props, STALogin}) => (
        <SwitchAccounts STALogin={STALogin} isLoggedIn={isLoggedIn} auth0props={auth0props} />
      )}
    </AuthContext.Consumer>
  );
};
