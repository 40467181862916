import * as React from 'react';
import Autocomplete, {AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import {FilterOptionsState} from '@mui/material/useAutocomplete';

export type AutoCompleteDropDownProps<TOption> = {
  options: TOption[];
  currentOption: NonNullable<TOption>;
  renderOptions: (option: TOption) => React.JSX.Element;
  renderInput: (params: AutocompleteRenderInputParams) => React.JSX.Element;
  popupIcon: React.JSX.Element;
  renderOptionLabel: (option: TOption) => string;
  onChange: (e: React.ChangeEvent<{}>, v: NonNullable<TOption> | TOption[]) => void;
  filterOptions: (options: TOption[], state: FilterOptionsState<TOption>) => TOption[];
};

export const AutoCompleteDropDown = <TOption,>({
  options,
  currentOption,
  renderOptions,
  renderInput,
  popupIcon,
  renderOptionLabel,
  onChange,
  filterOptions,
}: AutoCompleteDropDownProps<TOption>) => {
  return (
    <Autocomplete
      filterOptions={(options, state) => filterOptions(options, state)}
      style={{width: 300}}
      options={options}
      onChange={(e, v) => onChange(e, v)}
      getOptionLabel={(option) => renderOptionLabel(option)}
      value={currentOption}
      disableClearable={true}
      renderOption={(props, option) => renderOptions(option)}
      popupIcon={popupIcon}
      renderInput={(params) => renderInput(params)}
    />
  );
};
