import * as React from 'react';
import styled from '@emotion/styled';
import Linkify from 'linkifyjs/react';
import {CONSULT, IMAGE, ATTACHMENT, TEMPLATE} from 'src/constants/messageTypes';
import ConsultMessageBubble from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-bubbles/ConsultMessageBubble';
import TemplateMessageBubble from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-bubbles/TemplateMessageBubble';
import ImageMessageBubble from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-bubbles/ImageMessageBubble';
import {Message, MessageInChat} from 'src/types';
import mapMimeTypeToIconName from 'src/utils/mapMimeTypeToIconName';
import DefaultFileIcon from 'src/svgs/DefaultFileIcon';
import FileErrorIcon from 'src/svgs/FileErrorIcon';
import NestedMessageReplyPreview from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-bubbles/NestedMessageReplyPreview';
import isValidImageAttachment from 'src/utils/messengerHelper/isValidImageAttachment';
import {MessageImageFixedHeight} from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-bubbles/ImageMessageBubble';
import setDownloadAttachment from 'src/utils/messengerHelper/setDownloadAttachment';
import {StatMessagePriority, UrgentMessagePriority, SYSTEM} from 'src/constants/messageTypes';
import PriorityIndicator from 'src/pages/MessengerPage/messenger/messages-layout/message-item/PriorityIndicator';
import {Marker} from 'react-mark.js';
import moment from 'moment';

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ParsedLinkifyToImg = styled.img`
  height: ${MessageImageFixedHeight}px;
  max-width: 100%;
  display: block;
  border-radius: 10px;
  object-fit: cover;
`;

const FileName = styled.a<{
  isSelfMessage: boolean;
  priorityType: string;
}>`
  color: ${(props) =>
    props.priorityType === UrgentMessagePriority
      ? props.theme.colors.messageBlack
      : !props.isSelfMessage && props.priorityType !== StatMessagePriority
      ? props.theme.colors.messageBlack
      : props.theme.colors.messageWhite};
  line-height: 1.5em;
  cursor: pointer;
  padding-left: 0.5em;
  text-decoration: underline;
`;

const FileIconIndicator = styled.img`
  min-height: 18px;
  min-width: 18px;
`;

const MessageTime = styled.div`
  font-size: 12px;
  align-self: flex-end;
`;

const WildTypeMessageBubbleWrapper = styled.div<{
  hasRepliedMsg: boolean;
  priorityType: string;
  isSelf: boolean;
  id: string;
  searchMessageId?: string;
}>`
  ${(props) =>
    props.hasRepliedMsg
      ? `
      background-color: ${
        props.priorityType === UrgentMessagePriority
          ? props.theme.colors.priorityYellow
          : props.priorityType === StatMessagePriority
          ? props.theme.colors.statRed
          : props.isSelf
          ? props.theme.colors.chatBlue
          : props.theme.colors.chatGrey
      };
      padding: 1em;
      border-radius: 5px;
      max-width: 400px;
      position: relative;
      overflow: hidden;
    `
      : 'max-width: 400px;'}
`;

const LinkifyContent = ({children, isSelfMessage}) => {
  return (
    <Linkify
      tagName="div"
      options={{
        format: (value: string, type: string) => {
          if (
            type === 'url' &&
            value.length > 5 &&
            (['.gif', '.png', '.jpg'].includes(value.slice(-4)) || ['.webp', '.jpeg'].includes(value.slice(-5)))
          ) {
            return <ParsedLinkifyToImg src={value} alt="gif preview" />;
          }
          return value;
        },
      }}
      className={`Linkify ${isSelfMessage ? 'Linkify-self' : 'Linkify-other'}`}
    >
      {children}
    </Linkify>
  );
};

interface MessageBubbleRendererProps {
  popperRef: any;
  message: Message;
  chatId: string;
  isSelfMessage: boolean;
  isLastInConsecutiveBySender: boolean;
  messageBubbleStyledTagName: any; //styled.component
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  children: React.ReactNode;
  searchText: string;
  searchMessageData: MessageInChat[] | [];
  jumpToMessage: MessageInChat;
  isSideSearchActive: boolean;
  searchMessage: MessageInChat | null;
}

const MessageBubbleRenderer = ({
  popperRef,
  message,
  chatId,
  isSelfMessage,
  isLastInConsecutiveBySender,
  messageBubbleStyledTagName,
  onMouseEnter,
  onMouseLeave,
  children,
  searchText,
  searchMessageData,
  jumpToMessage,
  isSideSearchActive,
  searchMessage,
}: MessageBubbleRendererProps) => {
  const MessageBubble = messageBubbleStyledTagName;

  function onFileClick() {
    if (message.attachment?.fileName) {
      setDownloadAttachment({
        chatId,
        messageId: message.id,
      });
    }
  }

  // Note: image message type is depercated 2019-11-30
  if (message.type === IMAGE && (message.image || message.attachment?.url)) {
    return (
      <WildTypeMessageBubbleWrapper
        isSelf={isSelfMessage}
        hasRepliedMsg={!!message.repliedTo}
        priorityType={message.priorityType}
        searchMessageId={searchMessage?.message?.id}
        id={message.id}
        className={message.priorityType}
        ref={popperRef}
        onClick={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {message.repliedTo && <PriorityIndicator priorityType={message.priorityType} isImageMessage={false} />}
        {message.repliedTo && <NestedMessageReplyPreview message={message.repliedTo} chatId={chatId} />}
        <ImageMessageBubble
          isOptimistic={!!message.isOptimistic}
          isSelfMessage={isSelfMessage}
          priorityType={message.priorityType}
          originalFile={message.file!}
          imgSrc={message.image || message.attachment?.url}
          dateCreated={message.dateCreated}
          repliedTo={message.repliedTo}
        />
      </WildTypeMessageBubbleWrapper>
    );
  }

  // Consult message bubble
  if (message.type === CONSULT) {
    return (
      <WildTypeMessageBubbleWrapper
        isSelf={isSelfMessage}
        hasRepliedMsg={!!message.repliedTo}
        priorityType={message.priorityType}
        searchMessageId={searchMessage?.message?.id}
        id={message.id}
        className={message.priorityType}
        ref={popperRef}
        onClick={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {message.repliedTo && <PriorityIndicator priorityType={message.priorityType} isConsultMessage={true} />}
        {message.repliedTo && <NestedMessageReplyPreview message={message.repliedTo} chatId={chatId} />}
        <ConsultMessageBubble message={message} />
      </WildTypeMessageBubbleWrapper>
    );
  }

  // @ts-ignore
  if (message.type === TEMPLATE) {
    return (
      <WildTypeMessageBubbleWrapper
        isSelf={isSelfMessage}
        hasRepliedMsg={!!message.repliedTo}
        priorityType={message.priorityType}
        searchMessageId={searchMessage?.message?.id}
        id={message.id}
        className={message.priorityType}
        ref={popperRef}
        onClick={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {message.repliedTo && <PriorityIndicator priorityType={message.priorityType} isConsultMessage={false} />}
        {message.repliedTo && <NestedMessageReplyPreview message={message.repliedTo} chatId={chatId} />}
        <TemplateMessageBubble message={message} chatId={chatId} isSelf={isSelfMessage} />
      </WildTypeMessageBubbleWrapper>
    );
  }

  // ATTACHMENT message bubble
  if (message.type === ATTACHMENT) {
    if (isValidImageAttachment(message)) {
      return (
        <WildTypeMessageBubbleWrapper
          isSelf={isSelfMessage}
          hasRepliedMsg={!!message.repliedTo}
          priorityType={message.priorityType}
          searchMessageId={searchMessage?.message?.id}
          id={message.id}
          className={message.priorityType}
          ref={popperRef}
          onClick={onMouseLeave}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {message.repliedTo && (
            <PriorityIndicator
              priorityType={message.priorityType}
              isImageMessage={false}
              isConsultMessage={false}
              isTemplateMessage={false}
            />
          )}
          {message.repliedTo && <NestedMessageReplyPreview message={message.repliedTo} chatId={chatId} />}
          <ImageMessageBubble
            isOptimistic={!!message.isOptimistic}
            isSelfMessage={isSelfMessage}
            priorityType={message.priorityType}
            originalFile={message.file}
            imgSrc={message.attachment?.url}
            dateCreated={message.dateCreated}
            repliedTo={message.repliedTo}
          />
        </WildTypeMessageBubbleWrapper>
      );
    } else {
      return (
        <MessageBubble
          ref={popperRef}
          id={message.id} // for scroll to unread message
          className={message.priorityType}
          onClick={onMouseLeave}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          isLastInConsecutiveBySender={isLastInConsecutiveBySender}
          priorityType={message.priorityType}
          searchMessageId={searchMessage?.message?.id}
          messageId={message.id}
        >
          <PriorityIndicator priorityType={message.priorityType} isConsultMessage={false} />
          <div>
            {message.repliedTo && <NestedMessageReplyPreview message={message.repliedTo} chatId={chatId} />}
            <FlexContainer>
              {message.attachment && Boolean(mapMimeTypeToIconName[message.attachment.mimeType]) ? (
                <FileIconIndicator
                  alt="file_type_icon"
                  src={`/assets/${mapMimeTypeToIconName[message.attachment.mimeType]}.svg`}
                />
              ) : message.attachment ? (
                <DefaultFileIcon />
              ) : (
                <FileErrorIcon />
              )}

              <FileName
                priorityType={message.priorityType}
                isSelfMessage={isSelfMessage}
                onClick={onFileClick}
                rel="noopener noreferrer"
              >
                {message.attachment?.fileName || 'Attachment not found.'}
              </FileName>
            </FlexContainer>
          </div>
          <MessageTime>{moment(message.dateCreated).format('hh:mm A')}</MessageTime>
        </MessageBubble>
      );
    }
  }

  const markMessage = () => {
    let mark;
    if (!isSideSearchActive && searchText.length > 0 && searchMessageData.length > 0) {
      if (searchMessageData.length > 0 && !jumpToMessage) {
        mark = searchMessageData.find((searchMessage: MessageInChat) => {
          return searchMessage.message.id === message.id;
        });
        if (mark) {
          return mark.matchedSequences;
        } else {
          mark = [];
        }
      }
    }

    if (isSideSearchActive && jumpToMessage) {
      if (jumpToMessage) {
        return jumpToMessage.matchedSequences;
      } else {
        mark = [];
      }
    }

    return mark;
  };

  const markerStyleClass = () => {
    if (searchMessage) {
      const type = message.type;
      const priorityType = message.priorityType;
      const styledClass = searchMessage?.message?.id === message.id ? 'selected' : 'unselected';

      return {
        className: `${styledClass}-${type}-${priorityType}-marker`,
      };
    }
  };

  return (
    <MessageBubble
      ref={popperRef}
      id={message.id} // for scroll to unread message
      className={message.priorityType}
      onClick={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isLastInConsecutiveBySender={isLastInConsecutiveBySender}
      priorityType={message.priorityType}
      searchMessageId={searchMessage?.message?.id}
      messageId={message.id}
    >
      <PriorityIndicator priorityType={message.priorityType} isConsultMessage={false} />
      <div>
        {message.repliedTo && <NestedMessageReplyPreview message={message.repliedTo} chatId={chatId} />}
        <Marker mark={markMessage()} options={searchMessage ? markerStyleClass() : undefined}>
          <LinkifyContent children={children} isSelfMessage={isSelfMessage} />
        </Marker>
      </div>
      {message.type !== SYSTEM && <MessageTime>{moment(message.dateCreated).format('hh:mm A')}</MessageTime>}
    </MessageBubble>
  );
};

export default React.memo(MessageBubbleRenderer);
