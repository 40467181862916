import {createAction, ActionType, getType} from 'typesafe-actions';
import {AssigneeWithRole, Role, User, ShiftResultInfo, ShiftRequestType} from 'src/types';
import client from 'src/apollo';
import moment from 'moment';
import ShiftFragment from 'src/gql/fragment/ShiftFragment';
import {Actions} from '../reducers';

export const actions = {
  setSelfShift: createAction('tradeShift/SET_SELF', (resolve) => (shift: AssigneeWithRole) => resolve({shift})),
  clearSelfShift: createAction('tradeShift/CLEAR_SELF'),
  setOtherShift: createAction('tradeShift/SET_OTHER', (resolve) => (shift: AssigneeWithRole) => resolve({shift})),
  clearOtherShift: createAction('tradeShift/CLEAR_OTHER'),
  setTargetUser: createAction('tradeShift/SET_TARGET_USER', (resolve) => (user: User) => resolve({user})),
  clearTargetUser: createAction('tradeShift/CLEAR_TARGET_USER'),
  setPreferredRole: createAction('tradeShift/SET_ROLE', (resolve) => (role: Role) => resolve({role})),
  clearPreferredRole: createAction('tradeShift/CLEAR_ROLE', (resolve) => (role: Role) => resolve({role})),
  setAllPreferredRole: createAction('tradeShift/SET_ALL_ROLE', (resolve) => (roles: Role[]) => resolve({roles})),
  clearAllPreferredRole: createAction('tradeShift/CLEAR_ALL_ROLE'),
  setOfferTypes: createAction('tradeShift/SET_OFFER_TYPE', (resolve) => (type: ShiftRequestType) => resolve({type})),
  resetAll: createAction('tradeShift/RESET'),
};

export type TradeShiftActions = ActionType<typeof actions>;

export const initialState: TradeShiftState = {
  selfShift: null,
  otherShift: null,
  filterByUser: null,
  filterByRoles: [],
  filterOfferTypes: (() => {
    const initialSet: Set<ShiftRequestType> = new Set();
    initialSet.add('give_away');
    initialSet.add('swap');
    return initialSet;
  })(),
};

export interface TradeShiftState {
  selfShift: AssigneeWithRole | null;
  otherShift: AssigneeWithRole | null;
  filterByUser: User | null;
  filterByRoles: Role[];
  filterOfferTypes: Set<ShiftRequestType>;
}

function formatShiftWithRealEndTime(shift: AssigneeWithRole): AssigneeWithRole {
  // scheudle vm might be splitted from full calendar view,
  // get the real start and end time from data model from apollo store

  const targetShiftFragment: ShiftResultInfo | null = client.readFragment({
    id: `Shift:${shift.assignee.shiftId}`,
    fragment: ShiftFragment,
  });

  const newSelfShift = {
    ...shift,
    assignee: {
      ...shift.assignee,
      startTime: moment(targetShiftFragment?.startDate),
      endTime: moment(targetShiftFragment?.endDate),
    },
  };

  return newSelfShift;
}

export default (state: TradeShiftState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(actions.setSelfShift):
      let newSelfShift = formatShiftWithRealEndTime(action.payload.shift);
      return {
        ...state,
        selfShift: {...newSelfShift},
      };

    case getType(actions.clearSelfShift):
      return {
        ...state,
        selfShift: null,
      };

    case getType(actions.setOtherShift):
      let newOtherShift = formatShiftWithRealEndTime(action.payload.shift);
      return {
        ...state,
        otherShift: {...newOtherShift},
      };

    case getType(actions.clearOtherShift):
      return {
        ...state,
        otherShift: null,
      };

    case getType(actions.setTargetUser):
      return {
        ...state,
        filterByUser: action.payload.user,
      };

    case getType(actions.clearTargetUser):
      return {
        ...state,
        filterByUser: null,
      };

    case getType(actions.setPreferredRole):
      return {
        ...state,
        filterByRoles: [...state.filterByRoles, action.payload.role],
      };

    case getType(actions.clearPreferredRole):
      return {
        ...state,
        filterByRoles: state.filterByRoles.filter((roles) => roles.roleId !== action.payload.role.roleId),
      };

    case getType(actions.setAllPreferredRole):
      return {
        ...state,
        filterByRoles: [...action.payload.roles],
      };

    case getType(actions.clearAllPreferredRole):
      return {
        ...state,
        filterByRoles: [],
      };

    case getType(actions.setOfferTypes):
      let targetType = action.payload.type;
      let newSet = new Set(state.filterOfferTypes);
      newSet.has(targetType) ? newSet.delete(targetType) : newSet.add(targetType);
      return {
        ...state,
        filterOfferTypes: newSet,
      };

    case getType(actions.resetAll):
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
