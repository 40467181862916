import React from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import {ActionButtonWrapper} from 'src/styles/styled-components/Button';
import {ActiveEscalation, EscalationLadder} from 'src/types';
import {toast} from 'react-toastify';
import client from 'src/apollo';
import ActivateDepartmentsEscalationMutation from 'src/gql/mutation/ActivateDepartmentsEscalationMutation';
import TriggerEscalationPreview from 'src/components/TriggerEscalationPreview';
import updateActiveEscalationsQueryList from 'src/utils/updateActiveEscalationsQueryList';
import {ScrollLayout} from 'src/styles/styled-components/ModalStyleComponents';
import sleep from 'src/utils/sleep';
import GetChatQuery from 'src/gql/query/GetChatQuery';

const IntroText = styled.div`
  line-height: 24px;
`;

interface Props {
  selectedEscalationLadder: EscalationLadder;
  message: string;
  handlePrev: () => void;
  closeModal: () => void;
}

interface ActivateDepartmentsEscalationMutationResult {
  escalation: {
    ladder: {
      activate: ActiveEscalation;
    };
  };
}

const LocatingEscalationPreviewStep = ({selectedEscalationLadder, message, handlePrev, closeModal}: Props) => {
  const [startingLevel, setStartingLevel] = React.useState(1);
  const [isLoading, setLoading] = React.useState(false);

  const handleSendEscalation = async () => {
    try {
      setLoading(true);

      const result = await client.mutate<ActivateDepartmentsEscalationMutationResult>({
        mutation: ActivateDepartmentsEscalationMutation,
        variables: {
          escalationLadderId: selectedEscalationLadder.id,
          startingLevel,
          message,
        },
      });
      const resultChatId = result?.data?.escalation.ladder.activate.chat.id;
      const resultEscalationID = result?.data?.escalation.ladder.activate.id;

      updateActiveEscalationsQueryList(resultEscalationID ?? 0);

      closeModal();
      window.routerHistory.push(`/messenger/${resultChatId}`);

      // TODO: remove this when backend get the socket working
      await sleep(5000);

      client.query({
        query: GetChatQuery,
        variables: {chatId: resultChatId},
        fetchPolicy: 'network-only',
      });
    } catch (e: any) {
      console.error(e);
      if (e?.networkError?.result?.errors && e?.networkError?.result?.errors[0]?.code === 'EscalationInProgress') {
        toast.error('This department already has an active escalation');
      } else {
        toast.error('Failed to send escalation, please check your internet connection and try again');
      }
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <IntroText>
        Your escalation will start from <strong>level {startingLevel}</strong>. To pick a new starting point, click on
        the desired level.
      </IntroText>
      <ScrollLayout>
        <TriggerEscalationPreview
          selectedEscalationLadder={selectedEscalationLadder}
          startingLevel={startingLevel}
          setStartingLevel={setStartingLevel}
        />
      </ScrollLayout>

      <ActionButtonWrapper>
        <Button variant="outlined" disabled={isLoading} onClick={handlePrev}>
          Previous
        </Button>
        <Button disabled={isLoading} variant="contained" color="secondary" onClick={handleSendEscalation}>
          {isLoading ? 'Sending...' : 'Send escalation'}
        </Button>
      </ActionButtonWrapper>
    </React.Fragment>
  );
};

export default LocatingEscalationPreviewStep;
