import React from 'react';
import {FormikProps} from 'formik';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import JoinOrganizationStepController, {
  IntendedOrganization,
} from 'src/pages/JoinOrganizationPage/join-organization/JoinOrganizationStepController';
import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress';
import DeafaultOrg from 'src/svgs/DefaultOrganizationIcon';
import JoinOrgFormStepZero from 'src/pages/JoinOrganizationPage/join-organization/JoinOrgFormStepZero';
import JoinOrgFormmStepOne from 'src/pages/JoinOrganizationPage/join-organization/JoinOrgFormStepOne';
import JoinOrgFormStepTwo from 'src/pages/JoinOrganizationPage/join-organization/JoinOrgFormStepTwo';

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: 0.5em;
` as React.ComponentType<CircularProgressProps>;

const LoadingText = styled.div`
  color: dimgrey;
  margin-top: 1em;
  font-size: 12px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 1em;
`;

const OrganizationWrapper = styled.div`
  text-align: center;
  margin-top: 90px;
  margin-bottom: 30px;
  img {
    width: 300px;
    height: 100px;
    object-fit: contain;
  }
`;

const InnerFormLayout = ({
  formikProp,
  step,
  isLoading,
  validationCodeProps,
  resendCode,
  isJoinUsingInviteCode,
  intendedOrganization,
  joinOrganizationErrMsg,
}) => {
  switch (step) {
    case 0:
      return (
        <JoinOrgFormStepZero
          formikProps={formikProp}
          isLoading={isLoading}
          isJoinUsingInviteCode={isJoinUsingInviteCode}
        />
      );
    case 1:
      return (
        <JoinOrgFormmStepOne
          formikProps={formikProp}
          validationCodeProps={validationCodeProps}
          isLoading={isLoading}
          resendCode={resendCode}
        />
      );
    case 2:
      return (
        <JoinOrgFormStepTwo
          isLoading={isLoading}
          formikProps={formikProp}
          isJoinUsingInviteCode={isJoinUsingInviteCode}
          intendedOrganization={intendedOrganization}
          joinOrganizationErrMsg={joinOrganizationErrMsg}
        />
      );
    default:
      return <div />;
  }
};

const VerticalMultiStepForm = (props: FormikProps<any>) => {
  const {values} = props;
  const [isJoinUsingInviteCode, setJoinUsingInviteCode] = React.useState(false);

  const steps = [
    isJoinUsingInviteCode ? 'Enter Organization Code' : 'Enter Organization Email',
    'Input Verification Code',
    JoinOrganizationStepController.getCreatingNewOrgFalg() ? 'Create Organization' : 'Confirm',
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [isAddressVerified, setAddressVerified] = React.useState(false);
  const [validationCodeArray, setValidationCodeArray] = React.useState(['', '', '', '', '', '']);
  const [validationErrorMsg, setValidationErrorMsg] = React.useState('');
  const [joinOrganizationErrMsg, setJoinOrganizationErrMsg] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [intendedOrganization, setIntendedOrganization] = React.useState<IntendedOrganization | null>(null);
  const [challengeId, setChallengeId] = React.useState<string>('');

  const skippingVerificationStep = isAddressVerified || isJoinUsingInviteCode;

  function handleNext() {
    if (!intendedOrganization) {
      console.error('intendedOrganization missing');
    }
    JoinOrganizationStepController.stepController(
      {
        activeStep,
        intendedOrganization,
        validationCodeArray,
        isJoinUsingInviteCode,
        setActiveStep,
        setLoading,
        setAddressVerified,
        setValidationErrorMsg,
        setJoinOrganizationErrMsg,
        setIntendedOrganization,
        challengeId,
        setChallengeId,
      },
      props,
    );
  }

  function handleBack() {
    if (skippingVerificationStep) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    setValidationCodeArray(['', '', '', '', '', '']);
    setValidationErrorMsg('');
    setJoinOrganizationErrMsg('');
  }

  function resendCode() {
    setValidationErrorMsg('');
    JoinOrganizationStepController.resendValidationCode({
      email: values.email,
      setLoading,
      setValidationErrorMsg,
      setChallengeId,
    });
  }

  return (
    <div>
      <OrganizationWrapper>
        {intendedOrganization && intendedOrganization.image ? (
          <img src={intendedOrganization.image} alt="organization logo" />
        ) : (
          <DeafaultOrg />
        )}
      </OrganizationWrapper>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel style={{textDecoration: index === 1 && skippingVerificationStep ? 'line-through' : 'unset'}}>
              {label}
            </StepLabel>
            <StepContent>
              <InnerFormLayout
                formikProp={props}
                step={index}
                isJoinUsingInviteCode={isJoinUsingInviteCode}
                intendedOrganization={intendedOrganization}
                joinOrganizationErrMsg={joinOrganizationErrMsg}
                isLoading={isLoading}
                resendCode={resendCode}
                validationCodeProps={{
                  validationErrorMsg,
                  validationCodeArray,
                  setValidationCodeArray,
                }}
              />
              {isLoading && (
                <LoadingText>
                  <StyledCircularProgress color="inherit" size={12} />
                  Processing request...
                </LoadingText>
              )}
              <ButtonsWrapper>
                {activeStep !== 0 && (
                  <Button disableTouchRipple disabled={activeStep === 0 || isLoading} onClick={handleBack}>
                    Back
                  </Button>
                )}
                {activeStep === 0 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{fontWeight: 'bold', marginRight: '1em'}}
                    disableTouchRipple
                    onClick={() => setJoinUsingInviteCode((prevState) => !prevState)}
                  >
                    {isJoinUsingInviteCode ? 'Use Organization Email' : 'Use Organization Code'}
                  </Button>
                )}
                <Button
                  disableTouchRipple
                  variant="contained"
                  color="primary"
                  disabled={
                    isLoading || (activeStep === 1 && validationCodeArray.some((num) => num === '' || num.length > 1))
                  }
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Confirm Organization' : 'Next'}
                </Button>
              </ButtonsWrapper>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default React.memo(VerticalMultiStepForm);
