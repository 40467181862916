import * as React from 'react';
import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import EscalationProgressModal from 'src/components/EscalationProgressModal';
import {CheckActiveEscalationExists} from 'src/gql/query/FetchAllActiveEscalations';
import {useQuery} from '@apollo/react-hooks';
import {QueryResult} from 'react-apollo';
import {GetAllActiveEscalationsResult} from 'src/types';

const StyledAlert = styled(Alert)`
  border-bottom: 1px solid lightgrey;
  border-radius: 0 !important;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: bold !important;
  display: flex;
  height: 64px;
  background-color: ${(props) => props.theme.colors.priorityYellow} !important;
  align-items: center;
  justify-content: center;
`;

const EscalationBanner = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const {loading, error, data}: QueryResult<GetAllActiveEscalationsResult> = useQuery(CheckActiveEscalationExists);

  if (loading || error) return null;

  const activeEscalations = data?.me.activeEscalations?.filter(
    (activeEscalation) => activeEscalation.state === 'active',
  );

  if (activeEscalations && activeEscalations.length > 0) {
    return (
      <React.Fragment>
        <StyledAlert severity="error" onClick={() => setModalOpen(true)}>
          You have {activeEscalations.length} escalations in progress. Click for more information.
        </StyledAlert>
        {isModalOpen && (
          <EscalationProgressModal activeEscalationID={0} isOpen={isModalOpen} closeModal={() => setModalOpen(false)} />
        )}
      </React.Fragment>
    );
  }

  return null;
};

export default EscalationBanner;
