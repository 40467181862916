import React from 'react';
import styled from '@emotion/styled';
import ChatIcon from 'src/svgs/ChatIcon';
import Button from '@mui/material/Button';
import {toast} from 'react-toastify';
import createNewChat from 'src/utils/messengerHelper/createNewChat';
import {CustomLoader} from 'src/components/LoadingDiv';
import {User} from 'src/types';
import PagerIcon from 'src/svgs/PagerIcon';
import RedCalendar from 'src/svgs/RedCalendar';
import {MESSENGER} from 'src/constants/routerPathName';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import getAddressOfType from 'src/utils/getAddressOfType';
import {PagerType} from 'src/constants/user';
import SmsIcon from '@mui/icons-material/SmsOutlined';
import getPhoneNumbers from 'src/utils/getPhoneNumbers';
import {DIR_SYNC_INFO, LEARN_MORE, SMS_ACTION_BUTTON_TEXT} from 'src/constants/strings';
import WarningBanner from 'src/components/shared/WarningBanner';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import {StyledHrefLink} from 'src/styles/styled-components/PopoverMenuComponents';

const ActionButtonsSection = styled.div`
  margin-bottom: 20px;
  margin-top: 44px;
  button {
    border-radius: 8px;
    height: 40px;
    text-transform: capitalize;
    margin-right: 16px;
    margin-bottom: 10px;
    svg {
      transform: scale(0.8);
    }
    span {
      padding-left: 5px;
    }
    .material-icons {
      font-size: 1em;
    }
  }
`;

const AlternativeContactSection = styled.div`
  margin-bottom: 1.5em;
  color: darkgray;
  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: black;
    }
  }
`;

const StyledActionButton = styled(Button)`
  color: '#4A4A4A';
  span {
    text-transform: none;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }
`;

const HypercareContactMessageText = styled.span`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #ff3e55;
`;

interface Props {
  user: User | any;
  isSelf: boolean;
  isEditMode: boolean;
  hasPublicPager: boolean;
  setShowCallbackPageModal: () => void;
  setEditMode: () => void;
  handleSubscriptionModal: () => void;
  setShowPagerModal: () => void;
  setPagerSelected: (string) => void;
  setAddressSelected: (string) => void;
  setShowSMSModal: () => void;
}

const ActionButtons = ({
  user,
  isSelf,
  isEditMode,
  hasPublicPager,
  setShowCallbackPageModal,
  setEditMode,
  handleSubscriptionModal,
  setShowPagerModal,
  setPagerSelected,
  setAddressSelected,
  setShowSMSModal,
}: Props) => {
  const [loadingNewChatBtn, setLoadingNewChatBtn] = React.useState(false);

  const {workStatus} = user;

  const hasAlternativeContact = Boolean(workStatus.alternateContact);

  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);

  const handleMessageOnHCButtonPressed = () => {
    if (!isSelf) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.contactsProfileMessageOnHypercareButtonPressed,
      });
    }
    tryCreateNewChat(true);
  };

  const handleIsEditButtonPressed = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileEditButtonPressed,
    });
    setEditMode();
  };

  async function tryCreateNewChat(isAlternative: boolean) {
    try {
      setLoadingNewChatBtn(true);
      const newChatId = isAlternative
        ? await createNewChat([user] as User[])
        : await createNewChat([workStatus.alternateContact] as User[]);

      if (!newChatId) throw new Error('failed');
      if (!isAlternative) {
        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.alternativeContactFromBanner,
          params: {
            user_id: workStatus.alternateContact.id,
            user_status: workStatus,
          },
        });
      }
      window.routerHistory.push(`/${MESSENGER}/${newChatId}`);
    } catch (e) {
      setLoadingNewChatBtn(false);
      console.error(e);
      toast.error('Failed to start new chat, please check your internet connection and try again');
    }
  }

  function getAlternativeContactCopy() {
    if (!hasAlternativeContact) return null;
    const {alternateContact} = workStatus;
    return `${alternateContact.firstName} ${alternateContact.lastName} @${alternateContact.username}`;
  }

  const handlePagerMixpanel = (pagerType: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.contactsGenericPagerButtonPressed,
    });
  };

  return (
    <React.Fragment>
      <AlternativeContactSection>
        <span data-testid="userProfileWrapperWorkStatusMessage">{workStatus.message} </span>
        {hasAlternativeContact && (
          <span data-testid="userProfileWrapperAccountStatus">
            {workStatus.alternateContact.accountStatus && workStatus.alternateContact.status !== 'shell' ? (
              <span onClick={() => tryCreateNewChat(false)}>(click here to message {getAlternativeContactCopy()})</span>
            ) : (
              <span onClick={() => window.routerHistory.push(`/${MESSENGER}/user/${workStatus.alternateContact.id}`)}>
                (click here to view {getAlternativeContactCopy()})
              </span>
            )}
          </span>
        )}
      </AlternativeContactSection>

      <ActionButtonsSection>
        {ldapDirectorySyncFlag && isSelf && isEditMode && user?.isDirectorySynced && (
          <WarningBanner
            title={DIR_SYNC_INFO}
            description={
              <StyledHrefLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.hypercare.com/hc/en-us/articles/19501293376525-Why-can-t-I-edit-some-fields-in-a-user-s-profile-"
              >
                <span>{LEARN_MORE}</span>
              </StyledHrefLink>
            }
          />
        )}
        {user.accountStatus && user.accountStatus !== 'shell' && !isEditMode && (
          <Button
            disabled={loadingNewChatBtn}
            onClick={() => handleMessageOnHCButtonPressed()}
            disableTouchRipple
            variant="outlined"
            color="secondary"
            style={{
              minHeight: '40px',
              border: '2px solid #FF3E55',
            }}
          >
            {loadingNewChatBtn ? (
              <CustomLoader />
            ) : (
              <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0}}>
                <img
                  style={{width: '21px', height: '25px'}}
                  src="/assets/hypercare-icon.svg"
                  alt="hypercare logo with text"
                />
                <HypercareContactMessageText>Message on Hypercare</HypercareContactMessageText>
              </span>
            )}
          </Button>
        )}
        {!isEditMode && getAddressOfType(user.addresses).length > 0 && (
          <StyledActionButton
            disabled={false}
            onClick={() => {
              const address = getAddressOfType(user.addresses)[0];
              setShowPagerModal();
              if (!address) return;
              setPagerSelected(address.type);
              setAddressSelected(address.address);
              handlePagerMixpanel(address.type!);
            }}
            disableTouchRipple
            variant="outlined"
            style={{minHeight: '40px'}}
            data-testid="alpha-numeric-btn"
            id="alpha-numeric-btn"
          >
            <React.Fragment>
              <PagerIcon color="#4A4A4A" />
              <span>{`Send ${
                getAddressOfType(user.addresses)[0].type === PagerType.ALPHANUMERIC_PAGER
                  ? 'an alphanumeric page'
                  : 'a numeric page'
              }`}</span>
            </React.Fragment>
          </StyledActionButton>
        )}
        {!isEditMode && getPhoneNumbers(user.addresses).length > 0 && (
          <StyledActionButton
            disableTouchRipple
            variant="outlined"
            onClick={() => {
              setShowSMSModal();
              setAddressSelected(getPhoneNumbers(user.addresses)[0].address);
            }}
            style={{width: 'auto'}}
          >
            <SmsIcon style={{color: '#4A4A4A'}} />
            <span>{SMS_ACTION_BUTTON_TEXT}</span>
          </StyledActionButton>
        )}

        {isSelf && !isEditMode && (
          <Button
            disableTouchRipple
            variant="outlined"
            color="secondary"
            onClick={() => handleSubscriptionModal()}
            style={{width: 'auto'}}
          >
            <RedCalendar />
            <span>Subscribe to personal schedule</span>
          </Button>
        )}
      </ActionButtonsSection>
    </React.Fragment>
  );
};

export default React.memo(ActionButtons);
