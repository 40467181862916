import React from 'react';
import {
  SchedulingVariableContext,
  SchedulingVariableContextInterface,
} from 'src/pages/SchedulingPage/scheduling/SchedulingVariableProvider';
import MonthSwitcher from 'src/pages/SchedulingPage/schedule-layout/MonthSwitcher';
import moment from 'moment';
import {FetchMyCalendarShiftsResponse, MyShift} from 'src/types';
import MycalendarRefetchProvider from 'src/pages/SchedulingPage/action-calendar/mycalendar-section/MycalendarRefetchProvider';
import {useQuery} from '@apollo/react-hooks';
import FetchAllShifts from 'src/gql/query/FetchAllShifts';
import LoadingDiv from 'src/components/LoadingDiv';
import MyCalendarActionCalendarLayout from 'src/pages/SchedulingPage/action-calendar/MyCalendarActionCalendarLayout';
import styled from '@emotion/styled';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';
import Button from '@mui/material/Button';
import SubscriptionModal from 'src/components/SubscriptionModal';
import isEndTimeMinutesBeforeStartTime from 'src/utils/scheduling/isEndTimeMinutesBeforeStartTime';

interface Props extends SchedulingVariableContextInterface {}

const CalendarSectionWrapper = styled.div`
  max-width: 100%;
  min-width: min-content;
  padding: 1.5em 1.5em 1.5em 1.5em;
  flex: 3;
  height: min-content;
`;

const StyledSubscriptionButton = styled(Button)`
  float: right !important;
  text-transform: none !important;
  .MuiButton-label {
    font-family: Nunito Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 24px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #00859a;
  }
`;

const MyCalendarLayout = ({currentDepartmentId, startOfMonthUTC, endOfMonthUTC, setScheduleQueryVariables}: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchMyCalendarShiftsResult = useQuery<FetchMyCalendarShiftsResponse>(FetchAllShifts, {
    variables: {
      startDate: startOfMonthUTC,
      endDate: endOfMonthUTC,
      limit: 100,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  if (fetchMyCalendarShiftsResult.loading) return <LoadingDiv />;

  const momentUTCStartDate = moment(startOfMonthUTC).utc();
  const myShifts = transformMyShifts(fetchMyCalendarShiftsResult.data?.me.shifts.shifts ?? []);

  function onSelectMonth(startTime: string, endTime: string) {
    setScheduleQueryVariables((prevState) => ({
      ...prevState,
      startOfMonthUTC: startTime,
      endOfMonthUTC: endTime,
    }));
  }

  return (
    <MycalendarRefetchProvider refetch={fetchMyCalendarShiftsResult.refetch}>
      <CalendarSectionWrapper>
        <StyledSubscriptionButton variant="outlined" onClick={() => setOpen(true)}>
          Subscribe
        </StyledSubscriptionButton>
        <MonthSwitcher onSelectMonth={onSelectMonth} startDate={momentUTCStartDate} />
        <MyCalendarActionCalendarLayout endDateISOstring={endOfMonthUTC} shifts={myShifts} />
        {open && <SubscriptionModal openModal={open} handleCloseModal={handleClose} />}
      </CalendarSectionWrapper>
    </MycalendarRefetchProvider>
  );
};

// I have no idea why this needs to be done, just copying it over from a refactor
const transformMyShifts = (shifts: Array<MyShift>) => {
  return shifts
    .filter((shift) => shift.status === 'active')
    .map((shift) => {
      let isMidnightShift =
        moment(shift.startDate).format('HH:mm') === '00:00' && moment(shift.endDate).format('HH:mm') === '00:00';
      return {
        ...shift,
        start: moment(shift.startDate).toISOString(),
        end: moment(shift.endDate)
          .add(isMidnightShift ? '1' : '0', 'ms')
          .toISOString(),
        allDay: !isEndTimeMinutesBeforeStartTime(moment(shift.startDate), moment(shift.endDate)),
      };
    });
};

export default (props) => (
  <SchedulingVariableContext.Consumer>
    {(contextProps) => <MyCalendarLayout {...contextProps} {...props} />}
  </SchedulingVariableContext.Consumer>
);
