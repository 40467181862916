import * as React from 'react';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import {
  ChevronHolder,
  TableWrapper,
  TableCollapseHeader,
  StyledTableContainer,
  TableCellFlexContainer,
} from 'src/pages/SchedulingPage/request-layout/TableStyles';
import Chevron from 'src/svgs/Chevron';
import {ShiftRequestPayload} from 'src/types';
import TableHeader from 'src/pages/SchedulingPage/request-layout/TableHeader';
import SwapShiftIcon from 'src/svgs/SwapShiftIcon';
import MarketplaceIcon from 'src/svgs/MarketplaceIcon';
import convertRequestTableData from 'src/utils/scheduling/convertRequestTableData';
import TableActionCell from 'src/pages/SchedulingPage/request-layout/TableActionCell';

/** Table content for SWAP
 * Request details:
 *  .desiredShift to .surrenderingShift
 *
 * Date requested:
 *  .createdAt
 *
 * Requested by:
 *  .postedBy
 *
 * Reason for request:
 *  .notes(The lastest notes with active status)
 *
 * Action:
 *  - active
 *   >if requested by me then Withdraw request
 *   >else show decline or accept
 *
 *  - previous
 *   > the format is always Accepted/declined on MM/DD/YYYY, with lastest note based on the requests' .status
 *   > if active show checkmark, else x
 */

interface Props {
  shiftRequestPayloads: ShiftRequestPayload[];
  isPending: boolean;
  defaultOpen: boolean;
}

const RequestTable = ({shiftRequestPayloads, isPending, defaultOpen}: Props) => {
  const [open, setOpen] = React.useState(defaultOpen);

  React.useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  function sortByCreateAt(payloadA: ShiftRequestPayload, payloadB: ShiftRequestPayload) {
    let startTimeA = moment(payloadA.createdAt);
    let startTimeB = moment(payloadB.createdAt);

    if (startTimeA.isBefore(startTimeB)) return 1;
    if (startTimeA.isAfter(startTimeB)) return -1;
    return 0;
  }

  return (
    <TableWrapper>
      <TableCollapseHeader onClick={() => setOpen((prev) => !prev)}>
        <span>{isPending ? 'Needs Actions' : 'Previous'}</span>
        <ChevronHolder>
          <Chevron transform={open ? 180 : 0} />
        </ChevronHolder>
      </TableCollapseHeader>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <StyledTableContainer component={Paper}>
          <Table aria-label="Request table">
            <TableHeader />
            <TableBody>
              {shiftRequestPayloads.sort(sortByCreateAt).map((shiftRequest, index) => {
                let tableData = convertRequestTableData(shiftRequest);
                let {details, dateRequested, requestedBy, reason, isSelf} = tableData;
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <TableCellFlexContainer>
                        <div>
                          {shiftRequest.type === 'swap' && <SwapShiftIcon />}
                          {shiftRequest.type === 'give_away' && <MarketplaceIcon />}
                        </div>
                        <div>{details}</div>
                      </TableCellFlexContainer>
                    </TableCell>
                    <TableCell align="left">{dateRequested}</TableCell>
                    <TableCell align="left">{requestedBy}</TableCell>
                    <TableCell align="left">{reason}</TableCell>
                    <TableCell align="left">
                      <TableActionCell shiftRequest={shiftRequest} isSelf={isSelf} isPending={isPending} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Collapse>
    </TableWrapper>
  );
};

export default React.memo(RequestTable);
