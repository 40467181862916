import React from 'react';
import {useQuery} from 'react-apollo';
import {GET_MESSAGE_TEMPLATE_REPORT} from 'src/gql/query/TemplateReportQuery';
import FormHelperText from '@mui/material/FormHelperText';
import {Box, Grid, useTheme, Button, Radio, FormControlLabel, TextField} from '@mui/material';
import TemplateModal from 'src/components/TemplateReport/TemplateModal';
import FetchContactList from 'src/gql/query/FetchContactList';
import {ContactQueryResult} from 'src/types/Contact';
import SelectAvatar from 'src/components/TemplateReport/SelectAvatar';
import CircularProgress from '@mui/material/CircularProgress';
import RadioGroup from '@mui/material/RadioGroup';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';
import CalendarIcon from 'src/svgs/CalendarIcon';
import {SmallLoader} from 'src/components/LoadingDiv';
import styled from '@emotion/styled';
import {Formik, FormikProps} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {FieldInputLabel, PrimaryButton, SecondaryButton} from 'src/styles/styled-components/StyledMaterialComponents';
import {TemplateReport, GetAllTemplateResult} from 'src/types';
import {DateRange} from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

const PREFIX = 'TemplateReportForm';

const classes = {
  root: `${PREFIX}-root`,
  checked: `${PREFIX}-checked`,
  form: `${PREFIX}-form`,
  calendarContainer: `${PREFIX}-calendarContainer`,
  calendar: `${PREFIX}-calendar`,
  radioLabel: `${PREFIX}-radioLabel`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },

  [`& .${classes.calendarContainer}`]: {
    paddingRight: 6,
    marginTop: theme.spacing(3),
    position: 'relative',
  },

  [`& .${classes.calendar}`]: {
    position: 'absolute',
    right: '4%',
    top: '54%',
    height: '18px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
  },

  [`& .${classes.radioLabel}`]: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

export const DateTextField = styled(TextField)`
  .MuiOutlinedInput-inputMarginDense {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #222222;
    border-radius: 2px;
    padding: 12px 14px;
  }
  .MuiOutlinedInput-inputMarginDense.Mui-disabled {
    background-color: hsl(0, 0%, 95%) !important;
    border-color: hsl(0, 0%, 80%) !important;
    opacity: 1 !important;
  }
  .Mui-focused fieldset {
    border-color: #00859a !important;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: 0 0 0 1px #00859a !important;
  }
`;

export const RadioButton = (props) => <Radio color="default" {...props} />;

export const RadioLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
`;

interface FormValue {
  templateIds: string[];
  startDate: string;
  endDate: string;
  senderUserIds: string[];
  sender: string;
  recipientUserIds: string[];
  recipient: string;
}

interface IDateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface Props {
  handleTemplateReport: (formValue: FormValue) => Promise<any>;
}

const templateReportValidationSchema = Yup.object().shape({
  sender: Yup.string(),
  recipient: Yup.string(),
  templateIds: Yup.string().required('This field is required!'),
  senderUserIds: Yup.string().when('sender', {
    is: 'other',
    then: Yup.string().required('This field is required!'),
  }),
  recipientUserIds: Yup.string().when('recipient', {
    is: 'other',
    then: Yup.string().required('This field is required!'),
  }),
});

const TemplateReportForm = ({handleTemplateReport}: Props) => {
  const theme = useTheme();

  const [isTouch, setIsTouch] = React.useState(false);
  const ref = React.useRef<any>(null);
  const fromRef = React.useRef<any>(null);
  const toRef = React.useRef<any>(null);
  const [open, setOpen] = React.useState(false);
  const [dateRangeVisible, setDateRangeVisible] = React.useState(false);
  const [internalDateRange, setInternalDateRange] = React.useState<IDateRange[]>([
    {
      startDate: moment().subtract(7, 'day').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const templateData = useQuery<GetAllTemplateResult>(GET_MESSAGE_TEMPLATE_REPORT, {
    variables: {
      organizationId: getCurrentOrganizationId(),
    },
  });

  const usersData = useQuery<ContactQueryResult>(FetchContactList);

  React.useEffect(() => {
    usersData.refetch();
    templateData.refetch();
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [usersData, templateData]);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setDateRangeVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      setDateRangeVisible(false);
    }
  };

  const handleClickOpen = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.exportTemplateMessageReportQuitEditingButtonPressed,
    });
    if (isTouch) {
      setOpen(true);
    } else {
      window.routerHistory.push('/messenger/home');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showDatePicker = (targetInput) => {
    targetInput === 'from' ? fromRef.current.focus() : toRef.current.focus();
    setDateRangeVisible(true);
  };

  const handleTypeChange = (selected, event, setFieldValue, options) => {
    let values = [];
    if (event.action === 'select-option' && event.option.value === 'all') {
      values = options;
    } else if (
      (event.action === 'deselect-option' && event.option.value === 'all') ||
      (event.action === 'remove-value' && event.removedValue.value === 'all')
    ) {
      values = [];
    } else if (event.action === 'deselect-option' || event.action === 'remove-value') {
      values = selected ? selected.filter((select) => select.value !== 'all') : [];
    } else if (selected && selected.length === options.length - 1) {
      values = options;
    } else {
      values = selected;
    }

    setFieldValue(event.name, values);
    setIsTouch(true);
  };

  const formatFormValue = ({
    templateIds,
    startDate,
    endDate,
    senderUserIds,
    sender,
    recipientUserIds,
    recipient,
  }: FormValue): TemplateReport => {
    const senderIds =
      sender === 'you'
        ? [getParsedAuthInfo()?.user.id]
        : senderUserIds.filter((sender: any) => sender.value !== 'all').map((sender: any) => sender.value);
    const recipientIds =
      recipient === 'you'
        ? [getParsedAuthInfo()?.user.id]
        : recipientUserIds
            .filter((recipient: any) => recipient.value !== 'all')
            .map((recipient: any) => recipient.value);

    return {
      templateIds: templateIds
        .filter((template: any) => template.value !== 'all')
        .map((template: any) => template.value),
      startDate: moment(startDate, 'DD/MMM/YYYY').startOf('day').toISOString(true),
      endDate: moment(endDate, 'DD/MMM/YYYY').endOf('day').toISOString(true),
      senderUserIds: senderIds,
      sender: sender,
      recipientUserIds: recipientIds,
      recipient: recipient,
    };
  };

  const handleDate = (setFieldValue) => {
    const startDate = moment(internalDateRange[0].startDate).format('DD/MMM/YYYY');
    const endDate = moment(internalDateRange[0].endDate).format('DD/MMM/YYYY');
    setDateRangeVisible(false);
    setFieldValue('startDate', startDate);
    setFieldValue('endDate', endDate);
    setIsTouch(true);
  };

  const filterListByID = (list) => {
    return list.length === 1 ? list[0].value : list.map((elem) => elem.value).filter((elem) => elem !== 'all');
  };

  const handleUser = (event, setFieldValue) => {
    let selectName = event.target.name === 'sender' ? 'recipient' : 'sender';
    let selectValue = event.target.value === 'you' ? 'other' : 'you';
    setFieldValue(event.target.name, event.target.value);
    setFieldValue(selectName, selectValue);
  };

  if (usersData.loading || templateData.loading) return <SmallLoader />;

  if (usersData.error || templateData.error) {
    return (
      <div className="networkErrorHolder">
        <span>An Error Occurred, Please Check Your Internet Connection And Try To Refresh The Page.</span>
        <p>If The Problem Persists, Please Let Us Know By Contacting Hypercare Support.</p>
      </div>
    );
  }

  const allUsers = usersData?.data?.colleagues ?? [];
  const allTemplates = templateData?.data?.locating.organization.messageTemplates.templates;
  const userOption = allUsers.map((user) => {
    return {
      value: user.id,
      label: user.firstname + ' ' + user.lastname,
      avatar: user.profilePic,
      title: user.role,
    };
  });

  const templateOption =
    allTemplates?.map((template) => {
      return {
        value: template.id,
        label: template.formTitle,
      };
    }) ?? [];

  const initialValues: FormValue = {
    templateIds: [],
    startDate: moment().subtract(7, 'day').format('DD/MMM/YYYY'),
    endDate: moment().format('DD/MMM/YYYY'),
    senderUserIds: [],
    sender: 'you',
    recipientUserIds: [],
    recipient: 'other',
  };

  return (
    <Root>
      <Formik
        initialValues={initialValues}
        validationSchema={templateReportValidationSchema}
        onSubmit={(values, {resetForm, setFieldError, setSubmitting}) => {
          const formData = formatFormValue(values);
          handleTemplateReport(formData)
            .then((_) => {
              toast.success('Request received. You will receive the report via a Hypercare message within 5 minutes.', {
                className: 'Toast-Container',
              });
              resetForm();
              setIsTouch(false);
            })
            .catch((e) => {
              let message = '';
              switch (e) {
                case 'network error':
                  setSubmitting(false);
                  message = 'Unknown backend error occurred, please try again';
                  break;
                default:
                  resetForm();
                  message = 'Unknown error occurred, please try again';
              }
              setFieldError('general', message);
              toast.error(message, {
                className: 'Toast-Container',
              });
              setSubmitting(false);
            });
        }}
        render={(props) => {
          const {values, errors, touched, isSubmitting, setFieldValue, handleSubmit} = props as FormikProps<any>;
          const {templateIds, startDate, endDate, senderUserIds, sender, recipientUserIds, recipient} =
            values as FormValue;
          const hasErrors = Object.keys(errors).length > 0;

          const handleExportButtonPressed = () => {
            const user = getParsedAuthInfo()?.user;

            const templateIdsList = filterListByID(templateIds);
            const recipientUserIdsList = recipient === 'you' ? user?.id : filterListByID(recipientUserIds);
            const senderUserIdsList = sender === 'you' ? user?.id : filterListByID(senderUserIds);

            AnalyticsManager.applyAnalytics({
              eventName: EVENTS.exportTemplateMessageReportButtonPressed,
              params: {
                template_ids: templateIdsList,
                start_date: startDate,
                end_date: endDate,
                sender_ids: senderUserIdsList,
                recipient_ids: recipientUserIdsList,
              },
            });
          };

          return (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FieldInputLabel>Template types</FieldInputLabel>
                  <SelectAvatar
                    name="templateIds"
                    option={[{label: `Select all (${templateOption?.length})`, value: 'all'}, ...templateOption]}
                    selectedValue={templateIds}
                    type="normal"
                    placeholder="Search or select templates"
                    touched={touched.templateIds}
                    isSubmitting={isSubmitting}
                    handleSelect={handleTypeChange}
                    setFieldValue={setFieldValue}
                  />
                  {touched.templateIds && (
                    <FormHelperText error={Boolean(errors.templateIds)}>{errors.templateIds}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} className={classes.calendarContainer}>
                  <FieldInputLabel>From</FieldInputLabel>
                  <DateTextField
                    size="small"
                    name="startDate"
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    disabled={isSubmitting ? true : false}
                    onClick={() => setDateRangeVisible(!isSubmitting ? true : false)}
                    value={startDate}
                    inputRef={fromRef}
                  />
                  <i className={classes.calendar} onClick={() => showDatePicker('from')}>
                    <CalendarIcon />
                  </i>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.calendarContainer}>
                  <FieldInputLabel>To</FieldInputLabel>
                  <DateTextField
                    size="small"
                    name="endDate"
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    disabled={isSubmitting ? true : false}
                    onClick={() => setDateRangeVisible(!isSubmitting ? true : false)}
                    value={endDate}
                    inputRef={toRef}
                  />
                  <i className={classes.calendar} onClick={() => showDatePicker('to')}>
                    <CalendarIcon />
                  </i>
                </Grid>

                <Box
                  position="relative"
                  bgcolor={theme.palette.common.white}
                  zIndex={10000}
                  boxShadow={1}
                  marginLeft="1.53rem"
                  width="41.5rem"
                  hidden={!dateRangeVisible}
                  {...{ref: ref}}
                >
                  <Grid item xs={10} sm={5}>
                    <DateRange
                      onChange={(item: any) => setInternalDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={internalDateRange}
                      showSelectionPreview={false}
                      months={2}
                      maxDate={new Date()}
                      direction="horizontal"
                    />
                    <Box justifyContent="flex-end" display="flex" paddingBottom={2} paddingRight={2}>
                      <Button
                        style={{
                          textTransform: 'none',
                          paddingLeft: theme.spacing(4),
                          paddingRight: theme.spacing(4),
                        }}
                        disableElevation
                        disableTouchRipple
                        color="secondary"
                        onClick={() => setDateRangeVisible(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          textTransform: 'none',
                          paddingLeft: theme.spacing(4),
                          paddingRight: theme.spacing(4),
                        }}
                        disableElevation
                        disableTouchRipple
                        color="primary"
                        onClick={() => handleDate(setFieldValue)}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Grid>
                </Box>
                <Grid item xs={12} style={{marginTop: theme.spacing(3)}}>
                  <FieldInputLabel>Senders</FieldInputLabel>
                  <RadioGroup
                    aria-label="sender"
                    name="sender"
                    value={sender}
                    onChange={(e) => handleUser(e, setFieldValue)}
                  >
                    <RadioLabel
                      value="you"
                      control={
                        <RadioButton
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={`${getParsedAuthInfo()?.user.firstname}  ${getParsedAuthInfo()?.user.lastname} (you)`}
                    />
                    <RadioLabel
                      value="other"
                      control={
                        <RadioButton
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Other users"
                    />
                  </RadioGroup>
                  {sender === 'other' && (
                    <SelectAvatar
                      name="senderUserIds"
                      option={[{label: `Select all (${userOption.length})`, value: 'all'}, ...userOption]}
                      selectedValue={senderUserIds}
                      type="withAvtar"
                      placeholder="Search or select users"
                      touched={touched.senderUserIds}
                      isSubmitting={isSubmitting}
                      handleSelect={handleTypeChange}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {touched.senderUserIds && sender === 'other' && (
                    <FormHelperText style={{marginLeft: '4%'}} error={Boolean(errors.senderUserIds)}>
                      {errors.senderUserIds}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} style={{marginTop: theme.spacing(3)}}>
                  <FieldInputLabel>Recipients</FieldInputLabel>
                  <RadioGroup
                    aria-label="recipient"
                    name="recipient"
                    value={recipient}
                    onChange={(e) => handleUser(e, setFieldValue)}
                  >
                    <RadioLabel
                      value="you"
                      control={
                        <RadioButton
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={`${getParsedAuthInfo()?.user.firstname}  ${getParsedAuthInfo()?.user.lastname} (you)`}
                    />
                    <RadioLabel
                      value="other"
                      control={
                        <RadioButton
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Other users"
                    />
                  </RadioGroup>
                  {recipient === 'other' && (
                    <SelectAvatar
                      name="recipientUserIds"
                      option={[{label: `Select all (${userOption.length})`, value: 'all'}, ...userOption]}
                      selectedValue={recipientUserIds}
                      type="withAvtar"
                      placeholder="Search or select users"
                      touched={touched.recipientUserIds}
                      isSubmitting={isSubmitting}
                      handleSelect={handleTypeChange}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {touched.recipientUserIds && recipient === 'other' && (
                    <FormHelperText style={{marginLeft: '4%'}} error={Boolean(errors.recipientUserIds)}>
                      {errors.recipientUserIds}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={10} sm={8} style={{marginTop: theme.spacing(5)}}>
                  <SecondaryButton
                    type="button"
                    disabled={isSubmitting ? true : false}
                    onClick={handleClickOpen}
                    style={{marginRight: theme.spacing(1)}}
                  >
                    Quit editing
                  </SecondaryButton>
                  {!isSubmitting ? (
                    <PrimaryButton
                      type="submit"
                      disabled={hasErrors || isSubmitting}
                      onClick={handleExportButtonPressed}
                    >
                      Export reports
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton type="submit" disabled={true}>
                      <CircularProgress
                        color="inherit"
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                      />{' '}
                      &nbsp; Exporting reports...
                    </PrimaryButton>
                  )}
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
      {open && <TemplateModal openModal={open} handleCloseModal={handleClose} />}
    </Root>
  );
};

export default TemplateReportForm;
