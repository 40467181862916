import React from 'react';
import styled from '@emotion/styled';
import {LocatingRole} from 'src/types';
import AccordionDetailsWrapper from './ExpansionPanelDetailsWrapper';

const StyledLabel = styled.p`
  font-size: 12px;
  color: dimgrey;
  margin-bottom: 0;
`;

interface Props {
  searchKeyword: string;
  favouriteRolesList: LocatingRole[];
  setFavouriteRolesList: React.Dispatch<React.SetStateAction<LocatingRole[]>>;
}

const RoleFavourites = ({searchKeyword, favouriteRolesList, setFavouriteRolesList}: Props) => {
  if (favouriteRolesList && favouriteRolesList.length > 0) {
    return (
      <div>
        <StyledLabel>Favourites</StyledLabel>
        <AccordionDetailsWrapper
          isAllFavourite={true}
          setFavouriteRolesList={setFavouriteRolesList}
          roleList={favouriteRolesList}
          teamList={[]}
          searchKeyword={searchKeyword}
          favouriteRolesList={favouriteRolesList}
        />
      </div>
    );
  }
  return <div />;
};

export default RoleFavourites;
