import React from 'react';
import * as R from 'ramda';
import client from 'src/apollo';
import {ApolloQueryResult} from 'apollo-client';
import tryReadChatsQuery from 'src/utils/messengerHelper/tryReadCachedQuery';
import GetChatsQuery from 'src/gql/query/GetChatsQuery';
import {Chat, lastMessage, Message} from 'src/types';

interface Props {
  chatId: string;
  lastMessageId: string | null;
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<any>>;
  isSearch: boolean;
  jumpToMessage: any;
}

export default ({refetch, chatId, lastMessageId, isSearch, jumpToMessage}: Props) => {
  const [chatLastMessagePreview, setChatLastMessagePreview] = React.useState<lastMessage | null>(null);

  const savedCallbackRef = React.useRef<() => void>();

  // re-reading cache when chatID changes
  React.useEffect(() => {
    const readChatsQuery = async () => {
      try {
        if (!isSearch && jumpToMessage === null) {
          const localChatsQuery = await tryReadChatsQuery();
          const activeChatsQuery = localChatsQuery
            ? localChatsQuery
            : (await client.query({query: GetChatsQuery})).data; // should happen iff chatList load > chat message load

          const chats = R.pathOr([], ['chats', 'chats'], activeChatsQuery) as Chat[];
          const targetChat = chats.find((chat) => chat.id === chatId);
          setChatLastMessagePreview(targetChat?.lastMessage ?? null);
        }
      } catch (e) {
        console.error(e);
      }
    };
    readChatsQuery();
  }, [chatId, lastMessageId, isSearch, jumpToMessage]);

  React.useEffect(() => {
    if (!isSearch && jumpToMessage === null) {
      savedCallbackRef.current = refetch;
    }
  }, [refetch, isSearch, jumpToMessage]);

  React.useEffect(() => {
    if (!isSearch && jumpToMessage === null) {
      if (!chatLastMessagePreview || !lastMessageId) return;
      if (String(chatLastMessagePreview.id) !== lastMessageId) {
        const id = setInterval(tick, 1000);
        return () => {
          clearInterval(id);
        };
      }
    }
    function tick() {
      savedCallbackRef.current?.();
    }
  }, [refetch, chatLastMessagePreview, lastMessageId, isSearch, jumpToMessage]);
};
