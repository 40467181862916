import client from 'src/apollo';
import GetMessageQuery from 'src/gql/query/GetMessageQuery';
import getMessageFragment from 'src/utils/messengerHelper/getMessageFragment';
import MessageFragment from 'src/gql/fragment/MessageFragment';
import {Message} from 'src/types';

interface Props {
  chatId: string;
  repliedToId: string;
  originalMessageId: string;
}

export default async function ({chatId, repliedToId, originalMessageId}: Props) {
  try {
    const {data} = await client.query({
      query: GetMessageQuery,
      variables: {
        chatId,
        messageId: repliedToId,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    });

    const repliedMessage: Message = data.chat.message;
    repliedMessage.repliedTo = null;

    const messageFragment = getMessageFragment(originalMessageId, client);
    if (messageFragment) {
      messageFragment.repliedTo = data.chat.message;
    }

    client.writeFragment({
      id: `Message:${originalMessageId}`,
      fragment: MessageFragment,
      fragmentName: 'MessageFragment',
      data: {
        ...messageFragment,
      },
    });
  } catch (e) {
    console.error(e);
    return;
  }
}
