import React, {useEffect} from 'react';
import styled from '@emotion/styled';
import {useParams} from 'react-router-dom';
import {AuthContext} from 'src/auth/AuthProvider';
import UserProfileWrapperV2 from './profile-section/UserProfileWrapperV2';
import {ProfilePageFormFieldsProvider} from 'src/contexts/HypercareProfileFieldsContext';
import {AuthPayload, LocatingRouteParams} from 'src/types';
import SelfProfileWrapperV2 from './profile-section/SelfProfileWrapperV2';

const ProfileLayoutWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  min-width: 400px;
`;

interface IProfileLayout {
  authInfo: AuthPayload;
}

const ProfileLayout = ({authInfo}: IProfileLayout) => {
  const {userId: routeUserId}: LocatingRouteParams = useParams();
  const isSelf = routeUserId === authInfo.user.id;

  if (!routeUserId) return <ProfileLayoutWrapper></ProfileLayoutWrapper>;

  return (
    <ProfilePageFormFieldsProvider>
      <ProfileLayoutWrapper>
        {isSelf ? <SelfProfileWrapperV2 userId={routeUserId} /> : <UserProfileWrapperV2 userId={routeUserId} />}
      </ProfileLayoutWrapper>
    </ProfilePageFormFieldsProvider>
  );
};

export const ProfileLayoutContainer = () => (
  <AuthContext.Consumer>{({authInfo}) => <ProfileLayout authInfo={authInfo} />}</AuthContext.Consumer>
);
