import * as React from 'react';
import styled from '@emotion/styled';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {SCHEDULING} from 'src/constants/routerPathName';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 1.5em !important;
  padding-left: 12px;
  .material-icons {
    color: ${(props) => props.theme.colors.androidGreen};
  }
`;

const Crumb = styled.div`
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  color: ${(props) => props.theme.colors.androidGreen};
`;

const SchedulingSubHeader = () => {
  const handleBackToSchedulingPage = () => {
    window.routerHistory.push(`/${SCHEDULING}`);
  };

  return (
    <StyledBreadcrumbs separator={<span className="material-icons">navigate_next</span>} aria-label="breadcrumb">
      <Crumb color="inherit" onClick={handleBackToSchedulingPage}>
        Scheduling
      </Crumb>
      <Crumb color="inherit">Swap shifts</Crumb>
    </StyledBreadcrumbs>
  );
};

export default SchedulingSubHeader;
