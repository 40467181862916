import React from 'react';
import WarningAltIcon from '../../svgs/WarningAltIcon';
import {
  ADDRESS_BANNER_BOTH_ADDRESS_DESCRIPTION,
  ADDRESS_BANNER_BOTH_ADDRESS_TEXT,
  ADDRESS_BANNER_BOTH_ADDRESS_TITLE,
  ADDRESS_BANNER_EMAIL_BUTTON_TEXT,
  ADDRESS_BANNER_EMAIL_DESCRIPTION,
  ADDRESS_BANNER_EMAIL_TITLE,
  ADDRESS_BANNER_PHONE_BUTTON_TEXT,
  ADDRESS_BANNER_PHONE_DESCRIPTION,
  ADDRESS_BANNER_PHONE_TITLE,
  LEARN_MORE,
} from '../../constants/strings';
import {HCCustomSpan, HCTextContext} from '../../components/HypercareComponents';
import getParsedAuthInfo from '../../utils/localStorageHandler';
import styled from '@emotion/styled';

const Banner = styled.div`
  background: #fff4cd;
  border-radius: 3px;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  position: relative;
  justify-content: center;
`;

const StyledContent = styled.div`
  align-items: center;
  gap: 5px;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
`;

const StyledLink = styled.a`
  color: black;
`;

export enum MissingBannerType {
  EMAIL = 'email',
  PHONE = 'phoneNumber',
  BOTH = 'both',
}

interface Props {
  type: MissingBannerType;
  setAddressModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const bannerContent = {
  email: {
    title: ADDRESS_BANNER_EMAIL_TITLE,
    description: ADDRESS_BANNER_EMAIL_DESCRIPTION,
    actionButtonText: ADDRESS_BANNER_EMAIL_BUTTON_TEXT,
  },
  phoneNumber: {
    title: ADDRESS_BANNER_PHONE_TITLE,
    description: ADDRESS_BANNER_PHONE_DESCRIPTION,
    actionButtonText: ADDRESS_BANNER_PHONE_BUTTON_TEXT,
  },
  both: {
    title: ADDRESS_BANNER_BOTH_ADDRESS_TITLE,
    description: ADDRESS_BANNER_BOTH_ADDRESS_DESCRIPTION,
    actionButtonText: ADDRESS_BANNER_BOTH_ADDRESS_TEXT,
  },
};

export const AddressBanner: React.FC<Props> = ({type, setAddressModal}) => {
  const {title, description, actionButtonText} = bannerContent[type];

  const handlePhoneButtonAction = () => {
    setAddressModal(true);
  };
  const handleEmailButtonAction = () => {
    setAddressModal(true);
  };
  const handleBothAction = () => {
    const userId = getParsedAuthInfo()?.user.id;
    window.routerHistory.push(`/messenger/user/${userId}`);
  };

  const handleButtonClick = (type: MissingBannerType) => {
    switch (type) {
      case MissingBannerType.PHONE:
        return handlePhoneButtonAction();
      case MissingBannerType.EMAIL:
        return handleEmailButtonAction();
      case MissingBannerType.BOTH:
        return handleBothAction();
    }
  };

  return (
    <Banner>
      <WarningAltIcon />
      <StyledContent>
        <HCTextContext>{title}</HCTextContext>
        <HCCustomSpan>{description}</HCCustomSpan>
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.hypercare.com/hc/en-us/articles/24953957201037-How-to-Add-an-Email-Address-and-Mobile-Number-to-Your-Profile"
        >
          <HCCustomSpan>{LEARN_MORE}</HCCustomSpan>
        </StyledLink>
        <button style={{marginLeft: '18px'}} onClick={() => handleButtonClick(type)}>
          {actionButtonText}
        </button>
      </StyledContent>
    </Banner>
  );
};
