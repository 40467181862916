import store from 'src/redux';
import MessageObject from './MessageObject';
import {MessageObjectType, MessageRequestType} from 'src/types';
import {trySendMessage, tryTemplateAttachmentUpload} from './sendMessage';
import scrollMessageBodyToBottom from './scrollMessageBodyToBottom';
import {actions as MessageActions} from 'src/redux/actions/messages';
import {actions as FileActions} from 'src/redux/actions/filedrop';
import {toast} from 'react-toastify';

const sendTemplateAttachments = async (
  files: File[],
  messageTemplatePriority,
  chatId,
  authInfo,
  sendTemplateMessage,
  setLoadingStatus,
) => {
  try {
    store.dispatch(FileActions.clearDroppedFiles());
    setLoadingStatus({isLoading: true, loadingMessage: 'Uploading files...'});

    let filesMessageObject = new MessageObject(
      null,
      messageTemplatePriority,
      chatId,
      authInfo?.user?.id,
      null,
      null,
      files,
      null,
      null,
    );

    return await tryTemplateAttachmentUpload({filesMessageObject});
  } catch (e) {
    console.error(e);
    setLoadingStatus({isLoading: false, loadingMessage: ''});
    toast.error('Upload file failed, please check your internet connection and try again');
  }
};

/**
 * Function to send template messages both request and responding to a template request
 * @param {MessageRequestType} sendRequesttype Type of request to send template message or respond to template request
 * @param chatId chatId of the message
 * @param authInfo Authinfo
 * @param sendTemplateMessage
 * @param responseData any
 * @param message {Message} Message object to fetch various properties
 * @param setLoadingStatus to set message sending/file upload status to update UI
 * @return {Promise<void>}
 */
const sendTemplateMessages = async (
  sendRequesttype: MessageRequestType,
  chatId: any,
  authInfo: any,
  sendTemplateMessage: any,
  responseData: any,
  message: any | null = null,
  setLoadingStatus: ({isLoading, loadingMessage}: {isLoading: boolean; loadingMessage: string}) => void,
) => {
  try {
    const files = store.getState().filedrop.files,
      hasFiles = files && files.length > 0,
      addOptimisticMessage = MessageActions.addOptimisticMessage,
      failOptimisticMessageMark = MessageActions.failOptimisticMessageMark,
      removeOptimisticMessage = MessageActions.removeOptimisticMessage,
      {messageTemplatePriority, formValues, selectedTemplate, requestTitle} = store.getState().messageTemplate;

    let attachmentIds;

    if (hasFiles) {
      attachmentIds = await sendTemplateAttachments(
        files,
        messageTemplatePriority,
        chatId,
        authInfo,
        sendTemplateMessage,
        setLoadingStatus,
      );
      let attachmentFormValueIndex = formValues.findIndex((fieldValue) => fieldValue.hasOwnProperty('attachmentIds'));
      formValues[attachmentFormValueIndex].attachmentIds = attachmentIds;
    }

    setLoadingStatus({isLoading: true, loadingMessage: 'Sending...'});
    let newMessage: MessageObjectType;

    if (sendRequesttype === MessageRequestType.request) {
      const templateData = Object.assign({}, {templateId: selectedTemplate?.id, requestTitle, fields: formValues});
      newMessage = new MessageObject(
        '',
        messageTemplatePriority,
        chatId,
        authInfo?.user?.id,
        templateData,
        null,
        null,
        null,
        null,
      );
    } else {
      const {id} = message;
      newMessage = new MessageObject(
        '',
        messageTemplatePriority,
        chatId,
        authInfo?.user?.id,
        null,
        null,
        null,
        responseData,
        id,
      );
    }

    await trySendMessage(newMessage, {
      sendMessage: sendTemplateMessage,
      addOptimisticMessage,
      failOptimisticMessageMark,
      removeOptimisticMessage,
    });
    scrollMessageBodyToBottom();
    await Promise.resolve();
  } catch (e) {
    console.error(e);
    const messageBody = document.getElementById('scrollContainer');
    if (messageBody) messageBody.scrollTop = messageBody.scrollHeight;
    throw e;
  } finally {
    setLoadingStatus({isLoading: false, loadingMessage: ''});
  }
};

export default sendTemplateMessages;
