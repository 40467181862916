import React from 'react';
import {Dialog, DialogContent, Grid, Divider, TextField} from '@mui/material';

import store from 'src/redux';
import AlertModal from './AlertModal';
import {typedUseSelector} from 'src/redux';
import {TemplateViews, TemplateMessageType} from 'src/types';
import {actions as MessageTemplateActions} from 'src/redux/actions/messageTemplates';
import {actions as FiledropActions} from 'src/redux/actions/filedrop';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
  StyledDialogActions,
} from 'src/styles/styled-components/StyledMaterialComponents';
import {MaterialDialogHeader, Transition} from 'src/components/HypercareComponents';
import validateTemplateMessageForm from 'src/utils/messengerHelper/validateTemplateMessageForm';
import MessageTemplateFactory from 'src/pages/MessengerPage/messenger/messages-layout/message-template/MessageTemplateFactory';
import styled from '@emotion/styled';

const RequestTitleTextField = styled(TextField)`
  .MuiTextField-root {
    margin-bottom: 16px;
    width: 100%;
  }
`;

interface MessageTemplateLayoutProps {
  closeMessageTemplateLayout: () => void;
  showMessageTemplateLayoutModal: boolean;
  templateLayoutNextBtnHandler: () => void;
  templateLayoutPreviousBtnHandler: () => void;
}
const MessageTemplateLayout = ({
  closeMessageTemplateLayout,
  templateLayoutNextBtnHandler,
  showMessageTemplateLayoutModal,
  templateLayoutPreviousBtnHandler,
}: MessageTemplateLayoutProps) => {
  const [resetFormAlert, setResetFormAlert] = React.useState<boolean>(false);
  const [quitEditingAlert, setQuitEditingAlert] = React.useState<boolean>(false);
  const {selectedTemplate, requestTitle, formValues} = typedUseSelector((state) => state.messageTemplate);
  const [requestTitleField, setRequestTitle] = React.useState<string>(selectedTemplate?.requestTitle || '');

  React.useEffect(() => {
    store.dispatch(MessageTemplateActions.setRequestTitle(requestTitle!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRequestTitle(requestTitle!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestTitle]);

  return (
    <>
      <Dialog maxWidth={'sm'} fullWidth={true} open={showMessageTemplateLayoutModal} TransitionComponent={Transition}>
        <MaterialDialogHeader
          dialogTitle={selectedTemplate?.formTitle ?? ''}
          closeIconButtonClickHandler={() => setQuitEditingAlert(true)}
        />
        <DialogContent>
          <RequestTitleTextField
            value={requestTitleField}
            id="request-title"
            placeholder="Add request title"
            onChange={(e) => setRequestTitle(e.target.value)}
            onBlur={(e) => store.dispatch(MessageTemplateActions.setRequestTitle(requestTitleField))}
          />
          <Grid container spacing={2}>
            {selectedTemplate &&
              new MessageTemplateFactory(selectedTemplate, TemplateViews.RequestEditView).createUIWithJSONParser()}
          </Grid>
        </DialogContent>
        <Divider />
        <StyledDialogActions>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <TextButton onClick={() => setResetFormAlert(true)}>Reset form</TextButton>
            </Grid>
            <Grid item>
              <SecondaryButton style={{marginRight: 8}} onClick={() => templateLayoutPreviousBtnHandler()}>
                Previous
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  let templateFormErrors = validateTemplateMessageForm(
                    selectedTemplate,
                    formValues,
                    TemplateMessageType.request,
                  );
                  if (templateFormErrors.length <= 0) templateLayoutNextBtnHandler();
                }}
              >
                Next
              </PrimaryButton>
            </Grid>
          </Grid>
        </StyledDialogActions>
      </Dialog>
      {resetFormAlert && (
        <AlertModal
          title={'Reset form?'}
          closeAlertModal={setResetFormAlert}
          isAlertModalVisible={resetFormAlert}
          alertModalButtons={[
            {
              buttonLabel: 'Back to editing',
              type: 'secondary',
              onClickHandler: () => setResetFormAlert(false),
            },
            {
              buttonLabel: 'Reset anyway',
              type: 'primary',
              onClickHandler: () => {
                setResetFormAlert(false);
                store.dispatch(FiledropActions.clearDroppedFiles());
                store.dispatch(MessageTemplateActions.resetFormValues());
              },
            },
          ]}
          subtitle={'This will clear all the information that you have entered so far. This action cannot be undone.'}
        />
      )}
      {quitEditingAlert && (
        <AlertModal
          title={'Quit editing?'}
          closeAlertModal={setQuitEditingAlert}
          isAlertModalVisible={quitEditingAlert}
          alertModalButtons={[
            {
              buttonLabel: 'Back to editing',
              type: 'secondary',
              onClickHandler: () => setQuitEditingAlert(false),
            },
            {
              buttonLabel: 'Quit anyway',
              type: 'primary',
              onClickHandler: () => {
                store.dispatch(FiledropActions.clearDroppedFiles());
                store.dispatch(MessageTemplateActions.resetToInitalState());
                setQuitEditingAlert(false);
                closeMessageTemplateLayout();
              },
            },
          ]}
          subtitle={'All the information that you have entered so far will not be saved. This action cannot be undone.'}
        />
      )}
    </>
  );
};

export default React.memo(MessageTemplateLayout);
