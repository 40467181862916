import {muiTheme} from 'src/styles/theme';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="20" cy="20" r="20" fill={props.color || muiTheme.colors.messageActionIconGrey} />
      <path
        fill={props.strokefill || '#FFF'}
        fillRule="nonzero"
        stroke="transparent"
        strokeWidth="0.5"
        d="M18.642 12h-.02c-.354.01-.632.262-.622.565l.31 8.903c.011.296.295.532.642.532h2.096c.347 0 .631-.236.641-.532l.31-8.903c.001-.32-.286-.565-.64-.565h-2.717zM20 28c.631 0 1.11-.176 1.459-.522.357-.354.541-.842.541-1.472 0-.647-.185-1.14-.54-1.49-.35-.341-.828-.516-1.46-.516-.623 0-1.101.174-1.454.517-.36.349-.546.842-.546 1.49 0 .629.186 1.117.547 1.47.353.347.83.523 1.453.523z"
      />
    </g>
  </svg>
);
