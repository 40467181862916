import {TextField} from '@mui/material';
import styled from '@emotion/styled';
import {muiTheme} from '../theme';

export const StyledTextField = styled(TextField)`
  && {
    input {
      // caret-color: ${muiTheme.colors.watermelon}; // Change this to the desired color
    }
  }
  & label.Mui-focused {
    color: rgb(0, 133, 154);
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: rgb(0, 133, 154);
    }
  }
  & .MuiInputBase-input {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  /* & .MuiFormHelperText-root.Mui-error {
    color: #ff3e55;
  }

  & .MuiFormLabel-root.Mui-error {
    color: #ff3e55;
  } */
`;
