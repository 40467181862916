import {createAction, ActionType, getType} from 'typesafe-actions';
import {Actions} from '../reducers';

export const actions = {
  setCiscoStatus: createAction('CTI/SET_CISCO_STATUS', (resolve) => (status) => resolve({status})),
  setMitelStatus: createAction('CTI/SET_MITEL_STATUS', (resolve) => (status) => resolve({status})),
};

export type CTIActions = ActionType<typeof actions>;

export const initialState: CTIState = {
  mitelStatus: false,
  ciscoStatus: false,
};

export interface CTIState {
  mitelStatus: boolean;
  ciscoStatus: boolean;
}

export default (state: CTIState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(actions.setCiscoStatus):
      return {...state, ciscoStatus: action.payload.status};
    case getType(actions.setMitelStatus):
      return {...state, mitelStatus: action.payload.status};
    default:
      return state;
  }
};
