import React from 'react';
import {Avatar, ListItemAvatar, ListItemText} from '@mui/material';
import styled from '@emotion/styled';
import {User as Contact} from 'src/types/User';
import {getUpperCaseInitials} from 'src/utils/user';

const UserListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
`;

interface Props {
  contactList: Contact[];
}
const ContactList = ({contactList}: Props) => {
  return (
    <UserListWrapper>
      {contactList.map((contact) => (
        <UserItem key={contact.id} onClick={() => window.routerHistory.push(`/contacts/${contact.id}`)}>
          <ListItemAvatar>
            {contact?.profilePic ? (
              <Avatar src={contact?.profilePic.url ?? undefined} />
            ) : (
              <Avatar>{getUpperCaseInitials(contact)}</Avatar>
            )}
          </ListItemAvatar>
          <ListItemText primary={`${contact?.firstname || ''} ${contact?.lastname || ''}`} secondary={contact?.role} />
        </UserItem>
      ))}
    </UserListWrapper>
  );
};

export default ContactList;
