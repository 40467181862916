import React, {useState} from 'react';
import {ProfileFieldVisibility} from 'src/types';
import styled from '@emotion/styled';
import {exhaustiveGuard} from 'src/utils/exhaustiveGuard';
import {Button, IconButton} from '@mui/material';
import EyeIcon from 'src/svgs/EyeIcon';
import LockIcon from 'src/svgs/LockIcon';
import ChevrDown from 'src/svgs/ChevrDown';
import {TrashButtonIcon} from 'src/svgs/TrashBucketIcon';
import {VISIBILITY} from 'src/constants/hiddenNotes';
import {Dropdown} from 'src/components/@hc-ui/components/Dropdown/Dropdown';
import store from 'src/redux';
import {muiTheme} from 'src/styles/theme';

type ProfileFieldProps = {
  onRemove?(): void;
  onChangeVisibility?(newVisibility: ProfileFieldVisibility): void;
  isSynced: boolean;
  visibility: ProfileFieldVisibility;
  mode: 'view' | 'edit';
  isSelf: boolean;
};

const getProfileFieldVisibilitySelectorIcon = (visibility: ProfileFieldProps['visibility']) => {
  switch (visibility) {
    case ProfileFieldVisibility.PUBLIC:
      return EyeIcon;
    case ProfileFieldVisibility.PRIVATE:
      return LockIcon;
    default:
      return exhaustiveGuard(visibility);
  }
};

const getProfileFieldVisibilitySelectorText = (visibility: ProfileFieldProps['visibility']) => {
  switch (visibility) {
    case ProfileFieldVisibility.PUBLIC:
      return VISIBILITY.PUBLIC;
    case ProfileFieldVisibility.PRIVATE:
      return VISIBILITY.PRIVATE;
    default:
      return exhaustiveGuard(visibility);
  }
};
const PROFILE_FIELD_VISIBILITY_OPTIONS: ProfileFieldVisibility[] = [
  ProfileFieldVisibility.PUBLIC,
  ProfileFieldVisibility.PRIVATE,
];

type ProfileFieldVisibilityOptionProps = {
  visibility: ProfileFieldVisibility;
};

const ProfileFieldVisibilityOption = (props: ProfileFieldVisibilityOptionProps) => {
  const VisibilityIcon = getProfileFieldVisibilitySelectorIcon(props.visibility);
  const visibilityText = getProfileFieldVisibilitySelectorText(props.visibility);

  return (
    <>
      <VisibilityIcon style={{width: 16, height: 16}} />
      {visibilityText}
    </>
  );
};

const ProfileFieldVisibilitySelector = (
  props: Pick<ProfileFieldProps, 'visibility' | 'onChangeVisibility' | 'mode'>,
) => {
  const VisibilityIcon = getProfileFieldVisibilitySelectorIcon(props.visibility);
  const visibilityText = getProfileFieldVisibilitySelectorText(props.visibility);

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        disabled={!props.onChangeVisibility}
        startIcon={<VisibilityIcon style={{width: 16, height: 16}} />}
        endIcon={props.mode === 'edit' ? <ChevrDown /> : null}
        ref={setAnchorElement}
        onClick={() => setIsOpen(!isOpen)}
      >
        {visibilityText}
      </Button>
      <Dropdown
        options={PROFILE_FIELD_VISIBILITY_OPTIONS}
        getOptionId={(o) => o}
        getIsOptionSelected={(o) => o === props.visibility}
        renderOptionContent={(o) => <ProfileFieldVisibilityOption visibility={o} />}
        onOptionClick={(o) => {
          props?.onChangeVisibility?.(o);
          setIsOpen(false);
        }}
        onClickAway={() => setIsOpen(false)}
        anchorElement={anchorElement}
        isOpen={isOpen}
      />
    </>
  );
};

const ProfileFieldContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

const ProfileFieldInputContainer = styled.div`
  flex: 1;
`;

const ProfileFieldActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const ProfileField: React.FC<ProfileFieldProps> = (props) => {
  const isUserAdmin = store.getState().organization.isAdmin;

  return (
    <ProfileFieldContainer>
      <ProfileFieldInputContainer>{props.children}</ProfileFieldInputContainer>
      <ProfileFieldActionsContainer>
        {(props.isSelf || isUserAdmin) && (
          <ProfileFieldVisibilitySelector
            visibility={props.visibility}
            onChangeVisibility={props.onChangeVisibility}
            mode={props.mode}
          />
        )}
        {props.mode === 'edit' && (
          <IconButton disabled={!props.onRemove} onClick={props.onRemove} size="large">
            <TrashButtonIcon color={!!props.onRemove ? muiTheme.colors.errorRed : undefined} />
          </IconButton>
        )}
      </ProfileFieldActionsContainer>
    </ProfileFieldContainer>
  );
};
