import styled from '@emotion/styled';
import React from 'react';
import AlertTriangleIcon from 'src/svgs/AlertTriangleIcon';
import {muiTheme} from 'src/styles/theme';
import {GROUP_CHAT_BUSY_OR_UNAVAILABLE_MEMBERS_BANNER_TITLE} from 'src/constants/strings';
import {ArrowRight, ChevronRight} from '@mui/icons-material';
import {Typography} from '@mui/material';

const BannerHolder = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  color: ${muiTheme.colors.greyishBrown};
  background-color: ${muiTheme.colors.etherealMoodBlue};
  font-family: Nunito;
  font-size: 14px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export const ChatMembersBusyOrUnavailableBanner = ({
  busyOrUnavailableChatMembersCount,
  onClick,
}: {
  busyOrUnavailableChatMembersCount: number;
  onClick: () => void;
}) => {
  if (busyOrUnavailableChatMembersCount === 0) return null;

  return (
    <BannerHolder onClick={onClick}>
      <AlertTriangleIcon stroke={muiTheme.colors.primary} />
      <Typography variant="body2" style={{paddingLeft: 8}}>
        {GROUP_CHAT_BUSY_OR_UNAVAILABLE_MEMBERS_BANNER_TITLE(busyOrUnavailableChatMembersCount)}
      </Typography>
      <ChevronRight />
    </BannerHolder>
  );
};
