import React from 'react';
import moment from 'moment';
import {QueryResult} from 'react-apollo';
import {FetchRolesAndShiftsResult, ScheduleLayoutRouterParams} from 'src/types';
import MonthSwitcher from 'src/pages/SchedulingPage/schedule-layout/MonthSwitcher';
import BaseActionCalendarLayout from 'src/pages/SchedulingPage/action-calendar/BaseActionCalendarLayout';
import {useScheduleViewModelData} from 'src/pages/SchedulingPage/scheduling/hooks';
import {toast} from 'react-toastify';
import {SCHEDULING, SCHEDULINGQUERYPARAM} from 'src/constants/routerPathName';
import {GRIDSCHEDULINGVIEW, LISTSCHEDULINGVIEW, SWAPSCHEDULINGVIEW} from 'src/constants/scheduler';
import styled from '@emotion/styled';
import {useParams} from 'react-router-dom';
import SchedulingToggleButton from 'src/components/SchedulingToggleButton';

interface Props {
  currentDepartmentId: number;
  onSelectMonth: (startTime: string, endTime: string) => void;
  momentUTCStartDate: moment.Moment;
  fetchRolesAndShiftsResult: QueryResult<FetchRolesAndShiftsResult>;
  isSelfScoped: boolean;
}

const HeaderWrapper = styled.div<{isSwap: boolean}>`
  display: flex;
  justify-content: ${(props) => (props.isSwap ? 'center' : 'space-between')};
  margin-bottom: 20px;
`;

const SchedulingGridSwapSectionContainer = ({
  fetchRolesAndShiftsResult,
  isSelfScoped,
  currentDepartmentId,
  onSelectMonth,
  momentUTCStartDate,
}: Props) => {
  const params = useParams() as ScheduleLayoutRouterParams;
  if (!isSelfScoped && params?.actionType === SWAPSCHEDULINGVIEW) {
    toast.warn('You are not part of the department for the shift you want to take.', {
      autoClose: false,
    });
    window.routerHistory.push(`/${SCHEDULING}/`);
  }

  const viewModelScheduleData = useScheduleViewModelData({
    fetchRolesAndShiftsResult,
    currentDepartmentId,
    preventSplit: true,
  });

  if (!viewModelScheduleData) return null;

  return (
    <BaseActionCalendarLayout viewModelScheduleData={viewModelScheduleData}>
      <HeaderWrapper isSwap={params?.actionType === SWAPSCHEDULINGVIEW}>
        <div />
        <MonthSwitcher onSelectMonth={onSelectMonth} startDate={momentUTCStartDate} />
        {params?.actionType !== SWAPSCHEDULINGVIEW && (
          <SchedulingToggleButton
            value={GRIDSCHEDULINGVIEW}
            onChange={() => {
              window.routerHistory.push(`/${SCHEDULING}?${SCHEDULINGQUERYPARAM}=${LISTSCHEDULINGVIEW}`);
            }}
          />
        )}
      </HeaderWrapper>
    </BaseActionCalendarLayout>
  );
};

export default SchedulingGridSwapSectionContainer;
