import React from 'react';
import styled from '@emotion/styled';
import ListIcon from '@mui/icons-material/List';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';

import {LISTSCHEDULINGVIEW, GRIDSCHEDULINGVIEW} from 'src/constants/scheduler';
import ScheduleToggleButton from 'src/svgs/ScheduleToggleButton';

const StyledToggleButton = styled(ToggleButton)`
  padding: 0 16px 0 16px !important;
  span {
    color: #000;
  }
`;

/**
 * Toggle button shown on top right corner of the scheduling page for toggling
 * between list and grid views of schedule
 * @param onChange () => {} Function to execute when the other toggle button is clicked
 * @param value string selected value of the toggle button
 */
const SchedulingToggleButton = ({onChange, value}) => {
  return (
    <ToggleButtonGroup size="small" value={value} onChange={onChange} aria-label="toggle schedule view">
      <StyledToggleButton
        selected={value === GRIDSCHEDULINGVIEW}
        value={GRIDSCHEDULINGVIEW}
        aria-label={GRIDSCHEDULINGVIEW}
      >
        <ScheduleToggleButton /> &nbsp; Grid view
      </StyledToggleButton>
      <StyledToggleButton
        selected={value === LISTSCHEDULINGVIEW}
        value={LISTSCHEDULINGVIEW}
        aria-label={LISTSCHEDULINGVIEW}
      >
        <ListIcon /> &nbsp; List view
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};

export default SchedulingToggleButton;
