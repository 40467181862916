import React from 'react';
import styled from '@emotion/styled';
import {WideWidthStyleModal, Header} from 'src/styles/styled-components/ModalStyleComponents';
import Button from '@mui/material/Button';
import {AppDispatch} from 'src/redux';
import {useDispatch} from 'react-redux';
import {actions} from 'src/redux/actions/sidebar';
import AnalyticsManager from 'src/analytics/AnalyticsManager';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  button {
    min-width: 120px;
  }
  button:last-child {
    color: ${(props) => props.theme.colors.androidGreen};
    background-color: white;
  }
  button:first-child {
    margin-left: 1em;
  }
  button:disabled {
    color: white;
    background-color: lightgrey;
  }
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const StatusSuggestionModal = ({isOpen, closeModal}: Props) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <WideWidthStyleModal isOpen={isOpen} shouldCloseOnEsc={false} ariaHideApp={false} shouldCloseOnOverlayClick={false}>
      <Header>
        <span>Your status is set to Unavailable</span>
      </Header>
      <p>
        Your will not receive any notifications, and will not be able to send or read messages until status is turned
        back to available or busy.
      </p>
      <ButtonsWrapper>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(actions.toggleStatusSettings(true));
          }}
        >
          Update status
        </Button>
      </ButtonsWrapper>
    </WideWidthStyleModal>
  );
};

export default StatusSuggestionModal;
