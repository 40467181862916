import styled from '@emotion/styled';

export const ShiftHolderWrapper = styled.div`
  padding: 4px 6px;
  min-height: inherit;
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: inherit;
  background-color: #f2ece9;
`;

export const ShiftRoleAndStartTimeHolder = styled.div`
  padding-bottom: 4px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
`;
export const ShiftDeptAndEndTimeHolder = styled.div`
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
`;

export const ShiftRoleName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 1.25;
`;

export const ShiftDepartmentName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.25;
`;

export const ShiftRoleHolderTitleTime = styled.div`
  letter-spacing: normal;
  padding-left: 2px;
  min-width: max-content;
  width: 48px;
`;

export const ShiftDateHolder = styled.div`
  min-width: 165px;
  min-height: 185px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

export const ShiftWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ShiftDateIndicator = styled.div`
  align-self: flex-start;
  font-size: 14px;
  width: 100%;
  margin-bottom: 4px;
  padding: 4px 4px 0px;
`;
