import React from 'react';
import styled from '@emotion/styled';
import {InvitedUser} from 'src/types';
import moment from 'moment';
import Button from '@mui/material/Button';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';

const ResendButtonWrapper = styled.div<{isReinvited: boolean}>`
  min-width: 120px;
  margin-right: 8px;
  button {
    width: 120px;
    box-shadow: none;
    text-transform: none;
    padding: 4px 8px;
    height: 32px;
    &:disabled {
      background-color: #f1f1f1 !important;
    }
  }
  svg {
    transform: scale(0.8);
  }
`;
interface Props {
  invitedUser: InvitedUser;
  succeedList: string[];
  loadingList: string[];
  handleResendInvitation: (invitedUser: InvitedUser) => Promise<void>;
}

const UserItemButton = ({invitedUser, succeedList, loadingList, handleResendInvitation}: Props) => {
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');
  const isInviteRecentlyResent = invitedUser?.history?.filter(
    (inviteHistory) =>
      inviteHistory.action === 'resend_invite' && moment(moment.now()).diff(inviteHistory.createdAt, 'hours') < 12,
  );
  let isResendDisabled: boolean;
  if (coSigningInvitesFF)
    isResendDisabled =
      loadingList.includes(invitedUser.id) || succeedList.includes(invitedUser.id) || !!isInviteRecentlyResent?.length;
  else isResendDisabled = loadingList.includes(invitedUser.id) || succeedList.includes(invitedUser.id);
  return (
    <ResendButtonWrapper isReinvited={succeedList.includes(invitedUser.id)}>
      <Button
        color="primary"
        disabled={isResendDisabled}
        variant="contained"
        onClick={() => handleResendInvitation(invitedUser)}
        disableTouchRipple
      >
        {isResendDisabled ? 'Invite resent' : loadingList.includes(invitedUser.id) ? 'Sending...' : 'Resend Invite'}
      </Button>
    </ResendButtonWrapper>
  );
};

export default UserItemButton;
