import * as React from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {Chat} from 'src/types';
import getChatTitle from 'src/utils/messengerHelper/getChatTitle';
import {actions} from 'src/redux/actions/sidebar';
import {actions as messageActions} from 'src/redux/actions/messages';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Info from 'src/svgs/Info';
import isActualChatId from 'src/utils/messengerHelper/isActualChatId';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import AlternativeContactBanner from 'src/pages/MessengerPage/messenger/messages-layout/header-section/AlternativeContactBanner';
import {HeaderDiv} from 'src/styles/styled-components/StyledMessagesHeader';
import {useQuery} from '@apollo/react-hooks';
import SearchIcon from 'src/svgs/SearchIcon';
import {GetChatWithoutMessages} from 'src/gql/query/GetChatQuery';
import {QueryResult} from 'react-apollo';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {ActionEvents, openChatBodySearchFieldKeys} from 'src/constants/hotkeyEvents';
import {muiTheme} from 'src/styles/theme';
import {ChatMembersBusyOrUnavailableBanner} from './ChatMembersBusyOrUnavailableBanner';

const StyledInfo = styled(Info)`
  margin-top: 8px;
  &:hover circle {
    fill: ${(props) => props.theme.redPink};
  }
`;

const HeaderTitleContainer = styled.div`
  height: 40px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const RoleSpan = styled.span`
  color: ${(props) => props.theme.colors.slightlyGrey};
  font-size: 13px;
`;

export const SidebarInfo = styled.div`
  margin-right: 1em;
  cursor: pointer;
`;

export const SearchChatBar = styled(SearchIcon)`
  cursor: pointer;
`;

export const SearchBox = styled.div<{isSearch}>`
  float: left;
  margin-right: 26px;
  background: ${(props) => (props.isSearch ? '#f6f6f9' : '')};
  width: 40px;
  height: 40px;
  border-radius: 30px;
  padding: 10px;
  &:hover {
    background: #f6f6f9;
    .search-icon {
      fill: #ff3e55;
    }
  }
`;

interface Props {
  chatId: string;
  isExpanded: boolean;
  isSearch: boolean;
  setIsSearch: (isSearch) => void;
  toggleSidebar: () => void;
  targetUserStatus: (targetUser) => void;
  handleIsSearch: (isVisible: boolean) => void;
}

const MessageBodyHeader = ({
  chatId,
  isExpanded,
  isSearch,
  setIsSearch,
  toggleSidebar,
  targetUserStatus,
  handleIsSearch,
}: Props) => {
  const {loading, error, data}: QueryResult = useQuery(GetChatWithoutMessages, {
    variables: {chatId},
    fetchPolicy: 'cache-only',
  });

  if (loading || error) return null;

  const chat = data?.chat as Chat | undefined;

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo && parsedAuthInfo.user.id;
  const trimedTitle = getTrimmedTitle(50);
  const singleChatPersonRole = getRole();
  const isSingleChat = chat?.type === 'single';
  const isGroupChat = chat?.type === 'group';
  const busyOrUnavailableChatMembersCount = chat?.members?.filter(
    (m) => m.workStatus && ['busy', 'unavailable'].includes(m.workStatus),
  ).length;

  var keysHeldDown = {};
  const primaryKey = 'g';
  const secondaryKey = 'f';

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    window.addEventListener('keydown', enterKeyListener);
    window.addEventListener('keyup', exitKeyListener);
    return () => {
      window.removeEventListener('keydown', enterKeyListener);
      window.removeEventListener('keyup', exitKeyListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exitKeyListener = (e) => {
    const eventKey = e.key.toLocaleLowerCase();
    if (eventKey === primaryKey || eventKey === secondaryKey) keysHeldDown = {};
  };

  const enterKeyListener = (e: KeyboardEvent) => {
    const eventKey = e.key.toLocaleLowerCase();
    const currentFocusedElement = document.activeElement;

    if (currentFocusedElement instanceof HTMLTextAreaElement || currentFocusedElement instanceof HTMLInputElement)
      return null;

    if (eventKey === secondaryKey && keysHeldDown[primaryKey] && Object.keys(keysHeldDown).length === 1)
      keysHeldDown[eventKey] = true;
    if (eventKey === primaryKey) keysHeldDown[eventKey] = true;

    if (keysHeldDown[primaryKey] && keysHeldDown[secondaryKey]) {
      e.preventDefault();
      setIsSearch(true);
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.hotkeyActivated,
        params: {
          keys: openChatBodySearchFieldKeys,
          action: ActionEvents.OPEN_CHAT_BODY_SEARCH_FIELD,
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    const targetUser = isSingleChat ? chat?.members?.filter((member) => member.id !== selfID)[0] : undefined;
    targetUserStatus(targetUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!chat?.members) return null;

  async function tryToggleSidebar() {
    try {
      await toggleSidebar();
      document.documentElement.scrollLeft = document.documentElement.scrollWidth - document.documentElement.clientWidth;
    } catch (e) {
      console.error(e);
    }
  }

  function getTrimmedTitle(charLimit: number) {
    if (!chat) return '';
    const chatTitle = getChatTitle(chat);

    if (chatTitle.length > charLimit) {
      return chatTitle.substring(0, Math.min(chatTitle.length, charLimit)) + '...';
    }

    return chatTitle.substring(0, Math.min(chatTitle.length, charLimit));
  }

  function getRole() {
    const members = chat?.members;

    if (members && members.length === 2 && selfID) {
      let role;
      members.forEach((member) => {
        if (member.id !== selfID) role = member.role;
      });
      return role;
    }
  }

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <title>Inbox - {trimedTitle} - Hypercare</title>
      </Helmet>
      <HeaderDiv>
        <div />
        <HeaderTitleContainer>
          <span>{trimedTitle}</span>
          {singleChatPersonRole ? <RoleSpan> {singleChatPersonRole} </RoleSpan> : null}
        </HeaderTitleContainer>
        <SidebarInfo>
          <SearchBox
            isSearch={isSearch}
            onClick={() => {
              setIsSearch(true);
              handleIsSearch(true);
            }}
          >
            <SearchChatBar color={isSearch ? '#FF3E55' : '#9B9B9B'} />
          </SearchBox>

          <StyledInfo
            title="Chat Info"
            onClick={tryToggleSidebar}
            circlefill={isExpanded ? muiTheme.colors.main : muiTheme.colors.warmGrey}
            strokefill={'white'}
          />
        </SidebarInfo>
      </HeaderDiv>
      {/*  */}
      {isSingleChat && <AlternativeContactBanner chat={chat} />}
      {isGroupChat && (
        <ChatMembersBusyOrUnavailableBanner
          onClick={tryToggleSidebar}
          busyOrUnavailableChatMembersCount={busyOrUnavailableChatMembersCount || 0}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isExpanded: state.sidebar.isExpanded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => {
      dispatch(actions.toggleSidebar());
    },
    targetUserStatus: (targetUser) => {
      dispatch(messageActions.targetUserStatus(targetUser));
    },
  };
};

const ConnectedMessagesHeader = connect(mapStateToProps, mapDispatchToProps)(MessageBodyHeader);

export default (props) => {
  const {chatId} = props;
  return isActualChatId(chatId) ? <ConnectedMessagesHeader {...props} chatId={chatId} /> : <div />;
};
