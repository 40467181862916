import React, {useContext, useState} from 'react';
import HypercareAuthRegionContext from '../../../../contexts/HypercareLoginCoordinatorContext';
import {AccountSelectionEditModeTitle, AccountSelectionTitle} from '../../../../microfrontend/login/SavedAccountsTitle';
import {LoginContainer, RowItemContainer, RowItemsRightSide} from '../../../../microfrontend/styled/login.styled';
import {
  SavedAccountActionButtons,
  SavedAccountOrganizationRowItems,
} from '../../../../microfrontend/login/SavedAccountOrganizationRowItems';
import {AddButtonV2} from '../../../../microfrontend/svgs/AddButtonV2';
import {HCLabelOne} from '../../../../components/HypercareComponents';
import {
  LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT,
  LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT,
  LOGIN_PAGE_REMOVE_ALL_ACCOUNT,
} from '../../../../constants/strings';
import {CloseButtonV2} from '../../../../microfrontend/svgs/CloseButtonV2';
import {GearIcon} from '../../../../svgs/GearIcon';
import {getParsedAuthRegion} from '../../../../utils/localStorageHandler';
import {AUTH_INFO, INTRO_DONE, ORGANIZATION_ACCOUNTS_DATA} from '../../../../constants/storageKeys';
import {OrganizationAccountsCacheData, OrgLoginMethods} from '../../../../types/sta';
import {toast} from 'react-toastify';
import {localStorageService} from '../../../../services/localStorageService';
import {AuthPayloadDTO, STALogin} from '../../../../types';
import {AccountSelectionViewModel} from '../view-models/AccountSelectionViewModel';
import {checkIfUserIsSignedIn} from '../../../../utils/userHelper/userUtils';
import {CurrentSelectedOrganization} from '../../../../microfrontend/types/login.types';
import {LogoutViewModel} from '../view-models/LogoutViewModel';
import {LogoutAllAccountsModal} from '../components/LogoutAllAccountsModal';
import {AccountLogoutModal} from '../components/AccountLogoutModal';
import {muiTheme} from 'src/styles/theme';

interface IAccountSelectionViewProps {
  handleNextStep: () => void;
  STALogin: STALogin;
  showOtpView: () => void;
  showPasswordView: () => void;
  loginWithAuth0: (auth0Id: string) => void;
  setCurrentChallengeId: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setCurrentSelectedOrg: React.Dispatch<React.SetStateAction<CurrentSelectedOrganization>>;
}

export const AccountSelectionView = ({
  handleNextStep,
  STALogin,
  showOtpView,
  showPasswordView,
  loginWithAuth0,
  setCurrentChallengeId,
  setEmail,
  setCurrentSelectedOrg,
}: IAccountSelectionViewProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [showLogoutAllAccountsModal, setShowLogoutAllAccountsModal] = useState(false);
  const [showLogoutAccountModal, setShowLogoutAccountModal] = useState(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] = useState(false);
  const {setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);
  const currentRegion = getParsedAuthRegion();
  const [currentSelectedAcc, setCurrentSelectedAcc] = useState<AuthPayloadDTO | null>(null);
  const [loginToAccountLoader, setLoginToAccountLoader] = useState(false);
  const {handleLoginToClickedAccount} = AccountSelectionViewModel();
  const {logoutOfAccount, removeAccount} = LogoutViewModel();

  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  const handleSwitchRegion = () => {
    setChangeRegionModalOpenStatus(true);
  };

  const handleOpenLogoutAllAccountsModal = () => {
    setShowLogoutAllAccountsModal(true);
  };

  const handleOpenRemoveAccountModal = (organization: AuthPayloadDTO) => {
    let isUserSignedIn = checkIfUserIsSignedIn(organization);

    setCurrentSelectedAcc(organization);
    isUserSignedIn ? setShowLogoutAccountModal(true) : setShowRemoveAccountModal(true);
  };

  const handleRemoveAccount = () => {
    const removeAccountResponse = removeAccount(currentSelectedAcc);

    if (removeAccountResponse?.success) {
      toast.info(
        `${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name}) has been removed from browser.`,
      );
    } else {
      toast.error(`Unable to remove account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowRemoveAccountModal(false);
  };

  const handleLogoutOfAccount = () => {
    const logoutResponse = logoutOfAccount(currentSelectedAcc);

    if (logoutResponse?.success) {
      toast.info(
        `Logged out of account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name})`,
      );
    } else {
      toast.error(`Unable to logout of account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowLogoutAccountModal(false);
  };

  const handleRemoveAllAccountsOnDevice = () => {
    localStorageService.removeItem(ORGANIZATION_ACCOUNTS_DATA);
    localStorageService.removeItem(INTRO_DONE);
    localStorageService.removeItem(AUTH_INFO);
    window.location.assign(`/`);
  };

  const showAccountDiscoveryFlow = () => {
    return <div>Account discovery flow</div>;
  };

  const handleLoginToAccount = async (savedOrg: AuthPayloadDTO) => {
    setLoginToAccountLoader(true);
    setCurrentSelectedOrg(savedOrg.organization);
    let isUserSignedIn = checkIfUserIsSignedIn(savedOrg);

    if (!isUserSignedIn) {
      const res = await handleLoginToClickedAccount(savedOrg);

      if (res?.error) {
        toast.error(res.error);
      }
      if (res?.auth0Id) {
        loginWithAuth0(res.auth0Id);
      }

      switch (res?.screen) {
        case OrgLoginMethods.OTP:
          res.challengeId && setCurrentChallengeId(res.challengeId);
          showOtpView();
          return;
        case OrgLoginMethods.PASSWORD:
          setEmail(savedOrg.user.email || '');
          showPasswordView();
          return;
        default:
          break;
      }
    } else {
      await STALogin(savedOrg.organization, savedOrg, savedOrg.user.email || '');
    }
    setLoginToAccountLoader(false);
  };

  return (
    <LoginContainer loading={loginToAccountLoader ? 'true' : undefined} width={'550px'}>
      {isEditMode ? (
        <AccountSelectionEditModeTitle title={'Manage accounts'} onClick={() => setIsEditMode(false)} />
      ) : (
        <AccountSelectionTitle handleSwitchRegion={handleSwitchRegion} currentAuthRegion={currentRegion} />
      )}
      <RowItemContainer>
        {currentCacheData?.savedOrganizations.map((savedOrg) => (
          <SavedAccountOrganizationRowItems
            key={savedOrg.user.id}
            user={savedOrg.user}
            organization={savedOrg.organization}
            isSignedIn={checkIfUserIsSignedIn(savedOrg)}
            isEditMode={isEditMode}
            handleRowClick={() => handleLoginToAccount(savedOrg)}
            rightSideContent={
              isEditMode ? (
                <RowItemsRightSide onClick={() => handleOpenRemoveAccountModal(savedOrg)}>
                  <CloseButtonV2 />
                </RowItemsRightSide>
              ) : (
                <></>
              )
            }
          />
        ))}

        <SavedAccountActionButtons
          onIconClick={handleNextStep}
          icon={<AddButtonV2 />}
          description={
            <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
              {LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT}
            </HCLabelOne>
          }
        />
        {isEditMode ? (
          <SavedAccountActionButtons
            icon={<CloseButtonV2 />}
            onIconClick={handleOpenLogoutAllAccountsModal}
            description={
              <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
                {LOGIN_PAGE_REMOVE_ALL_ACCOUNT}
              </HCLabelOne>
            }
          />
        ) : (
          <SavedAccountActionButtons
            icon={<GearIcon />}
            onIconClick={() => setIsEditMode(true)}
            description={
              <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
                {LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT}
              </HCLabelOne>
            }
          />
        )}
      </RowItemContainer>
      {showLogoutAllAccountsModal && (
        <LogoutAllAccountsModal
          isOpen={showLogoutAllAccountsModal}
          setIsOpen={setShowLogoutAllAccountsModal}
          handleOnClick={handleRemoveAllAccountsOnDevice}
        />
      )}
      {showLogoutAccountModal && (
        <AccountLogoutModal
          title={`Logout out of ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization.name})`}
          subTitle={`We recommend also removing your account from device upon logging out if you’re using a shared device.`}
          isOpen={showLogoutAccountModal}
          setIsOpen={setShowLogoutAccountModal}
          modalButtons={[
            {
              type: 'primary',
              buttonLabel: 'Logout & Remove',
              onClickHandler: handleRemoveAccount,
              id: 'cancel-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Logout',
              onClickHandler: handleLogoutOfAccount,
              id: 'remove-btn',
            },
          ]}
        />
      )}
      {showRemoveAccountModal && (
        <AccountLogoutModal
          title={`Remove account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization.name}) from browser?`}
          subTitle={`You’ll need to enter your credentials again the next time you log into Hypercare on this device.`}
          isOpen={showRemoveAccountModal}
          setIsOpen={setShowRemoveAccountModal}
          modalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Cancel',
              onClickHandler: () => setShowRemoveAccountModal(false),
              id: 'cancel-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Remove',
              onClickHandler: handleRemoveAccount,
              id: 'remove-btn',
            },
          ]}
        />
      )}
    </LoginContainer>
  );
};
