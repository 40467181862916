import React from 'react';
import {HCLabelOne} from 'src/components/HypercareComponents';
import {ADD_LABEL, ProfileAddressMap, ProfileVisibiltyMap} from 'src/constants/strings';
import {PagerType} from 'src/constants/user';
import {StyledAnchor} from 'src/styles/styled-components/component';
import {UserAddress} from 'src/types';
import convertAddressTypeToString from 'src/utils/convertAddressTypeToString';
import formatNationalNumber from 'src/utils/locating/formatNationalNumber';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import {StyledLabelOption, TD} from 'src/pages/LocatingPage/styles/LocatingPageStyles';
import {muiTheme} from 'src/styles/theme';

interface Props {
  address: UserAddress;
  isEditMode: boolean;
  handleContactMethodsAddressAnchor: (address: UserAddress) => void;
  isSelf: boolean;
  onModeChange: (mode: 'view' | 'edit') => void;
  isAdmin: boolean;
}

export const SingleAddressView = ({
  address,
  isEditMode,
  handleContactMethodsAddressAnchor,
  isSelf,
  isAdmin,
  onModeChange,
}: Props) => {
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabel);
  const onAddLabelClick = () => {
    if (isSelf) onModeChange('edit');
  };
  return (
    <tr>
      <TD>{1}</TD>
      {profileAddressLabelFlag && (
        <TD>
          {address?.label ? (
            address.label
          ) : (
            <StyledLabelOption handleLabelOptionClick={onAddLabelClick} text={isSelf ? ADD_LABEL : '--'} />
          )}
        </TD>
      )}
      <TD>{address.type && ProfileAddressMap[address.type]}</TD>
      {
        <TD style={{width: '349px'}}>
          <StyledAnchor
            isEditMode={isEditMode}
            rel="noreferrer"
            target={address?.type === 'email' ? '_blank' : undefined}
            title={`${convertAddressTypeToString(address?.type)}  this person`}
            href={
              address?.type === PagerType.NUMERIC_PAGER || address?.type === PagerType.ALPHANUMERIC_PAGER
                ? undefined
                : `${address?.type === 'email' ? 'mailto' : 'callto'}:${address?.address}`
            }
            onClick={() => handleContactMethodsAddressAnchor(address)}
          >
            <HCLabelOne color={isEditMode ? '' : muiTheme.colors.primary}>
              {address?.type === 'phoneNumber' ? formatNationalNumber(address?.address) : address?.address}
            </HCLabelOne>
          </StyledAnchor>
        </TD>
      }
      {(isAdmin || isSelf) && <TD>{ProfileVisibiltyMap[address?.access]}</TD>}
    </tr>
  );
};
