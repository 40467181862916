import styled from '@emotion/styled';

export const StyledAnchor = styled.a<{isEditMode: boolean}>`
  cursor: pointer;
  text-decoration: none;
  pointer-events: ${(props) => (props.isEditMode ? 'none' : '')};
  &:hover {
    text-decoration: ${(props) => (props.isEditMode ? 'none' : 'underline')};
  }
`;
