import React from 'react';
import client from 'src/apollo';
import styled from '@emotion/styled';
import InputBase from '@mui/material/InputBase';
import GetInviteCodeQuery from 'src/gql/query/GetInviteCodeQuery';
import {SmallLoader} from 'src/components/LoadingDiv';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import CopyIcon from 'src/svgs/CopyIcon';
import Checkmark from 'src/svgs/Checkmark';
import Tooltip from '@mui/material/Tooltip';

const TooltipWrapper = styled.div`
  svg {
    transform: translateY(1px);
    margin-right: 1em;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 24px;
  border: solid 1px ${(props) => props.theme.colors.borderBottomLight};
  background-color: whitesmoke;
`;

const InputBaseWrapper = styled.div`
  margin: 5px 0;
  & .MuiInputBase-input {
    text-align: center;
    color: ${(props) => props.theme.colors.greyishBrown};
    font-size: 22px;
    font-weight: bold;
  }
`;

const CopyButtonWrapper = styled.div`
  display: flex;
  margin-top: 1em;
  cursor: pointer;
  align-items: center;
  color: ${(props) => props.theme.colors.chatTeal};
  span {
    padding-left: 8px;
  }
  svg {
    color: ${(props) => props.theme.colors.chatTeal};
  }
`;

const InviteCodeCopySection = () => {
  const [inviteCode, setInviteCode] = React.useState('');
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const {data} = await client.query({
          query: GetInviteCodeQuery,
        });
        const {inviteCode} = data.me;
        setInviteCode(inviteCode);
      } catch (e) {
        console.error(e);
      }
    })();
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.inviteCode);
  }, []);

  if (!inviteCode) return <SmallLoader />;

  function copyCode() {
    (document.getElementById('inviteCode') as HTMLInputElement).select();
    document.execCommand('copy');
    setShowTooltip(true);
  }

  return (
    <SectionWrapper>
      <InputBaseWrapper>
        <InputBase
          id="inviteCode"
          value={inviteCode}
          inputProps={{
            'aria-label': 'naked',
            readOnly: true,
          }}
        />
      </InputBaseWrapper>
      <Tooltip
        open={showTooltip}
        title={
          <TooltipWrapper>
            <Checkmark />
            <span>Organization code copied. </span>
          </TooltipWrapper>
        }
      >
        <CopyButtonWrapper onClick={() => copyCode()}>
          <CopyIcon />
          <span>Click to copy code</span>
        </CopyButtonWrapper>
      </Tooltip>
    </SectionWrapper>
  );
};

export default React.memo(InviteCodeCopySection);
