import {User} from 'src/types/User';
import {MessageTemplate, MessageTemplateResponse} from './MessageTemplate';
import {MutationFunction} from 'react-apollo';
import {ApolloQueryResult} from 'apollo-client';

export enum PriorityType {
  'stat',
  'urgent',
  'regular',
}

export enum PriorityTypeValue {
  'stat' = 'stat',
  'urgent' = 'urgent',
  'regular' = 'regular',
}

export enum MessagePriorityViewSize {
  'small' = 'small',
  'large' = 'large',
}

export enum MessageRequestType {
  'request',
  'respondingtoRequest',
}

export interface UnreadMsgPayload {
  unreadMessageCount: number;
  unreadPriorityMessageCount: number;
  lastUnreadMessageId: number | null;
}

export interface SendMessagePropsVariables {
  sendMessage: MutationFunction<{}, SendMessageMutationVariables>;
  addOptimisticMessage: (message: Message, chatId: string) => void;
  removeOptimisticMessage: (messageId: string, chatId: string) => void;
  failOptimisticMessageMark: (messageId: string, chatId: string) => void;
}

export interface MessageObjectType {
  uniqueId: string;
  message: string;
  chatId: string;
  selfId: string;
  templateData?: MessageTemplate;
  messagePriority: PriorityType;
  messageId?: string;
  responseData?: MessageTemplateResponse;
  consultData: string | ConsultMessageData;
  files: File[] | null;
  refetch?: any;
  clearSearchOnNewMessage?: any;
  jumpToLatestMessageOnSearch?: boolean;
}

export interface MessagePayload {
  messages: {
    continuationId: string;
    messages: Message[];
  };
}

export interface SendMessageResponse {
  sendMessage: Message;
}

export interface SendMessageMutationVariables {
  chatId: string;
  fileId: number;
  message: string;
  type: MessageType;
  priorityType: string;
  repliedTo?: string;
  templateData?: MessageTemplate;
  data: ConsultMessageData | string;
}

export interface ConsultMessageData {
  mrn?: string;
  firstname?: string;
  lastname?: string;
  details?: string;
  __typename?: string;
}

export interface MessageAttachment {
  fileName: string;
  id: number;
  mimeType: string;
  url: string;
  __typename?: string;
}

export type MessageType = 'system' | 'text' | 'image' | 'consult' | 'attachment' | 'template';

export interface Message {
  id: string;
  message: string;
  image: string; // image is depercated, information will be present in attachment
  type: MessageType;
  sender: User;
  priority?: PriorityType;
  priorityType: string;
  dateCreated: string;
  deliveredTo?: DeliveryReceipt[];
  readBy: ReadReceipt[];
  attachment: MessageAttachment | null;
  repliedTo: Message | null;
  chatId?: string;
  selfId?: string;
  __typename?: string;
  template?: MessageTemplate;
  data?: ConsultMessageData;
  organizationId?: string;
  /* for reducer optimistic msg, frontend only */
  isFailing?: boolean;
  isOptimistic?: boolean;
  file?: File;
}

export interface lastMessage {
  id: number;
  message: string;
  image: string; // image is depercated, information will be present in attachment
  type: MessageType;
  sender: User;
  priority?: PriorityType;
  priorityType: string;
  dateCreated: string;
  deliveredTo?: DeliveryReceipt[];
  readBy: ReadReceipt[];
  attachment: MessageAttachment | null;
  repliedTo: Message;
  chatId?: string;
  selfId?: string;
  __typename?: string;
  template?: MessageTemplate;
  data?: ConsultMessageData;
  organizationId?: string;
  /* for reducer optimistic msg, frontend only */
  isFailing?: boolean;
  isOptimistic?: boolean;
  file?: File;
}

export interface ReadReceipt {
  user: User;
  messageId: string;
  timestamp: string;
  __typename?: string;
}

export interface DeliveryReceipt extends ReadReceipt {
  fillin?: string;
}

export interface PaginatedMessages {
  continuationId: number;
  messages: Message[];
}

export interface MessageWsPayload {
  id: number;
  chatId: string;
  dateCreated: string;
  message: string;
  priorityType: number;
  sender: User;
  type: string;
  userId: string;
  organizationId?: string;
  image?: string;
  imageId?: number;
  imageUrl?: string;
  template?: MessageTemplate;
  data?: ConsultMessageData;
  repliedTo?: number;
}

export interface MessageInChat {
  matchedSequences: string[];
  message: {
    id: string;
    message: string;
    dateCreated: string;
  };
}

export interface GetSearchMessageInChatResult {
  searchQuery: {
    searchMessagesInChat: {
      totalResultsCount: number;
      continuationId: number;
      messages: MessageInChat[];
    };
  };
}

export interface SearchMessagesChat {
  id: string;
  members: SearchMembers[];
  title: string;
  type: string;
}

export interface SearchMembers {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  profilePic?: any;
}

export interface SearchUsers {
  matchedSequences: string[];
  user: SearchMembers;
}

export interface GetSearchUserResult {
  searchQuery: {
    searchUsers: {
      users: SearchUsers[];
    };
  };
}

export interface GetSearchGroupResult {
  searchQuery: {
    searchChatTitles: {
      chats: [
        {
          matchedSequences: string[];
          user: User;
        },
      ];
    };
  };
}

export interface GetSearchMessageResult {
  searchQuery: {
    searchChatTitles: {
      chats: [
        {
          matchedSequences: string[];
          user: User;
        },
      ];
    };
  };
}

export interface Pic {
  size: number;
  fontSize?: number;
}
