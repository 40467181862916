export const minAppWidth = 768;

const sizes = {
  xl: 1920,
  lg: 1280,
  widescreen: 1024,
  md: 960,
  minAppWidth,
  sm: 600,
  xs: 376,
};

type Media = {
  [index: string]: (literals: TemplateStringsArray, ...placeholders: any[]) => string;
};

export const media = Object.keys(sizes).reduce<Media>((accumulator, label) => {
  accumulator[label] = (literals, ...placeholders) => {
    let result = '';
    literals.forEach((lit, index) => {
      result += lit;
      if (index < placeholders.length) result += placeholders[index];
    });
    return `@media (max-width: ${sizes[label]}px) { ${result} }`;
  };
  return accumulator;
}, {});
