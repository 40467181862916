import tryReadChatsQuery from 'src/utils/messengerHelper/tryReadCachedQuery';

export default function ensureChatListLoaded(ms: number) {
  let timeout: number | null = null;
  return new Promise<void>((resolve) => {
    (async function waitForQuery() {
      const chatResult = await tryReadChatsQuery();
      if (chatResult) {
        timeout && clearTimeout(timeout);
        resolve();
      } else {
        timeout = window.setTimeout(() => {
          waitForQuery();
        }, ms);
      }
    })();
  });
}
