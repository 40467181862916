import {Assignee, AssigneeWithRole} from 'src/types';

function sortByStartTime(shiftA: Assignee | AssigneeWithRole, shiftB: Assignee | AssigneeWithRole, isSwap: boolean) {
  let startTimeA = isSwap ? (shiftA as AssigneeWithRole).assignee.startTime : (shiftA as Assignee).startTime;
  let startTimeB = isSwap ? (shiftB as AssigneeWithRole).assignee.startTime : (shiftB as Assignee).startTime;

  if (startTimeA.isBefore(startTimeB)) return -1;
  if (startTimeA.isAfter(startTimeB)) return 1;
  return 0;
}

export const sortStartTimeTypeofAssignee = (shiftA: Assignee, shiftB: Assignee) =>
  sortByStartTime(shiftA, shiftB, false);

export const sortStartTimeTypeofAssigneeWithRole = (shiftA: AssigneeWithRole, shiftB: AssigneeWithRole) =>
  sortByStartTime(shiftA, shiftB, true);
