import {createAction, ActionType, getType} from 'typesafe-actions';
import {User, ColleagueStore} from 'src/types';
import {Actions} from '../reducers';

export const actions = {
  addSelectedColleagues: createAction('colleagues/ADD_MEMBER', (resolve) => (colleague: User) => resolve({colleague})),
  removeSelectedColleagues: createAction(
    'colleagues/REMOVE_MEMBER',
    (resolve) => (colleague: User) => resolve({colleague}),
  ),
  setSelectedColleagues: createAction(
    'colleagues/SET_MEMBER',
    (resolve) => (colleagues: any[]) => resolve({colleagues}),
  ),
  clearAllColleagues: createAction('colleagues/CLEAR_ALL'),
};

export type ColleguesActions = ActionType<typeof actions>;

export const initialState: ColleguesState = {
  selectedColleagues: new Set(),
};

export interface ColleguesState {
  selectedColleagues: any;
}

export default (state: ColleguesState = initialState, action: Actions) => {
  const cloneSet = new Set(state.selectedColleagues);
  switch (action.type) {
    case getType(actions.addSelectedColleagues):
      cloneSet.add({
        id: action.payload.colleague.id,
        label: action.payload.colleague.firstname + ' ' + action.payload.colleague.lastname,
      } as ColleagueStore);
      return {
        selectedColleagues: cloneSet,
      };

    case getType(actions.setSelectedColleagues):
      const selectedColleagues = action.payload.colleagues;
      const result = selectedColleagues
        ? selectedColleagues.map((payload) => {
            return {
              id: payload.value,
              label: payload.label,
            } as ColleagueStore;
          })
        : [];
      return {
        selectedColleagues: new Set(result),
      };

    case getType(actions.removeSelectedColleagues):
      cloneSet.forEach((user) => {
        if ((user as User).id === action.payload.colleague.id) {
          cloneSet.delete(user);
        }
      });
      return {
        selectedColleagues: cloneSet,
      };

    case getType(actions.clearAllColleagues):
      return {
        selectedColleagues: new Set(),
      };

    default:
      return state;
  }
};
