import React from 'react';
import styled from '@emotion/styled';
import {FormikProps} from 'formik';
import convertTypeToReadbleText from 'src/utils/locating/convertAddressTypeToText';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import EmailIcon from 'src/svgs/EmailIcon';
import PhoneIcon from 'src/svgs/PhoneIcon';
import {muiTheme} from 'src/styles/theme';

const ChangTypeHolder = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  margin-top: 0.5em;
  color: ${(props) => props.theme.colors.chatTeal};
  svg {
    transform: scale(0.75);
  }
`;

interface Props {
  isLoading: boolean;
  handleChangeAddressType: () => void;
  formikProps: FormikProps<any>;
}

const AddAddressStepZero = ({formikProps, handleChangeAddressType, isLoading}: Props) => {
  const {values, touched, errors, handleChange} = formikProps;
  return (
    <React.Fragment>
      <TextField
        fullWidth
        autoFocus
        id="address"
        name="address"
        label={convertTypeToReadbleText(values.type)}
        value={values.address}
        disabled={isLoading}
        InputProps={
          values.type === 'phoneNumber'
            ? {
                startAdornment: <InputAdornment position="start">+1</InputAdornment>,
              }
            : undefined
        }
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        onChange={handleChange}
        helperText={touched.address ? errors.address : ''}
        error={touched.address && Boolean(errors.address)}
      />
      <ChangTypeHolder onClick={handleChangeAddressType}>
        {values.type === 'email' ? (
          <PhoneIcon stroke={muiTheme.colors.chatTeal} />
        ) : (
          <EmailIcon fill={muiTheme.colors.chatTeal} />
        )}
        Use {values.type === 'email' ? 'phone' : 'email'} instead
      </ChangTypeHolder>
    </React.Fragment>
  );
};

export default AddAddressStepZero;
