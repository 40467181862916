import {BackButton, StyledBox} from '../styled/signup.styled';
import {Box} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {HCHeadingTwo, HCLabelOne} from '../../components/HypercareComponents';
import {muiTheme} from '../../styles/theme';
import React from 'react';

interface ILoginHeaderProps {
  title: string;
  description?: string;
  onBackButtonClick: () => void;
  headerTitle: React.ReactNode;
}

export const LoginHeader = ({title, description, onBackButtonClick, headerTitle}: ILoginHeaderProps) => {
  return (
    <StyledBox>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <BackButton onClick={onBackButtonClick}>
          <ArrowBackIcon />
        </BackButton>

        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          {headerTitle}
        </Box>
      </Box>
      <HCHeadingTwo color={muiTheme.colors.black} margin={'16px 0 16px 0'}>
        {title}
      </HCHeadingTwo>
      <HCLabelOne margin={'0 0 16px 0'}>{description}</HCLabelOne>
    </StyledBox>
  );
};
