import React from 'react';
import {Box, Dialog, Divider, Typography, DialogContent, DialogActions, FormControlLabel} from '@mui/material';
import store, {typedUseSelector} from 'src/redux';
import {actions} from 'src/redux/actions/messageTemplates';
import {MaterialDialogHeader, Transition} from 'src/components/HypercareComponents';
import {PrimaryButton, SecondaryButton, ThemedCheckbox} from 'src/styles/styled-components/StyledMaterialComponents';
import styled from '@emotion/styled';

const PREFIX = 'MessageTemplateDisclaimerModal';

const classes = {
  root: `${PREFIX}-root`,
  disclaimerHeader: `${PREFIX}-disclaimerHeader`,
  disclaimerDescription: `${PREFIX}-disclaimerDescription`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.disclaimerHeader}`]: {
    fontSize: 21,
    fontWeight: 700,
    lineHeight: '28px',
    fontFamily: 'Nunito Sans',
  },

  [`& .${classes.disclaimerDescription}`]: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
}));

export const StyledDialogActions = DialogActions;

interface MessageTemplateDisclaimerModalProps {
  disclaimerModalNextBtnHandler: () => void;
  showMessageTemplateDisclaimerModal: boolean;
  closeMessageTemplateDisclaimerModal: () => void;
}
const MessageTemplateDisclaimerModal = ({
  disclaimerModalNextBtnHandler,
  showMessageTemplateDisclaimerModal,
  closeMessageTemplateDisclaimerModal,
}: MessageTemplateDisclaimerModalProps) => {
  const {selectedTemplate} = typedUseSelector((state) => state.messageTemplate);
  const [isInstructionsRead, setIsInstructionsRead] = React.useState<boolean>(false);

  // Whenever we close this modal using cancel button or close icon, reset the useState variables before closing the modal
  const closeDisclaimerModal = () => {
    store.dispatch(actions.resetToInitalState());
    setIsInstructionsRead(false);
    closeMessageTemplateDisclaimerModal();
  };

  return (
    <StyledDialog
      maxWidth={'sm'}
      fullWidth={true}
      open={showMessageTemplateDisclaimerModal}
      onClose={closeMessageTemplateDisclaimerModal}
      TransitionComponent={Transition}
    >
      <MaterialDialogHeader
        dialogTitle={'Message templates'}
        closeIconButtonClickHandler={() => closeDisclaimerModal()}
      />
      <DialogContent>
        <Typography gutterBottom classes={{body1: classes.disclaimerHeader}}>
          {selectedTemplate?.disclaimer?.title}
        </Typography>
        <Typography classes={{body1: classes.disclaimerDescription}}>{selectedTemplate?.disclaimer?.body}</Typography>
      </DialogContent>
      <Divider />
      <StyledDialogActions
        style={{display: 'block'}}
        classes={{
          root: classes.root,
        }}
      >
        <Box>
          <FormControlLabel
            key={'instructions_chkbox'}
            control={
              <ThemedCheckbox
                name={'instructions_chkbox'}
                checked={isInstructionsRead}
                onChange={(event) => setIsInstructionsRead(event.target.checked)}
              />
            }
            label={'I have read and understood the instructions'}
          />
        </Box>
        <Box style={{textAlign: 'end'}}>
          <SecondaryButton style={{marginRight: 8}} onClick={() => closeDisclaimerModal()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            disabled={!isInstructionsRead}
            onClick={() => {
              setIsInstructionsRead(false);
              disclaimerModalNextBtnHandler();
            }}
          >
            Next
          </PrimaryButton>
        </Box>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default MessageTemplateDisclaimerModal;
