import * as React from 'react';
import moment from 'moment';
import {Weekday, ActionCalendar, DayOfWeek, DateGrid} from './ActionCalendarGridSystem';
import {CalenderViewItem} from './CalenderViewItem';
import {CalenderViewDataSourceProtocol} from './CalenderViewDataSourceProtocol';

export class CalenderView {
  calenderItem: JSX.Element[] = [];

  constructor() {
    for (let i = 0; i < CalenderViewDataSourceProtocol.gridCount; i++) {
      this.calenderItem.push(
        <CalenderViewItem
          index={CalenderViewDataSourceProtocol.proceedingArr + i}
          dateIndicatorValue={(i + 1).toString()}
          content={<></>}
        />,
      );
    }

    for (let i = 0; i < CalenderViewDataSourceProtocol.proceedingArr; i++)
      this.calenderItem.unshift(
        <CalenderViewItem
          index={CalenderViewDataSourceProtocol.proceedingArr - i - 1}
          dateIndicatorValue=""
          content={<></>}
        />,
      );

    for (let i = 0; i < CalenderViewDataSourceProtocol.succeedingArr; i++)
      this.calenderItem.push(
        <CalenderViewItem
          index={i + CalenderViewDataSourceProtocol.proceedingArr + CalenderViewDataSourceProtocol.gridCount}
          dateIndicatorValue=""
          content={<></>}
        />,
      );
  }

  addDayOfWeek() {
    const weekdaysArr = moment.weekdaysShort();
    return (
      <DayOfWeek>
        {weekdaysArr.map((weekday, i) => (
          <Weekday key={`week-${i}`}>{weekday}</Weekday>
        ))}
      </DayOfWeek>
    );
  }

  renderTableData() {
    return (
      <ActionCalendar>
        {this.addDayOfWeek()}
        <DateGrid>
          <React.Fragment>
            {this.calenderItem.map((item, index) => {
              return this.calenderItem[index];
            })}
          </React.Fragment>
        </DateGrid>
      </ActionCalendar>
    );
  }

  addDatatoCalenderItem(index: number, data: JSX.Element) {
    this.calenderItem[CalenderViewDataSourceProtocol.proceedingArr + index] = (
      <CalenderViewItem
        index={CalenderViewDataSourceProtocol.proceedingArr + index}
        dateIndicatorValue={String(index + 1)}
        content={data}
      />
    );
  }
}
