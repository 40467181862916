import React, {useState} from 'react';
import styled from '@emotion/styled';
import {TextField} from '@mui/material';
import WarningIcon from 'src/svgs/WarningIcon';
import {inputValidation, inputValidationNumericPager} from 'src/utils/validations';
import {PagerType} from 'src/constants/user';

const PREFIX = 'PagerMessageTabView';

const classes = {
  input: `${PREFIX}-input`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.input}`]: {
    '&::placeholder': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px !important',
      lineHeight: '24px !important',
    },
  },
}));

const ErrorMessage = styled.div`
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: red;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 0;
`;

const PagerMessageWrapper = styled.div<{height: string}>`
  max-height: 500px;
  overflow-y: auto;
  margin-top: 1em;
  padding-top: 10px;
  .MuiInputBase-root {
    align-items: flex-start !important;
    min-height: ${(props) => props.height};
  }
  .MuiAutocomplete-endAdornment {
    display: none !important;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
    min-width: 100px !important;
  }
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-right: 9px !important;
  }
`;

const HintContainer = styled.div`
  gap: 5px;
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  color: ${(props) => props.color};
  margin-top: 8px;
  margin-bottom: 24px;
`;

const TextSizeWrapper = styled.div`
  text-align: right;
  width: fit-content;
  position: absolute;
  right: 24px;
`;

interface Props {
  errorMessage: string;
  setErrorMessage: (string) => void;
  setPagerMessage: (string) => void;
  isSubmitting: boolean;
  setSubmitting: (boolean) => void;
  inputType?: string;
}

// TODO: separate all concerns into different hooks and views

const PagerMessageTabView = ({
  errorMessage,
  setErrorMessage,
  setPagerMessage,
  isSubmitting,
  setSubmitting,
  inputType,
}: Props) => {
  const [inputMessageLength, setInputMessageLength] = useState(0);
  const MaxInput = inputType === PagerType.ALPHANUMERIC_PAGER ? '235' : '16';

  const onInputChange = (e: React.ChangeEvent<{}>, value: string) => {
    setPagerMessage(value);
    if (!errorMessage && isSubmitting) {
      setErrorMessage('');
      setSubmitting(false);
    }
    if (Boolean(value) && errorMessage && value.slice(-1) !== ' ' && value.slice(-1) !== ',') {
      setErrorMessage('');
      setSubmitting(false);
    }

    setInputMessageLength(value.length);

    inputType === PagerType.ALPHANUMERIC_PAGER
      ? inputValidation(value)
          .validate(value)
          .then((_) => {
            setErrorMessage('');
            setSubmitting(false);
          })
          .catch((err) => {
            setErrorMessage(err.errors[0]);
            setSubmitting(true);
          })
      : inputValidationNumericPager
          .validate(value)
          .then((_) => {
            setErrorMessage('');
            setSubmitting(false);
          })
          .catch((err) => {
            setErrorMessage(err.errors[0]);
            setSubmitting(true);
          });
  };

  return (
    <Root>
      <PagerMessageWrapper height={inputType === PagerType.ALPHANUMERIC_PAGER ? '6em !important' : '48px !important'}>
        <TextField
          fullWidth
          multiline={inputType === PagerType.ALPHANUMERIC_PAGER ? true : false}
          autoFocus
          rows={inputType === PagerType.ALPHANUMERIC_PAGER ? 5 : 1}
          color={errorMessage ? 'secondary' : 'primary'}
          variant="outlined"
          placeholder="Enter your message here"
          InputProps={{classes: {input: classes.input}}}
          onChange={(e) => onInputChange(e, e.target.value)}
          type="text"
          inputProps={{'data-testid': 'alphanumeric-pager-input', id: 'alphanumeric-pager-input'}}
        />
      </PagerMessageWrapper>
      <HintContainer color={errorMessage.length > 0 ? 'red' : '#767676'}>
        {errorMessage && (
          <div>
            <WarningIcon width={14} height={14} />
          </div>
        )}
        {errorMessage && <ErrorMessage data-testid="errmsg">{errorMessage}</ErrorMessage>}
        <TextSizeWrapper>{`${inputMessageLength}/${MaxInput}`}</TextSizeWrapper>
      </HintContainer>
    </Root>
  );
};

export default PagerMessageTabView;
