import React, {useEffect, useState, useRef} from 'react';
import styled from '@emotion/styled';
import {UserOrganizationSwitcherPayload} from 'src/types';
import FetchUnreadChatCount from 'src/gql/query/FetchUnreadChatCount';
import {useQuery} from '@apollo/react-hooks';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {QueryResult} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import client from 'src/apollo';
import {useFetchUsersOrganizations} from 'src/utils/fetchUsersOrganizations';

import {SwitchAccountsDropdownItems} from './SwitchAccountsDropdownItems';
import {IconWrapper, RowItemsLeftInfo} from '../../../../microfrontend/styled/login.styled';
import {
  HCHeadingFour,
  HCTextContext,
  HCTextContextThree,
  HCTextContextTwo,
  HCTextSpanContextThree,
} from '../../../../components/HypercareComponents';
import WarningAltIcon from '../../../../svgs/WarningAltIcon';
import {localStorageService} from '../../../../services/localStorageService';
import {OrganizationAccountsCacheData} from '../../../../types/sta';
import {ORGANIZATION_ACCOUNTS_DATA} from '../../../../constants/storageKeys';
import {checkForLoggedOutAccounts, getCurrentLoggedInAccount} from '../../../../utils/sta/staUtils';
import {muiTheme} from 'src/styles/theme';
import {usePopper} from 'react-popper';
import {FilterPreferredRolesListItemPopperArrow} from '../../../SchedulingPage/action-calendar/filter-section/FilterPreferredRolesListItem';
import {
  LEARN_MORE,
  ORG_WELCOME_POPUP_BUTTON_TEXT,
  ORG_WELCOME_POPUP_DESCRIPTION_ONE,
  ORG_WELCOME_POPUP_DESCRIPTION_TITLE,
  ORG_WELCOME_POPUP_DESCRIPTION_TWO,
} from '../../../../constants/strings';

const itemHeight = 40;

const DropdownToggler = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
  justify-content: center;
`;

const DropdownMenu = styled.ul`
  list-style: none;
  padding: 8px;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 50px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  margin-top: 50px;
  min-height: ${itemHeight}px;
  max-height: 600px;
  overflow-y: auto;

  width: 500px;
`;

const triangleBoxBase = 1;
const triangleBoxSides = 1;
const triangleBase = 0.5;

export const OrganizationWelcomePopupContainer = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  background: #ffffff;
  width: 350px;
  border-radius: 8px;
  box-shadow: 0px 18px 28px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  padding: 16px;
  border: 1px solid #d8d8d8;

  margin-left: 8px;
  justify-content: center;
  .arrow {
    left: 50%;
    top: -1em;
    margin-left: -${triangleBoxSides}em;
    height: ${triangleBoxBase}em;
    width: ${triangleBoxSides}em;
    transform: rotate(90deg);
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-style: solid;
      border-width: ${triangleBase}em ${triangleBoxSides}em ${triangleBase}em 0;
      border-color: transparent #d8d8d8 transparent transparent;
    }
  }
`;

const OrganizationWelcomePopupOrgInfoContainer = styled.div`
  display: flex;
  padding-left: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid #d8d8d8;
  background: #f6f6f9;
`;

const OrganizationWelcomePopupOrgInfoButton = styled.button`
  border: none;
  color: #37869b;
  width: fit-content;
`;

interface Props {
  onSelect?: (option: UserOrganizationSwitcherPayload, isAcceptingTOS?: boolean) => Promise<void>;
  client?: ApolloClient<any>;
  currentOrganization?: UserOrganizationSwitcherPayload;
}

// TODO: in future track unread count somewhere else and reflects the changes base on message read Oand incoming message socket event;
interface SelfUnreadChatCountResult {
  me: {
    unreadChatCount: {
      count: number;
      organizationId: number;
    }[];
  };
}

export const SwitchAccountDropdownContainer = ({onSelect, currentOrganization}: Props) => {
  const node = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  const {data}: QueryResult<SelfUnreadChatCountResult> = useQuery(FetchUnreadChatCount);
  const {organizationListData} = useFetchUsersOrganizations();
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const currentLoggedInAccount = getCurrentLoggedInAccount(currentCacheData);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const [showWelcomeFirstLoginPopup, setShowWelcomeFirstLoginPopup] = useState(
    currentLoggedInAccount?.firstLogin ?? false,
  );
  const unreadList = data && data.me ? data.me.unreadChatCount : null;

  const getCountFromUnreadList = (orgId: number): string | null => {
    if (!unreadList) return null;
    const target = unreadList.find((list) => list.organizationId === orgId);
    return target ? (target.count > 99 ? '99+' : `${target.count}`) : null;
  };

  const handleClickAway = (e) => {
    const modal = document.querySelector('.MuiDialog-container');
    const isVisible = !!(modal?.clientWidth || modal?.clientHeight || modal?.getClientRects().length);

    if ((node && node.current && node.current.contains(e.target)) || isVisible) {
      return;
    }

    setOpen(false);
  };

  const handleCloseWelcomePopupClick = () => {
    setShowWelcomeFirstLoginPopup(false);
  };

  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
    placement: 'bottom-start',
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      AnalyticsManager.recordPageVisited(PAGE_VIEWS.switchOrganizationView);
      client.query({
        query: FetchUnreadChatCount,
        fetchPolicy: 'network-only',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!currentLoggedInAccount) {
    return <>No current selected acc</>;
  }

  return (
    <DropdownToggler ref={node} title="switch organization" onClick={() => setOpen(true)}>
      <React.Fragment>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px', paddingLeft: '8px'}}>
          <IconWrapper>
            {currentLoggedInAccount.organization.imageUrl ? (
              <img src={currentLoggedInAccount.organization.imageUrl} width="40px" height="40px" alt={'org logo'} />
            ) : (
              currentLoggedInAccount.organization.name[0]
            )}
          </IconWrapper>
          <div>
            <HCTextContext lineHeight={'24px'} color={muiTheme.colors.black}>
              {`${currentLoggedInAccount.user.firstname} ${currentLoggedInAccount.user.lastname}`}
              {checkForLoggedOutAccounts(currentCacheData) && <WarningAltIcon width={16} height={16} />}
            </HCTextContext>
            <HCTextContext lineHeight={'24px'} color={muiTheme.colors.text}>
              {currentLoggedInAccount.organization.name}
            </HCTextContext>
          </div>
          {showWelcomeFirstLoginPopup && (
            <OrganizationWelcomePopupContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '16px'}}>
                <OrganizationWelcomePopupOrgInfoContainer>
                  <IconWrapper>
                    {currentLoggedInAccount.organization.imageUrl ? (
                      <img
                        src={currentLoggedInAccount.organization.imageUrl}
                        width="40px"
                        height="40px"
                        alt="org logo"
                      />
                    ) : (
                      currentLoggedInAccount.organization.name[0]
                    )}
                  </IconWrapper>
                  <RowItemsLeftInfo>
                    <HCTextContextTwo lineHeight="24px" color={muiTheme.colors.black}>
                      {currentLoggedInAccount.organization.url}
                    </HCTextContextTwo>
                    <HCTextContextThree lineHeight="18px" color={muiTheme.colors.text}>
                      {currentLoggedInAccount.organization.name}
                    </HCTextContextThree>
                  </RowItemsLeftInfo>
                </OrganizationWelcomePopupOrgInfoContainer>
                <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                  <HCTextContext lineHeight="24px">
                    {ORG_WELCOME_POPUP_DESCRIPTION_TITLE(currentLoggedInAccount.organization.name)}
                  </HCTextContext>
                  <HCTextContextThree lineHeight="21px">
                    {ORG_WELCOME_POPUP_DESCRIPTION_ONE} <b>{currentLoggedInAccount.organization.url}</b>
                    {'. '}
                    {ORG_WELCOME_POPUP_DESCRIPTION_TWO}{' '}
                    <a
                      onClick={() => AnalyticsManager.applyAnalytics({eventName: EVENTS.loginContactSupport})}
                      href="https://www.hypercare.com/contact"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <HCTextSpanContextThree color={muiTheme.colors.black} lineHeight="21px">
                        {LEARN_MORE}
                      </HCTextSpanContextThree>
                    </a>
                  </HCTextContextThree>
                </div>
                <OrganizationWelcomePopupOrgInfoButton onClick={handleCloseWelcomePopupClick}>
                  <HCHeadingFour color={muiTheme.colors.chatTeal} fontWeight={700}>
                    {ORG_WELCOME_POPUP_BUTTON_TEXT}
                  </HCHeadingFour>
                </OrganizationWelcomePopupOrgInfoButton>
              </div>
              <FilterPreferredRolesListItemPopperArrow ref={setArrowElement} className="arrow" />
            </OrganizationWelcomePopupContainer>
          )}
        </div>
        {isOpen && (
          <DropdownMenu>
            <SwitchAccountsDropdownItems currentActiveAccount={currentLoggedInAccount} />
          </DropdownMenu>
        )}
      </React.Fragment>
    </DropdownToggler>
  );
};
