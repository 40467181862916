import * as React from 'react';
import {AuthRegion, User} from 'src/types';
import styled from '@emotion/styled';
import LeftSectionWrapper from 'src/pages/MessengerPage/messenger/page-header/LeftSectionWrapper';
import RightSectionWrapper from 'src/pages/MessengerPage/messenger/page-header/RightSectionWrapper';
import {minAppWidth} from 'src/styles/utils';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';

const Header = styled.div`
  display: flex;
  padding: 10px 15px;
  height: 60px;
  min-height: 60px;
  min-width: ${minAppWidth}px;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  box-shadow: 0 4px 2px -2px whitesmoke;
`;

interface Props {
  logout: () => void;
  user: User;
  authRegion: AuthRegion;
  flags?: {[key: string]: boolean};
}

interface State {
  isMenuOpen: boolean;
}

class MessengerPageHeader extends React.PureComponent<Props, State> {
  public render() {
    const {user, logout, authRegion, flags} = this.props;
    return (
      <Header>
        <LeftSectionWrapper />
        <RightSectionWrapper user={user} logout={logout} authRegion={authRegion} />
      </Header>
    );
  }
}

export default withLDConsumer()(MessengerPageHeader);
