import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Chevron from 'src/svgs/Chevron';
import styled from '@emotion/styled';
import InvitedUserList from 'src/pages/ContactsPage/InvitedUserList';
import {SmallLoader} from 'src/components/LoadingDiv';
import useRefetchInvitedUsers from './hooks/useFetchInvitedUsers';

const PREFIX = 'InvitationUserListWrapper';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  expandWrapper: `${PREFIX}-expandWrapper`,
  summaryHeaderWrapper: `${PREFIX}-summaryHeaderWrapper`,
};

const UserListWrapper = styled.div`
  display: contents;
  max-height: 100%;
`;

const StyledUserListWrapper = styled(UserListWrapper)(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.expandWrapper}`]: {
    boxShadow: 'none',
    margin: '0 !important',
  },

  [`& .${classes.summaryHeaderWrapper}`]: {
    position: 'sticky',
    top: 0,
    padding: '4px 0.5em',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));
const InviteSummary = styled.span`
  font-weight: bold;
  font-family: Nunito;
`;

const NoResult = styled.p`
  margin-top: 1em;
  font-size: 16px;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

interface Props {
  searchKeyword: string;
}

const InvitationUserListWrapper = ({searchKeyword}: Props) => {
  const {loading, error, invitationResultList} = useRefetchInvitedUsers({
    searchKeyword,
  });

  if (loading || error || !invitationResultList) return <SmallLoader />;

  const noSearchResult = invitationResultList.length === 0 && Boolean(searchKeyword);

  return (
    <StyledUserListWrapper className={classes.root}>
      <Accordion defaultExpanded={true} className={classes.expandWrapper}>
        <AccordionSummary
          className={classes.summaryHeaderWrapper}
          expandIcon={<Chevron />}
          aria-controls="invite-summary"
        >
          <Typography className={classes.heading}>
            <InviteSummary>Pending Invites ({invitationResultList.length})</InviteSummary>
          </Typography>
        </AccordionSummary>
        {noSearchResult ? (
          <NoResult>No results found</NoResult>
        ) : (
          <AccordionDetails style={{padding: 0}}>
            <InvitedUserList invitedUserList={invitationResultList} />
          </AccordionDetails>
        )}
      </Accordion>
    </StyledUserListWrapper>
  );
};

export default InvitationUserListWrapper;
