/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {useIdleTimer} from 'react-idle-timer/dist/index.legacy.cjs.js';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {ModalWrapper} from 'src/components/SessionTimeout/sessionTimeoutModals/IdleModal';
import {
  MODAL_POPUP,
  MODAL_NOTIFICATION_POPUP_TEXT,
  SESSION_TIMEOUT_LOGINPAGE_MODAL,
  USER_IS_IDLE,
  USER_IS_IDLE_NOTIFICATION_TEXT,
  LAST_ACTIVE_TIME,
} from 'src/constants/sessionTimeout';

interface Props {
  organizationIdleTimeoutValue: number;
  showModal: boolean;
  logout: () => void;
  handleModalChange: (arg: boolean) => void;
}

export const IdleTimerManager = ({showModal, organizationIdleTimeoutValue, logout, handleModalChange}: Props) => {
  const promptBeforeIdle = 300000;

  const notificationHandler = (title: string, options: NotificationOptions) => {
    const notification = new Notification(title, options);
    notification.onclick = () => {
      window.focus();
      notification.close();
    };
  };

  const notificationBuilder = (notificationType: string) => {
    const appIsNotVisible = document.visibilityState !== 'visible';

    const notificationTitle = 'Hypercare';
    let notificationBody: string | undefined = undefined;

    switch (notificationType) {
      case USER_IS_IDLE:
        notificationBody = `${USER_IS_IDLE_NOTIFICATION_TEXT} for ${organizationIdleTimeoutValue / 60000} minutes`;
        break;
      case MODAL_POPUP:
        notificationBody = MODAL_NOTIFICATION_POPUP_TEXT;
        break;
      default:
        break;
    }

    const notificationOptions = {
      body: notificationBody,
    };

    if (appIsNotVisible) notificationHandler(notificationTitle, notificationOptions);
  };

  const onIdle = () => {
    window.localStorage.removeItem(LAST_ACTIVE_TIME);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.sessionTimeoutIdleLogout,
    });
    notificationBuilder(USER_IS_IDLE);
    logout();
    window.sessionStorage.setItem(SESSION_TIMEOUT_LOGINPAGE_MODAL, 'true');
  };

  const handleModalClose = () => {
    handleModalChange(false);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.sessionTimeoutIdleDialogContinue,
    });
    IdleTimer.start();
  };

  const onPrompt = () => {
    handleModalChange(true);
    notificationBuilder(MODAL_POPUP);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.sessionTimeoutIdleDialogShown,
    });
  };

  const handleBeforeUnload = () => {
    const time = new Date().getTime();
    localStorage.setItem(LAST_ACTIVE_TIME, `${time}`);
  };

  const checkForIdle = () => {
    const currentTime = new Date().getTime();
    const lastActiveTime = parseInt(localStorage.getItem(LAST_ACTIVE_TIME)!);

    if (currentTime - lastActiveTime >= organizationIdleTimeoutValue) onIdle();
  };

  useEffect(() => {
    checkForIdle();
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const IdleTimer = useIdleTimer({
    onPrompt,
    onIdle,
    eventsThrottle: 2000,
    promptBeforeIdle,
    timeout: organizationIdleTimeoutValue,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    debounce: 2000,
    startOnMount: true,
  });

  return showModal ? <ModalWrapper isOpen={showModal} logout={logout} onClose={handleModalClose} /> : null;
};
