import * as R from 'ramda';
import store from 'src/redux';
import {actions as messageActions} from 'src/redux/actions/messages';
import {actions as draftsActions} from 'src/redux/actions/drafts';
import {TEXT, ATTACHMENT, TEMPLATE} from 'src/constants/messageTypes';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import getChatFromId from 'src/utils/messengerHelper/getChatFromId';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import client from 'src/apollo';
import {ARCHIVED, MESSENGER} from 'src/constants/routerPathName';
import {
  Chat,
  User,
  Message,
  MessageTemplate,
  PriorityType,
  ConsultMessageData,
  MessageType,
  FileResponse,
  UploadFileSuccess,
  SendMessagePropsVariables,
  SendMessageMutationVariables,
  MessageObjectType,
  MessageTemplateResponse,
} from 'src/types';
import ApiHelper from 'src/api';
import {MutationFunction} from 'react-apollo';

interface SendMessageHelperParams {
  uniqueId: string;
  chat: Chat;
  sendMessageMutation: MutationFunction<{}, SendMessageMutationVariables>;
  message: string;
  type: MessageType;
  priorityType: PriorityType;
  fileId?: number;
  consultData?: ConsultMessageData | string;
  templateData?: MessageTemplate | string;
  responseData?: MessageTemplateResponse | string;
  messageId?: string;
  repliedToId: string | null;
}

interface createOptimisticMessageParams {
  uniqueId: string;
  chatId: string;
  message: string;
  priority: PriorityType;
  me: User;
  messageType: MessageType;
  attachment?: any;
  file?: File;
  consultData?: ConsultMessageData | string;
  templateData?: MessageTemplate | string;
  responseData?: MessageTemplateResponse | string;
  messageId?: string;
  repliedTo?: Message;
  selfId?: string;
}

interface FileUploadParams {
  filesMessageObject: MessageObjectType;
  sendMessageMutation: MutationFunction<{}, SendMessageMutationVariables>;
  retryRepliedTo?: Message;
}

/**
 * remove store state for target repliedTo chat after retrieving messageId to hide preview ui
 */
const getRepliedToMessageId = (chatId: string): string | null => {
  let messageId: string | null = null;
  const {draftMsgToChatId} = store.getState().drafts;
  const hasReply: boolean = draftMsgToChatId[chatId] && Boolean(draftMsgToChatId[chatId].repliedTo);
  if (hasReply) {
    messageId = draftMsgToChatId[chatId].repliedTo?.id ?? null;
    store.dispatch(draftsActions.deleteRepliedTo(chatId));
  }
  return messageId;
};

/**
 * Mimic Message data as in apollo cache
 * OptimisticMessage is used for showing loading status of a message,
 * and also for retry sending message if it failed due to any error
 */
const createOptimisticMessage = ({
  uniqueId,
  chatId,
  selfId,
  message,
  me,
  priority,
  attachment,
  file,
  messageType,
  consultData,
  templateData,
  responseData,
  messageId,
  repliedTo,
}: createOptimisticMessageParams): Message => {
  const optimisticId = uniqueId;
  let parsedMsgData: ConsultMessageData | null = null;
  let parsedTemplateMessageData: MessageTemplate | null = null;
  let parsedResponseData: MessageTemplateResponse | null = null;

  const {draftMsgToChatId} = store.getState().drafts;
  const repliedToMessage: Message | undefined =
    (draftMsgToChatId[chatId] && draftMsgToChatId[chatId].repliedTo) || repliedTo;

  if (consultData)
    typeof consultData === 'object' ? (parsedMsgData = consultData) : (parsedMsgData = JSON.parse(consultData));
  else if (templateData)
    typeof templateData === 'object'
      ? (parsedTemplateMessageData = templateData)
      : (parsedTemplateMessageData = JSON.parse(templateData));
  else if (responseData)
    typeof responseData === 'object'
      ? (parsedResponseData = responseData)
      : (parsedResponseData = JSON.parse(responseData));

  return {
    id: optimisticId,
    isOptimistic: true,
    selfId,
    file: Boolean(file) ? file : undefined, // for resend, the current flow is not ideal as it upload file again
    message,
    attachment, // TODO: show file upload progression with attachment preview
    image: '',
    type: messageType,
    chatId,
    priorityType: PriorityType[priority],
    deliveredTo: [
      {
        user: me,
        messageId: optimisticId,
        timestamp: new Date().toISOString(),
        __typename: 'DeliveryReceipt',
      },
    ],
    readBy: [
      {
        user: me,
        messageId: optimisticId,
        timestamp: new Date().toISOString(),
        __typename: 'ReadReceipt',
      },
    ],
    dateCreated: new Date().toISOString(),
    sender: me,
    data: consultData ? parsedMsgData ?? undefined : undefined,
    template: templateData ? parsedTemplateMessageData ?? undefined : undefined,
    repliedTo: repliedToMessage || null,
    __typename: 'Message',
  };
};

/**
 * send message mutation and update local cache
 */
const sendMessageHelper = async ({
  uniqueId,
  chat,
  sendMessageMutation,
  message,
  type,
  priorityType,
  fileId,
  consultData,
  templateData,
  responseData,
  messageId,
  repliedToId,
}: SendMessageHelperParams) => {
  const {id: chatId} = chat;
  const isArchive = window.location.href.includes(ARCHIVED);

  // Mutation
  await sendMessageMutation({
    variables: {
      chatId,
      uniqueId,
      fileId,
      message,
      type,
      priorityType: PriorityType[priorityType],
      data: consultData,
      templateData,
      responseData,
      messageId,
      repliedTo: repliedToId,
    } as SendMessageMutationVariables,
    update: (store, {data}) => {
      let messageResponse;
      if ((data as any).chat.hasOwnProperty('sendTemplateMessage'))
        messageResponse = R.pathOr({}, ['chat', 'sendTemplateMessage'], data);
      else if ((data as any).chat.hasOwnProperty('sendMessage'))
        messageResponse = R.pathOr({}, ['chat', 'sendMessage'], data);
      else if ((data as any).chat.hasOwnProperty('message'))
        messageResponse = R.pathOr({}, ['chat', 'message', 'template', 'respond'], data);
      else messageResponse = {};
      const chatQuery = store.readQuery({
        query: GetChatQuery,
        variables: {chatId},
      }) as {chat: Chat};
      if (chatQuery && messageResponse) {
        const newQuery = R.evolve({
          chat: {
            messages: {
              messages: (msgs: Message[]) => {
                // bug: investigate this case
                if (msgs.find((pastMessage) => String(pastMessage.id) === String(messageResponse.id))) return msgs;
                return [messageResponse, ...msgs];
              },
            },
            lastMessage: () => messageResponse,
          },
        })(chatQuery);

        store.writeQuery({
          query: GetChatQuery,
          variables: {chatId},
          data: newQuery,
        });

        if (isArchive) window.location.assign(window.location.href.replace(ARCHIVED, MESSENGER));
      }
    },
  });
};

/**
 * Mimic file data as in apollo cache
 */
const generateOptimisticFileResponse = (file: File): FileResponse => {
  return {
    id: file['id'],
    url: '',
    mimeType: file.type,
    fileName: file.name,
    __typename: 'File',
  } as FileResponse;
};

function tryRecordFailedMessage(error: any, chatId: string) {
  AnalyticsManager.applyAnalytics({
    eventName: EVENTS.messageSentFailed,
    params: {
      error_message:
        error.networkError && error.networkError.result && error.networkError.result.errors[0]
          ? error.networkError.result.errors[0].message
          : error.message,
      error_code:
        error.networkError &&
        error.networkError.result &&
        error.networkError.result.errors[0] &&
        error.networkError.result.errors[0].code
          ? error.networkError.result.errors[0].code
          : '',
      status_code: error.networkError ? error.networkError.statusCode : '',
      chat_id: chatId,
    },
  });
}

/**
 * Send message for message type ATTACHMENT
 * file has to be upload to the backend first in order to send the message with that fileId
 */
export const tryFileUpload = async ({filesMessageObject, sendMessageMutation, retryRepliedTo}: FileUploadParams) => {
  const {chatId, selfId, files, messagePriority} = filesMessageObject;
  const chatQuery = await getChatFromId(chatId, client);
  const chat: Chat = chatQuery.chat;

  const me = chat.members.find((m) => m.id === selfId);
  if (!me) return;
  let optimisticMessagesArray: Message[] = [];

  // show all optimistic message first
  for (let index in files) {
    let optimisticMessage = createOptimisticMessage({
      uniqueId: files[index]['id'],
      chatId,
      selfId,
      message: '',
      messageType: ATTACHMENT,
      me,
      attachment: generateOptimisticFileResponse(files[index]),
      consultData: undefined,
      templateData: undefined,
      priority: messagePriority,
      file: files[index],
      repliedTo: retryRepliedTo,
    });
    optimisticMessagesArray.push(optimisticMessage);
    store.dispatch(messageActions.addOptimisticMessage(optimisticMessage, chatId ? chatId : chat.id));
  }

  const repliedToMessageId = (retryRepliedTo && retryRepliedTo.id) || getRepliedToMessageId(chatId);

  for (let index in files) {
    let currentFile = files[index];
    let currentFileResponse;

    const formData = new FormData();
    formData.append(`${ATTACHMENT}-${index}`, files[index]);

    try {
      const result = (await ApiHelper.PrivateEndpoints.uploadFile(formData)) as UploadFileSuccess;

      const {
        data: {response},
      } = result;

      for (let uploadResponse of response) {
        currentFileResponse = uploadResponse;
        await sendMessageHelper({
          uniqueId: currentFile['id'],
          chat: chat,
          sendMessageMutation,
          message: '',
          type: ATTACHMENT,
          priorityType: messagePriority,
          fileId: uploadResponse.id,
          repliedToId: repliedToMessageId,
        });

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.uploadFile,
          params: {
            file_id: uploadResponse.id,
            file_type: files[index].type,
            size_in_mb: Math.floor(files[index].size / 1024),
          },
        });

        store.dispatch(messageActions.removeOptimisticMessage(optimisticMessagesArray[index].id, chat.id));

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.messageSent,
          params: {
            message_type: ATTACHMENT,
            priority_type: PriorityType[messagePriority],
            chat_id: chat.id,
            chat_type: chat.type,
          },
        });
      }
    } catch (error: any) {
      tryRecordFailedMessage(error, chat.id);

      if (currentFileResponse || currentFile) {
        const firstOccuranceMessage = optimisticMessagesArray.find((msg) => {
          let targetName = Boolean(currentFileResponse) ? currentFileResponse.fileName : currentFile.name;
          return msg.attachment && msg.attachment.fileName === targetName;
        });
        store.dispatch(messageActions.failOptimisticMessageMark(firstOccuranceMessage?.id ?? '', chat.id));
      } else {
        console.error('Error uploading file', error);
        throw new Error(error);
      }
    }
  }
};

export const tryTemplateAttachmentUpload = async ({filesMessageObject}) => {
  const {files} = filesMessageObject;
  let attachmentIds: number[] = [];

  for (let index in files) {
    const formData = new FormData();
    formData.append(`${ATTACHMENT}-${index}`, files[index]);

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.uploadFile,
      params: {
        type: files[index].type,
        size: files[index].size,
      },
    });

    try {
      const result = (await ApiHelper.PrivateEndpoints.uploadFile(formData)) as UploadFileSuccess,
        {
          data: {response},
        } = result;

      for (let uploadResponse of response) {
        attachmentIds.push(uploadResponse.id);
      }
    } catch (error: any) {
      console.error('Error uploading file', error);
      throw new Error(error);
    }
  }

  return attachmentIds;
};

/**
 * Send message for message type TEXT or TEMPLATE
 * @param {MessageObjectType} messageObject - message inputs, empty when type is TEMPLATE
 * @param {object} sendMessagePropsVariables - props from the caller component, see SendMessagePropsVariables
 * @param {Message} retryRepliedTo? - only passed in when retrying to send failed message
 */
export async function trySendMessage(
  messageObject: MessageObjectType,
  sendMessagePropsVariables: SendMessagePropsVariables,
  retryRepliedTo?: Message,
) {
  const {sendMessage, addOptimisticMessage, failOptimisticMessageMark, removeOptimisticMessage} =
    sendMessagePropsVariables;

  const {message, messagePriority, chatId, selfId, consultData, uniqueId, templateData, responseData, messageId} =
    messageObject;

  const chatQuery = await getChatFromId(chatId, client);
  const chat: Chat = chatQuery.chat;

  const me = chat.members.find((m: User) => m.id === selfId);
  if ((message || consultData || templateData || responseData) && me) {
    const optimisticMessage = createOptimisticMessage({
      uniqueId,
      chatId,
      selfId,
      messageType: templateData ? TEMPLATE : TEXT,
      consultData: consultData ? consultData : undefined,
      templateData: templateData ? templateData : undefined,
      message,
      priority: messagePriority,
      me,
      repliedTo: retryRepliedTo,
    });

    addOptimisticMessage(optimisticMessage, chatId ? chatId : chat.id);
    const repliedToMessageId = (retryRepliedTo && retryRepliedTo.id) || getRepliedToMessageId(chatId);

    try {
      await sendMessageHelper({
        uniqueId,
        chat,
        sendMessageMutation: sendMessage,
        message: consultData || templateData ? '' : message,
        type: templateData ? TEMPLATE : TEXT,
        priorityType: messagePriority,
        consultData,
        templateData,
        responseData,
        messageId,
        repliedToId: repliedToMessageId,
      });
      // note: most of the case the OptimisticMessage already removed from the socket event when handling incoming message
      removeOptimisticMessage(optimisticMessage.id, chat.id);

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.messageSent,
        params: {
          message_type: templateData ? TEMPLATE : TEXT,
          priority_type: PriorityType[messagePriority],
          chat_id: chat.id,
          chat_type: chat.type,
        },
      });
    } catch (error) {
      tryRecordFailedMessage(error, chat.id);

      failOptimisticMessageMark(optimisticMessage.id, chat.id);
      console.error(error);
      throw error;
    }
  }
}

export default {
  tryFileUpload,
  trySendMessage,
  tryTemplateAttachmentUpload,
};
