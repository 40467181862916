import React from 'react';
import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Locate from 'src/svgs/Locate';
import {SiteScope} from 'src/types';
import {HypercareOnCallActions} from 'src/redux/actions/onCall';
import store from 'src/redux';
import {MinimalSiteData} from '../../../../gql/query/FetchSitesForOrganization';

const StyledLocateIcon = styled(Locate)`
  position: absolute;
  left: 10px;
  z-index: 1;
  width: 1em;
  height: 1em;
`;

const StyledInputWithoutBorder = styled(StyledInputBase)`
  .MuiSelect-select {
    /* border: none; */
    padding-left: 2em;
  }
`;

const NoSiteMessageHolder = styled.p`
  padding: 3em 12px;
  text-align: center;
`;

interface Props {
  onSelectedSiteChange: (selectedSite: MinimalSiteData) => void;
  selectedSite: MinimalSiteData;
  siteList: MinimalSiteData[];
}

const SiteDropdown = ({onSelectedSiteChange: setSelectedSite, selectedSite, siteList}: Props) => {
  const [siteId, setSiteId] = React.useState(selectedSite ? selectedSite.id : null);

  const handleChange = (event: SelectChangeEvent<any>) => {
    let newSelectedSite = siteList.find((site) => site.id === parseInt(event.target.value));
    store.dispatch(HypercareOnCallActions.setOnCallDepartments([]));
    if (newSelectedSite) {
      store.dispatch(HypercareOnCallActions.setCurrentSelectedSite({...newSelectedSite, id: newSelectedSite.id}));
      setSelectedSite(newSelectedSite);
    }
    setSiteId(parseInt(event.target.value));
  };

  if (!siteId)
    return (
      <NoSiteMessageHolder>
        Please contact your organization administrator if you would like to create a department.
      </NoSiteMessageHolder>
    );

  return (
    <div>
      <StyledFormControl>
        <Select
          value={siteId}
          onChange={handleChange}
          input={<StyledInputWithoutBorder name="siteDropdown" startAdornment={<StyledLocateIcon />} />}
        >
          {siteList.map((site) => (
            <MenuItem value={site.id} key={site.id}>
              {site.name}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </div>
  );
};

export default SiteDropdown;
