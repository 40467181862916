import React from 'react';
import ApiHelper from '../../../../api';
import {SSO_VENDOR} from '../../../../constants/storageKeys';
import {AuthSSOVendors, RequestVerificationPayload} from '../../../../types';
import {AccountStatus, OrgLoginMethods} from '../../../../types/sta';
import {TOO_MANY_CHALLENGES} from '../../../../constants/networkError';
import AuthHelper from '../../../../auth/authHelper';
import authHelper from '../../../../auth/authHelper';
import {convertAuthResponseToAuthDTO} from '../../../../auth/convertAuthResponseToAuthDTO';
import {checkIfUserHasLoginScope} from '../../../../utils/userHelper/userUtils';

export const OrganizationViewModel = () => {
  const fetchOrganizationByURL = async (orgURL: string) => {
    const result = await ApiHelper.PublicEndpoints.fetchOrganizationByURL(orgURL);

    if (result.data?.findOrganizationByURL.__typename === 'OrganizationNotFound') {
      return {data: null, error: 'Organization Not Found'};
    }
    if (result) return {data: result.data, error: false};

    return {data: null, error: 'Network error'};
  };

  const fetchOrganizationByName = async (name: string) => {
    const result = await ApiHelper.PublicEndpoints.fetchOrganizationByName(name);

    if (result) return result;
  };

  const submitEmailAddress = async (email: string, orgUrl: string) => {
    const basicUser = await getBasicUser(email, orgUrl);
    const getEnteredEmailDomain = email.split('@')[1];

    sessionStorage.setItem('email', email);

    if (!basicUser || basicUser === 'UserNotFound') {
      return {error: 'No account found for this organization'};
    }

    const firstSSOProfile = basicUser.ssoProfiles?.[0];

    const checkAccountForSSOScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.SSO);
    const checkAccountForOTPScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.OTP);
    const checkAccountForPasswordScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.PASSWORD);

    if (firstSSOProfile?.domain === getEnteredEmailDomain && checkAccountForSSOScope) {
      if (firstSSOProfile?.auth0Id && firstSSOProfile?.ssoVendor) {
        localStorage.setItem(SSO_VENDOR, firstSSOProfile?.ssoVendor);
        if (firstSSOProfile?.ssoVendor === AuthSSOVendors.WORKOS) {
          await getSSOAuthorizationUrl(firstSSOProfile?.auth0Id, firstSSOProfile?.ssoVendor);
        } else {
          return {auth0Id: firstSSOProfile?.auth0Id, address: ''};
        }
      }
    }

    if (basicUser.accountStatus === AccountStatus.ACTIVE || basicUser.accountStatus === AccountStatus.SHELL) {
      if (checkAccountForSSOScope && firstSSOProfile) {
        if (firstSSOProfile.auth0Id && firstSSOProfile.ssoVendor) {
          localStorage.setItem(SSO_VENDOR, firstSSOProfile.ssoVendor);
          if (firstSSOProfile.ssoVendor === AuthSSOVendors.WORKOS) {
            await getSSOAuthorizationUrl(firstSSOProfile.auth0Id, firstSSOProfile.ssoVendor);
          } else {
            return {auth0Id: firstSSOProfile.auth0Id, address: ''};
          }
        }
      } else {
        if (checkAccountForOTPScope || checkAccountForPasswordScope) {
          if (basicUser.accountStatus === AccountStatus.SHELL) {
            // claim shell acc
          } else {
            if (checkAccountForOTPScope) {
              //show otp
              const startRequestOTPFlow = await requestAddressOTP({address: email, type: 'email'});

              if (typeof startRequestOTPFlow === 'string') {
                return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow};
              } else {
                return startRequestOTPFlow;
              }
            }

            if (checkAccountForPasswordScope) {
              // show password view
              return {screen: OrgLoginMethods.PASSWORD};
            }

            return {error: 'No supported login methods'};
          }
        } else {
          return {error: 'Please use corp email to sign in'};
        }
      }
    }

    if (basicUser.accountStatus === AccountStatus.INACTIVE) {
      //check if account is associated with any removed accounts
      return {error: 'Account has been removed'};
    } else {
      //check for org bound email
    }

    return {email};
  };

  const getBasicUser = async (email: string, orgUrl: string) => {
    const res = await ApiHelper.PublicEndpoints.fetchBasicUser(email, orgUrl);

    if (res?.data?.user.__typename === 'UserNotFound') {
      return 'UserNotFound';
    }

    if (res.data) {
      return res.data.user;
    }
  };

  const checkIfOrgBoundEmail = (email: string) => {
    // check open door org, WIP
  };

  const submitPassword = async (orgUrl: string, email: string, password: string) => {
    const res = await authHelper.fetchAuthInfoWithEmailAndPassword(orgUrl, email, password);
    if (!res || res.data.error) {
      return {
        error: res.data.error_description ?? 'Error occurred when fetching password for organization',
      };
    }

    if (res && res.data) {
      const convertResponseToAuth = convertAuthResponseToAuthDTO(res.data);

      return {
        data: convertResponseToAuth,
      };
    }
  };

  const submitOTP = async (challengeId: string, otp: string, orgUrl: string) => {
    const res = await ApiHelper.PrivateEndpoints.ValidateAddressVerification(challengeId, otp);

    if (!res || res?.data?.errors) {
      return {
        error: res.data?.errors?.[0].message ?? 'Error occurred when fetching OTP',
      };
    }

    if (res && res.data?.response.status) {
      const otpGrantTypeResponse = await exchangeOTPTokenForPartialToken(challengeId);

      if (otpGrantTypeResponse.data?.access_token) {
        const otpExchangeToken = await AuthHelper.exchangeToken(orgUrl, otpGrantTypeResponse.data?.access_token);

        if (otpExchangeToken?.data?.error) {
          return {
            error: otpExchangeToken.data.error_description,
          };
        }
        if (otpExchangeToken.data) {
          const convertResponseToAuth = convertAuthResponseToAuthDTO(otpExchangeToken.data);

          return {
            data: convertResponseToAuth,
          };
        }
      }
      if (otpGrantTypeResponse.error) {
        return {success: false, error: otpGrantTypeResponse.error};
      }
    }

    return {
      success: false,
      error: 'Error occurred',
    };
  };

  const exchangeOTPTokenForPartialToken = async (challengeId: string) => {
    const res = await AuthHelper.exchangeOTPTokenForPartialAccessToken(challengeId);
    return res;
  };

  const requestAddressOTP = async ({address, type}: RequestVerificationPayload) => {
    const res = await ApiHelper.PrivateEndpoints.addressVerificationRequest({address, type});

    if (res && res?.data?.response) {
      const challengeId: string = res.data.response.challengeId;
      return challengeId;
    } else {
      if (res && res?.data?.errors[0].name === TOO_MANY_CHALLENGES) {
        return {
          error: 'Too many challenges, please wait before starting another challenge',
        };
      } else {
        return {
          error: 'Failed to request validation code, please check your internet connection and try again',
        };
      }
    }
  };

  const activateShellAccount = () => {};

  const checkIfAddressIsSSO = async (email: string) => {
    const ssoProfileResult = await ApiHelper.PublicEndpoints.getSSODomainsForUsername(email);

    if (ssoProfileResult?.success && ssoProfileResult.data?.ssoProfileForUser) {
      const {auth0Id, ssoId, ssoVendor} = ssoProfileResult.data.ssoProfileForUser;
      return {auth0Id, ssoId, ssoVendor};
    }

    if (ssoProfileResult?.error) {
      let error = ssoProfileResult.error?.response?.data?.errors[0]?.message || ssoProfileResult.error[0]?.message;
      return {error};
    }
  };

  const getSSOAuthorizationUrl = async (connectionId: string, provider: AuthSSOVendors) => {
    const result = await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(connectionId, provider);
    return result;
  };

  const getListOfOrgsByEmail = (email: string) => {
    //hamad@hypercare.com
    //check if hypercare is inside the list
    let url = 'ds.hypercare.com';
    return url;
  };

  const handleAccountDiscoveryFlow = async (email: string) => {
    const isEmailSSO = await checkIfAddressIsSSO(email);

    if (isEmailSSO) {
      const accMatchedUrl = await getListOfOrgsByEmail(email);

      const basicUser = await getBasicUser(email, accMatchedUrl);

      if (!basicUser || basicUser === 'UserNotFound') {
        return {error: 'UserNotFound'};
      }

      let isEmailLinkedWithActiveOrShellAccount = true;

      if (isEmailLinkedWithActiveOrShellAccount) {
        if (basicUser.accountStatus === AccountStatus.ACTIVE || basicUser.accountStatus === AccountStatus.SHELL) {
          let checkForMoreThanOneAccount = true;

          if (checkForMoreThanOneAccount) {
            //  check if account is logged in. If yes - continue
            //  if no
            // check if shell account. If yes - dont long the user into the acc but save the acc on device
            // if no
            // does the account have sso scope and own the sso email. If yes: is the authenticated email the sso email
            // of the org
            // if no. save the account
            // if no
            // check account login methods: otp and password. If yes: user logged into that account, save acc on device
            // if no
            // dont log user in
          } else {
            let checkForShellAccount = true;

            if (checkForShellAccount) {
              //activate shell account
            } else {
              // account is active
              //is the acc logged in via SSO & without SSO scope? throw error: sso not supported
            }
          }
        } else {
          //system create account is domain org
        }
      } else {
        const checkIfAccountIsRemoved = basicUser.accountStatus === AccountStatus.INACTIVE;

        if (checkIfAccountIsRemoved) {
          return {error: 'Account has been removed'};
        } else {
          // redirect to signup
        }
      }
    } else {
      //redirect to signup
    }
  };

  return {
    fetchOrganizationByURL,
    submitEmailAddress,
    fetchOrganizationByName,
    submitPassword,
    submitOTP,
    handleAccountDiscoveryFlow,
  };
};
