import {AuthPayload} from 'src/types';
import HypercareSignupRepository from '../HypercareSignupRepository';
import sleep from 'src/utils/sleep';
import {ALREADY_EXISTS, INVITE_CODE_NOT_FOUND, VULNERABLEPASSWORD} from 'src/constants/networkError';

class HypercareSignupCreateAccountViewModel {
  nextButtonPressed;
  repository: HypercareSignupRepository;

  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    try {
      const createUserAccountResult = await this.handleCreateUserAccount(values);
      if (createUserAccountResult?.data.data.createUser.username) {
        const authResult = await this.handleFetchUserAuthInfo(
          createUserAccountResult.data.data.createUser.username,
          values.password,
        );
        if (authResult.success) {
          const authPayload: AuthPayload = {...(authResult.data as AuthPayload)};
          const result = await this.handleAcceptEula(authPayload.accessToken as string);

          // TODO: fix with proper handling - temp solution as backend input delay
          // e.g. might not write the status before login call
          await sleep(2000);

          if (result) this.nextButtonPressed(values);
        } else {
          return {error: 'accessToken'};
        }
      } else {
        if (createUserAccountResult.data.data.createUser && createUserAccountResult.data.data.createUser.message) {
          return {error: createUserAccountResult.data.data.createUser.message};
        }
      }
    } catch (error: any) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };

  handleCreateUserAccount = async (payload) => {
    const createUserResult = await this.repository?.createUserAccount(payload);
    return createUserResult;
  };

  handleFetchUserAuthInfo = async (username, password) => {
    const userAuthInfo = await this.repository?.fetchAuthInfo(username, password);
    return userAuthInfo;
  };

  handleAcceptEula = async (accessToken) => {
    const fetchEulaPayload = await this.repository?.acceptEula(accessToken);
    return fetchEulaPayload;
  };
}

export default HypercareSignupCreateAccountViewModel;
