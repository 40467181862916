import React from 'react';
import {StyledTooltipWhite} from 'src/components/StyledTooltip';
import {
  CONTACT,
  PREFERENCE_ORDER,
  PROFILE_PAGE_PREFERENCE_ORDER_TOOLTIP_TEXT,
  TYPE,
  VISIBILITY,
  LABEL,
} from 'src/constants/strings';
import InfoIcon from 'src/svgs/InfoIcon';
import styled from '@emotion/styled';
import {IsFeatureFlagEnabled} from '../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../utils/FeatureFlags';

const TH = styled.th`
  text-align: left;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  padding: 8px;
`;

const TR = styled.tr`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #d8d8d8;
`;

const PreferenceCellWrapper = styled.div<{isEditMode: boolean}>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: ${(props) => (props.isEditMode ? '26px' : '')};
`;

const PreferencesCellIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  isEditMode: boolean;
  isSelf: boolean;
  isAdmin: boolean;
}

export const HypercareProfileAddressTableHeader = ({isEditMode, isSelf, isAdmin}: Props) => {
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabel);
  return (
    <thead style={{borderBottom: '1px solid black'}}>
      <TR>
        <TH style={{width: '150px'}}>
          <PreferenceCellWrapper isEditMode={isEditMode}>
            <span>{PREFERENCE_ORDER}</span>
            <StyledTooltipWhite
              arrow
              title={PROFILE_PAGE_PREFERENCE_ORDER_TOOLTIP_TEXT}
              className="styledPopper"
              placement="top"
            >
              <PreferencesCellIconWrapper>
                <InfoIcon />
              </PreferencesCellIconWrapper>
            </StyledTooltipWhite>
          </PreferenceCellWrapper>
        </TH>
        {profileAddressLabelFlag && <TH style={{width: '150px'}}>{LABEL}</TH>}
        <TH style={{width: '150px'}}>{TYPE}</TH>
        <TH>{CONTACT}</TH>
        {(isEditMode || isSelf || isAdmin) && <TH style={{width: '150px'}}>{VISIBILITY}</TH>}
        {isEditMode && <TH style={{width: '100px'}}></TH>}
      </TR>
    </thead>
  );
};
