import {combineReducers} from 'redux';
import {createAction, ActionType, getType} from 'typesafe-actions';
import MessagesReducer, {MessagesState, MessageActions} from 'src/redux/actions/messages';
import ColleaguesReducer, {ColleguesState, ColleguesActions} from 'src/redux/actions/colleagues';
import SideBarReducer, {SidebarState, SidebarActions} from 'src/redux/actions/sidebar';
import OrganizationReducer, {OrganizationActions, OrganizationState} from 'src/redux/actions/organization';
import FileDropReducer, {DroppedFileState, FileDropActions} from 'src/redux/actions/filedrop';
import DraftsReducer, {DraftsState, DraftsActions} from 'src/redux/actions/drafts';
import TradeShiftReducer, {TradeShiftState, TradeShiftActions} from 'src/redux/actions/tradeShift';
import SchedulingReducer, {SchedulingState, SchedulingActions} from 'src/redux/actions/scheduling';
import MessageTemplatesReducer, {
  MessageTemplateActions,
  MessageTemplateState,
} from 'src/redux/actions/messageTemplates';
import CTIReducer, {CTIState, CTIActions} from 'src/redux/actions/cti';
import hypercareRegionReducer, {HypercareRegionState, AuthRegionActions} from 'src/redux/actions/authRegion';
import hypercareOnCallReducer, {HypercareOnCallState, onCallActions} from 'src/redux/actions/onCall';
import currentAccountReducer, {CurrentAccountActions, CurrentAccountState} from './actions/currentAccount';

const rootActions = {
  resetRedux: createAction('RESET'),
  alertAuthProvider: createAction('AUTH_ALERT'),
};

type rootActions = ActionType<typeof rootActions>;

export interface State {
  readonly messages: MessagesState;
  readonly colleagues: ColleguesState;
  readonly sidebar: SidebarState;
  readonly organization: OrganizationState;
  readonly filedrop: DroppedFileState;
  readonly drafts: DraftsState;
  readonly tradeShift: TradeShiftState;
  readonly scheduling: SchedulingState;
  readonly messageTemplate: MessageTemplateState;
  readonly auth: {
    tokenLastRefreshAt: number | null;
  };
  readonly cti: CTIState;
  readonly hypercareRegion: HypercareRegionState;
  readonly onCall: HypercareOnCallState;
  readonly currentSelectedAccount: CurrentAccountState;
}

export type Actions =
  | MessageActions
  | ColleguesActions
  | SidebarActions
  | OrganizationActions
  | FileDropActions
  | DraftsActions
  | TradeShiftActions
  | SchedulingActions
  | MessageTemplateActions
  | rootActions
  | CTIActions
  | AuthRegionActions
  | onCallActions
  | CurrentAccountActions;

const appReducer = combineReducers<State, Actions>({
  messages: MessagesReducer,
  colleagues: ColleaguesReducer,
  sidebar: SideBarReducer,
  organization: OrganizationReducer,
  filedrop: FileDropReducer,
  drafts: DraftsReducer,
  tradeShift: TradeShiftReducer,
  scheduling: SchedulingReducer,
  messageTemplate: MessageTemplatesReducer,
  auth: (state = {tokenLastRefreshAt: null}) => state,
  cti: CTIReducer,
  hypercareRegion: hypercareRegionReducer,
  onCall: hypercareOnCallReducer,
  currentSelectedAccount: currentAccountReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: State | undefined, action: Actions) => {
  switch (action.type) {
    case getType(rootActions.resetRedux):
      return appReducer(undefined, action);

    case getType(rootActions.alertAuthProvider):
      if (!state) return appReducer(state, action);
      return appReducer(
        {
          ...state,
          auth: {
            tokenLastRefreshAt: Date.now(),
          },
        },
        action,
      );

    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
