import React from 'react';
import {FormikProps} from 'formik';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import ResetPasswordFormStepZero from 'src/pages/ResetPasswordPage/reset-password/ResetPasswordFormStepZero';
import ResetPasswordFormStepOne from 'src/pages/ResetPasswordPage/reset-password/ResetPasswordFormStepOne';
import ResetPasswordFormStepTwo from 'src/pages/ResetPasswordPage/reset-password/ResetPasswordFormStepTwo';
import ResetPasswordController from 'src/pages/ResetPasswordPage/reset-password/ResetPasswordMultiStepFormController';
import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress';
import {Link} from 'react-router-dom';
import {ResetPasswordValidationCodeProps, ResetPasswordFormValues} from 'src/types';

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: 0.5em;
` as React.ComponentType<CircularProgressProps>;

const LoadingText = styled.div`
  color: dimgrey;
  margin-top: 1em;
  font-size: 12px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 1em;
`;

const CompletionTextHolder = styled.p`
  text-align: center;
  margin-top: 2em;
  a {
    color: black;
    font-weight: bold;
    padding-left: 5px;
    text-decoration: underline;
  }
`;

interface Props {
  formikProp: FormikProps<ResetPasswordFormValues>;
  step: number;
  isLoading: boolean;
  validationCodeProps: ResetPasswordValidationCodeProps;
  resendCode: () => Promise<void>;
}

const InnerFormLayout = ({formikProp, step, isLoading, validationCodeProps, resendCode}: Props) => {
  switch (step) {
    case 0:
      return <ResetPasswordFormStepZero formikProps={formikProp} isLoading={isLoading} />;
    case 1:
      return (
        <ResetPasswordFormStepOne
          validationCodeProps={validationCodeProps}
          isLoading={isLoading}
          resendCode={resendCode}
        />
      );
    case 2:
      return <ResetPasswordFormStepTwo formikProps={formikProp} isLoading={isLoading} />;
    default:
      return <div />;
  }
};

const VerticalMultiStepForm = (props: FormikProps<ResetPasswordFormValues>) => {
  const {values} = props;

  const steps = ['Enter Username', 'Verify Reset Token', 'Create a New Password'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [validationCodeArray, setValidationCodeArray] = React.useState(['', '', '', '', '', '']);
  const [validationErrorMsg, setValidationErrorMsg] = React.useState('');
  const [validationResultType, setValidationResultType] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);

  function handleNext() {
    ResetPasswordController.stepController(
      {
        activeStep,
        validationCodeArray,
        setActiveStep,
        setLoading,
        setValidationErrorMsg,
        setValidationResultType,
      },
      props,
    );
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setValidationErrorMsg('');
    setValidationCodeArray(['', '', '', '', '', '']);
  }

  async function resendCode() {
    setValidationErrorMsg('');
    ResetPasswordController.resendValidationCode({
      username: values.username,
      setLoading,
      setValidationErrorMsg,
    });
  }

  return (
    <div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <InnerFormLayout
                formikProp={props}
                step={index}
                isLoading={isLoading}
                resendCode={resendCode}
                validationCodeProps={{
                  validationErrorMsg,
                  validationCodeArray,
                  validationResultType,
                  setValidationCodeArray,
                }}
              />
              {isLoading && (
                <LoadingText>
                  <StyledCircularProgress color="inherit" size={12} />
                  Verifying availability...
                </LoadingText>
              )}
              <ButtonsWrapper>
                {activeStep !== 2 && activeStep !== 0 && (
                  <Button disableTouchRipple disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                )}
                <Button
                  disableTouchRipple
                  variant="contained"
                  color="primary"
                  disabled={
                    isLoading || (activeStep === 1 && validationCodeArray.some((num) => num === '' || num.length > 1))
                  }
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </ButtonsWrapper>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <CompletionTextHolder>
          <span role="img" aria-labelledby="cheers">
            🎉
          </span>{' '}
          All steps completed, you can now go back to
          <Link to="/login">login page</Link>.
        </CompletionTextHolder>
      )}
    </div>
  );
};

export default VerticalMultiStepForm;
