import React from 'react';
import {isEmpty} from 'ramda';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import styled from '@emotion/styled';
import {Mutation, MutationFunction} from 'react-apollo';
import {Dialog, DialogContent, Grid, Divider, LinearProgress} from '@mui/material';

import AlertModal from './AlertModal';
import store, {typedUseSelector} from 'src/redux';
import {AuthContext} from 'src/auth/AuthProvider';
import {TemplateViews, MessageRequestType} from 'src/types';
import StatPriorityIcon from 'src/svgs/StatPriorityIcon';
import UrgentPriorityIcon from 'src/svgs/UrgentPriorityIcon';
import {actions as MessageTemplateActions} from 'src/redux/actions/messageTemplates';
import {
  PrimaryButton,
  SecondaryButton,
  StyledDialogActions,
} from 'src/styles/styled-components/StyledMaterialComponents';
import {actions as MessageActions} from 'src/redux/actions/messages';
import {actions as FiledropActions} from 'src/redux/actions/filedrop';
import sendTemplateMessage from 'src/utils/messengerHelper/SendTemplateMessage';
import {SendTemplateMessageMutation} from 'src/gql/mutation/SendMessageMutation';
import {MaterialDialogHeader, Transition} from 'src/components/HypercareComponents';
import {PriorityType, AuthPayload, SendMessageMutationVariables} from 'src/types';
import MessageTemplateFactory from 'src/pages/MessengerPage/messenger/messages-layout/message-template/MessageTemplateFactory';

const RequestName = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  font-family: 'Nunito Sans', sans-serif;
`;

const MessagePriorityWrapper = styled.div<{priority: number}>`
  background-color: ${(props) =>
    props.priority === PriorityType.urgent ? '#FFC705' : props.priority === PriorityType.stat ? '#CB3E00' : null};
  padding: 4px 8px 4px 0;
  display: flex;
  border-radius: 4px;
  float: left;
  margin-bottom: 8px;
`;

const PriorityStatusText = styled.div<{priority: number}>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.priority === PriorityType.urgent ? '#CB3E00' : props.priority === PriorityType.stat ? 'white' : null};
`;

interface PreviewTemplateLayoutProps {
  chatId: string;
  authInfo: AuthPayload;
  openPreviewMessageTemplate: boolean;
  closePreviewTemplateModal: (boolean) => void;
  previewTemplatePreviousBtnHandler: () => void;
  sendTemplateMessageMutation: MutationFunction<{}, SendMessageMutationVariables>;
}
const PreviewTemplateLayout = ({
  chatId,
  authInfo,
  sendTemplateMessageMutation,
  openPreviewMessageTemplate,
  closePreviewTemplateModal,
  previewTemplatePreviousBtnHandler,
}: PreviewTemplateLayoutProps) => {
  const [priorityConfirmationDialog, setPriorityConfirmationDialog] = React.useState<boolean>(false),
    [quitConfirmationDialog, setQuitConfirmationDialog] = React.useState<boolean>(false),
    {messageTemplatePriority, selectedTemplate, requestTitle} = typedUseSelector((state) => state.messageTemplate),
    [loadingStatus, setLoadingStatus] = React.useState({isLoading: false, loadingMessage: ''});

  const sendMessageHandler = () => {
    setPriorityConfirmationDialog(false);
    sendTemplateMessage(
      MessageRequestType.request,
      chatId,
      authInfo,
      sendTemplateMessageMutation,
      null,
      null,
      setLoadingStatus,
    )
      .then((_) => {
        setPriorityConfirmationDialog(false);
        closePreviewTemplateModal(false);
        store.dispatch(MessageTemplateActions.resetToInitalState());
      })
      .catch((e) => {
        console.error(e);
        toast.error('Failed to make the network request, please check your internet connection and try again');
      });
  };

  const TemplateMessagePriority = () => {
    return (
      <MessagePriorityWrapper priority={messageTemplatePriority}>
        {messageTemplatePriority === PriorityType.stat ? (
          <StatPriorityIcon height={20} width={20} />
        ) : messageTemplatePriority === PriorityType.urgent ? (
          <UrgentPriorityIcon height={20} width={20} />
        ) : null}
        <PriorityStatusText priority={messageTemplatePriority}>
          {messageTemplatePriority === PriorityType.stat
            ? 'STAT'
            : messageTemplatePriority === PriorityType.urgent
            ? 'URGENT'
            : null}
        </PriorityStatusText>
      </MessagePriorityWrapper>
    );
  };

  return (
    <React.Fragment>
      <Dialog maxWidth={'sm'} fullWidth={true} open={openPreviewMessageTemplate} TransitionComponent={Transition}>
        {loadingStatus.isLoading && <LinearProgress />}
        <MaterialDialogHeader
          dialogTitle={'Review request'}
          closeIconButtonClickHandler={() => setQuitConfirmationDialog(true)}
        />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item container>
              <Grid item lg={12}>
                {messageTemplatePriority !== PriorityType.regular && <TemplateMessagePriority />}
              </Grid>
              <Grid item lg={12}>
                <RequestName>{`${selectedTemplate?.formTitle} ${
                  !isEmpty(requestTitle) ? `- ${requestTitle}` : ''
                }`}</RequestName>
              </Grid>
            </Grid>
            {selectedTemplate &&
              new MessageTemplateFactory(selectedTemplate, TemplateViews.RequestReview).createUIWithJSONParser()}
          </Grid>
        </DialogContent>
        <Divider />
        <StyledDialogActions>
          <SecondaryButton
            disabled={loadingStatus.isLoading}
            onClick={() => previewTemplatePreviousBtnHandler()}
            style={{marginRight: 4}}
          >
            Previous
          </SecondaryButton>
          <PrimaryButton
            disabled={loadingStatus.isLoading}
            onClick={() => {
              if (messageTemplatePriority !== PriorityType.regular) setPriorityConfirmationDialog(true);
              else sendMessageHandler();
            }}
          >
            {loadingStatus.isLoading ? loadingStatus.loadingMessage : 'Send request'}
          </PrimaryButton>
        </StyledDialogActions>
      </Dialog>
      <AlertModal
        title={`Send request as ${
          messageTemplatePriority === PriorityType.stat
            ? 'STAT'
            : messageTemplatePriority === PriorityType.urgent
            ? 'URGENT'
            : ''
        }?`}
        subtitle={
          messageTemplatePriority === PriorityType.stat
            ? 'This will be sent as a high-priority message and will bypass silent and do-not-disturb modes, which may play a loud notification.'
            : 'This will be sent as a medium-priority message and will bypass silent and do-not-disturb modes, which may play a loud notification.'
        }
        closeAlertModal={setPriorityConfirmationDialog}
        isAlertModalVisible={priorityConfirmationDialog}
        alertModalButtons={[
          {
            buttonLabel: 'Cancel',
            type: 'secondary',
            onClickHandler: () => setPriorityConfirmationDialog(false),
          },
          {
            buttonLabel: 'Send',
            type: 'primary',
            onClickHandler: () => sendMessageHandler(),
          },
        ]}
      />
      <AlertModal
        title={'Quit editing?'}
        subtitle={'All the information that you have entered so far will not be saved. This action cannot be undone.'}
        closeAlertModal={setQuitConfirmationDialog}
        isAlertModalVisible={quitConfirmationDialog}
        alertModalButtons={[
          {
            buttonLabel: 'Cancel',
            type: 'secondary',
            onClickHandler: () => setQuitConfirmationDialog(false),
          },
          {
            buttonLabel: 'Quit anyway',
            type: 'primary',
            onClickHandler: () => {
              store.dispatch(FiledropActions.clearDroppedFiles());
              store.dispatch(MessageTemplateActions.resetToInitalState());
              setQuitConfirmationDialog(false);
              closePreviewTemplateModal(false);
            },
          },
        ]}
      />
    </React.Fragment>
  );
};

const PreviewTemplateLayoutManager = React.memo(PreviewTemplateLayout);

export default connect<{}, {}, any>(null, {
  addOptimisticMessage: MessageActions.addOptimisticMessage,
  removeOptimisticMessage: MessageActions.removeOptimisticMessage,
  failOptimisticMessageMark: MessageActions.failOptimisticMessageMark,
})((props: any) => (
  <AuthContext.Consumer>
    {({authInfo}) => (
      <Mutation mutation={SendTemplateMessageMutation}>
        {(sendTemplateMessageMutation) => (
          <PreviewTemplateLayoutManager
            {...props}
            sendTemplateMessageMutation={sendTemplateMessageMutation}
            authInfo={authInfo}
          />
        )}
      </Mutation>
    )}
  </AuthContext.Consumer>
));
