import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AppBar from '@mui/material/AppBar';
import {useTheme} from '@mui/material/styles';
import MessageItem from 'src/pages/MessengerPage/messenger/search-chat-list-layout/MessageItem';
import {SINGLE, USERS, GROUP, GROUPS, MESSAGES} from 'src/constants/user';
import LoadingDiv from 'src/components/LoadingDiv';
import {SearchChatTypes, SearchUserTypes} from 'src/constants/messageTypes';
import SearchNoRecordFound from 'src/pages/MessengerPage/messenger/SearchNoRecordFound';
import styled from '@emotion/styled';

const PREFIX = 'SearchTabItem';

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  indicator: `${PREFIX}-indicator`,
  root3: `${PREFIX}-root3`,
  selected: `${PREFIX}-selected`,
  root4: `${PREFIX}-root4`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.root3}`]: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const tabProps = (index) => {
  return {
    id: `search-chat-tab-${index}`,
    'aria-controls': `search-chat-tabpanel-${index}`,
  };
};

const FetchMoreLoadingWrapper = styled.div`
  height: 28rem;
  padding: 5px;
`;

const StyledAppBar = AppBar;

const ChatTypeTabs = Tabs;

const ChatTypeTab = Tab;

const TabPanel = (props) => {
  const {
    value,
    index,
    searchUserData,
    searchGroupData,
    searchMessageData,
    searchSubMessageData,
    handleShowMatchedMessage,
    isSearching,
    handleJumpToMessage,
    handleViewAll,
    totalUsersCount,
    totalGroupsCount,
    totalMessageCount,
    handleChange,
    activeChat,
    isBack,
    setIsBack,
    selectedChat,
    setSelectedChat,
    selectedChatTitle,
    setSelectedChatTitle,
    handleSetActiveChat,
    createChat,
    isJumpToMessageEnable,
    ...other
  } = props;

  const usersChatList = searchUserData;
  const groupChatList = searchGroupData.filter((chat) => chat?.chat?.type === GROUP);
  const messages = isBack ? searchSubMessageData.searchMessages.messages : searchMessageData;

  return (
    <Root
      role="tabpanel"
      hidden={value !== index}
      id={`search-chat-tabpanel-${index}`}
      aria-labelledby={`search-chat-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {value === 0 ? (
            usersChatList.length === 0 && groupChatList.length === 0 ? (
              <SearchNoRecordFound tabValue={value} handleChange={handleChange} />
            ) : (
              <>
                <MessageItem
                  chats={usersChatList}
                  title={USERS}
                  type={SINGLE}
                  createChat={createChat}
                  totalUsersCount={totalUsersCount}
                  totalGroupsCount={totalGroupsCount}
                  handleViewAll={handleViewAll}
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  selectedChatTitle={selectedChatTitle}
                  setSelectedChatTitle={setSelectedChatTitle}
                  activeChat={activeChat}
                  handleSetActiveChat={handleSetActiveChat}
                />
                <MessageItem
                  chats={groupChatList}
                  title={GROUPS}
                  type={GROUP}
                  totalUsersCount={totalUsersCount}
                  totalGroupsCount={totalGroupsCount}
                  handleViewAll={handleViewAll}
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  selectedChatTitle={selectedChatTitle}
                  setSelectedChatTitle={setSelectedChatTitle}
                  activeChat={activeChat}
                  handleSetActiveChat={handleSetActiveChat}
                />
              </>
            )
          ) : messages.length === 0 ? (
            <SearchNoRecordFound tabValue={value} handleChange={handleChange} />
          ) : (
            <MessageItem
              chats={messages}
              title={MESSAGES}
              isBack={isBack}
              setIsBack={setIsBack}
              handleJumpToMessage={handleJumpToMessage}
              showMatchedMessage={handleShowMatchedMessage}
              selectedChat={selectedChat}
              setSelectedChat={setSelectedChat}
              selectedChatTitle={selectedChatTitle}
              setSelectedChatTitle={setSelectedChatTitle}
              activeChat={activeChat}
              handleSetActiveChat={handleSetActiveChat}
              totalMessageCount={totalMessageCount}
              isJumpToMessageEnable={isJumpToMessageEnable}
            />
          )}
        </>
      )}
    </Root>
  );
};

const SearchTabItem = (props) => {
  const {
    tabValue,
    searchUserData,
    searchGroupData,
    searchMessageData,
    searchSubMessageData,
    fetchMessageList,
    handleShowMatchedMessage,
    isSearching,
    handleJumpToMessage,
    handleViewAll,
    totalUsersCount,
    totalGroupsCount,
    totalMessageCount,
    activeChat,
    isBack,
    setIsBack,
    handleChange,
    handleChangeIndex,
    setSelectedChat,
    selectedChat,
    selectedChatTitle,
    setSelectedChatTitle,
    handleSetActiveChat,
    createChat,
    isJumpToMessageEnable,
  } = props;

  const theme = useTheme();
  const searchTabs = SearchChatTypes;

  return (
    <div className={classes.root}>
      <StyledAppBar
        position="static"
        color="default"
        classes={{
          root: classes.root,
        }}
      >
        <ChatTypeTabs
          value={tabValue}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="search-chat-list"
          classes={{
            root: classes.root2,
            indicator: classes.indicator,
          }}
        >
          {searchTabs.map((type, i) => {
            return (
              <ChatTypeTab
                key={i}
                label={type}
                {...tabProps(i)}
                onClick={() => (i === 1 ? fetchMessageList() : null)}
                classes={{
                  root: classes.root3,
                  selected: classes.selected,
                }}
              />
            );
          })}
        </ChatTypeTabs>
      </StyledAppBar>
      <div onChange={handleChangeIndex}>
        {isSearching ? (
          <FetchMoreLoadingWrapper>
            <LoadingDiv />
          </FetchMoreLoadingWrapper>
        ) : (
          searchTabs.map((type, i) => {
            return (
              <TabPanel
                key={i}
                value={tabValue}
                index={i}
                searchUserData={searchUserData}
                searchGroupData={searchGroupData}
                searchMessageData={searchMessageData}
                searchSubMessageData={searchSubMessageData}
                handleShowMatchedMessage={handleShowMatchedMessage}
                isSearching={isSearching}
                handleJumpToMessage={handleJumpToMessage}
                dir={theme.direction}
                handleViewAll={handleViewAll}
                totalUsersCount={totalUsersCount}
                totalGroupsCount={totalGroupsCount}
                totalMessageCount={totalMessageCount}
                activeChat={activeChat}
                isBack={isBack}
                handleChange={handleChange}
                setIsBack={setIsBack}
                setSelectedChat={setSelectedChat}
                selectedChat={selectedChat}
                selectedChatTitle={selectedChatTitle}
                setSelectedChatTitle={setSelectedChatTitle}
                handleSetActiveChat={handleSetActiveChat}
                createChat={createChat}
                isJumpToMessageEnable={isJumpToMessageEnable}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default SearchTabItem;
