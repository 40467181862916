import * as React from 'react';
import {Redirect} from 'react-router-dom';
import styled from '@emotion/styled';
import PasswordBox from 'src/pages/ChangePasswordPage/change-password/PasswordBox';
import Helmet from 'react-helmet';
import {AuthContext} from 'src/auth/AuthProvider';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

const HorizontalLogoWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
  padding-top: 140px;
  padding-bottom: 97px;
`;

const PageWrapper = styled.div`
  max-width: 480px;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
`;

const ChangePwdTitle = styled.h1`
  font-family: Nunito;
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.2px;
  text-align: center;
  color: dimgrey;
  margin-bottom: 35px;
`;

const ChangePwdContainer = styled.div`
  width: 100%;
  min-width: 310px;
`;

interface Props {
  isLoggedIn: boolean;
}

class ChangePasswordPage extends React.PureComponent<Props> {
  public componentDidMount() {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.changePassword);
  }

  public render() {
    const {isLoggedIn} = this.props;
    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }
    return (
      <PageWrapper>
        <Helmet>
          <title>Change Password - Hypercare</title>
        </Helmet>
        <ChangePwdContainer>
          <HorizontalLogoWrapper>
            <img src="/assets/horizontal-logo-red.svg" alt="hypercare logo with text" />
          </HorizontalLogoWrapper>
          <ChangePwdTitle>Change Password</ChangePwdTitle>
          <PasswordBox />
        </ChangePwdContainer>
      </PageWrapper>
    );
  }
}

export default () => (
  <AuthContext.Consumer>{({isLoggedIn}) => <ChangePasswordPage isLoggedIn={isLoggedIn} />}</AuthContext.Consumer>
);
