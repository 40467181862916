import React from 'react';
import {DepartmentScope, EscalationLadder, EscalationType} from 'src/types';

export interface ISelectionContext {
  selectedDepartment: DepartmentScope | null;
  selectedEscalationLadder: EscalationLadder | null;
}

export interface EscalationModalStepVariableContextInterface {
  escalatingMessageText: string;
  escalationType: EscalationType | null;
  selectionContext: ISelectionContext | null;
  setEscalationType: (escalation: EscalationType) => void;
  setSelectionContext: (context: ISelectionContext) => void;
}

export const EscalationModalStepVariableContext = React.createContext<EscalationModalStepVariableContextInterface>({
  escalatingMessageText: '',
  escalationType: null,
  selectionContext: null,
  setEscalationType: () => {},
  setSelectionContext: () => {},
});

interface Props {
  message: string;
  children?: React.ReactNode;
}

const EscalationModalStepProvider = ({message, children}: React.PropsWithChildren<Props>) => {
  const [escalationType, setEscalationType] = React.useState<EscalationType | null>(null);
  const [selectionContext, setSelectionContext] = React.useState<ISelectionContext | null>(null);

  return (
    <EscalationModalStepVariableContext.Provider
      value={{
        escalatingMessageText: message,
        escalationType,
        setEscalationType,
        selectionContext,
        setSelectionContext,
      }}
    >
      {children}
    </EscalationModalStepVariableContext.Provider>
  );
};

export default EscalationModalStepProvider;
