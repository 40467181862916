import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {INVITE_CODE_NOT_FOUND, ALREADY_EXISTS, VULNERABLEPASSWORD} from 'src/constants/networkError';
import {AuthPayload, Login} from 'src/types';
import {toast} from 'react-toastify';
import sleep from 'src/utils/sleep';
import {AuthContext} from 'src/auth';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {passwordSchema, usernameSchema, confirmPasswordSchema} from 'src/utils/validations';

import {CustomCheckbox} from 'src/styles/styled-components/StyledMaterialComponents';
import {StepContainerCopy} from '../components/step-container-copy';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewStepHeader from '../components/step-header-copy';
import NewNextStepButton from '../components/NextStepButton';
import NewStepFooter from '../components/step-footer-copy';
import {FormControlLabel, Icon, IconButton, InputAdornment, Typography} from '@mui/material';
import {muiTheme} from 'src/styles/theme';

interface IAutoLoginProps {
  password: string;
  login: Login;
}

export interface ICreateAccountStepInputData {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
  emailVerificationChallengeId: string;
  orgInviteCode: string;
  orgName: string;
}

export interface ICreateAccountStepOutputData {
  password: string;
  confirmPassword: string;
}

interface ICreateAccountViewProps {
  inputData: ICreateAccountStepInputData;
  onBackButtonClick: () => void;
  viewModel: any;
}

const validationSchema = yup.object().shape<ICreateAccountStepOutputData>({
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema,
});

export const CreateAccountView = ({inputData, onBackButtonClick, viewModel}: ICreateAccountViewProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupCredentials);
  }, []);

  const getPasswordStrengthMessages = (password: string) => {
    const strengthMessages: Array<{message: string; passed: boolean}> = [];

    strengthMessages.push({
      message: '8 - x characters',
      passed: !!password && password.length >= 8,
    });
    strengthMessages.push({
      message: 'At least 1 special character',
      passed: !!password && /[\W_]/.test(password),
    });

    return strengthMessages;
  };

  const onChangeCheckBox = () => {
    const checkboxData = !isChecked;
    setIsChecked(checkboxData);
    !checkboxData ? setError('This field is required!') : setError('');
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupAccountInfoConfirmCheckboxPressed,
      params: {
        state: checkboxData,
      },
    });
  };

  const str2 = (values) => (
    <React.Fragment>
      Your password must contain:
      <br />
      {getPasswordStrengthMessages(values.password).map((x, index) => (
        <Typography key={String(index)} style={{color: x.passed ? '#00859a' : '#FF3E55'}}>
          {x.passed ? (
            <Icon style={{fontSize: 18, marginBottom: -4}}>check</Icon>
          ) : (
            <Icon style={{fontSize: 18, marginBottom: -4}}>close</Icon>
          )}
          &nbsp;{x.message}
        </Typography>
      ))}
    </React.Fragment>
  );

  return (
    <StepContainerCopy progress={false} currentStep={7} showOverflow={true}>
      <Formik
        initialValues={{password: '', confirmPassword: ''}}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async (values, actions) => {
          setError('');

          const payload = {
            email: inputData.email,
            challengeId: inputData.emailVerificationChallengeId,
            inviteCode: inputData.orgInviteCode,
            orgName: inputData.orgName,
            userDetails: {
              firstName: inputData.firstName.trim(),
              lastName: inputData.lastName.trim(),
              role: inputData.jobTitle.trim(),
              password: values.password,
            },
          };

          const result = await viewModel.handleNextButtonPressed(payload);

          if (result.error) {
            setError(result.error);
          }
        }}
      >
        {({handleSubmit, values, isValid, handleChange, handleBlur, setFieldValue, touched, errors, isSubmitting}) => (
          <>
            <NewStepHeader
              title="Create password"
              description={str2(values)}
              isDisabled={isSubmitting}
              onBackButtonClick={onBackButtonClick}
            />

            <form onSubmit={handleSubmit}>
              <StyledTextField
                variant="outlined"
                fullWidth={true}
                value={values.password}
                disabled={isSubmitting}
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Minimum 8 characters"
                autoComplete="off"
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password && errors.password}
                error={touched.password && Boolean(errors.password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        onClick={() => setShowPassword((prev) => !prev)}
                        style={{marginRight: -8, color: muiTheme.colors.primary}}
                      >
                        {!showPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <StyledTextField
                variant="outlined"
                fullWidth={true}
                value={values.confirmPassword}
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                autoComplete="off"
                placeholder="Minimum 8 characters"
                margin="normal"
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.confirmPassword && errors.confirmPassword}
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                        style={{marginRight: -8, color: muiTheme.colors.primary}}
                      >
                        {!showConfirmPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormControlLabel
                control={<CustomCheckbox onChange={onChangeCheckBox} />}
                label={'I confirm that all information provided is accurate. (Required)'}
              />

              {Boolean(error) && (
                <Typography color="error" style={{marginTop: 16}}>
                  {error}
                </Typography>
              )}
              <NewNextStepButton
                disabled={!isValid || !isChecked}
                loading={isSubmitting}
                loadingLabel="Creating account..."
                label="Finish"
              >
                Next
              </NewNextStepButton>
            </form>
            <NewStepFooter />
          </>
        )}
      </Formik>
    </StepContainerCopy>
  );
};
