import * as React from 'react';
import moment from 'moment';
import {
  TableCellFlexButtonsContainer,
  TableCellResultContainer,
} from 'src/pages/SchedulingPage/request-layout/TableStyles';
import Button from '@mui/material/Button';
import {ShiftRequestPayload, ShiftRequestStatus} from 'src/types';
import Checkmark from 'src/svgs/Checkmark';
import Closemark from 'src/svgs/Closemark';
import RequestActionModal from 'src/pages/SchedulingPage/request-layout/RequestActionModal';
import {RequestShiftModalType} from 'src/types';

interface Props {
  isSelf: boolean;
  isPending: boolean;
  shiftRequest: ShiftRequestPayload;
}

const TableActionCell = ({shiftRequest, isSelf, isPending}: Props) => {
  const [confirmationModalActionType, setConfirmationModalActionType] = React.useState<RequestShiftModalType | null>(
    null,
  );

  const {id, status, notes} = shiftRequest;

  const translateStatusToReadable = (status: ShiftRequestStatus) => {
    switch (status) {
      case 'completed':
        return 'Accepted';

      case 'cancelled':
        return 'Cancelled';

      case 'declined':
        return 'Declined';

      default:
        return 'Not applicable';
    }
  };

  if (!isPending) {
    const targetNotes = notes ? notes.filter((note) => note.status === status) : [];
    const lastestNote =
      targetNotes.length > 0
        ? targetNotes.reduce((acc, cur) => (new Date(acc.createdAt) > new Date(cur.createdAt) ? acc : cur))
        : null;

    return (
      <TableCellResultContainer>
        <div>{status === 'completed' ? <Checkmark color={'green'} /> : <Closemark />}</div>
        <div>
          <span>
            {translateStatusToReadable(status)} on {moment(shiftRequest.updatedAt).format('MMM DD, YYYY [at] h:mm a')}
          </span>
          {lastestNote && <p>"{lastestNote.note}"</p>}
        </div>
      </TableCellResultContainer>
    );
  }

  const shouldModalOpen = Boolean(document.getElementById(`action-cell-${id}`)) && Boolean(confirmationModalActionType);

  const handleCloseModal = () => {
    if (shouldModalOpen) setConfirmationModalActionType(null);
  };

  return (
    <TableCellFlexButtonsContainer id={`action-cell-${id}`}>
      {isSelf ? (
        <Button variant="outlined" disableTouchRipple onClick={() => setConfirmationModalActionType('cancel')}>
          Withdraw request
        </Button>
      ) : (
        <React.Fragment>
          <Button variant="outlined" disableTouchRipple onClick={() => setConfirmationModalActionType('decline')}>
            Decline
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disableTouchRipple
            onClick={() => setConfirmationModalActionType('accept')}
          >
            Accept
          </Button>
        </React.Fragment>
      )}
      {Boolean(confirmationModalActionType) && (
        <RequestActionModal
          isOpen={shouldModalOpen}
          closeModal={handleCloseModal}
          shiftRequest={shiftRequest}
          actionType={confirmationModalActionType}
        />
      )}
    </TableCellFlexButtonsContainer>
  );
};

export default TableActionCell;
