import * as React from 'react';
import styled from '@emotion/styled';
import {WideWidthStyleModal, Header} from 'src/styles/styled-components/ModalStyleComponents';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {AuthContext} from 'src/auth/AuthProvider';
import trySwitchOrganization from 'src/utils/organizationHelper/trySwitchOrganization';
import ApiHelper from 'src/api';
import {UserOrganizationSwitcherPayload} from 'src/types';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {PREVIOUS_ORGANIZATION} from 'src/constants/storageKeys';

const EulaLabelHolder = styled.span`
  a {
    padding-left: 5px;
    font-weight: bold;
    color: inherit;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  .MuiFormControlLabel-root span {
    user-select: none;
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSubmitButton = styled(Button)``;
const StyledCancelButton = styled(Button)`
  margin-right: 6px !important;
  font-weight: bold !important;
`;

const AgreementExplaination = styled.div`
  color: grey;
  font-size: 14px;
  margin-top: 1em;
  p {
    margin: 0;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 0;
`;

const AcceptTOSModal = ({isOpen, closeModal, tosURL, currentOrganization, logout}) => {
  const [isAgreementChecked, setAgreementChecked] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  React.useEffect(() => {
    document.body.className += ' blured';
    return () => {
      document.body.classList.remove('blured');
      sessionStorage.removeItem(PREVIOUS_ORGANIZATION);
    };
  }, []);

  async function onAgreementSubmit() {
    setLoading(true);
    setErrorMsg('');
    const {organizationId} = currentOrganization as UserOrganizationSwitcherPayload;
    const result = await ApiHelper.PrivateEndpoints.acceptingTOS(organizationId ?? 0);
    if (result.success) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.agreeTOS,
        params: {
          organization_id: organizationId,
        },
      });
      closeModal();
    } else {
      setLoading(false);
      setErrorMsg('Failed to accept terms of service, please check your internet connection and try again');
    }
  }

  function onCancelAgreement() {
    const previousOrganization = sessionStorage.getItem(PREVIOUS_ORGANIZATION);

    if (
      Boolean(previousOrganization) &&
      currentOrganization.organizationId !== JSON.parse(previousOrganization!).organizationId
    ) {
      trySwitchOrganization(JSON.parse(previousOrganization!));
    } else {
      if (window.confirm('You will be logged out, are you sure?')) {
        sessionStorage.removeItem(PREVIOUS_ORGANIZATION);
        logout();
        window.location.reload();
      }
    }
  }

  return (
    <WideWidthStyleModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeModal}
    >
      <Header>
        <span>Terms of Service</span>
      </Header>

      <AgreementExplaination>
        <p>
          By clicking 'SUBMIT', you agree to the Terms of Service set out by the organization. You also grant your
          organization ability to manage your account.
        </p>
      </AgreementExplaination>

      <FlexContainer>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAgreementChecked}
              disabled={isLoading}
              onChange={() => setAgreementChecked((prevState) => !prevState)}
            />
          }
          label={
            <EulaLabelHolder>
              &nbsp; I agree to these
              <a href={tosURL} rel="noopener noreferrer" target="_blank">
                terms and conditions
              </a>
              &nbsp;
            </EulaLabelHolder>
          }
        />
        <ButtonContainer>
          <StyledCancelButton onClick={onCancelAgreement} disabled={isLoading}>
            cancel
          </StyledCancelButton>
          <StyledSubmitButton
            variant="contained"
            color="secondary"
            disabled={!isAgreementChecked || isLoading}
            onClick={onAgreementSubmit}
          >
            {isLoading ? <CircularProgress size={20} /> : 'submit'}
          </StyledSubmitButton>
        </ButtonContainer>
      </FlexContainer>

      {Boolean(errorMsg) && <ErrorMessage>{errorMsg}</ErrorMessage>}
    </WideWidthStyleModal>
  );
};

export default (props) => (
  <AuthContext.Consumer>{({logout}) => <AcceptTOSModal {...props} logout={logout} />}</AuthContext.Consumer>
);
