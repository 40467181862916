import * as React from 'react';
import {transformMarketplaceShiftsToViewModel} from 'src/utils/scheduling/transformResponseToViewModel';
import {QueryResult} from 'react-apollo';
import {ScheduleViewModelData} from 'src/types';

interface Props {
  fetchMarketPlaceShiftsResult: QueryResult<any>;
  currentDepartmentId: number;
}

const useMarketplaceViewModelData = ({fetchMarketPlaceShiftsResult, currentDepartmentId}: Props) => {
  const {loading, error, variables, data} = fetchMarketPlaceShiftsResult;

  const viewModelScheduleData: ScheduleViewModelData | null = React.useMemo(() => {
    if (loading || error || !data || !data.locating || !variables) {
      return null;
    }

    const {startDate, endDate} = variables.marketplaceFilter;
    const {marketplacePosts} = data.locating?.department;
    let {roles} = data.locating?.department;

    if (data.locating?.department?.schedules && data.locating?.department?.schedules?.[0]) {
      roles = data.locating?.department?.schedules?.[0].roles;
    }

    const scheduleData = transformMarketplaceShiftsToViewModel({
      roles,
      startDate,
      endDate,
      marketplacePosts,
      preventSplitCrossDay: true,
    });

    return scheduleData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDepartmentId, variables, data]);

  return viewModelScheduleData;
};

export default useMarketplaceViewModelData;
