import * as React from 'react';
import styled from '@emotion/styled';
import GridCellAssignee from 'src/pages/SchedulingPage/action-calendar/GridCellAssignee';
import {typedUseSelector, AppDispatch} from 'src/redux';
import {useDispatch} from 'react-redux';

const EmptyStatePlaceHolder = styled.div`
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: lightgrey;
  line-height: 40px;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 5px;
  user-select: none;
  border: solid 1px ${(props) => props.theme.colors.borderBottomLight};
`;

interface Props {
  isSelf: boolean;
  userToColorCodes: {
    [userId: string]: string;
  };
}

const FilterShiftBlock = ({isSelf, userToColorCodes}: Props) => {
  const selfShift = typedUseSelector((state) => state.tradeShift.selfShift);
  const otherShift = typedUseSelector((state) => state.tradeShift.otherShift);

  const dispatch: AppDispatch = useDispatch();

  const handleClearSelf = () => {
    dispatch({type: 'tradeShift/CLEAR_SELF'});
  };

  const handleClearOther = () => {
    dispatch({type: 'tradeShift/CLEAR_OTHER'});
  };

  const renderEmptyState = () => <EmptyStatePlaceHolder>Pick a shift from the calendar</EmptyStatePlaceHolder>;

  if (isSelf) {
    return selfShift ? (
      <GridCellAssignee
        timeformat="MMM DD, YYYY / HH:mm"
        showOfferTypes={false}
        isSwap={false}
        isSelf={true}
        assignee={selfShift.assignee}
        role={selfShift.role}
        onCellClick={handleClearSelf}
        userToColorCodes={userToColorCodes}
      />
    ) : (
      renderEmptyState()
    );
  }

  return otherShift ? (
    <GridCellAssignee
      timeformat="MMM DD, YYYY / HH:mm"
      showOfferTypes={false}
      isSwap={false}
      isSelf={false}
      assignee={otherShift.assignee}
      role={otherShift.role}
      onCellClick={handleClearOther}
      userToColorCodes={userToColorCodes}
    />
  ) : (
    renderEmptyState()
  );
};

export default FilterShiftBlock;
