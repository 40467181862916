import React from 'react';
import hexToRgb from '../../utils/scheduling/hexToRgba';
import {
  RoleHolderWrapper,
  RoleHolderDetail,
  RoleHolderTitle,
  RoleHolderTitleName,
  RoleHolderTitleTime,
  RoleIconHolder,
  MarketplaceIconHolder,
  SwapShiftIconHolder,
} from './ActionCalendarGridSystem';
import isEndTimeMinutesBeforeStartTime from '../../utils/scheduling/isEndTimeMinutesBeforeStartTime';
import {ClickAwayListener, Fade, MenuList, Paper, Popper} from '@mui/material';
import {StyledPopper, MenuItem} from './SharedPopperStyles';
import {ScheduleLayoutRouterParams, Assignee} from '../../types';
import SwapShiftIcon from '../../svgs/SwapShiftIcon';
import {GIVEAWAYSHIFTVIEW, SWAPSCHEDULINGVIEW} from '../../constants/scheduler';
import GiveAwayShiftIcon from '../../svgs/GiveAwayShiftIcon';

interface Props {
  assignee: Assignee;
  roleName: string;
  userToColorCodes: {
    [userId: string]: string;
  };
  onCellClick: any;
  menuItem: {
    name: JSX.Element;
    clickHandler: any;
  }[];
  anchorEl: HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  isSelf?: boolean;
  onMouseEnter?: any;
  onMouseLeave?: any;
  params?: ScheduleLayoutRouterParams;
  showOfferTypes?: boolean;
  isSelecting?: boolean;
  shouldGreyOut?: boolean;
}
export function TimeWithNameVariant({
  assignee,
  roleName,
  userToColorCodes,
  onCellClick,
  menuItem,
  anchorEl,
  setAnchorEl,
  isSelf = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  params = undefined,
  showOfferTypes,
  isSelecting = false,
  shouldGreyOut = false,
}: Props) {
  function modalHandleClick(callBackFunction: any) {
    setAnchorEl(null);
    callBackFunction();
  }
  function handleClickAway(e: MouseEvent | TouchEvent) {
    // @ts-ignore
    if (anchorEl && anchorEl.contains(e.target)) return;
    setAnchorEl(null);
  }

  return (
    <div key={`shift-${assignee?.shiftId}`}>
      <RoleHolderWrapper
        shouldGreyOut={Boolean(shouldGreyOut)}
        isSelecting={isSelecting}
        assignedColorBackground={hexToRgb(userToColorCodes[assignee.userId], 0.1)}
        assignedColor={userToColorCodes[assignee.userId]}
        className={`${assignee?.shiftId}`}
        onClick={onCellClick}
        title={assignee.userFullName}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <RoleHolderTitle>
          <RoleHolderTitleName>{isSelf ? 'ME' : assignee.userFullName}</RoleHolderTitleName>
          <RoleHolderTitleTime>
            {`${assignee.startTime.format('HH:mm')}-${assignee.endTime.format('HH:mm')}`}
            {isEndTimeMinutesBeforeStartTime(assignee.startTime, assignee.endTime) && <sup>+1</sup>}
          </RoleHolderTitleTime>
        </RoleHolderTitle>
        <RoleIconHolder>
          <RoleHolderDetail>{roleName}</RoleHolderDetail>
          {showOfferTypes && assignee.offerTypes && (
            <MarketplaceIconHolder>
              {assignee.offerTypes.includes(SWAPSCHEDULINGVIEW) && <SwapShiftIcon />}
              {assignee.offerTypes.includes(GIVEAWAYSHIFTVIEW) && <GiveAwayShiftIcon />}
            </MarketplaceIconHolder>
          )}
          {isSelecting && params?.actionType === SWAPSCHEDULINGVIEW && (
            <SwapShiftIconHolder>
              <SwapShiftIcon />
            </SwapShiftIconHolder>
          )}
        </RoleIconHolder>
      </RoleHolderWrapper>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        className="popper__container"
        placement="bottom-end"
        transition
        disablePortal
      >
        <StyledPopper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom-start'} transition>
          {({TransitionProps}) => (
            <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className="popper__content__wrapper">
                  <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
                    <MenuList>
                      {menuItem.map((item, index) => {
                        return (
                          <MenuItem onClick={() => modalHandleClick(item.clickHandler)} key={index}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </StyledPopper>
      </Popper>
    </div>
  );
}
