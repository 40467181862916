import React from 'react';
import styled from '@emotion/styled';
import {typedUseSelector} from 'src/redux';
import {HCBodyOne, HCBodyTwo} from 'src/components/HypercareComponents';

const RoleDetail = styled.div`
  padding: 20px;
  width: 320px;
  overflow-wrap: break-word;
  position: absolute;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 18px 28px 0px rgba(9, 30, 66, 0.15);
`;

const RoleDetailWrapper = styled.div`
  display: flex;
  gap: 16px;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 24px;
`;

const RoleDetailNoteList = styled.ul`
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  overflow: hidden auto;
  max-height: 400px;
  padding-right: 5px;
`;

const RoleNoteDetailPopper = () => {
  const currentSelectedRole = typedUseSelector((state) => state.scheduling.currentSelectedRole);
  const currentSelectedRoleId = currentSelectedRole?.roleId;
  const roleNotes = typedUseSelector((state) => state.scheduling.roleNotes);

  return (
    <RoleDetail>
      <RoleDetailWrapper>
        <HCBodyOne>{currentSelectedRole ? `'${currentSelectedRole?.roleName}' Notes` : ''}</HCBodyOne>
      </RoleDetailWrapper>
      <RoleDetailNoteList>
        {currentSelectedRoleId &&
          roleNotes[currentSelectedRoleId]?.notes.map((note) => (
            <li key={note.id} style={{marginLeft: '-10px'}}>
              <HCBodyTwo lineHeight={'20px'}>{note.note}</HCBodyTwo>
            </li>
          ))}
      </RoleDetailNoteList>
    </RoleDetail>
  );
};

export default RoleNoteDetailPopper;
