import React, {PureComponent} from 'react';
import styled from '@emotion/styled';
import ProfilePic from 'src/components/ProfilePic';
import {User} from 'src/types';
import {Layout, UserDescription, UserName, UserRole} from 'src/pages/MessengerPage/messenger/StyledUserList';

export const StyledLayout = styled(Layout)`
  max-width: 300px;
`;

interface Props {
  colleague: User;
  onClick?: (colleague: User) => void;
}

class UserListItem extends PureComponent<Props> {
  public render() {
    const {colleague} = this.props;
    const {firstname, role, lastname} = colleague;
    return (
      <StyledLayout onClick={this.onClick}>
        <ProfilePic users={[colleague]} />
        <UserDescription>
          <UserName>
            {firstname} {lastname}
          </UserName>
          <UserRole>{role}</UserRole>
        </UserDescription>
      </StyledLayout>
    );
  }

  private onClick = () => {
    const {onClick, colleague} = this.props;
    if (onClick) {
      onClick(colleague);
    }
  };
}

export default UserListItem;
