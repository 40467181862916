import styled from '@emotion/styled';

export const BackToPreviousLink = styled.div`
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: grey;
  padding-bottom: 1.5em;
  .material-icons {
    font-size: 18px;
  }
  a {
    text-decoration: underline;
    color: grey;
  }
  a:hover {
    color: ${(props) => props.theme.colors.greyishBrown};
  }
`;
