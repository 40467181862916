import React from 'react';
import client from 'src/apollo';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {WideWidthStyleModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import CircularProgress from '@mui/material/CircularProgress';
import {toast} from 'react-toastify';
import Checkmark from 'src/svgs/Checkmark';
import {AcceptShiftRequest, DeclineShiftRequest, WithdrawalShiftRequest} from 'src/gql/mutation/ShiftRequestMutation';
import {
  CustomToastFlexContainer,
  ModalBodyWrapper,
  TextareaWrapper,
  ErrorText,
  ButtonsWrapper,
} from 'src/styles/styled-components/SharedModalStyles';
import {
  RequestRefetchContext,
  RefetchRequestType,
} from 'src/pages/SchedulingPage/request-layout/RequestRefetchProvider';
import {RequestShiftModalType, ShiftRequestPayload} from 'src/types';
import {convertSwapDetailString, convertMarketplaceDetailString} from 'src/utils/scheduling/convertRequestTableData';
import sleep from 'src/utils/sleep';
import {CancelMarketplaceShiftMutation} from 'src/gql/mutation/OfferMarketplaceMutation';
import {
  SWAP_REQUEST_NOT_ACTIVE,
  DESIRED_SHIFT_EXPIRED,
  SURRENDERING_SHIFT_EXPIRED,
  POST_NOT_AVAILABLE,
  POST_SHIFT_EXPIRED,
  CONFLICT_WITH_SURRENDERING_SHIFT,
  CONFLICT_WITH_DESIRED_SHIFT,
} from 'src/constants/networkError';
import RequestDoubleConfirmationModal from './RequestDoubleConfirmationModal';

const HelperTextWrapper = styled.div``;

interface Props {
  isOpen: boolean;
  shiftRequest: ShiftRequestPayload;
  closeModal: () => void;
  actionType: RequestShiftModalType | null;
}

interface PropsWithRefetch extends Props {
  refetch: RefetchRequestType | null;
}

const RequestActionModal = ({isOpen, shiftRequest, closeModal, refetch, actionType}: PropsWithRefetch) => {
  const [noteValue, setNoteValue] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [showDoubleConfirmationModal, setShowDoubleConfirmationModal] = React.useState(false);

  const {id, type: requestType, surrenderingShift, desiredShift} = shiftRequest;
  const isSwap = requestType === 'swap';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteValue(e.target.value);
  };

  const readableActionType = actionType === 'cancel' ? 'withdraw' : actionType;

  const translateActionTypeToMutation = () => {
    switch (actionType) {
      case 'accept':
        return AcceptShiftRequest;
      case 'decline':
        return DeclineShiftRequest;
      case 'cancel':
        return isSwap ? WithdrawalShiftRequest : CancelMarketplaceShiftMutation;
      default:
        return null;
    }
  };

  const handleSendRequest = async () => {
    const mutation = translateActionTypeToMutation();
    try {
      setLoading(true);
      setError('');
      await client.mutate({
        mutation: mutation!,
        variables: {
          note: noteValue,
          [isSwap ? 'requestId' : 'postId']: id,
        },
      });
      // wait for input delay of shift change for user in full calendar's schedule
      await sleep(2000);
      toast.info(CustomizedActionToast());
      refetch?.();
    } catch (e: any) {
      console.error(e);
      setLoading(false);
      if (e.networkError && e.networkError.result && e.networkError.result.errors && e.networkError.result.errors[0]) {
        let errorCodeName = e.networkError.result.errors[0].code;
        switch (errorCodeName) {
          case SWAP_REQUEST_NOT_ACTIVE:
            toast.error('This request is no longer active');
            return refetch?.();
          case DESIRED_SHIFT_EXPIRED:
            toast.error('The desired shift is in the past and is no longer available');
            return refetch?.();
          case SURRENDERING_SHIFT_EXPIRED:
            toast.error('Your shift is in the past and is no longer available');
            return refetch?.();
          case POST_NOT_AVAILABLE:
            toast.error('This marketplace post is no longer available');
            return refetch?.();
          case POST_SHIFT_EXPIRED:
            toast.error('This shift is in the past and is no longer available');
            return refetch?.();
          case CONFLICT_WITH_SURRENDERING_SHIFT:
            setError('Your shift conflicts with the user’s schedule');
            return setShowDoubleConfirmationModal(true);
          case CONFLICT_WITH_DESIRED_SHIFT:
            setError('The desired shift conflicts with your current schedule');
            return setShowDoubleConfirmationModal(true);
          default:
            return toast.error(`Unable to complete your request due to unknown error (${errorCodeName})`);
        }
      } else {
        setError('Failed to send request, please check your internet connection and try again');
      }
    }
  };

  const CustomizedActionToast = () => {
    return (
      <CustomToastFlexContainer>
        <div>
          <Checkmark />
          You have {readableActionType} the request
        </div>
      </CustomToastFlexContainer>
    );
  };

  const helperTextDetails = `You are about to ${readableActionType} ${
    isSwap
      ? convertSwapDetailString(surrenderingShift, desiredShift!)
      : convertMarketplaceDetailString(surrenderingShift, shiftRequest.offerTypes ?? [])
  }`;

  return (
    <React.Fragment>
      <WideWidthStyleModal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnEsc={!isLoading}
        shouldCloseOnOverlayClick={!isLoading}
        onRequestClose={closeModal}
      >
        <Header>
          <span>Confirm {readableActionType}</span>
          {!isLoading && <CloseBtn onClick={closeModal} />}
        </Header>
        <ModalBodyWrapper>
          <HelperTextWrapper>{helperTextDetails}</HelperTextWrapper>

          {isSwap && (
            <TextareaWrapper>
              <TextField
                variant="outlined"
                multiline={true}
                rows={4}
                fullWidth
                autoFocus
                value={noteValue}
                onChange={handleChange}
                label="Add a note (optional)"
              />
            </TextareaWrapper>
          )}

          <ErrorText>{error}</ErrorText>

          <ButtonsWrapper>
            <Button variant="contained" disabled={isLoading} disableTouchRipple onClick={closeModal}>
              cancel
            </Button>

            <Button
              variant="contained"
              onClick={handleSendRequest}
              disabled={isLoading}
              color="secondary"
              disableTouchRipple
            >
              {isLoading ? <CircularProgress size={20} /> : <span>{readableActionType}</span>}
            </Button>
          </ButtonsWrapper>
        </ModalBodyWrapper>
      </WideWidthStyleModal>

      {showDoubleConfirmationModal && isOpen && (
        <RequestDoubleConfirmationModal
          isOpen={showDoubleConfirmationModal && isOpen}
          closeModal={() => setShowDoubleConfirmationModal(false)}
          closeAll={() => {
            setShowDoubleConfirmationModal(false);
            closeModal();
          }}
          refetch={refetch}
          requestId={id}
          note={noteValue}
          warningReason={error}
          customizedToast={CustomizedActionToast}
        />
      )}
    </React.Fragment>
  );
};

export default (props: Props) => (
  <RequestRefetchContext.Consumer>
    {({refetch}) => <RequestActionModal refetch={refetch} {...props} />}
  </RequestRefetchContext.Consumer>
);
