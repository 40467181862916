import styled from '@emotion/styled';

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  min-height: 40px;
  font-family: Nunito;
  font-weight: 600;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;
