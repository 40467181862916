import styled from '@emotion/styled';

export const PageBody = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
`;
export const PageContainer = styled.div`
  width: min-content;
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
`;
