import styled from '@emotion/styled';
import BackIcon from 'src/svgs/BackIcon';
import {withRouter} from 'react-router-dom';
import {Grid} from '@mui/material';
import parseDate from 'src/utils/parseDate';
import {getChatDetailedPrettyReadableDate} from 'src/utils/getPrettyReadableDate';
import {USERS, GROUP, MESSAGES, GROUPS} from 'src/constants/user';
import {MESSENGER} from 'src/constants/routerPathName';
import {MessageInChat} from 'src/types';
import {InitialsPic, ProfilePicImg} from 'src/styles/styled-components/StyledMaterialComponents';
import {getInitials} from 'src/utils/messengerHelper/getChatTitle';
import {getTotalMembers} from 'src/utils/messengerHelper/getTotalMembers';
import ProfilePic from 'src/components/ProfilePic';

const PREFIX = 'MessageItem';

const classes = {
  container: `${PREFIX}-container`,
  backIcon: `${PREFIX}-backIcon`,
  backText: `${PREFIX}-backText`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.container}`]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },

  [`& .${classes.backIcon}`]: {
    padding: '11px 2px 0px 12px !important',
    flexBasis: '4%',
  },

  [`& .${classes.backText}`]: {
    paddingTop: '9px !important',
  },
}));

const ChatTypeText = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.blackFontColor};
  padding: 24px 16px 8px 16px;
`;

const ChatContainer = styled.div<{title: string; isChatActive: boolean; isJumpToMessageEnable: boolean}>`
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 16px;
  cursor: ${(props) => (!props.isJumpToMessageEnable && props.title === MESSAGES ? `no-drop` : `pointer`)};
  user-select: none;
  position: relative;
  color: ${(props) => props.theme.colors.blackFontColor};
  background-color: ${(props) => {
    if (props.isChatActive) {
      return props.theme.colors.lightGrey;
    }
    return 'white';
  }};
  &:hover {
    background: #f6f6f9;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 5px;
`;

const RightSection = styled.div`
  flex: 5;
  min-width: 0;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

const MessageDetails = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatTitle = styled.div`
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

const MessageContent = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  min-width: 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.5em;
  text-overflow: ellipsis;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  font-weight: normal;
  color: ${(props) => props.theme.colors.blackFontColor};
  }};
`;

const Time = styled.div`
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.blackFontColor};
`;

const BackMessage = styled.div`
  cursor: pointer;
`;

const ViewAll = styled.div`
  float: right;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #00859a;
  cursor: pointer;
`;

const MessageItem = (props) => {
  const {
    chats,
    title,
    type,
    showMatchedMessage,
    createChat,
    isBack,
    setIsBack,
    handleJumpToMessage,
    handleViewAll,
    totalUsersCount,
    totalGroupsCount,
    totalMessageCount,
    selectedChat,
    setSelectedChat,
    selectedChatTitle,
    setSelectedChatTitle,
    activeChat,
    handleSetActiveChat,
    isJumpToMessageEnable,
  } = props;

  const messageCount = title === USERS ? totalUsersCount : title === GROUPS ? totalGroupsCount : totalMessageCount;

  const handleMessageClick = (chats) => {
    const {chat, searchMessages} = chats;
    const {history} = props;
    const title = getTotalMembers(chat, 16, true);
    showMatchedMessage(chats);
    setSelectedChat(chat);
    setSelectedChatTitle(title);
    setIsBack(true);
    if (searchMessages.messages.length > 0) {
      var chatListScrollContainer = document.getElementById('chatListScrollContainer');
      chatListScrollContainer?.scrollTo({top: 0, behavior: 'smooth'});
      history.push(`/${MESSENGER}/${selectedChat ? selectedChat.id : chat.id}`);
      handleJumpToMessage(searchMessages.messages[0]);
      handleSetActiveChat(searchMessages.messages[0].message.id);
    }
  };

  const openAndScrollMessage = (message: MessageInChat) => {
    if (isJumpToMessageEnable) {
      handleJumpToMessage(message);
      handleSetActiveChat(message.message.id);
    }
  };

  const isActiveSearchChat = (chat, title) => {
    const id = title === MESSAGES && isBack ? chat.message.id : chat.id;

    return activeChat === id ? true : false;
  };

  return (
    <Root>
      {chats?.length > 0 && (
        <ChatTypeText>
          {isBack ? (
            <BackMessage
              onClick={() => {
                setSelectedChat(null);
                setSelectedChatTitle('');
                setIsBack(false);
                handleJumpToMessage(null);
              }}
            >
              <Grid container spacing={3}>
                <Grid className={classes.backIcon} item xs={3}>
                  <BackIcon />
                </Grid>
                <Grid className={classes.backText} item xs={8}>
                  Back
                </Grid>
              </Grid>
            </BackMessage>
          ) : (
            <>
              <div>
                {`${title} (${messageCount})`}
                {title !== MESSAGES && <ViewAll onClick={() => handleViewAll(title)}>View all</ViewAll>}
              </div>
            </>
          )}
        </ChatTypeText>
      )}
      {chats.map((searchChat, i) => {
        const chat =
          type === GROUP || title === MESSAGES ? (selectedChat ? searchChat : searchChat.chat) : searchChat.user;
        const charLimit = isBack ? 10 : 24;
        const isGroupChat = type === GROUP;
        const messageType = type ? type : isBack ? selectedChat.type : chat.type;
        const lastMessage = selectedChat ? chat : '';
        const chatTitle =
          type === GROUP || title === MESSAGES
            ? selectedChat
              ? selectedChatTitle
                ? selectedChatTitle
                : selectedChat.title
              : getTotalMembers(chat, charLimit, true)
            : `${chat.firstName} ${chat.lastName}`;
        const id = title === MESSAGES && selectedChat ? chat.message.id : chat.id;
        const isChatActive = isActiveSearchChat(chat, title);
        const getInitialTitle = title === MESSAGES ? (isBack ? selectedChat.members[0] : chat.members[0]) : chat;
        const chatImg = title === USERS ? chat.avatar?.url : selectedChat ? selectedChat.image?.url : chat.image?.url;
        return (
          <ChatContainer
            id={`search-${id}`}
            key={i}
            title={title}
            isChatActive={isChatActive}
            isJumpToMessageEnable={isJumpToMessageEnable}
            onClick={() => {
              showMatchedMessage
                ? title === MESSAGES && isBack
                  ? openAndScrollMessage(lastMessage)
                  : handleMessageClick(searchChat)
                : title === USERS
                ? type === GROUP
                  ? createChat(chat)
                  : window.routerHistory.push(`/${MESSENGER}/user/${chat.id}`)
                : window.routerHistory.push(`/${MESSENGER}/${chat.id}`);
              title !== MESSAGES && handleSetActiveChat(id);
            }}
          >
            <LeftSection>
              {chatImg ? (
                <ProfilePic users={[chat]} chatImageUrl={chatImg} />
              ) : messageType === GROUP ? (
                <ProfilePicImg size={45} src={'/assets/group-chat.svg'} />
              ) : (
                <InitialsPic size={45} fontSize={14}>
                  {getInitials(getInitialTitle)}
                </InitialsPic>
              )}
            </LeftSection>
            <RightSection>
              <MessageDetails>
                <ChatTitle>{chatTitle}</ChatTitle>
                {title === MESSAGES && isBack && (
                  <>
                    <Time>
                      {lastMessage ? getChatDetailedPrettyReadableDate(parseDate(lastMessage.message.dateCreated)) : ''}
                    </Time>
                  </>
                )}
                {title === MESSAGES && !isBack ? '' : <></>}
              </MessageDetails>
              <MessageContent>
                <div>
                  {title === MESSAGES && !isBack
                    ? `${searchChat?.searchMessages?.totalResultsCount} matches`
                    : title !== MESSAGES
                    ? type === GROUP
                      ? getTotalMembers(chat, charLimit, false)
                      : chat.role
                    : lastMessage.message.message}
                </div>
              </MessageContent>
            </RightSection>
          </ChatContainer>
        );
      })}
    </Root>
  );
};

export default withRouter(MessageItem);
