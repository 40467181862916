import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';

const StyledTableHead = styled(TableHead)`
  background-color: ${(props) => props.theme.colors.lightGrey};
`;

const TableHeader = () => {
  return (
    <StyledTableHead>
      <TableRow>
        <TableCell align="left">Request details</TableCell>
        <TableCell align="left" valign="middle">
          Date requested
          <span className="material-icons">arrow_downward</span>
        </TableCell>
        <TableCell align="left">Requested by</TableCell>
        <TableCell align="left">Reason for request</TableCell>
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default TableHeader;
