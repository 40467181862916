import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import {HCLabelThree} from './HypercareComponents';
import {Moment} from 'moment';
import {RefreshIcon} from '../svgs/RefreshIcon';
import {ON_CALL_PAGE_LAST_REFRESHED_AT_} from '../constants/strings';
const THIRTY_SECONDS = 30000;

const useKeepNowUpToDate = (setNow: (value: ((prevState: Moment) => Moment) | Moment) => void) => {
  useEffect(() => {
    const t = setInterval(() => {
      setNow(moment());
    }, THIRTY_SECONDS);
    return () => {
      clearInterval(t);
    };
  }, [setNow]);
};

const UpdatedLabelContainer = styled.span`
  align-items: center;
  display: flex;
  & > svg {
    margin-right: 4px;
  }
`;

const IconWrapper = styled.span`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const UpdatedLabel = ({lastUpdated}: {lastUpdated: Moment}) => {
  const [now, setNow] = useState(moment());

  useKeepNowUpToDate(setNow);

  return (
    <UpdatedLabelContainer>
      <IconWrapper>
        <RefreshIcon />
      </IconWrapper>
      <HCLabelThree>
        {ON_CALL_PAGE_LAST_REFRESHED_AT_} {lastUpdated.fromNow()}
      </HCLabelThree>
    </UpdatedLabelContainer>
  );
};
