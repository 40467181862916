import React from 'react';
import styled from '@emotion/styled';
import client from 'src/apollo';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import {JOINORGANIZATION, MESSENGER} from 'src/constants/routerPathName';
import HRtext from 'src/styles/styled-components/HRtext';
import Button from '@mui/material/Button';
import {organizationNameSchema} from 'src/utils/validations';
import {Formik} from 'formik';
import StepFormErrorMessage, {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import InputLabel from '@mui/material/InputLabel';
import * as Yup from 'yup';
import ApiHelper from 'src/api';
import {GetUserWithPhoneAndEmail} from 'src/gql/query/GetUserByIds';
import {AuthContext} from 'src/auth';
import {ORG_NAME_ALREADY_EXIST, ORG_DOMAIN_ALREADY_EXIST} from 'src/constants/networkError';
import {UserOrganizationSwitcherPayload, UserAddress} from 'src/types';
import sleep from 'src/utils/sleep';
import trySwitchOrganization from 'src/utils/organizationHelper/trySwitchOrganization';
import {IS_FINISHING_CREATE_ORGANIZATION} from 'src/constants/storageKeys';
import {muiTheme} from 'src/styles/theme';

const OverridenStyledModal = styled(StyledModal)`
  z-index: 99;
  overflow-y: unset !important;
  button {
    height: 36px;
    width: 100%;
    margin: 0 auto;
  }
`;

const StyledHeader = styled(Header)`
  margin-left: 2%;
`;

const GreetingText = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

const StyledButtonHolder = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 1em;
`;

const ContentWrapper = styled.div`
  padding: 0 2%;
  position: relative;
  padding-bottom: 20px;
`;

type customErrorString = 'name' | 'domain' | 'address' | 'unknown error';

const CreateOrgForm = ({handleModalFormSubmission}) => {
  return (
    <Formik
      initialValues={{
        organizationName: '',
      }}
      validationSchema={Yup.object().shape({
        organizationName: organizationNameSchema,
      })}
      onSubmit={(values, {resetForm, setFieldError, setSubmitting}) => {
        handleModalFormSubmission(values.organizationName).catch((e) => {
          switch (e as customErrorString) {
            case 'name':
              setSubmitting(false);
              setFieldError(
                'organizationName',
                `organization already exists for your institution's email, please click join existing organization button instead`,
              );
              break;
            case 'domain':
              setSubmitting(false);
              setFieldError(
                'organizationName',
                `organization already exists for your institution's email, please click join existing organization button instead`,
              );
              break;
            case 'address':
              setSubmitting(false);
              setFieldError(
                'organizationName',
                'no email associated with your account, please click join existing organization button instead',
              );
              break;
            case 'unknown error':
              setSubmitting(false);
              setFieldError('organizationName', 'failed to create organization, please try again');
              break;
            default:
              resetForm();
          }
        });
      }}
    >
      {(props) => {
        const {values, errors, isSubmitting, handleChange, handleSubmit} = props;

        const hasError = Object.keys(errors).length > 0;

        return (
          <form onSubmit={handleSubmit} className="modalForm">
            <StyledFormControl disabled={isSubmitting}>
              <InputLabel
                shrink
                htmlFor="organizationName"
                style={hasError ? undefined : {color: muiTheme.colors.greyishBrown}}
                error={hasError}
              >
                Organization name
              </InputLabel>
              <StyledInputBase
                fullWidth
                autoFocus
                id="organizationName"
                name="organizationName"
                placeholder="Enter organization name here"
                value={values.organizationName}
                onChange={handleChange}
                error={hasError}
              />
              <StepFormErrorMessage name="organizationName" />
            </StyledFormControl>
            <StyledButtonHolder>
              <Button
                type="submit"
                disabled={hasError || isSubmitting}
                disableTouchRipple
                variant="contained"
                color="secondary"
              >
                {isSubmitting ? 'Creating...' : 'Create'}
              </Button>
            </StyledButtonHolder>
          </form>
        );
      }}
    </Formik>
  );
};

const JoinOrgModal = ({isOpen, authInfo, closeModal}) => {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [organizationExistForEmail, setOrganizationExistForEmail] = React.useState<string>('');

  async function handleCreateAndJoinOrganization(name) {
    setLoading(true);
    setOrganizationExistForEmail('');
    try {
      const {users} = await client.readQuery({
        query: GetUserWithPhoneAndEmail,
        variables: {ids: [authInfo.user.id]},
      });
      const {addresses} = users[0];
      if (!addresses) throw {errors: ['address']};
      const firstEmailAddresss: UserAddress = addresses.find((address) => address.type === 'email');
      if (!firstEmailAddresss) throw {errors: ['address']};

      const email = firstEmailAddresss.address;
      setOrganizationExistForEmail(email);

      const result = await ApiHelper.PrivateEndpoints.createOrganization({
        name,
      });

      if (result.success) {
        const newOrg = result.data.createNewOrganization;
        const newOrganizationOption = {
          name: newOrg.name,
          image: newOrg.image,
          type: 'Organization',
          organizationId: newOrg.id,
        } as UserOrganizationSwitcherPayload;

        // wait for potential replication delay
        await sleep(2000);

        sessionStorage.setItem(IS_FINISHING_CREATE_ORGANIZATION, '1');
        trySwitchOrganization(newOrganizationOption);
        window.location.assign(`/${MESSENGER}/home`);
      } else {
        if (result.error && result.error.response && result.error.response.data && result.error.response.data.errors) {
          if (result.error.response.data.errors[0].code === ORG_DOMAIN_ALREADY_EXIST) {
            throw {errors: ['domain']};
          }
          if (result.error.response.data.errors[0].code === ORG_NAME_ALREADY_EXIST) {
            throw {errors: ['name']};
          }
        }
        throw new Error('unknown error');
      }
    } catch (e: any) {
      setLoading(false);
      if (e.errors && e.errors[0]) return Promise.reject(e.errors[0]);
      console.error(e);
      return Promise.reject('unknown error');
    }
  }

  return (
    <OverridenStyledModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeModal}
    >
      <StyledHeader>
        <span>Create an organization on Hypercare</span>
        <CloseBtn onClick={closeModal} />
      </StyledHeader>

      <ContentWrapper>
        <GreetingText>
          Looks like you are the first one! Create an organization so your colleagues can join you.
        </GreetingText>

        <CreateOrgForm handleModalFormSubmission={handleCreateAndJoinOrganization} />

        <HRtext data-content="OR" />
        <Button
          disableTouchRipple
          variant="outlined"
          color="secondary"
          disabled={isLoading}
          onClick={() => {
            organizationExistForEmail
              ? window.routerHistory.push(`/${JOINORGANIZATION}/?email=${organizationExistForEmail}`)
              : window.routerHistory.push(`/${JOINORGANIZATION}`);
            closeModal();
          }}
        >
          Join Existing Organization
        </Button>
      </ContentWrapper>
    </OverridenStyledModal>
  );
};

export default (props) => (
  <AuthContext.Consumer>{({authInfo}) => <JoinOrgModal {...props} authInfo={authInfo} />}</AuthContext.Consumer>
);
