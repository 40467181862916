import styled from '@emotion/styled';

export const StyledLabelOptionContainer = styled.span`
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;

export const TD = styled.td`
  width: 120px;
  padding: 8px;
  border-bottom: 1px solid #d8d8d8;
  color: #222;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  height: 41px;
`;

export const StyledLabelOption = ({
  text,
  handleLabelOptionClick,
}: {
  text: string;
  handleLabelOptionClick: () => void;
}) => {
  return <StyledLabelOptionContainer onClick={handleLabelOptionClick}>{text}</StyledLabelOptionContainer>;
};
