import ApiHelper from 'src/api';
import {AuthHelper} from 'src/auth';

import {
  CreateUserPayload,
  RequestVerificationPayload,
  SSOProfileForUserRequest,
  SSOProfileType,
  CreateOrganizationPayload,
  VerifyAddressValidationPayload,
} from 'src/types';

interface resultObject {
  success: boolean;
  data?: any | undefined;
  error?: any | undefined;
}

type addressType = 'email' | 'phoneNumber';

class HypercareSignupRepository {
  SSOConfiguration: Promise<SSOProfileType[]> | null;

  requestAddressToken = async (address: string, type: addressType) => {
    const requestVerificationPayload: RequestVerificationPayload = {
      address,
      type,
    };

    const requestVerificationResult = await ApiHelper.PrivateEndpoints.addressVerificationRequest(
      requestVerificationPayload,
    );

    return requestVerificationResult;
  };

  verifyAddressToken = async (challengeId: string, token: string) => {
    const verificationResult = await ApiHelper.PrivateEndpoints.ValidateAddressVerification(challengeId, token);
    return verificationResult;
  };

  requestAddressTokenSTA = async (address: string, type: addressType) => {
    const requestVerificationPayload: RequestVerificationPayload = {
      address,
      type,
    };

    const requestVerificationResult = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA(
      requestVerificationPayload,
    );

    return requestVerificationResult;
  };

  verifyAddressTokenSTA = async (challengeId: string, token: string) => {
    const verificationResult = await ApiHelper.PrivateEndpoints.validateAddressVerificationSTA(challengeId, token);
    return verificationResult;
  };

  checkIfAddressIsSSO = async (email: string) => {
    try {
      const result = await ApiHelper.PublicEndpoints.getSSODomainsForUsername(email);
      return result;
    } catch (err) {
      return {success: false, data: undefined, error: err};
    }
  };

  createUserAccount = async (payload: CreateUserPayload) => {
    const createUserAccountResult = await ApiHelper.PublicEndpoints.createUser(payload);
    return createUserAccountResult;
  };

  createSTAUserAccount = async (payload: CreateUserPayload) => {
    const createUserAccountResult = await ApiHelper.PublicEndpoints.createUserSTA(payload);
    return createUserAccountResult;
  };

  createSTAOrg = async (payload: CreateOrganizationPayload) => {
    const createUserAccountResult = await ApiHelper.PublicEndpoints.createOrganization(payload);
    return createUserAccountResult;
  };

  fetchAuthInfo = async (username: string, password: string) => {
    const authResult: resultObject = await AuthHelper.fetchAuthInfo(username, password);

    return authResult;
  };

  acceptEula = async (accessToken: string) => {
    const acceptEulaResult = await ApiHelper.PrivateEndpoints.acceptEula(accessToken);

    return acceptEulaResult;
  };

  //
}

export default HypercareSignupRepository;
