import {
  SIGN_UP_PAGE_VERIFY_EMAIL_ERROR_MESSAGE,
  SIGN_UP_PAGE_VERIFY_EMAIL_UNKOWN_ERROR_MESSAGE,
} from 'src/constants/strings';
import HypercareSignupRepository from '../HypercareSignupRepository';

interface resultObject {
  data?: any | undefined;
}
type addressType = 'email' | 'phoneNumber';

class SignupVerifyAddressViewModel {
  repository: HypercareSignupRepository;
  nextButtonPressed;
  private type: addressType = 'email';

  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async ({challengeId, token}) => {
    // * ! this should only happen when its successful ----- this.nextButtonPressed(values);

    const verifyCode = await this.verifyEmailValidationCode({challengeId, token});

    if (verifyCode?.data?.response) {
      this.nextButtonPressed({challengeId});
    } else {
      if (verifyCode.data?.errors && verifyCode.data.errors[0]) {
        return {
          success: false,
          error: {
            code: verifyCode.data.errors[0].name,
            message: verifyCode.data.errors[0].message,
          },
        };
      }
      return {
        success: false,
        error: {
          code: verifyCode.data?.errors?.[0].name,
          message: SIGN_UP_PAGE_VERIFY_EMAIL_UNKOWN_ERROR_MESSAGE,
        },
      };
    }
  };

  verifyEmailValidationCode = async ({challengeId, token}) => {
    const result = await this.repository?.verifyAddressTokenSTA(challengeId, token);
    return result;
  };

  resendEmailValidationCode = async (email) => {
    const result = await this.repository?.requestAddressToken(email, this.type);
    return result;
  };
}

export default SignupVerifyAddressViewModel;
